// import React from 'react'

function PersonIcon({
  fill,
  width,
  height,
  onClick
}: {
  fill?: string
  width?: number
  height?: number
  onClick?: Function
}) {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        aria-hidden="true"
        onClick={() => (onClick ? onClick() : () => {})}
        role={onClick ? 'button' : 'img'}
        width={width ?? 24}
        height={height ?? 24}
        preserveAspectRatio="xMidYMid meet"
        viewBox="0 0 16 16"
      >
        <path
          fill={fill || '#ffffff'}
          d="M3 14s-1 0-1-1s1-4 6-4s6 3 6 4s-1 1-1 1H3zm5-6a3 3 0 1 0 0-6a3 3 0 0 0 0 6z"
        />
      </svg>
    </>
  )
}

export default PersonIcon
