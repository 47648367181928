export const CONNECT_WALLET_SUCCESS = 'CONNECT_WALLET_SUCCESS'
export const CONNECT_WALLET_FAIL = 'CONNECT_WALLET_FAIL'
export const CANCEL_CONNECT_WALLET = 'CANCEL_CONNECT_WALLET'
export const CONNECT_WALLET_CLEAR_MESSAGE = 'CONNECT_WALLET_CLEAR_MESSAGE'

export const SWITCH_CHAIN_SUCCESS = 'SWITCH_CHAIN_SUCCESS'
export const SWITCH_CHAIN_FAIL = 'SWITCH_CHAIN_FAIL'
export const SWITCH_ACCOUNT_SUCCESS = 'SWITCH_ACCOUNT_SUCCESS'
export const SWITCH_ACCOUNT_FAIL = 'SWITCH_ACCOUNT_FAIL'

export const ADD_CHAIN_SUCCESS = 'ADD_CHAIN_SUCCESS'
export const ADD_CHAIN_FAIL = 'ADD_CHAIN_FAIL'
export const ADD_CHAIN_CLEAR_MESSAGE = 'ADD_CHAIN_CLEAR_MESSAGE'
