// import React from 'react'

function MoonIcon({
  fill,
  width,
  height,
  onClick
}: {
  fill: string
  width?: number
  height?: number
  onClick?: Function
}) {
  return (
    <>
      <svg
        onClick={() => (onClick ? onClick() : () => {})}
        role={onClick ? 'button' : 'img'}
        width={width ?? 30}
        height={height ?? 30}
        xmlns="http://www.w3.org/2000/svg"
      >
        <g data-name="Group 50" transform="translate(-192 -363)">
          <circle
            data-name="Ellipse 19"
            cx="15"
            cy="15"
            r="15"
            transform="translate(192 363)"
            fill={fill}
          />
          <g
            data-name="Icon feather-arrow-down"
            fill="none"
            stroke="#fff"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
          >
            <path data-name="Path 83" d="M207.066 372.187v11.759" />
            <path
              data-name="Path 84"
              d="m212.946 378.066-5.879 5.879-5.88-5.879"
            />
          </g>
        </g>
      </svg>
    </>
  )
}

export default MoonIcon
