//id
const CHAIN_ID = 137
//name
const CHAIN_NAME = 'Polygon Mainnet'
//name
const CHAIN_SHORT_NAME = 'Polygon'
//rpc
const RPC = 'https://polygon-rpc.com/'

//blockExplorerUrls
const BLOCK_EXPLORER_URLS = 'https://polygonscan.com'
//native currency name
const NAME_CURRENCY = 'MATIC Coin'
//symbol
const NATIVE_CURRENCY_NAME = 'MATIC'
//token price\
const TOKEN_PRICE = 'USDC'
//decimal
const DECIMAL = 18
//gas
const GAS_PRICE = 3

//img
const IMAGE_CHAIN =
  'https://storage.googleapis.com/chomchob/production/public/migrate/logo/2cc9a9f6296a47ce9c183d61f9124e40'

export const polygon = {
  chainId: CHAIN_ID,
  chainName: CHAIN_NAME,
  shortName: CHAIN_SHORT_NAME,
  nativeCurrency: {
    name: NAME_CURRENCY,
    symbol: NATIVE_CURRENCY_NAME,
    decimals: DECIMAL
  },
  rpcURL: RPC,

  blockExplorerUrls: BLOCK_EXPLORER_URLS,
  chainUrl: BLOCK_EXPLORER_URLS,
  gasPrice: GAS_PRICE,
  imgChain: IMAGE_CHAIN,
  tokenPrice: TOKEN_PRICE
}
