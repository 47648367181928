// import React from 'react'

function MoonIcon({
  fill,
  width,
  height
}: {
  fill: string | undefined
  width?: number
  height?: number
}) {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        aria-hidden="true"
        width={width ?? 50}
        height={height ?? 50}
        viewBox="0 0 24 24"
      >
        <path
          d="M12 3h.393a7.5 7.5 0 0 0 7.92 12.446A9 9 0 1 1 12 2.992z"
          fill="none"
          stroke={fill}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </>
  )
}

export default MoonIcon
