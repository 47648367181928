// Icons
import {
  ThaiFlagIcon,
  EnglishFlagIcon
} from 'components/IntroductionPage/Icons'

export const languageData = [
	{
		value: "en",
		flag_icon: <EnglishFlagIcon />
	},
	{
		value: "th",
		flag_icon: <ThaiFlagIcon />
	}
]