import axios from 'axios'

// Types
import xxsAuthAnonymousTokenServiceTypes from 'services/Auth/AnonymousToken/types'

// Utils
import xxaDevice from 'actions/Utils/Device'

// Configs
import { servicePath } from 'configs'
import xxaPlatform from 'actions/Platform'

const xxsAuthAnonymousTokenService = {
  GetAnonymousToken:
    async (): Promise<xxsAuthAnonymousTokenServiceTypes.GetAnonymousResponse> => {
      let response: xxsAuthAnonymousTokenServiceTypes.GetAnonymousResponse
      let platform = xxaPlatform.GetPlatformBySubdomain()
      try {
        const deviceId = await xxaDevice.GetDeviceId()
        const responseData =
          await axios.get<xxsAuthAnonymousTokenServiceTypes.GetAnonymousResponseSuccess>(
            `${servicePath.service.auth}/v1/tunnel/anonymous-token?sub_domain=${platform}`,
            {
              headers: {
                'device-id': deviceId,
                'accept-language': servicePath.acceptLanguage
              }
            }
          )

        response = {
          status: responseData.status,
          anonymous: responseData.data,
          deviceId: deviceId
        }
      } catch (error: any) {
        const err = error.toJSON()
        if (err.message === 'Network Error') {
          response = {
            status: 500,
            error: err
          }
        } else {
          response = {
            status: error.response.status,
            error: error.response.data
          }
        }
      }
      return response
    }
}

export default xxsAuthAnonymousTokenService
