// import React from 'react'

function ChevronLeftIcon({
  fill,
  width,
  height,
  onClick
}: {
  fill: string
  width?: number
  height?: number
  onClick?: Function
}) {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        aria-hidden="true"
        onClick={() => (onClick ? onClick() : () => {})}
        role={onClick ? 'button' : 'img'}
        width={width ?? 100}
        height={height ?? 100}
        preserveAspectRatio="xMidYMid meet"
        viewBox="0 0 12 12"
      >
        <g fill="none">
          <path
            d="M7.53 2.22a.75.75 0 0 1 0 1.06L4.81 6l2.72 2.72a.75.75 0 0 1-1.06 1.06L3.22 6.53a.75.75 0 0 1 0-1.06l3.25-3.25a.75.75 0 0 1 1.06 0z"
            fill={fill}
          />
        </g>
      </svg>
    </>
  )
}

export default ChevronLeftIcon
