import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Decimal from 'decimal.js'
import { toast } from 'react-toastify'
import ReactImageFallback from 'react-image-fallback'
import styled from 'styled-components'

import {
  Col,
  Row,
  Form,
  Button,
  InputGroup,
  OverlayTrigger,
  Tooltip
} from 'react-bootstrap'

// Types
import xxreStoreTypes from 'redux/types'
import xxsTunnelServiceTypes from 'services/Tunnel/types'
import xxcNavBarTypes from 'components/NavBar/Model'
import xxcModalDetailsHistoryTypes from 'components/HistoryPage/ModalDetailsHistory/Model'

// Actions
import xxaTunnelService from 'actions/TunnelService'
import xxaQueryString from 'actions/QueryString'

//type
import {
  BINANCE_TO_POINT_REQUEST_CLEAR_MESSAGE,
  BINANCE_TO_POINT_REQUEST_FAIL,
  BINANCE_TO_POINT_REQUEST_SUCCESS
} from 'redux/Constants/TunnelService'

import { TUNNEL_GET_CONFIG_SUCCESS } from 'redux/Constants/TunnelService'
import xxcChainModalTypes from 'configs/Model'

// Components
import ModalUserRef from 'components/ConvertPage/ModalUserRef'
import ModalDetailsHistory from 'components/HistoryPage/ModalDetailsHistory'
// import BinancePayModal from 'components/ConvertPage/BinancePayModal'

//icon
import image_icon from 'assets/Svg/icon/image_icon.svg'
import question_icon from 'assets/Svg/icon/question_icon.svg'
import ConvertIcon from 'components/CustomIcon/ConvertIcon'

// Styles
import './style.scss'

let targetInput = ''

const PointToCrypto = () => {
  // Initial
  const dispatch = useDispatch()
  const tunnelType: xxcChainModalTypes.tunnelType = 'binance_to_point'
  const howToUse = 'https://www.chomchob.com/how-to-use/binance-to-ccp'
  const orMoreImage =
    'https://storage.googleapis.com/chomchob/production/public/migrate/others/8d68e061867f44ac9a8e860683ab0f9b'

  // Redux
  const tunnelConfig = useSelector(
    (state: xxreStoreTypes.Props) => state.getConfig
  )
  const nativePrice = useSelector(
    (state: xxreStoreTypes.Props) => state.getTokenPrice
  )
  const themeConfig = useSelector(
    (state: xxreStoreTypes.Props) => state.themeConfig
  )
  const platformChain = useSelector(
    (state: xxreStoreTypes.Props) => state.platformChain
  )
  const getProfile = useSelector(
    (state: xxreStoreTypes.Props) => state.getProfile
  )
  const binanceToPointGetTransaction = useSelector(
    (state: xxreStoreTypes.Props) => state.binanceToPointGetTransaction
  )
  const binanceToPointRequest = useSelector(
    (state: xxreStoreTypes.Props) => state.binanceToPointRequest
  )
  const darkMode = useSelector((state: xxreStoreTypes.Props) => state.darkMode)

  // User Input State
  const [selectedToken, setSelectedToken] =
    useState<xxsTunnelServiceTypes.TunnelToken | null>(null)
  const [selectedChain, setSelectedChain] =
    useState<xxcChainModalTypes.offChainType | null>(null)
  const [tokenAmount, setTokenAmount] = useState<string>('')
  const [ccpAmount, setCcpAmount] = useState<string>('')
  const [dataDetails, setDataDetails] = useState<any>(null)

  // Calculation and Display State
  const [minCcpAmount, setMinCcpAmount] = useState<string>('1')
  const [maxCcpAmount, setMaxCcpAmount] = useState<string>('100000')

  // UI control state
  const [isProcessConvert, setIsProcessConvert] = useState<boolean>(false)
  const [openModalUserRef, setOpenModalUserRef] = useState<boolean>(false)
  const [showDetailTnx, setShowDetailTnx] = useState<boolean>(false)

  // Event Listener
  const onInputCCPAmount = (event: React.ChangeEvent<HTMLInputElement>) => {
    // const regx = /^([1-9][0-9]*|0?)\.?[0-9]*$/
    const value = event.currentTarget.value
    if (!value) {
      setCcpAmount('')
    } else if (value === '0' || value === '00') {
      setCcpAmount('0')
    } else {
      if (/^[\d]*\.?[\d]{0,2}$/.test(value)) {
        if (value.startsWith('.')) {
          setCcpAmount('0.')
        } else if (value.startsWith('0.')) {
          setCcpAmount(value)
        } else {
          setCcpAmount(value.replace(/^0+/, ''))
        }
      }
    }
    targetInput = 'CCP_AMOUNT'
  }

  const onInputTokenAmount = (event: React.FormEvent<HTMLInputElement>) => {
    // const regx = /^([1-9][0-9]*|0?)\.?[0-9]*$/
    const value = event.currentTarget.value
    if (!value) {
      setTokenAmount('')
    } else if (value === '0' || value === '00') {
      setTokenAmount('0')
    } else {
      if (/^[\d]*\.?[\d]{0,8}$/.test(value)) {
        if (value.startsWith('.')) {
          setTokenAmount('0.')
        } else if (value.startsWith('0.')) {
          setTokenAmount(value)
        } else {
          setTokenAmount(value.replace(/^0+/, ''))
        }
      }
    }
    targetInput = 'TOKEN_AMOUNT'
  }

  const onClickConvert = async () => {
    if (selectedChain && selectedToken) {
      try {
        validateConvertParams()
        setIsProcessConvert(true)
        dispatch(
          xxaTunnelService.BinanceToPointRequest(
            selectedToken.symbol,
            tokenAmount
          )
        )
      } catch (error) {
        if (error instanceof Error) {
          toast.error(error.message)
        }
      }
    }
  }

  // Helper function
  const validateConvertParams = () => {
    if (!getProfile.getProfile) {
      throw new Error(`Please sign in before the exchange.`)
    }
    if (Number(ccpAmount) <= 0) {
      throw new Error('Please fill token amount.')
    }
    if (Number(minCcpAmount) > Number(maxCcpAmount)) {
      throw new Error(`${selectedToken?.symbol} is not enough to exchange.`)
    }
    if (Number(ccpAmount) < Number(minCcpAmount)) {
      throw new Error(
        `${
          themeConfig.themeConfig?.config.pointUnit || 'point'
        } amount is lower than minimum.`
      )
    }
    if (Number(ccpAmount) > Number(maxCcpAmount)) {
      throw new Error(
        `${
          themeConfig.themeConfig?.config.pointUnit || 'point'
        } amount is higher than maximum.`
      )
    }
  }

  const setMinMaxCCP = () => {
    const minAmountOut = nativePrice.price?.min_point
    const maxAmountOut = nativePrice.price?.max_point
    setMinCcpAmount(minAmountOut || '330')
    setMaxCcpAmount(maxAmountOut || '990000')
    if (
      Number(nativePrice.price?.min_point) >
        Number(nativePrice.price?.max_point) &&
      selectedToken
    ) {
      toast.error(`${selectedToken?.symbol} is not enough to exchange.`)
    }
  }

  // Format Function
  const formatCurrency = (value: string | number): string => {
    return Intl.NumberFormat('en-US', { maximumSignificantDigits: 21 }).format(
      Number(value)
    )
  }

  const formatBeforeSet = (value: number | string | Decimal, d: number = 2) =>
    new Decimal(value).toFixed(d).replace(/\.?0*$/, '')

  const getChainFormLocalStorage = () => {
    let chainID = window.localStorage.getItem('chainID')
    if (chainID) {
      const chain = platformChain.platformChain?.offChain.find(item => {
        return Number(chainID) === item.chainId
      })
      if (chain) {
        setSelectedChain(chain)
      } else {
        setSelectedChain(platformChain.platformChain?.offChain[0])
      }
    } else {
      setSelectedChain(platformChain.platformChain?.offChain[0])
    }
  }

  const openModalDetails = (txn: any) => {
    let dataHistory: xxcModalDetailsHistoryTypes.dataHistory = {}
    dataHistory.binance_to_point = txn
    setDataDetails(dataHistory)
    setShowDetailTnx(true)
  }

  const setSelectTokenDefault = () => {
    let queryStr = xxaQueryString.GetQueryString()
    const tokenSearch = queryStr.tokenSymbol ?? 'SIX'

    if (tunnelConfig.config) {
      let newTokenSelect = tunnelConfig.config.tokens.find(item => {
        return item.symbol === tokenSearch.toUpperCase()
      })

      if (newTokenSelect) {
        setSelectedToken(newTokenSelect)
      } else {
        setSelectedToken(tunnelConfig.config.tokens[0])
      }
    }
  }

  useEffect(() => {
    setTokenAmount('')
    setCcpAmount('')
  }, [selectedToken])

  useEffect(() => {
    if (nativePrice.price) {
      setMinMaxCCP()
    }
  }, [nativePrice])

  useEffect(() => {
    const calculateConvertValue = async () => {
      try {
        const _ccpAmount = new Decimal(Number(tokenAmount)).mul(
          nativePrice.price?.price_point || 33
        )
        setCcpAmount(formatBeforeSet(_ccpAmount, 2))
      } catch (error) {
        console.error(error)
      }
    }
    if (tokenAmount === '') {
      return setCcpAmount('')
    }
    if (targetInput === 'TOKEN_AMOUNT') {
      calculateConvertValue()
    }
  }, [tokenAmount, nativePrice])

  useEffect(() => {
    const calculateConvertValue = async () => {
      try {
        const _tokenAmount = new Decimal(Number(ccpAmount)).div(
          nativePrice.price?.price_point || 33
        )
        setTokenAmount(formatBeforeSet(_tokenAmount, 8))
      } catch (error) {
        console.error(error)
      }
    }
    if (ccpAmount === '') {
      return setTokenAmount('')
    }
    if (targetInput === 'CCP_AMOUNT') {
      calculateConvertValue()
    }
  }, [ccpAmount, nativePrice])

  useEffect(() => {
    let queryStr = xxaQueryString.GetQueryString()
    if (queryStr.tid) {
      dispatch(xxaTunnelService.GetTransactionBinanceToPoint(queryStr.tid))
    }
  }, [])

  useEffect(() => {
    if (binanceToPointGetTransaction.binanceToPointGetTransaction) {
      openModalDetails(
        binanceToPointGetTransaction.binanceToPointGetTransaction
      )
    }
  }, [binanceToPointGetTransaction])

  useEffect(() => {
    if (selectedToken) {
      dispatch(
        xxaTunnelService.GetTokenPrice(selectedToken.symbol, 0, tunnelType)
      )
      let intervalTask = setInterval(() => {
        dispatch(
          xxaTunnelService.GetTokenPrice(selectedToken.symbol, 0, tunnelType)
        )
      }, 60000)
      return () => clearInterval(intervalTask)
    }
  }, [selectedToken])

  useEffect(() => {
    if (tunnelConfig.type === TUNNEL_GET_CONFIG_SUCCESS) {
      if (tunnelConfig.config?.type === tunnelType) {
        setSelectTokenDefault()
      }
    }
  }, [tunnelConfig])

  useEffect(() => {
    dispatch(xxaTunnelService.GetTunnelConfig(0, tunnelType))
  }, [])

  useEffect(() => {
    if (platformChain.platformChain) {
      getChainFormLocalStorage()
    }
  }, [platformChain])

  useEffect(() => {
    if (
      binanceToPointRequest.type === BINANCE_TO_POINT_REQUEST_SUCCESS &&
      binanceToPointRequest.binanceToPointRequest
    ) {
      let userAgent = navigator.userAgent || navigator.vendor
      let urlPayment = ''
      if (/android/i.test(userAgent) || /iPad|iPhone|iPod/.test(userAgent)) {
        urlPayment =
          binanceToPointRequest.binanceToPointRequest.payment_deep_link_url
      } else {
        urlPayment = binanceToPointRequest.binanceToPointRequest.payment_url
      }
      setIsProcessConvert(false)
      window.location.href = urlPayment
      dispatch(xxaTunnelService.ClearBinanceToPointRequest())
      //window.open(urlPayment, '_blank')
    } else if (binanceToPointRequest.type === BINANCE_TO_POINT_REQUEST_FAIL) {
      let msg: string =
        binanceToPointRequest.error?.msg || 'something was wrong'
      toast.error(msg)
      dispatch({ type: BINANCE_TO_POINT_REQUEST_CLEAR_MESSAGE })
    } else {
      // setShowBinancePayModal(false)
      setIsProcessConvert(false)
    }
  }, [binanceToPointRequest])

  return (
    <CardCustom
      theme={
        darkMode.darkMode === 'dark'
          ? themeConfig.themeConfig?.dark
          : themeConfig.themeConfig?.light
      }
      xl={4}
      lg={5}
      md={8}
      sm={10}
      xs={11}
      className="card convert-container"
    >
      <Col lg={12} className="convert-form-header text-center">
        Binance To CCP
      </Col>
      <hr className="m-0 convert-form-divider" />
      <Col className="px-3 px-sm-4 pt-3 pb-4" lg={12}>
        <Form>
          <Form.Group className="mt-0">
            <Row>
              <Col xs={12}>
                <div className="d-flex align-items-center mb-2">
                  <div className="d-flex align-items-center mx-1">
                    <ReactImageFallback
                      alt="Token Convert"
                      style={{ display: 'flex' }}
                      height="24px"
                      width="24px"
                      src={selectedToken?.image}
                      fallbackImage={image_icon}
                      initialImage={image_icon}
                    />
                    <div className="convert-text-ccp">
                      {selectedToken?.symbol}
                    </div>
                    <div className="mx-1 d-flex align-items-center">
                      <span
                        className="custom-link custom-link-or-more"
                        role="button"
                        onClick={() => {
                          setOpenModalUserRef(true)
                        }}
                      >
                        or more
                      </span>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
            <Form.Control
              className="convert-input convert-text-right"
              disabled={isProcessConvert}
              onInput={onInputTokenAmount}
              placeholder="0.0"
              type="text"
              value={tokenAmount}
            />
          </Form.Group>
          <Form.Group className="mt-4 pt-2">
            <Row>
              <Col className="d-flex justify-content-center align-items-center text-center">
                <div className="d-flex justify-content-center align-items-center convert-arrow-down">
                  <ConvertIcon
                    width={30}
                    height={30}
                    fill={
                      themeConfig.themeConfig?.[darkMode.darkMode]
                        .convertColor || '#ffffff'
                    }
                  />
                </div>
              </Col>
            </Row>
          </Form.Group>
          <Form.Group className="mt-0">
            <Row className="align-items-center">
              <Col xs={4}>
                <div className="d-flex align-items-center mb-2 mx-1">
                  <ReactImageFallback
                    alt="ChomCHOB Point"
                    style={{ display: 'flex' }}
                    height="24px"
                    width="24px"
                    src={themeConfig.themeConfig?.config.pointIcon}
                    fallbackImage={image_icon}
                    initialImage={image_icon}
                  />
                  <div className="convert-text-ccp">
                    {themeConfig.themeConfig?.config.pointUnit}
                  </div>
                  <div className="mx-1 d-flex align-items-center">
                    <OverlayTrigger
                      trigger="click"
                      rootClose
                      overlay={props => (
                        <Tooltip id="ccp-info" {...props}>
                          This is just estimate{' '}
                          {themeConfig.themeConfig?.config.pointUnit}, the
                          actual {themeConfig.themeConfig?.config.pointUnit}{' '}
                          maybe change because fluctuation of token price,
                          currency price or tax on transfer of some token.
                        </Tooltip>
                      )}
                      placement="right"
                    >
                      <img
                        alt="question icon"
                        role="button"
                        src={question_icon}
                      />
                    </OverlayTrigger>
                  </div>
                </div>
              </Col>
            </Row>
            <InputGroup>
              <Form.Control
                className="convert-input text-on-input text-on-input-text-end convert-text-right "
                disabled={isProcessConvert}
                onChange={onInputCCPAmount}
                placeholder="0.0"
                type="text"
                value={ccpAmount}
              />
            </InputGroup>
          </Form.Group>
          <Row className="mb-3 min-max-label">
            <Col xs={5}>
              Min: <span>{formatCurrency(minCcpAmount)}</span>{' '}
              {themeConfig.themeConfig?.config.pointUnit}
            </Col>
            <Col xs={7} className="convert-text-right">
              Max: <span>{formatCurrency(maxCcpAmount)}</span>{' '}
              {themeConfig.themeConfig?.config.pointUnit}
            </Col>
          </Row>

          <Form.Group className="d-grid gap-1">
            <Button
              className="button convert-button"
              disabled={isProcessConvert}
              onClick={onClickConvert}
              variant="primary"
            >
              {isProcessConvert && (
                <span
                  aria-hidden="true"
                  className="spinner-border spinner-border-sm convert-margin-right"
                  role="status"
                ></span>
              )}
              <b>CONVERT</b>
            </Button>
            <div className="mt-0 d-flex align-items-center w-100 justify-content-center">
              <a
                href={howToUse}
                className="custom-link custom-link-how-to"
                target="_blank"
                rel="noopener noreferrer"
              >
                How to use
              </a>
            </div>
          </Form.Group>
        </Form>
        <ModalUserRef
          show={openModalUserRef}
          onHide={() => setOpenModalUserRef(false)}
          imgSrc={[orMoreImage]}
          height={320}
        />
        <ModalDetailsHistory
          onHide={() => {
            setShowDetailTnx(false)
            dispatch(xxaTunnelService.ClearTransactionBinanceToPoint())
          }}
          data={dataDetails}
          show={showDetailTnx}
        />
      </Col>
    </CardCustom>
  )
}

export default PointToCrypto

const CardCustom = styled(Col)<xxcNavBarTypes.NavBarCustom>`
  background-color: ${props => props.theme.cardColor};
  .placeholder-text {
    color: ${props => props.theme.input.placeholderTextColor} !important;
  }
  .min-max-label {
    color: ${props => props.theme.fontSecondaryColor};
  }
  .convert-balance-content-label {
    color: ${props => props.theme.primaryColor};
  }
  .mini-info-box {
    background-color: ${props => props.theme.input.inputBackgroundColor};
  }

  .advance-options-text {
    color: ${props => props.theme.primaryColor};
  }
  .form-select {
    border: 2px solid ${props => props.theme.input.borderColor};
    background-color: ${props => props.theme.input.inputBackgroundColor};
    color: ${props => props.theme.input.inputTextColor};
    font-size: 20px;
    border-radius: 16px;
  }

  .custom-link {
    text-underline-offset: 0.5px;
    text-decoration: underline;
    &:hover {
      color: ${props => props.theme.fontPrimaryColor};
      text-decoration: underline solid ${props => props.theme.fontPrimaryColor}
        1px;
    }
    &-or-more {
      color: ${props => props.theme.fontSecondaryColor};
      text-decoration: underline solid
        ${props => props.theme.fontSecondaryColor} 1px;
    }
    &-how-to {
      color: ${props => props.theme.primaryColor};
      text-decoration: underline solid ${props => props.theme.primaryColor} 1px;
    }
  }

  .select-network {
    .dropdown-toggle {
      min-height: 46px;
      display: block;
      width: 100%;
      padding: 0.375rem 0.75rem;
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.5;
      border: 2px solid ${props => props.theme.input.borderColor};
      background-color: ${props => props.theme.input.inputBackgroundColor};
      color: ${props => props.theme.input.inputTextColor};
      background-clip: padding-box;
      appearance: none;
      border-radius: 0.25rem;
      transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      border-width: 2px;
      border-radius: 16px;
      font-size: 20px;
      text-align: left;
      &:focus {
        box-shadow: unset;
        // border-color: ${props =>
          props.theme.input.inputFocusBackGroundColor};
        // background-color: ${props =>
          props.theme.input.inputFocusBackGroundColor}4d;
      }
      &::after {
        position: absolute;
        right: 12px;
        top: calc(50% - 3px);
      }
    }

    > .dropdown-menu {
      border-radius: 16px;
      font-size: 20px;
      width: 100%;
      background-color: ${props => props.theme.cardColor};
      > a {
        color: ${props => props.theme.input.inputTextColor};
      }
      .dropdown-item {
        &:hover {
          background-color: ${props => props.theme.navBar.navBarFontHoverColor};
        }
        &:focus {
          background-color: ${props => props.theme.navBar.navBarFontHoverColor};
        }
        &:active {
          color: ${props => props.theme.navBar.navBarFontActiveColor};
          background-color: unset;
        }
        &:focus-visible {
          outline: none;
        }
        &:first-child {
          border-top-left-radius: 4px;
          border-top-right-radius: 4px;
        }
        &:last-child {
          border-bottom-left-radius: 4px;
          border-bottom-right-radius: 4px;
        }
      }
      & .select-network-active {
        color: ${props => props.theme.navBar.navBarFontActiveColor};
      }
    }
  }

  .approve-button {
    background-color: ${props =>
      props.theme.button.approveButton.backgroundColor};
    border-color: ${props => props.theme.button.approveButton.backgroundColor};
    color: ${props => props.theme.button.approveButton.fontColor};
    &:hover {
      opacity: 0.65;
      color: ${props => props.theme.button.approveButton.fontColor};
    }
    &:active {
      opacity: 0.65;
      color: ${props => props.theme.button.approveButton.fontColor};
    }
    &:active:focus {
      box-shadow: 0 0 0 0.25rem
        ${props => props.theme.button.approveButton.backgroundColor}8c !important;
    }
  }
  .convert-button {
    background-color: ${props =>
      props.theme.button.convertButton.backgroundColor};
    border-color: ${props => props.theme.button.convertButton.backgroundColor};
    color: ${props => props.theme.button.convertButton.fontColor};
    &:hover {
      opacity: 0.65;
      color: ${props => props.theme.button.convertButton.fontColor};
    }
    &:active {
      opacity: 0.65;
      color: ${props => props.theme.button.convertButton.fontColor};
    }
    &:active:focus {
      box-shadow: 0 0 0 0.25rem
        ${props => props.theme.button.convertButton.backgroundColor}8c !important;
    }
  }

  .display-chain-select {
    padding: 0.5rem 0.5rem;
    height: 32px;
    font-size: 18px !important;
    align-items: center;
    border-color: ${props => props.theme.button.addressButton.backgroundColor};
    background-color: ${props =>
      props.theme.button.addressButton.backgroundColor};
    color: ${props => props.theme.button.addressButton.fontColor};
    > img {
      margin-right: 8px;
    }
  }

  .button-address-wallet {
    padding: 0.5rem 0.5rem;
    height: 32px;
    font-size: 18px !important;
    align-items: center;
    border-color: ${props => props.theme.button.addressButton.backgroundColor};
    background-color: ${props =>
      props.theme.button.addressButton.backgroundColor};
    color: ${props => props.theme.button.addressButton.fontColor};
    &:hover {
      opacity: 0.65;
    }
    &:active {
      opacity: 0.65;
    }
    &:active:focus {
      opacity: 1;
      box-shadow: 0 0 0 0.25rem
        ${props => props.theme.button.addressButton.backgroundColor}8c !important;
    }
  }

  .button-address-book {
    padding: 0.25rem;
    height: 32px;
    font-size: 18px !important;
    align-items: center;
    border-color: ${props => props.theme.button.addressButton.backgroundColor};
    background-color: ${props =>
      props.theme.button.addressButton.backgroundColor};
    color: ${props => props.theme.button.addressButton.fontColor};
    &:hover {
      opacity: 0.65;
    }
    &:active {
      opacity: 0.65;
    }
    &:active:focus {
      opacity: 1;
      box-shadow: 0 0 0 0.25rem
        ${props => props.theme.button.addressButton.backgroundColor}8c !important;
    }
  }

  .btn-max {
    border-radius: 10px !important;
    border-color: ${props => props.theme.button.maxButton.backgroundColor};
    color: ${props => props.theme.button.maxButton.backgroundColor};
    &:hover {
      background-color: ${props =>
        props.theme.button.maxButton.backgroundColor};
      border-color: ${props => props.theme.button.maxButton.backgroundColor};
      color: ${props => props.theme.button.maxButton.fontColor};
    }
    &:focus {
      background-color: ${props => props.theme.button.maxButton.fontColor};
      border-color: ${props => props.theme.button.maxButton.backgroundColor};
      color: ${props => props.theme.button.maxButton.backgroundColor};
    }
    &:active {
      background-color: ${props =>
        props.theme.button.maxButton.backgroundColor};
      border-color: ${props => props.theme.button.maxButton.backgroundColor};
      color: ${props => props.theme.button.maxButton.fontColor};
    }
    &:active:focus {
      box-shadow: 0 0 0 0.25rem
        ${props => props.theme.button.maxButton.backgroundColor}8c !important;
    }
    &:disabled {
      background-color: ${props =>
        props.theme.button.maxButton.backgroundColor};
      color: ${props => props.theme.button.maxButton.fontColor};
    }
  }
  @media (max-width: 540px) {
    .approve-button {
      &:hover {
        background-color: ${props =>
          props.theme.button.approveButton.backgroundColor};
        border-color: ${props =>
          props.theme.button.approveButton.backgroundColor};
        color: ${props => props.theme.button.approveButton.fontColor};
        opacity: unset;
      }
      &:active {
        box-shadow: 0 0 0 0.25rem
          ${props => props.theme.button.approveButton.backgroundColor}8c !important;
        opacity: 0.65;
        color: ${props => props.theme.button.approveButton.fontColor};
      }
      &:disabled {
        opacity: 0.65 !important;
      }
    }
    .button-address-wallet {
      &:hover {
        background-color: ${props =>
          props.theme.button.addressButton.backgroundColor};
        border-color: ${props =>
          props.theme.button.addressButton.backgroundColor};
        color: ${props => props.theme.button.addressButton.fontColor};
        opacity: unset;
      }
      &:active {
        box-shadow: 0 0 0 0.25rem
          ${props => props.theme.button.addressButton.backgroundColor}8c !important;
        opacity: 0.65;
        color: ${props => props.theme.button.addressButton.fontColor};
      }
      &:disabled {
        opacity: 0.65 !important;
      }
    }
    .button-address-book {
      &:hover {
        background-color: ${props =>
          props.theme.button.addressButton.backgroundColor};
        border-color: ${props =>
          props.theme.button.addressButton.backgroundColor};
        color: ${props => props.theme.button.addressButton.fontColor};
        opacity: unset;
      }
      &:active {
        box-shadow: 0 0 0 0.25rem
          ${props => props.theme.button.addressButton.backgroundColor}8c !important;
        opacity: 0.65;
        color: ${props => props.theme.button.addressButton.fontColor};
      }
      &:disabled {
        opacity: 0.65 !important;
      }
    }
    .convert-button {
      &:hover {
        background-color: ${props =>
          props.theme.button.convertButton.backgroundColor};
        border-color: ${props =>
          props.theme.button.convertButton.backgroundColor};
        color: ${props => props.theme.button.convertButton.fontColor};
        opacity: unset;
      }
      &:active {
        box-shadow: 0 0 0 0.25rem
          ${props => props.theme.button.convertButton.backgroundColor}8c !important;
        opacity: 0.65;
        color: ${props => props.theme.button.convertButton.fontColor};
      }
      &:disabled {
        opacity: 0.65 !important;
      }
    }
    .btn-max {
      &:hover {
        background-color: unset;
        border-color: ${props => props.theme.button.maxButton.backgroundColor};
        color: ${props => props.theme.button.maxButton.backgroundColor};
      }
      &:active {
        box-shadow: 0 0 0 0.25rem
          ${props => props.theme.button.maxButton.backgroundColor}8c !important;
        background-color: ${props =>
          props.theme.button.maxButton.backgroundColor};
        color: ${props => props.theme.button.maxButton.fontColor};
      }
    }
  }
`
