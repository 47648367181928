import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ethers } from 'ethers'
import dayjs from 'dayjs'
import Decimal from 'decimal.js'
import { toast } from 'react-toastify'
import ReactImageFallback from 'react-image-fallback'
import styled from 'styled-components'

import {
  Col,
  Row,
  Form,
  Button,
  Card,
  Collapse,
  InputGroup,
  FormControl,
  OverlayTrigger,
  Tooltip
} from 'react-bootstrap'

// Types
import xxreStoreTypes from 'redux/types'
import xxsTunnelServiceTypes from 'services/Tunnel/types'
import xxcNavBarTypes from 'components/NavBar/Model'

// Actions
import xxaTunnelContract from 'actions/TunnelContract'
import xxaTunnelService from 'actions/TunnelService'
import xxaQueryString from 'actions/QueryString'

//type
import {
  TUNNEL_APPROVE_TOKEN_CLEAR_MESSAGE,
  TUNNEL_APPROVE_TOKEN_FAIL,
  TUNNEL_APPROVE_TOKEN_SUCCESS,
  TUNNEL_CONVERT_TOKEN_FAIL,
  TUNNEL_CONVERT_TOKEN_SUCCESS,
  TUNNEL_CONVERT_TOKEN_CLEAR_MESSAGE
} from 'redux/Constants/TunnelContract'

import {
  SWITCH_CHAIN_SUCCESS,
  CONNECT_WALLET_SUCCESS,
  CANCEL_CONNECT_WALLET,
  SWITCH_ACCOUNT_SUCCESS
} from 'redux/Constants/ConnectWallet'

import { TUNNEL_GET_CONFIG_SUCCESS } from 'redux/Constants/TunnelService'

// Components
import TokenDropdown from 'components/ConvertPage/TokenDropdown'
import TokenListModal from 'components/ConvertPage/TokenListModal'
import ModalUserRef from 'components/ConvertPage/ModalUserRef'

// Config
import xxuURL from 'utils/URL'
import { freeGas } from 'configs'

// Files
import ERC20ABI from 'contracts/ERC20.json'
import TunnelABI from 'contracts/tunnel.json'

//icon
import image_icon from 'assets/Svg/icon/image_icon.svg'
import question_icon from 'assets/Svg/icon/question_icon.svg'
import reload_icon from 'assets/Svg/icon/reload_icon.svg'
import ConvertIcon from 'components/CustomIcon/ConvertIcon'
import DownIcon from 'components/CustomIcon/DownIcon'

// Styles
import './style.scss'

let targetInput = ''

const ConvertForm = () => {
  // Initial
  const dispatch = useDispatch()
  const howToUse =
    'https://www.chomchob.com/how-to-use/topup-crypto-to-ccp-by-tunnel'

  // Redux
  const web3 = useSelector((state: xxreStoreTypes.Props) => state.web3)
  const tunnelConfig = useSelector(
    (state: xxreStoreTypes.Props) => state.getConfig
  )
  const nativePrice = useSelector(
    (state: xxreStoreTypes.Props) => state.getTokenPrice
  )
  const isCheckUserRef = useSelector(
    (state: xxreStoreTypes.Props) => state.checkUserRef
  )
  const approveTokenState = useSelector(
    (state: xxreStoreTypes.Props) => state.approveToken
  )
  const convertTokenState = useSelector(
    (state: xxreStoreTypes.Props) => state.convertToken
  )
  const connectWallet = useSelector(
    (state: xxreStoreTypes.Props) => state.connectWallet
  )
  const themeConfig = useSelector(
    (state: xxreStoreTypes.Props) => state.themeConfig
  )
  const platformChain = useSelector(
    (state: xxreStoreTypes.Props) => state.platformChain
  )

  const darkMode = useSelector((state: xxreStoreTypes.Props) => state.darkMode)

  // User Input State
  const [selectedToken, setSelectedToken] =
    useState<xxsTunnelServiceTypes.TunnelToken | null>(null)
  const [userReference, setUserReference] = useState<string>('')
  const [tokenAmount, setTokenAmount] = useState<string>('')
  const [ccpAmount, setCcpAmount] = useState<string>('')
  const [isFreeGas, setIsFreeGas] = useState<boolean>(false)

  // Calculation and Display State
  const [amountOut, setAmountOut] = useState<ethers.BigNumber>(
    ethers.BigNumber.from('0')
  )
  const [tokenBalance, setTokenBalance] = useState<string>('0')
  const [minCcpAmount, setMinCcpAmount] = useState<string>('1')
  const [maxCcpAmount, setMaxCcpAmount] = useState<string>('100000')
  const [price, setPrice] = useState<string>('0')
  const [priceToken, setPriceToken] = useState<string>('0')
  const [priceType, setPriceType] = useState<'ccp' | 'token'>('ccp')
  const [priceImpact, setPriceImpact] = useState<Decimal>(new Decimal(0))
  const [slippage, setSlippage] = useState<string>('5')
  const [deadlineMins, setDeadlineMins] = useState<string>('10')

  // UI control state
  const [isAllowance, setIsAllowance] = useState<boolean>(false)
  const [isProcessConvert, setIsProcessConvert] = useState<boolean>(false)
  const [isProcessApprove, setIsProcessApprove] = useState<boolean>(false)
  const [openAdvanceOptions, setOpenAdvanceOptions] = useState<boolean>(false)
  const [openTokenList, setOpenTokenList] = useState<boolean>(false)
  const [openModalUserRef, setOpenModalUserRef] = useState<boolean>(false)

  // Event Listener
  const onInputTokenAmount = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.currentTarget.value
    if (!value) {
      setTokenAmount('')
    } else if (value === '0' || value === '00') {
      setTokenAmount('0')
    } else {
      if (/^[\d]*\.?[\d]{0,8}$/.test(value)) {
        if (value.startsWith('.')) {
          setTokenAmount('0.')
        } else if (value.startsWith('0.')) {
          setTokenAmount(value)
        } else {
          setTokenAmount(value.replace(/^0+/, ''))
        }
      }
    }
    targetInput = 'TOKEN_AMOUNT'
    // setTargetInput('TOKEN_AMOUNT')
  }

  const onInputCcpAmount = (event: React.FormEvent<HTMLInputElement>) => {
    const value = event.currentTarget.value
    if (!value) {
      setCcpAmount('')
    } else if (value === '0' || value === '00') {
      setCcpAmount('0')
    } else {
      if (/^[\d]*\.?[\d]{0,2}$/.test(value)) {
        if (value.startsWith('.')) {
          setCcpAmount('0.')
        } else if (value.startsWith('0.')) {
          setCcpAmount(value)
        } else {
          setCcpAmount(value.replace(/^0+/, ''))
        }
      }
    }
    targetInput = 'CCP_AMOUNT'
    // setTargetInput('CCP_AMOUNT')
  }

  const onInputUserReference = (event: React.FormEvent<HTMLInputElement>) => {
    const regex = /[^A-Za-z0-9]/g
    const value = event.currentTarget.value.toString()
    const uref = value.replace(regex, '').slice(0, 200)
    setUserReference(uref)
  }

  const onBlurSlippage = (): any => {
    if (!slippage) {
      setSlippage('5')
    }
  }

  const onInputSlippage = (event: React.FormEvent<HTMLInputElement>) => {
    const regx = /^([1-9][0-9]*|0?)\.?[0-9]*$/
    const value = event.currentTarget.value
    if (regx.test(value) && Number(value) <= 50) {
      const _value = value.replace(/^0+/, '0').replace(/^\./, '0.')
      setSlippage(_value)
    }
  }

  const onBlurDeadline = (): any => {
    if (!deadlineMins) {
      setDeadlineMins('10')
    }
  }

  const onInputDeadline = (event: React.FormEvent<HTMLInputElement>) => {
    const regx = /^([1-9][0-9]*|0?)\.?[0-9]*$/
    const value = event.currentTarget.value
    if (regx.test(value) && Number(value) <= 60) {
      const _value = value.replace(/^0+/, '0').replace(/^\./, '0.')
      setDeadlineMins(_value)
    }
  }

  const onClickConvert = async () => {
    try {
      validateConvertParams()
      setIsProcessConvert(true)
      dispatch(xxaTunnelService.CheckUserRef(userReference))
    } catch (error) {
      if (error instanceof Error) {
        toast.error(error.message)
      }
    }
  }

  const onClickApprove = async () => {
    setIsProcessApprove(true)
    if (
      selectedToken?.address &&
      tunnelConfig.config?.tunnel_address &&
      web3.provider
    ) {
      const provider = web3.provider
      const signer = (provider as ethers.providers.Web3Provider).getSigner()
      if (isFreeGas) {
        dispatch(
          xxaTunnelContract.ApproveTokenFreeGas(
            selectedToken.address,
            tunnelConfig.config.tunnel_address,
            signer,
            web3.provider
          )
        )
      } else {
        dispatch(
          xxaTunnelContract.ApproveToken(
            selectedToken.address,
            tunnelConfig.config.tunnel_address,
            signer
          )
        )
      }
    }
  }

  // Helper function
  const validateConvertParams = () => {
    if (Number(tokenAmount) <= 0) {
      throw new Error('Please fill token amount.')
    }
    if (userReference.length <= 0) {
      throw new Error(
        `${themeConfig.themeConfig?.config.useRefTitle} is not allowed to be empty.`
      )
    }
    if (Number(tokenAmount) > Number(tokenBalance)) {
      throw new Error('Convert token exceed balance.')
    }
    if (Number(ccpAmount) < Number(minCcpAmount)) {
      throw new Error(
        `${themeConfig.themeConfig?.config.pointUnit} amount is lower than minimum.`
      )
    }
    if (Number(ccpAmount) > Number(maxCcpAmount)) {
      throw new Error(
        `${themeConfig.themeConfig?.config.pointUnit} amount is higher than maximum.`
      )
    }
    if (
      selectedToken?.tunnel_method === 'convertWithTransferETH' ||
      selectedToken?.tunnel_method === 'convertWithTransfer' ||
      selectedToken?.tunnel_method === 'convertWithTransferMiddleETH'
    ) {
      if (Number(tokenAmount) < Number(nativePrice.price?.min_amount)) {
        throw new Error(`${selectedToken.symbol} amount is lower than minimum.`)
      }
    } else if (
      selectedToken?.tunnel_method === 'convertForETH' ||
      selectedToken?.tunnel_method === 'convertForETHSupportingFeeOnTransfer'
    ) {
      if (Number(amountOut) < Number(nativePrice.price?.min_amount)) {
        throw new Error(`${selectedToken.symbol} amount is lower than minimum.`)
      }
    } else {
      if (Number(amountOut) < Number(nativePrice.price?.min_amount)) {
        throw new Error(
          `${selectedToken?.symbol} amount is lower than minimum.`
        )
      }
    }
  }

  const formatBeforeSet = (value: number | string | Decimal, d: number = 2) =>
    new Decimal(value).toFixed(d).replace(/\.?0*$/, '')

  const calculatePriceImpact = (
    amountIn: ethers.BigNumber,
    reserveIn: ethers.BigNumber
  ) => {
    const _amountIn = new Decimal(amountIn.toString())
    const _reserveId = new Decimal(reserveIn.toString())
    const priceImpact = new Decimal(1)
      .sub(_reserveId.div(_reserveId.add(_amountIn)))
      .mul(100)

    return priceImpact
  }

  const checkAllowance = async (
    tokenAddress: string,
    tunnelAddress: string,
    accountAddress: string,
    provider: ethers.providers.Provider
  ) => {
    try {
      const token = new ethers.Contract(tokenAddress, ERC20ABI, provider)
      const allowance: number = await token.allowance(
        accountAddress,
        tunnelAddress
      )

      setIsAllowance(allowance > 0)
    } catch (error) {
      console.error(error)
    }
  }

  const getTokenBalance = async (
    tokenAddress: string,
    accountAddress: string,
    provider: ethers.providers.Provider
  ) => {
    try {
      let balance: ethers.BigNumber
      if (!tokenAddress) {
        balance = await provider.getBalance(accountAddress)
      } else {
        const token = new ethers.Contract(tokenAddress, ERC20ABI, provider)
        balance = await token.balanceOf(accountAddress)
      }
      // floor in 3rd decimal
      const balanceF: string = new Decimal(
        ethers.utils.formatUnits(balance, selectedToken?.decimal || 18)
      )
        .mul(100000000)
        .floor()
        .div(100000000)
        .toFixed(8)
      setTokenBalance(balanceF)
    } catch (error) {
      console.error(error)
    }
  }

  const setMinMaxCCP = () => {
    const minAmountOut = nativePrice.price?.min_point
    const maxAmountOut = nativePrice.price?.max_point
    setMinCcpAmount(minAmountOut || '330')
    setMaxCcpAmount(maxAmountOut || '990000')
  }

  // Format Function
  const formatCurrency = (value: string | number): string => {
    return Intl.NumberFormat('en-US', { maximumSignificantDigits: 21 }).format(
      Number(value)
    )
  }

  const formatPriceImpact = (value: Decimal) => {
    if (value.lt(0.01)) {
      return '< 0.01'
    }
    return value.toFixed(2)
  }

  const formatPriceImpactStyle = (value: Decimal) => {
    if (value.lt(5)) {
      return 'good'
    } else if (value.lt(50)) {
      return 'warn'
    } else {
      return 'alert'
    }
  }

  const getTokenPrice = () => {
    const chain = platformChain.platformChain?.onChain.find(item => {
      return connectWallet.chainId === item.chainId
    })
    if (selectedToken) {
      if (
        selectedToken.tunnel_method === 'convertWithTransferETH' ||
        selectedToken.tunnel_method === 'convertWithTransfer' ||
        selectedToken.tunnel_method === 'convertWithTransferMiddleETH'
      ) {
        dispatch(
          xxaTunnelService.GetTokenPrice(
            selectedToken.symbol,
            connectWallet.chainId ||
              platformChain.platformChain.onChain[0].chainId
          )
        )
      } else if (
        selectedToken.tunnel_method === 'convertForETH' ||
        selectedToken.tunnel_method === 'convertForETHSupportingFeeOnTransfer'
      ) {
        if (chain) {
          dispatch(
            xxaTunnelService.GetTokenPrice(
              chain.nativeCurrency.symbol,
              connectWallet.chainId ||
                platformChain.platformChain.onChain[0].chainId
            )
          )
        }
      } else {
        if (chain) {
          dispatch(
            xxaTunnelService.GetTokenPrice(
              selectedToken.config_symbol, //chain.tokenPrice,
              connectWallet.chainId ||
                platformChain.platformChain.onChain[0].chainId
            )
          )
        }
      }
    }
  }

  const setMaxOnTokenInput = async () => {
    if (Number(tokenBalance) > 0 && tunnelConfig.config) {
      if (selectedToken?.address === null) {
        const tunnelAddress = tunnelConfig.config?.tunnel_address
        const provider = web3.provider
        const signer = (provider as ethers.providers.Web3Provider).getSigner()
        const tunnel = new ethers.Contract(tunnelAddress, TunnelABI, signer)
        const destinationWallet: string = await tunnel.destinationWallet()
        const txn: any = await signer.estimateGas({
          to: destinationWallet,
          value: ethers.utils.parseEther(tokenBalance.toString()),
          data: ethers.utils.toUtf8Bytes(userReference)
        })
        targetInput = 'TOKEN_AMOUNT'
        // setTargetInput('TOKEN_AMOUNT')
        setTokenAmount(
          (
            Number(tokenBalance) -
            Number(
              ethers.utils.formatEther(
                txn.mul(ethers.utils.parseUnits('10', 9)).toString()
              )
            )
          ).toString()
        )
      } else {
        targetInput = 'TOKEN_AMOUNT'
        // setTargetInput('TOKEN_AMOUNT')
        setTokenAmount(tokenBalance)
      }
    }
  }

  const switchPriceType = () => {
    if (priceType === 'ccp') {
      setPriceType('token')
    } else {
      setPriceType('ccp')
    }
  }

  const showFreeGas = useMemo(() => {
    if (selectedToken && selectedToken.address) {
      const newToken = freeGas.find(item => {
        return (
          item.address.toLowerCase() === selectedToken.address.toLowerCase() &&
          item.chainId === connectWallet.chainId
        )
      })
      return (
        newToken &&
        newToken.freeGas && (
          <Form.Group className="d-flex gap-2 justify-content-end align-items-center">
            <Form.Label
              role="button"
              onClick={isProcessConvert ? () => {} : setMaxOnTokenInput}
              className="min-max-label mb-0"
            >
              Gas Free
            </Form.Label>
            <Form.Check
              className="d-flex gap-2"
              type="switch"
              disabled={isProcessApprove || isProcessConvert}
              onChange={e => {
                setIsFreeGas((e.target as HTMLInputElement).checked)
              }}
              checked={isFreeGas}
              id="custom-switch"
              // label="Check this switch"
            />
          </Form.Group>
        )
      )
    } else {
      return null
    }
  }, [selectedToken, isFreeGas, isProcessApprove, isProcessConvert])

  useEffect(() => {
    if (
      selectedToken &&
      tunnelConfig?.config &&
      web3.provider &&
      connectWallet.accountAddress &&
      connectWallet.networkConnect
    ) {
      if (selectedToken.address) {
        checkAllowance(
          selectedToken.address,
          tunnelConfig.config?.tunnel_address,
          connectWallet.accountAddress,
          web3.provider
        )
      } else {
        setIsAllowance(true)
      }
      getTokenBalance(
        selectedToken.address,
        connectWallet.accountAddress,
        web3.provider
      )
    }
    setIsFreeGas(false)
  }, [selectedToken])

  useEffect(() => {
    getTokenPrice()
    setTokenAmount('')
    setCcpAmount('')
    if (selectedToken) {
      xxuURL.ChangeURL('token_symbol', selectedToken.symbol)
    }
  }, [selectedToken])

  useEffect(() => {
    if (userReference) {
      xxuURL.ChangeURL('user_reference', userReference)
    }
  }, [userReference])

  useEffect(() => {
    if (nativePrice.price) {
      setMinMaxCCP()
    }
  }, [nativePrice])

  useEffect(() => {
    const calculateConvertValue = async (_tokenAddress: string) => {
      try {
        if (
          web3.provider &&
          Number(tokenAmount) > 0 &&
          tunnelConfig.config?.tunnel_address &&
          selectedToken
        ) {
          if (
            selectedToken.tunnel_method === 'convertWithTransfer' ||
            selectedToken.tunnel_method === 'convertWithTransferETH' ||
            selectedToken.tunnel_method === 'convertWithTransferMiddleETH'
          ) {
            const _ccpAmount = new Decimal(tokenAmount).mul(
              nativePrice.price?.price_point || 33
            )
            setCcpAmount(formatBeforeSet(_ccpAmount, 2))

            setPrice(_ccpAmount.div(tokenAmount).toFixed(2))
            setPriceToken(new Decimal(tokenAmount).div(_ccpAmount).toFixed(8))
          } else {
            const provider = web3.provider
            const tunnelAddress = tunnelConfig.config?.tunnel_address
            const tunnel = new ethers.Contract(
              tunnelAddress,
              TunnelABI,
              provider
            )
            const { amountOut, reserveIn } = await tunnel.getAmountOut(
              _tokenAddress,
              ethers.utils.parseUnits(tokenAmount, selectedToken.decimal)
            )
            const _ccpAmount = new Decimal(
              // ethers.utils.formatEther(amountOut)
              ethers.utils.formatUnits(
                amountOut,
                nativePrice.price?.decimal || 18
              )
            ).mul(nativePrice.price?.price_point || '33')
            const _priceImpact = calculatePriceImpact(
              ethers.utils.parseUnits(tokenAmount, selectedToken.decimal),
              reserveIn
            )
            setCcpAmount(formatBeforeSet(_ccpAmount))
            setAmountOut(amountOut)

            setPrice(_ccpAmount.div(tokenAmount).toFixed(2))
            setPriceToken(new Decimal(tokenAmount).div(_ccpAmount).toFixed(8))

            setPriceImpact(_priceImpact)
          }
        } else if (tokenAmount === '') {
          setCcpAmount('')
        } else if (Number(tokenAmount) === 0) {
          setCcpAmount('0')
        }
      } catch (error) {
        console.error(error)
      }
      // setTargetInput(null)
    }
    if (targetInput === 'TOKEN_AMOUNT' && selectedToken) {
      const delayDebounceFn = setTimeout(() => {
        calculateConvertValue(selectedToken.address)
        // setTargetInput(null)
      }, 700)
      // calculateConvertValue(selectedToken.address)
      return () => clearTimeout(delayDebounceFn)
    }
  }, [tokenAmount, selectedToken, priceType, nativePrice])

  useEffect(() => {
    const calculateConvertValue = async (
      _tokenAddress: string,
      _ccpAmount: string
    ) => {
      try {
        if (
          web3.provider &&
          Number(_ccpAmount) > 0 &&
          tunnelConfig.config?.tunnel_address &&
          selectedToken
        ) {
          if (
            selectedToken.tunnel_method === 'convertWithTransfer' ||
            selectedToken.tunnel_method === 'convertWithTransferETH' ||
            selectedToken.tunnel_method === 'convertWithTransferMiddleETH'
          ) {
            const _tokenAmount = new Decimal(_ccpAmount).div(
              nativePrice.price?.price_point || 33
            )
            setTokenAmount(formatBeforeSet(_tokenAmount, 7))
            setPrice(formatBeforeSet(nativePrice.price?.price_point || 0, 2))

            if (nativePrice.price?.price_point) {
              setPriceToken(
                formatBeforeSet(
                  new Decimal(1).div(nativePrice.price.price_point),
                  8
                )
              )
            }
          } else {
            const provider = web3.provider
            const tunnelAddress = tunnelConfig.config.tunnel_address
            const tunnel = new ethers.Contract(
              tunnelAddress,
              TunnelABI,
              provider
            )
            const expectAmountOut = ethers.utils.parseEther(
              new Decimal(_ccpAmount)
                .div(nativePrice.price?.price_point || '33')
                .toFixed(5)
            )
            const { amountIn, reserveIn } = await tunnel.getAmountIn(
              _tokenAddress,
              expectAmountOut
            )

            const _priceImpact = calculatePriceImpact(amountIn, reserveIn)
            const _tokenAmount = ethers.utils.formatUnits(
              amountIn,
              selectedToken.decimal
            )
            const _price = new Decimal(_ccpAmount).div(_tokenAmount)
            setTokenAmount(formatBeforeSet(_tokenAmount, 7))
            setAmountOut(expectAmountOut)

            setPrice(formatBeforeSet(_price, 2))
            setPriceToken(formatBeforeSet(new Decimal(1).div(_price), 8))
            setPriceImpact(_priceImpact)
          }
        } else if (_ccpAmount === '') {
          setTokenAmount('')
        } else if (Number(_ccpAmount) === 0) {
          setTokenAmount('0')
        }
      } catch (error) {
        console.error(error)
      }
      // setTargetInput(null)
    }
    if (targetInput === 'CCP_AMOUNT' && selectedToken && priceType) {
      const delayDebounceFn = setTimeout(() => {
        calculateConvertValue(selectedToken.address, ccpAmount)
        // setTargetInput(null)
      }, 700)
      // calculateConvertValue(selectedToken.address)
      return () => clearTimeout(delayDebounceFn)
    }
  }, [ccpAmount, selectedToken, priceType, nativePrice])

  useEffect(() => {
    if (
      isCheckUserRef?.is_exist !== undefined &&
      selectedToken &&
      tunnelConfig.config &&
      web3.provider &&
      tokenAmount
    ) {
      if (!isCheckUserRef.is_exist) {
        toast.error(`${themeConfig.themeConfig?.config.useRefTitle} Not Found`)
        setIsProcessConvert(false)
      } else {
        const provider = web3.provider
        const signer = (provider as ethers.providers.Web3Provider).getSigner()
        const tokenAddress = selectedToken.address
        const tunnelAddress = tunnelConfig.config.tunnel_address
        const amountIn = ethers.utils
          .parseUnits(tokenAmount, selectedToken.decimal)
          .toString()
        const amountOutMin = new Decimal(amountOut.toString())
          .mul(100 - Number(slippage))
          .div(100)
          .toFixed(0)
        const deadline = dayjs().add(Number(deadlineMins), 'minutes').valueOf()
        if (isFreeGas) {
          dispatch(
            xxaTunnelContract.ConvertFreeGas(
              tunnelAddress,
              tokenAddress,
              amountIn,
              amountOutMin,
              deadline,
              userReference,
              signer,
              web3.provider
            )
          )
        } else {
          switch (selectedToken.tunnel_method) {
            case 'convert':
              dispatch(
                xxaTunnelContract.ConvertToken(
                  tunnelAddress,
                  tokenAddress,
                  amountIn,
                  amountOutMin,
                  deadline,
                  userReference,
                  signer
                )
              )
              break
            case 'convertSupportingFeeOnTransfer':
              dispatch(
                xxaTunnelContract.ConvertTokenSupportingFeeOnTransfer(
                  tunnelAddress,
                  tokenAddress,
                  amountIn,
                  amountOutMin,
                  deadline,
                  userReference,
                  signer
                )
              )
              break
            case 'convertWithTransferETH':
              dispatch(
                xxaTunnelContract.ConvertWithTransferETH(
                  tunnelAddress,
                  amountIn,
                  userReference,
                  signer
                )
              )
              break
            case 'convertWithTransferMiddleETH':
              dispatch(
                xxaTunnelContract.ConvertWithTransferMiddleETH(
                  tunnelAddress,
                  amountIn,
                  deadline,
                  userReference,
                  signer
                )
              )
              break
            case 'convertWithTransfer':
              dispatch(
                xxaTunnelContract.ConvertWithTransfer(
                  tunnelAddress,
                  tokenAddress,
                  amountIn,
                  deadline,
                  userReference,
                  signer
                )
              )
              break
            case 'convertForETH':
              dispatch(
                xxaTunnelContract.ConvertForETH(
                  tunnelAddress,
                  tokenAddress,
                  amountIn,
                  amountOutMin,
                  deadline,
                  userReference,
                  signer
                )
              )
              break
            case 'convertForETHSupportingFeeOnTransfer':
              dispatch(
                xxaTunnelContract.ConvertForETHSupportingFeeOnTransfer(
                  tunnelAddress,
                  tokenAddress,
                  amountIn,
                  amountOutMin,
                  deadline,
                  userReference,
                  signer
                )
              )
              break
            default:
              toast.error('No support method.')
              setIsProcessConvert(false)
              break
          }
        }
        dispatch(xxaTunnelService.ClearUserRef())
      }
    }
  }, [isCheckUserRef])

  useEffect(() => {
    if (approveTokenState.type !== TUNNEL_APPROVE_TOKEN_CLEAR_MESSAGE) {
      if (approveTokenState.type === TUNNEL_APPROVE_TOKEN_SUCCESS) {
        toast.success('Approve Success')
        setIsAllowance(true)
      } else if (approveTokenState.type === TUNNEL_APPROVE_TOKEN_FAIL) {
        console.error(approveTokenState.error)
        toast.error(approveTokenState.error.message)
      }

      dispatch({ type: TUNNEL_APPROVE_TOKEN_CLEAR_MESSAGE })
      setIsProcessApprove(false)
    }
  }, [approveTokenState])

  useEffect(() => {
    if (convertTokenState.type !== TUNNEL_CONVERT_TOKEN_CLEAR_MESSAGE) {
      if (
        convertTokenState.type === TUNNEL_CONVERT_TOKEN_SUCCESS &&
        convertTokenState.transaction
      ) {
        toast.success(
          <div className="noti-message-txn">
            <div>Convert Success</div>
            <a
              href={`${tunnelConfig.config?.explorer_base_url}/tx/${
                convertTokenState.transaction.hash ||
                convertTokenState.transaction.transactionHash
              }`}
              target="_blank"
              rel="noreferrer"
            >
              See Transaction
            </a>
          </div>
        )
        if (selectedToken) {
          getTokenBalance(
            selectedToken.address,
            connectWallet.accountAddress,
            web3.provider
          )
        }
      } else if (convertTokenState.type === TUNNEL_CONVERT_TOKEN_FAIL) {
        console.error(convertTokenState.error)
        let msg: string =
          convertTokenState.error.data?.message ||
          convertTokenState.error.message ||
          convertTokenState.error.msg
        msg = msg.replace('execution reverted: ', '')
        toast.error(msg)
      }
      dispatch({ type: TUNNEL_CONVERT_TOKEN_CLEAR_MESSAGE })
      setIsProcessConvert(false)
    }
  }, [convertTokenState])

  useMemo(async () => {
    let queryStr = xxaQueryString.GetQueryString()
    if (queryStr.qrURef) {
      setUserReference(queryStr.qrURef.toUpperCase())
    }
  }, [])

  useMemo(() => {
    getTokenPrice()
  }, [])

  useEffect(() => {
    let intervalTask = setInterval(() => {
      getTokenPrice()
      if (
        connectWallet.accountAddress &&
        connectWallet.networkConnect &&
        web3.provider &&
        selectedToken
      ) {
        getTokenBalance(
          selectedToken.address,
          connectWallet.accountAddress,
          web3.provider
        )
      }
    }, 60000)
    return () => clearInterval(intervalTask)
  }, [selectedToken, connectWallet, platformChain])

  const setSelectTokenDefault = () => {
    let queryStr = xxaQueryString.GetQueryString()
    const tokenSearch = queryStr.tokenSymbol ?? 'SIX'

    if (tunnelConfig.config) {
      let newTokenSelect = tunnelConfig.config.tokens.find(item => {
        return item.symbol === tokenSearch.toUpperCase()
      })

      if (newTokenSelect) {
        setSelectedToken(newTokenSelect)
      } else {
        setSelectedToken(tunnelConfig.config.tokens[0])
      }
    }
  }

  useEffect(() => {
    if (tunnelConfig.type === TUNNEL_GET_CONFIG_SUCCESS) {
      setSelectTokenDefault()
    }
  }, [tunnelConfig])

  useEffect(() => {
    if (
      (connectWallet.type === CONNECT_WALLET_SUCCESS ||
        connectWallet.type === SWITCH_CHAIN_SUCCESS ||
        connectWallet.type === SWITCH_ACCOUNT_SUCCESS) &&
      platformChain.platformChain?.onChain.length > 0
    ) {
      dispatch(
        xxaTunnelService.GetTunnelConfig(
          connectWallet.chainId,
          'crypto_to_point'
        )
      )
    } else if (
      connectWallet.type === CANCEL_CONNECT_WALLET &&
      platformChain.platformChain.onChain.length > 0
    ) {
      dispatch(
        xxaTunnelService.GetTunnelConfig(
          platformChain.platformChain.onChain[0].chainId,
          'crypto_to_point'
        )
      )
    }
    if (
      typeof window.ethereum === 'undefined' &&
      platformChain.platformChain.onChain.length > 0
    ) {
      dispatch(
        xxaTunnelService.GetTunnelConfig(
          platformChain.platformChain.onChain[0].chainId,
          'crypto_to_point'
        )
      )
    }
  }, [connectWallet, platformChain])

  return (
    <CardCustom
      theme={
        darkMode.darkMode === 'dark'
          ? themeConfig.themeConfig?.dark
          : themeConfig.themeConfig?.light
      }
      xl={4}
      lg={5}
      md={8}
      sm={10}
      xs={11}
      className="card convert-container"
    >
      <Col lg={12} className="convert-form-header text-center">
        {themeConfig.themeConfig?.config.cardTitle}
      </Col>
      <hr className="m-0 convert-form-divider" />
      <Col className="px-4 pt-3 pb-4" lg={12}>
        <Form>
          <Form.Group className="mb-3">
            <Form.Label className="d-flex align-items-center">
              <div className="convert-container-user-reference">
                {themeConfig.themeConfig?.config.useRefTitle}
              </div>
              <ReactImageFallback
                alt="question icon"
                className="convert-help-outline-icon"
                role="button"
                onClick={() => {
                  setOpenModalUserRef(true)
                }}
                src={question_icon}
                fallbackImage={image_icon}
                initialImage={image_icon}
              />
            </Form.Label>
            <Form.Control
              className="convert-input"
              disabled={isProcessConvert}
              id="floatingInputURef"
              onInput={onInputUserReference}
              placeholder={themeConfig.themeConfig?.config.useRefEX}
              type="text"
              value={userReference}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Row className="align-items-center">
              <Col xs={4}>
                <TokenDropdown
                  disabled={isProcessConvert || isProcessApprove}
                  onClick={() => {
                    setOpenTokenList(!openTokenList)
                  }}
                  selectedToken={selectedToken}
                />
              </Col>
              <Col className="text-right" xs={8}>
                <div className="d-flex justify-content-end align-items-center convert-balance-content">
                  <Form.Label
                    role="button"
                    onClick={isProcessConvert ? () => {} : setMaxOnTokenInput}
                    className="convert-balance-content-label"
                  >
                    Balance:{' '}
                    {Number(
                      new Decimal(tokenBalance).toFixed(6, Decimal.ROUND_DOWN)
                    ).toLocaleString('en-US', {
                      maximumFractionDigits: 6,
                      minimumFractionDigits: 3
                    })}
                  </Form.Label>
                </div>
              </Col>
            </Row>
            <InputGroup>
              <div className="text-on-input-button">
                <Button
                  disabled={isProcessConvert}
                  onClick={setMaxOnTokenInput}
                  className="button-outline btn-max"
                >
                  Max
                </Button>
              </div>
              <Form.Control
                className="convert-input text-on-input text-on-input-text-end convert-text-right "
                disabled={isProcessConvert}
                onChange={onInputTokenAmount}
                placeholder="0.0"
                type="text"
                value={tokenAmount}
              />
            </InputGroup>
          </Form.Group>
          <Form.Group className="mt-4 pt-2">
            <Row>
              <Col className="d-flex justify-content-center align-items-center text-center">
                <div className="d-flex justify-content-center align-items-center convert-arrow-down">
                  {/* <ReactImageFallback
                    alt="convert icon"
                    src={convert_icon}
                    fallbackImage={image_icon}
                    initialImage={image_icon}
                  /> */}
                  <ConvertIcon
                    width={30}
                    height={30}
                    fill={
                      themeConfig.themeConfig?.[darkMode.darkMode]
                        .convertColor || '#ffffff'
                    }
                  />
                </div>
              </Col>
            </Row>
          </Form.Group>
          <Form.Group className="">
            {/* <Form.Label className="d-flex align-items-center convert-point-label">
            </Form.Label> */}
            <Row>
              <Col xs={4}>
                <div className="d-flex align-items-center py-2">
                  <ReactImageFallback
                    alt="ChomCHOB Point"
                    style={{ display: 'flex' }}
                    height="24px"
                    width="24px"
                    src={themeConfig.themeConfig?.config.pointIcon}
                    fallbackImage={image_icon}
                    initialImage={image_icon}
                  />
                  <div className="convert-text-ccp">
                    {themeConfig.themeConfig?.config.pointUnit}
                  </div>
                  <OverlayTrigger
                    trigger="click"
                    rootClose
                    overlay={props => (
                      <Tooltip id="ccp-info" {...props}>
                        This is just estimate{' '}
                        {themeConfig.themeConfig?.config.pointUnit}, the actual{' '}
                        {themeConfig.themeConfig?.config.pointUnit} maybe change
                        because fluctuation of token price, currency price or
                        tax on transfer of some token.
                      </Tooltip>
                    )}
                    placement="right"
                  >
                    <img
                      alt="question icon"
                      className="convert-help-outline-icon"
                      role="button"
                      src={question_icon}
                    />
                  </OverlayTrigger>
                </div>
              </Col>
              <Col className="text-right" xs={8}>
                <div className="d-flex justify-content-end align-items-center convert-balance-content">
                  {selectedToken?.is_display_fee_rate ? (
                    <Form.Label className="min-max-label">
                      Fee: {selectedToken?.fee_rate}%
                    </Form.Label>
                  ) : null}
                </div>
              </Col>
            </Row>
            <Form.Control
              className="convert-input convert-text-right"
              disabled={isProcessConvert}
              onInput={onInputCcpAmount}
              placeholder="0.0"
              type="text"
              value={ccpAmount}
            />
          </Form.Group>
          <Row className="mb-3 min-max-label">
            <Col xs={5}>
              Min: <span>{formatCurrency(minCcpAmount)}</span>{' '}
              {themeConfig.themeConfig?.config.pointUnit}
            </Col>
            <Col xs={7} className="convert-text-right">
              Max: <span>{formatCurrency(maxCcpAmount)}</span>{' '}
              {themeConfig.themeConfig?.config.pointUnit}
            </Col>
          </Row>
          <Row>{showFreeGas}</Row>
          {selectedToken?.tunnel_method !== 'convertWithTransfer' &&
          selectedToken?.tunnel_method !== 'convertWithTransferETH' &&
          selectedToken?.tunnel_method !== 'convertWithTransferMiddleETH' ? (
            <>
              <Form.Group className="mb-3">
                <div className="advance-option-btn">
                  <div className="text-center convert-advance-option-text ">
                    Advance Options
                    {/* <ReactImageFallback
                      alt="Advance options icon"
                      className="token-dropdown-padding-icon"
                      role="button"
                      onClick={() => {
                        setOpenAdvanceOptions(!openAdvanceOptions)
                      }}
                      src={openAdvanceOptions ? up_icon : down_icon}
                      fallbackImage={image_icon}
                      initialImage={image_icon}
                    /> */}
                    <DownIcon
                      onClick={() => {
                        setOpenAdvanceOptions(!openAdvanceOptions)
                      }}
                      width={16}
                      height={16}
                      fill={
                        themeConfig.themeConfig?.[darkMode.darkMode]
                          .primaryColor || '#ffffff'
                      }
                    />
                  </div>
                </div>
                <Collapse in={openAdvanceOptions}>
                  <div>
                    <div className={`d-flex w-100 gap-2 mt-2`}>
                      <div className="w-50">
                        <Form.Group className="mb-3">
                          <Form.Label className="d-flex align-items-center advance-options-text">
                            Slippage
                          </Form.Label>
                          <InputGroup>
                            <Form.Control
                              onBlur={onBlurSlippage}
                              className="convert-input text-on-input text-on-input-text-start"
                              disabled={isProcessConvert}
                              onInput={onInputSlippage}
                              placeholder="0.0"
                              type="text"
                              value={slippage}
                            />
                            <InputGroup.Text className="text-on-input-text text-on-input-text-right">
                              %
                            </InputGroup.Text>
                          </InputGroup>
                        </Form.Group>
                      </div>
                      <div className="w-50">
                        <Form.Group className="mb-3">
                          <Form.Label
                            className="d-flex align-items-center advance-options-text"
                            htmlFor="deadline-input"
                          >
                            Deadline
                          </Form.Label>
                          <InputGroup>
                            <FormControl
                              onBlur={onBlurDeadline}
                              className="convert-input text-on-input text-on-input-text-start"
                              disabled={isProcessConvert}
                              id="deadline-input"
                              onInput={onInputDeadline}
                              placeholder="0"
                              type="text"
                              value={deadlineMins}
                            />
                            <InputGroup.Text className="text-on-input-text  text-on-input-text-right">
                              Minutes
                            </InputGroup.Text>
                          </InputGroup>
                        </Form.Group>
                      </div>
                    </div>
                  </div>
                </Collapse>
              </Form.Group>
            </>
          ) : null}
          <Collapse in={Number(ccpAmount) !== 0}>
            <Form.Group className="mb-3">
              <Form.Label className="card mini-info-box">
                <Card.Body className="row">
                  <Col xs={4}>Price</Col>
                  <Col xs={8} className="switch-price-text convert-text-right">
                    <ReactImageFallback
                      alt="switch price convert icon"
                      role="button"
                      onClick={switchPriceType}
                      src={reload_icon}
                      fallbackImage={image_icon}
                      initialImage={image_icon}
                    />
                    <div>
                      {priceType === 'ccp'
                        ? Number(price).toLocaleString('en-US', {
                            maximumFractionDigits: 2,
                            minimumFractionDigits: 2
                          })
                        : Number(priceToken).toLocaleString('en-US', {
                            maximumFractionDigits: 8,
                            minimumFractionDigits: 2
                          })}{' '}
                      {priceType === 'ccp'
                        ? `${themeConfig.themeConfig?.config.pointUnit}/${selectedToken?.symbol}`
                        : `${selectedToken?.symbol}/${themeConfig.themeConfig?.config.pointUnit}`}
                    </div>
                  </Col>
                  {selectedToken?.tunnel_method !== 'convertWithTransfer' &&
                  selectedToken?.tunnel_method !== 'convertWithTransferETH' &&
                  selectedToken?.tunnel_method !==
                    'convertWithTransferMiddleETH' ? (
                    <>
                      <Col xs={6}>Price Impact</Col>
                      <Col
                        className={`price-impact-${formatPriceImpactStyle(
                          priceImpact
                        )} convert-text-right`}
                        xs={6}
                      >
                        {formatPriceImpact(priceImpact)}%
                      </Col>
                    </>
                  ) : null}
                </Card.Body>
              </Form.Label>
            </Form.Group>
          </Collapse>
          <Form.Group className="d-grid gap-2">
            {connectWallet.accountAddress
              ? !isAllowance && (
                  <Button
                    className={`button approve-button shadow-none ${
                      isProcessApprove ? 'text-loading' : ''
                    }`}
                    disabled={isProcessApprove}
                    onClick={onClickApprove}
                    variant="primary"
                  >
                    {isProcessApprove && (
                      <span
                        aria-hidden="true"
                        className="spinner-border spinner-border-sm convert-margin-right"
                        role="status"
                      ></span>
                    )}
                    <b>APPROVE</b>
                  </Button>
                )
              : null}
            <Button
              className="button convert-button"
              disabled={isProcessConvert || !isAllowance}
              onClick={onClickConvert}
              variant="primary"
            >
              {isProcessConvert && (
                <span
                  aria-hidden="true"
                  className="spinner-border spinner-border-sm convert-margin-right"
                  role="status"
                ></span>
              )}
              <b>CONVERT</b>
            </Button>
          </Form.Group>
          <div className="mt-1 d-flex align-items-center w-100 justify-content-center">
            <a
              href={howToUse}
              className="custom-link custom-link-how-to"
              target="_blank"
              rel="noopener noreferrer"
            >
              How to use
            </a>
          </div>
        </Form>
        <TokenListModal
          show={openTokenList}
          onHide={() => setOpenTokenList(false)}
          setSelectedToken={setSelectedToken}
        />
        <ModalUserRef
          show={openModalUserRef}
          onHide={() => setOpenModalUserRef(false)}
          imgSrc={themeConfig.themeConfig?.config.userRefImage || []}
          height={200}
        />
      </Col>
    </CardCustom>
  )
}

export default ConvertForm

const CardCustom = styled(Col)<xxcNavBarTypes.NavBarCustom>`
  background-color: ${props => props.theme.cardColor};
  .min-max-label {
    color: ${props => props.theme.fontSecondaryColor};
  }
  .convert-balance-content-label {
    color: ${props => props.theme.primaryColor};
  }
  .mini-info-box {
    background-color: ${props => props.theme.input.inputBackgroundColor};
  }

  .advance-options-text {
    color: ${props => props.theme.primaryColor};
  }
  .form-check-input:checked {
    background-color: ${props => props.theme.primaryColor};
    border-color: ${props => props.theme.primaryColor};
  }
  .form-check-input:focus {
    box-shadow: 0 0 0 0.25rem ${props => props.theme.primaryColor}40;
  }
  .form-switch .form-check-input {
    width: 46px;
    height: 22px;
    margin-top:0;
  }
  .form-check-input:focus {
    box-shadow: 0 0 0 0.25rem ${props => props.theme.primaryColor}40;
    border-color: ${props => props.theme.primaryColor};
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23${props =>
      props.theme.primaryColor.replaceAll('#', '')}'/%3e%3c/svg%3e");
  }
  .form-switch .form-check-input:checked {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
  }
  .custom-link {
    text-underline-offset: 0.5px;
    &:hover {
      color: ${props => props.theme.fontPrimaryColor};
      text-decoration: underline solid ${props => props.theme.fontPrimaryColor}
        1px;
    }
    &-how-to {
      color: ${props => props.theme.primaryColor};
      text-decoration: underline solid ${props => props.theme.primaryColor} 1px;
    }
  }

  .approve-button {
    background-color: ${props =>
      props.theme.button.approveButton.backgroundColor};
    border-color: ${props => props.theme.button.approveButton.backgroundColor};
    color: ${props => props.theme.button.approveButton.fontColor};
    &:hover {
      opacity: 0.65;
      color: ${props => props.theme.button.approveButton.fontColor};
    }
    &:active {
      opacity: 0.65;
      color: ${props => props.theme.button.approveButton.fontColor};
    }
    &:active:focus {
      box-shadow: 0 0 0 0.25rem
        ${props => props.theme.button.approveButton.backgroundColor}8c !important;
    }
  }

  .convert-button {
    background-color: ${props =>
      props.theme.button.convertButton.backgroundColor};
    border-color: ${props => props.theme.button.convertButton.backgroundColor};
    color: ${props => props.theme.button.convertButton.fontColor};
    &:hover {
      opacity: 0.65;
      color: ${props => props.theme.button.convertButton.fontColor};
    }
    &:active {
      opacity: 0.65;
      color: ${props => props.theme.button.convertButton.fontColor};
    }
    &:active:focus {
      box-shadow: 0 0 0 0.25rem
        ${props => props.theme.button.convertButton.backgroundColor}8c !important;
    }
  }

  .btn-max {
    border-radius: 10px !important;
    border-color: ${props => props.theme.button.maxButton.backgroundColor};
    color: ${props => props.theme.button.maxButton.backgroundColor};
    &:hover {
      background-color: ${props =>
        props.theme.button.maxButton.backgroundColor};
      border-color: ${props => props.theme.button.maxButton.backgroundColor};
      color: ${props => props.theme.button.maxButton.fontColor};
    }
    &:focus {
      background-color: ${props => props.theme.button.maxButton.fontColor};
      border-color: ${props => props.theme.button.maxButton.backgroundColor};
      color: ${props => props.theme.button.maxButton.backgroundColor};
    }
    &:active {
      background-color: ${props =>
        props.theme.button.maxButton.backgroundColor};
      border-color: ${props => props.theme.button.maxButton.backgroundColor};
      color: ${props => props.theme.button.maxButton.fontColor};
    }
    &:active:focus {
      box-shadow: 0 0 0 0.25rem
        ${props => props.theme.button.maxButton.backgroundColor}8c !important;
    }
    &:disabled {
      background-color: ${props =>
        props.theme.button.maxButton.backgroundColor};
      color: ${props => props.theme.button.maxButton.fontColor};
    }
  }
  @media (max-width: 540px) {
    .approve-button {
      &:hover {
        background-color: ${props =>
          props.theme.button.approveButton.backgroundColor};
        border-color: ${props =>
          props.theme.button.approveButton.backgroundColor};
        color: ${props => props.theme.button.approveButton.fontColor};
        opacity: unset;
      }
      &:active {
        box-shadow: 0 0 0 0.25rem
          ${props => props.theme.button.approveButton.backgroundColor}8c !important;
        opacity: 0.65;
        color: ${props => props.theme.button.approveButton.fontColor};
      }
      &:disabled {
        opacity: 0.65 !important;
      }
    }
    .convert-button {
      &:hover {
        background-color: ${props =>
          props.theme.button.convertButton.backgroundColor};
        border-color: ${props =>
          props.theme.button.convertButton.backgroundColor};
        color: ${props => props.theme.button.convertButton.fontColor};
        opacity: unset;
      }
      &:active {
        box-shadow: 0 0 0 0.25rem
          ${props => props.theme.button.convertButton.backgroundColor}8c !important;
        opacity: 0.65;
        color: ${props => props.theme.button.convertButton.fontColor};
      }
      &:disabled {
        opacity: 0.65 !important;
      }
    }
    .btn-max {
      &:hover {
        background-color: unset;
        border-color: ${props => props.theme.button.maxButton.backgroundColor};
        color: ${props => props.theme.button.maxButton.backgroundColor};
      }
      &:active {
        box-shadow: 0 0 0 0.25rem
          ${props => props.theme.button.maxButton.backgroundColor}8c !important;
        background-color: ${props =>
          props.theme.button.maxButton.backgroundColor};
        color: ${props => props.theme.button.maxButton.fontColor};
      }
    }
  }
`
