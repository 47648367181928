// Constants
import {
  LOGOUT_CLEAR_MESSAGE,
  LOGOUT_FAIL,
  LOGOUT_SUCCESS
} from 'redux/Constants/Login/Logout'

// Props Types
import IAction from 'redux/Action/types'
import xxreLogoutReducerTypes from 'redux/Reducers/Login/Logout/types'

const logout = (
  state: object = {},
  action: IAction<xxreLogoutReducerTypes.Props>
) => {
  switch (action.type) {
    case LOGOUT_SUCCESS:
      return {
        ...state,
        ...action.payload,
        type: action.type
      }
    case LOGOUT_FAIL:
      return {
        ...state,
        ...action.payload,
        type: action.type
      }
    case LOGOUT_CLEAR_MESSAGE:
      return {}
    default:
      return state
  }
}

export default logout
