//components
import TableHistory from 'components/HistoryPage/TableHistory'

const HistoryPage = () => {
  return (
    <>
      <TableHistory type="point_to_crypto" />
    </>
  )
}

export default HistoryPage
