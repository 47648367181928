import { ethers } from 'ethers'
import { Dispatch } from 'redux'

// Redux Constants
import {
  CONNECT_WALLET_SUCCESS,
  CONNECT_WALLET_FAIL,
  CONNECT_WALLET_CLEAR_MESSAGE,
  SWITCH_CHAIN_SUCCESS,
  ADD_CHAIN_SUCCESS,
  CANCEL_CONNECT_WALLET
} from 'redux/Constants/ConnectWallet'
import { WEB3_CONNECT_SUCCESS } from 'redux/Constants/Web3'

//type
import xxcChainModalTypes from 'configs/Model'

declare let window: any

const xxaConnectWallet = {
  ChangeChainAndAccount:
    (chainId: number, address: string, networkConnect: boolean, type: string) =>
    async (dispatch: Dispatch) => {
      const provider = new ethers.providers.Web3Provider(window.ethereum)
      dispatch({
        type: WEB3_CONNECT_SUCCESS,
        payload: {
          provider
        }
      })
      dispatch({
        type: type,
        payload: {
          accountAddress: address,
          chainId,
          networkConnect
        }
      })
    },
  ClearConnectWallet: () => async (dispatch: Dispatch) => {
    dispatch({
      type: CONNECT_WALLET_CLEAR_MESSAGE
    })
  },
  ConnectWallet:
    (requestChain?: number | null) =>
    async (dispatch: Dispatch, getState: Function) => {
      if (window.ethereum) {
        const { ethereum } = window
        const provider = new ethers.providers.Web3Provider(ethereum)
        const chainId = (await provider.getNetwork()).chainId
        const platformChain = getState().platformChain
          .platformChain as xxcChainModalTypes.chainResponse
        const chainData = platformChain.onChain.find(item => {
          return chainId === item.chainId
        })
        const requestChainData = platformChain.onChain.find(item => {
          return requestChain === item.chainId
        })

        if (requestChain) {
          if (chainId === requestChain) {
            try {
              const accounts: string[] = await ethereum.request({
                method: 'eth_requestAccounts'
              })
              window.localStorage.setItem('metamask', 'metamask')
              dispatch({
                type: WEB3_CONNECT_SUCCESS,
                payload: {
                  provider
                }
              })
              dispatch({
                type: CONNECT_WALLET_SUCCESS,
                payload: {
                  accountAddress: accounts[0],
                  chainId,
                  networkConnect: true
                }
              })
            } catch (error) {
              dispatch({
                type: CANCEL_CONNECT_WALLET,
                payload: {
                  accountAddress: '',
                  chainId,
                  networkConnect: false
                }
              })
            }
          } else {
            dispatch({
              type: CONNECT_WALLET_FAIL,
              payload: {
                accountAddress: '',
                chainId: requestChainData
                  ? requestChain
                  : platformChain.onChain[0].chainId,
                networkConnect: false
              }
            })
          }
        } else {
          if (chainData) {
            try {
              const accounts: string[] = await ethereum.request({
                method: 'eth_requestAccounts'
              })
              window.localStorage.setItem('metamask', 'metamask')
              dispatch({
                type: WEB3_CONNECT_SUCCESS,
                payload: {
                  provider
                }
              })
              dispatch({
                type: CONNECT_WALLET_SUCCESS,
                payload: {
                  accountAddress: accounts[0],
                  chainId,
                  networkConnect: true
                }
              })
            } catch (error) {
              dispatch({
                type: CANCEL_CONNECT_WALLET,
                payload: {
                  accountAddress: '',
                  chainId,
                  networkConnect: false
                }
              })
            }
          } else {
            dispatch({
              type: CONNECT_WALLET_FAIL,
              payload: {
                accountAddress: '',
                chainId: platformChain.onChain[0].chainId,
                networkConnect: false
              }
            })
          }
        }
      } else {
        let url = `${window.location.hostname}${window.location.pathname}${window.location.search}`
        window.open(
          `https://metamask.app.link/dapp/${url}`,
          '_blank' // <- This is what makes it open in a new window.
        )
      }
    },
  SwitchChain:
    (chainId: number) => async (dispatch: Dispatch, getState: Function) => {
      if (window.ethereum) {
        try {
          const { ethereum } = window
          const platformChain = getState().platformChain
            .platformChain as xxcChainModalTypes.chainResponse
          const chainData = platformChain.onChain.find(item => {
            return chainId === item.chainId
          })
          let userChainID = ''
          if (chainData) {
            userChainID = `0x${chainId.toString(16)}`
          } else {
            userChainID = `0x${platformChain.onChain[0].chainId.toString(16)}`
          }
          await ethereum.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: userChainID }]
          })

          let networkConnect = true
          const provider = new ethers.providers.Web3Provider(window.ethereum)
          dispatch({
            type: WEB3_CONNECT_SUCCESS,
            payload: {
              provider
            }
          })
          const accounts: string[] = await ethereum.request({
            method: 'eth_requestAccounts'
          })
          dispatch({
            type: SWITCH_CHAIN_SUCCESS,
            payload: {
              accountAddress: accounts[0],
              chainId,
              networkConnect
            }
          })
        } catch (error: any) {
          if (error.code === 4902 || error.code === -32603) {
            try {
              const platformChain = getState().platformChain
                .platformChain as xxcChainModalTypes.chainResponse
              const chainData = platformChain.onChain.find(item => {
                return chainId === item.chainId
              })
              if (chainData) {
                await window.ethereum.request({
                  method: 'wallet_addEthereumChain',
                  params: [
                    {
                      chainId: `0x${chainData.chainId.toString(16)}`,
                      chainName: chainData.chainName,
                      nativeCurrency: {
                        name: chainData.nativeCurrency.name,
                        symbol: chainData.nativeCurrency.symbol,
                        decimals: chainData.nativeCurrency.decimals
                      },
                      rpcUrls: [chainData.rpcURL],
                      blockExplorerUrls: [chainData.blockExplorerUrls]
                    }
                  ]
                })
                dispatch({
                  type: ADD_CHAIN_SUCCESS,
                  payload: {
                    accountAddress: '',
                    chainId,
                    networkConnect: false
                  }
                })
              } else {
                dispatch({
                  type: CONNECT_WALLET_CLEAR_MESSAGE
                })
              }
            } catch {}
          }
        }
      }
    }
}

export default xxaConnectWallet
