import xxaPlatform from 'actions/Platform'
// Constants
import {
  GET_PLATFORM_CLEAR_MESSAGE,
  GET_PLATFORM_SUCCESS
} from 'redux/Constants/Platform/Get'

// Props Types
import IAction from 'redux/Action/types'
import xxreGetPlatformReducerTypes from 'redux/Reducers/Platform/Get/types'

const themeConfig = (
  state: xxreGetPlatformReducerTypes.Props = {
    type: GET_PLATFORM_SUCCESS,
    getPlatform: xxaPlatform.GetPlatformBySubdomain()
  },
  action: IAction<xxreGetPlatformReducerTypes.Props>
) => {
  switch (action.type) {
    case GET_PLATFORM_SUCCESS:
      return {
        ...state,
        ...action.payload,
        type: action.type
      }
    case GET_PLATFORM_CLEAR_MESSAGE:
      return {}
    default:
      return state
  }
}

export default themeConfig
