// Constants
import {
  GET_PLATFORM_CHAIN_CLEAR_MESSAGE,
  GET_PLATFORM_CHAIN_FAIL,
  GET_PLATFORM_CHAIN_SUCCESS
} from 'redux/Constants/Platform/Chain'

// Props Types
import IAction from 'redux/Action/types'
import xxretPlatformChainReducerTypes from 'redux/Reducers/Platform/Chain/types'

const platformChain = (
  state: object = {},
  action: IAction<xxretPlatformChainReducerTypes.Props>
) => {
  switch (action.type) {
    case GET_PLATFORM_CHAIN_SUCCESS:
      return {
        ...state,
        ...action.payload,
        type: action.type
      }
    case GET_PLATFORM_CHAIN_FAIL:
      return {
        ...state,
        ...action.payload,
        type: action.type
      }
    case GET_PLATFORM_CHAIN_CLEAR_MESSAGE:
      return {}
    default:
      return state
  }
}

export default platformChain
