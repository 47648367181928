// Constants
import {
  BINANCE_TO_POINT_REQUEST_CLEAR_MESSAGE,
  BINANCE_TO_POINT_REQUEST_FAIL,
  BINANCE_TO_POINT_REQUEST_SUCCESS
} from 'redux/Constants/TunnelService'

// Props Types
import IAction from 'redux/Action/types'
import xxreTunnelServiceBinanceToPointRequestReducerTypes from 'redux/Reducers/TunnelService/BinanceToPointRequest/types'

const binanceToPointRequest = (
  state: object = {},
  action: IAction<xxreTunnelServiceBinanceToPointRequestReducerTypes.Props>
) => {
  switch (action.type) {
    case BINANCE_TO_POINT_REQUEST_SUCCESS:
      return {
        ...state,
        ...action.payload,
        type: action.type
      }
    case BINANCE_TO_POINT_REQUEST_FAIL:
      return {
        ...state,
        ...action.payload,
        type: action.type
      }
    case BINANCE_TO_POINT_REQUEST_CLEAR_MESSAGE:
      return {}
    default:
      return state
  }
}

export default binanceToPointRequest
