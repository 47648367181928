// Constants
import {
  DELETE_ADDRESS_WALLET_CLEAR_MESSAGES,
  DELETE_ADDRESS_WALLET_FAIL,
  DELETE_ADDRESS_WALLET_SUCCESS
} from 'redux/Constants/Address'

// Props Types
import IAction from 'redux/Action/types'
import xxreDeleteWalletAddressServiceTypes from 'redux/Reducers/Address/Delete/types'

const deleteWalletAddress = (
  state: object = {},
  action: IAction<xxreDeleteWalletAddressServiceTypes.Props>
) => {
  switch (action.type) {
    case DELETE_ADDRESS_WALLET_SUCCESS:
      return {
        ...state,
        ...action.payload,
        type: action.type
      }
    case DELETE_ADDRESS_WALLET_FAIL:
      return {
        ...state,
        ...action.payload,
        type: action.type
      }
    case DELETE_ADDRESS_WALLET_CLEAR_MESSAGES:
      return {}
    default:
      return state
  }
}

export default deleteWalletAddress
