//components
import TableHistory from 'components/HistoryPage/TableHistory'

const HistoryPage = () => {
  return (
    <>
      <TableHistory type="crypto_to_point" />
    </>
  )
}

export default HistoryPage
