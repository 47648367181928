import { Modal, Button } from 'react-bootstrap'
import ReactImageFallback from 'react-image-fallback'

// Types
import xxcModalUserRefTypes from './Model'

import close_icon from 'assets/Svg/icon/close_icon.svg'
import image_icon from 'assets/Svg/icon/image_icon.svg'

function ModalUserRef({
  onHide,
  show,
  imgSrc,
  height
}: xxcModalUserRefTypes.Props) {
  return (
    <Modal
      aria-labelledby="contained-modal-title-vcenter"
      centered
      dialogClassName="modal-image"
      className="modal-fullscreen-md-down"
      id="item-modal"
      onHide={onHide}
      show={show}
    >
      <Modal.Body className="p-0">
        <div className="box-image-modal">
          {imgSrc.map((item: string, index: number) => {
            return (
              <ReactImageFallback
                style={{ minHeight: `${height}px` }}
                key={`img-${index}`}
                alt={`how-to-use`}
                src={item}
                fallbackImage={image_icon}
                initialImage={<div style={{ minHeight: `${height}px` }}></div>}
                // initialImage={image_icon}
              />
            )
          })}
        </div>
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-center">
        <Button className="btn btn-light close-button-image" onClick={onHide}>
          <ReactImageFallback
            width="20px"
            height="20px"
            alt={`close-modal-image`}
            src={close_icon}
            fallbackImage={image_icon}
            initialImage={image_icon}
          />
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default ModalUserRef
