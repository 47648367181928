import { useState } from 'react'

// Components
import ConvertWithBinance from 'components/IntroductionPage/Content/ConvertWithBinance'
import PointToCrypto from 'components/IntroductionPage/Content/PointToCrypto'
import CryptoToPoint from 'components/IntroductionPage/Content/CryptoToPoint'
import HowToUseModal from 'components/IntroductionPage/Modals'

// Data
import { modalDetail } from 'components/IntroductionPage/Content/data'

const Content = () => {
  const [isPointToBinanceModal, setPointToBinanceModal] = useState(false)
  const [isPointToCryptoModal, setPointToCryptoModal] = useState(false)
  const [isCryptoToPointModal, setCryptoToPointModal] = useState(false)

  return (
    <section className="introduction-container__content__container">
      <div className="content__background">
        <div className="content__background__container">
          <div className="splitter-container">
            <div className="vertical-dot-line" />
            <div className="circle-dot-top translate-middle-x" />
          </div>
          <div className="circle-green" />
          <div className="circle-blue" />
        </div>
      </div>

      <div className="content__content-container container">
        <ConvertWithBinance setPointToBinanceModal={setPointToBinanceModal} />
        <HowToUseModal
          show={isPointToBinanceModal}
          onHide={() => setPointToBinanceModal(false)}
          modalDetail={modalDetail().pointToBinance}
        />

        <div className="content__gap">
          <div className="circle-dot" />
        </div>

        <PointToCrypto setPointToCryptoModal={setPointToCryptoModal} />
        <HowToUseModal
          show={isPointToCryptoModal}
          onHide={() => setPointToCryptoModal(false)}
          modalDetail={modalDetail().pointToCrypto}
        />

        <div className="content__gap">
          <div className="circle-dot" />
        </div>

        <CryptoToPoint setCryptoToPointModal={setCryptoToPointModal} />
        <HowToUseModal
          show={isCryptoToPointModal}
          onHide={() => setCryptoToPointModal(false)}
          modalDetail={modalDetail().cryptoToPoint}
        />
      </div>
    </section>
  )
}

export default Content
