export const TUNNEL_CHECK_USER_REF_SUCCESS = 'TUNNEL_CHECK_USER_REF_SUCCESS'
export const TUNNEL_CHECK_USER_REF_FAIL = 'TUNNEL_CHECK_USER_REF_FAIL'
export const TUNNEL_CHECK_USER_REF_CLEAR_MESSAGE =
  'TUNNEL_CHECK_USER_REF_CLEAR_MESSAGE'

export const TUNNEL_GET_CONFIG_SUCCESS = 'TUNNEL_GET_CONFIG_SUCCESS'
export const TUNNEL_GET_CONFIG_FAIL = 'TUNNEL_GET_CONFIG_FAIL'
export const TUNNEL_GET_CONFIG_CLEAR_MESSAGE = 'TUNNEL_GET_CONFIG_CLEAR_MESSAGE'

export const TUNNEL_GET_TOKEN_PRICE_SUCCESS = 'TUNNEL_GET_TOKEN_PRICE_SUCCESS'
export const TUNNEL_GET_TOKEN_PRICE_FAIL = 'TUNNEL_GET_TOKEN_PRICE_FAIL'
export const TUNNEL_GET_TOKEN_PRICE_CLEAR_MESSAGE =
  'TUNNEL_GET_TOKEN_PRICE_CLEAR_MESSAGE'

export const TUNNEL_GET_HISTORY_SUCCESS = 'TUNNEL_GET_HISTORY_SUCCESS'
export const TUNNEL_GET_HISTORY_FAIL = 'TUNNEL_GET_HISTORY_FAIL'
export const TUNNEL_GET_HISTORY_CLEAR_MESSAGE =
  'TUNNEL_GET_HISTORY_CLEAR_MESSAGE'

export const POINT_TO_CRYPTO_REQUEST_SUCCESS = 'POINT_TO_CRYPTO_REQUEST_SUCCESS'
export const POINT_TO_CRYPTO_REQUEST_FAIL = 'POINT_TO_CRYPTO_REQUEST_FAIL'
export const POINT_TO_CRYPTO_REQUEST_CLEAR_MESSAGE =
  'POINT_TO_CRYPTO_REQUEST_CLEAR_MESSAGE'

export const POINT_TO_CRYPTO_CONFIRM_SUCCESS = 'POINT_TO_CRYPTO_CONFIRM_SUCCESS'
export const POINT_TO_CRYPTO_CONFIRM_FAIL = 'POINT_TO_CRYPTO_CONFIRM_FAIL'
export const POINT_TO_CRYPTO_CONFIRM_CLEAR_MESSAGE =
  'POINT_TO_CRYPTO_CONFIRM_CLEAR_MESSAGE'

export const POINT_TO_CRYPTO_HISTORY_SUCCESS = 'POINT_TO_CRYPTO_HISTORY_SUCCESS'
export const POINT_TO_CRYPTO_HISTORY_FAIL = 'POINT_TO_CRYPTO_HISTORY_FAIL'
export const POINT_TO_CRYPTO_HISTORY_CLEAR_MESSAGE =
  'POINT_TO_CRYPTO_HISTORY_CLEAR_MESSAGE'

export const BINANCE_TO_POINT_REQUEST_SUCCESS =
  'BINANCE_TO_POINT_REQUEST_SUCCESS'
export const BINANCE_TO_POINT_REQUEST_FAIL = 'BINANCE_TO_POINT_REQUEST_FAIL'
export const BINANCE_TO_POINT_REQUEST_CLEAR_MESSAGE =
  'BINANCE_TO_POINT_REQUEST_CLEAR_MESSAGE'

export const BINANCE_TO_POINT_HISTORY_SUCCESS =
  'BINANCE_TO_POINT_HISTORY_SUCCESS'
export const BINANCE_TO_POINT_HISTORY_FAIL = 'BINANCE_TO_POINT_HISTORY_FAIL'
export const BINANCE_TO_POINT_HISTORY_CLEAR_MESSAGE =
  'BINANCE_TO_POINT_HISTORY_CLEAR_MESSAGE'

export const BINANCE_TO_POINT_GET_TRANSACTION_SUCCESS =
  'BINANCE_TO_POINT_GET_TRANSACTION_SUCCESS'
export const BINANCE_TO_POINT_GET_TRANSACTION_FAIL =
  'BINANCE_TO_POINT_GET_TRANSACTION_FAIL'
export const BINANCE_TO_POINT_GET_TRANSACTION_CLEAR_MESSAGE =
  'BINANCE_TO_POINT_GET_TRANSACTION_CLEAR_MESSAGE'
