// Constants
import {
  VERIFY_LOGIN_CLEAR_MESSAGE,
  VERIFY_LOGIN_FAIL,
  VERIFY_LOGIN_SUCCESS
} from 'redux/Constants/Login/VerifyLogin'

// Props Types
import IAction from 'redux/Action/types'
import xxreVerifyLoginReducerTypes from 'redux/Reducers/Login/VerifyLogin/types'

const verifyLogin = (
  state: object = {},
  action: IAction<xxreVerifyLoginReducerTypes.Props>
) => {
  switch (action.type) {
    case VERIFY_LOGIN_SUCCESS:
      return {
        ...state,
        ...action.payload
      }
    case VERIFY_LOGIN_FAIL:
      return {
        ...state,
        ...action.payload
      }
    case VERIFY_LOGIN_CLEAR_MESSAGE:
      return {}
    default:
      return state
  }
}

export default verifyLogin
