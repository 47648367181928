export const modalDetail = () => ({
	pointToBinance: {
        altText : 'Point to Binance',
        imageLists : [
            'https://storage.googleapis.com/chomchob/production/public/Tunnel/bda7205a-b6ab-4a10-a55a-092640a5d871',
            'https://storage.googleapis.com/chomchob/production/public/Tunnel/4720eb31-c889-41f1-8649-b590efb89d50',
            'https://storage.googleapis.com/chomchob/production/public/Tunnel/e707dc90-6c65-4c43-9c7e-6b9fb52fbb18',
            'https://storage.googleapis.com/chomchob/production/public/Tunnel/e2e2e8a7-83d3-4b1e-8235-05f61ae9cc25',
            'https://storage.googleapis.com/chomchob/production/public/Tunnel/8b3189ae-6239-4dfb-b050-04cd701301c5',
            'https://storage.googleapis.com/chomchob/production/public/Tunnel/28a21af1-acc2-4f0b-aaff-c976da99574d',
            'https://storage.googleapis.com/chomchob/production/public/Tunnel/e80b4a29-9de2-49a9-b125-83944b3b9b1f',
            'https://storage.googleapis.com/chomchob/production/public/Tunnel/5b970676-fc60-406d-9f00-87c890ef6ce1',
            'https://storage.googleapis.com/chomchob/production/public/Tunnel/94dcc02e-c86d-467a-8359-7559d4b8fb1a'
        ]
	},
	pointToCrypto: {
        altText : 'Point to Crypto',
        imageLists : [
            'https://storage.googleapis.com/chomchob/production/public/Tunnel/53bc79fa-4c75-4f83-80d6-f7de12ae6195',
            'https://storage.googleapis.com/chomchob/production/public/Tunnel/2c58c6e3-9312-4457-aa7a-aa75d862f151',
            'https://storage.googleapis.com/chomchob/production/public/Tunnel/b9424af4-343e-4984-9104-83eeb7145963',
            'https://storage.googleapis.com/chomchob/production/public/Tunnel/62b5728c-f38b-4fc3-81a4-ec25d9cae071',
            'https://storage.googleapis.com/chomchob/production/public/Tunnel/8279dc7e-492d-4d10-9562-975f2a42e2bd',
            'https://storage.googleapis.com/chomchob/production/public/Tunnel/6508d985-d1af-49eb-96fd-c78a059ba0de'
        ]
	},
	cryptoToPoint: {
        altText : 'Crypto to Point',
        imageLists : [
            'https://storage.googleapis.com/chomchob/production/public/Tunnel/bb286c86-ffb4-4bd5-97ef-095b7bfa5660',
            'https://storage.googleapis.com/chomchob/production/public/Tunnel/181c4413-fc4f-4d3c-8cb8-8d0a118d5816',
            'https://storage.googleapis.com/chomchob/production/public/Tunnel/2c1206be-a07d-4450-851f-5415abd59319',
            'https://storage.googleapis.com/chomchob/production/public/Tunnel/44d0bdea-472a-455b-81fb-ef858d83c569',
            'https://storage.googleapis.com/chomchob/production/public/Tunnel/e760553e-3323-4d00-85fe-37c7270fdaa0',
            'https://storage.googleapis.com/chomchob/production/public/Tunnel/d007ada6-c982-48fa-916b-071f3bda2586',
            'https://storage.googleapis.com/chomchob/production/public/Tunnel/235fa963-3a6a-4de8-a0f6-aa28323bfc56',
            'https://storage.googleapis.com/chomchob/production/public/Tunnel/60a747fc-ee06-439a-8e82-301c6ee2e25f',
            'https://storage.googleapis.com/chomchob/production/public/Tunnel/c891ec16-7bc6-4cfb-905a-e1ffe325ad2f'
        ]
	}
})