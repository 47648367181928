// Constants
import {
  SET_THEME_FAIL,
  SET_THEME_SUCCESS,
  SET_THEME_CLEAR_MESSAGE
} from 'redux/Constants/Theme'

// Props Types
import IAction from 'redux/Action/types'
import xxreThemeReducerTypes from 'redux/Reducers/Theme/types'

//mock

const themeConfig = (
  state: object = {},
  action: IAction<xxreThemeReducerTypes.Props>
) => {
  switch (action.type) {
    case SET_THEME_SUCCESS:
      return {
        ...state,
        ...action.payload,
        type: action.type
      }
    case SET_THEME_FAIL:
      return {
        ...state,
        ...action.payload,
        type: action.type
      }
    case SET_THEME_CLEAR_MESSAGE:
      return {}
    default:
      return state
  }
}

export default themeConfig
