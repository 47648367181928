import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'

// Types
import xxcSkeletonLoadTypes from './Model'

function SkeletonLoad({
  height,
  width,
  baseColor
}: xxcSkeletonLoadTypes.Props) {
  const colorShade = (col: string, amt: number) => {
    col = col.replace(/^#/, '')
    if (col.length === 3)
      col = col[0] + col[0] + col[1] + col[1] + col[2] + col[2]

    let [r, g, b]: any = col.match(/.{2}/g)
    ;[r, g, b] = [
      parseInt(r, 16) + amt,
      parseInt(g, 16) + amt,
      parseInt(b, 16) + amt
    ]

    r = Math.max(Math.min(255, r), 0).toString(16)
    g = Math.max(Math.min(255, g), 0).toString(16)
    b = Math.max(Math.min(255, b), 0).toString(16)

    const rr = (r.length < 2 ? '0' : '') + r
    const gg = (g.length < 2 ? '0' : '') + g
    const bb = (b.length < 2 ? '0' : '') + b

    return `#${rr}${gg}${bb}`
  }

  return (
    // <div className={`${darkMode === 'dark' ? 'd-none' : 'd-flex'}`}>
    //   <Skeleton height={height} width={width} />
    // </div>
    <SkeletonTheme
      baseColor={baseColor}
      highlightColor={baseColor ? colorShade(baseColor, -10) : undefined}
    >
      <div style={{ minWidth: `${width}px` }}>
        <Skeleton height={height} width={width} />
      </div>
    </SkeletonTheme>
  )
}

export default SkeletonLoad
