import axios from 'axios'
import xxsProfileServiceTypes from 'services/Profile/types'
import xxaDevice from 'actions/Utils/Device'
import { servicePath } from 'configs'
import xxaInitial from 'actions/Initial'

const xxsProfileService = {
  GetProfile:
    async (): Promise<xxsProfileServiceTypes.GetProfileSuccessResponse> => {
      let response: xxsProfileServiceTypes.GetProfileSuccessResponse
      const token = await xxaInitial.Check()

      try {
        const responseData =
          await axios.get<xxsProfileServiceTypes.GetProfileSuccess>(
            `${servicePath.service.tunnel}/v1/tunnel/user/profile`,
            {
              headers: {
                Authorization: token,
                'device-Id': await xxaDevice.GetDeviceId(),
                'accept-language': servicePath.acceptLanguage
              }
            }
          )
        response = {
          status: responseData.status,
          getProfile: responseData.data
        }
      } catch (error: any) {
        const err = error.toJSON()
        if (err.message === 'Network Error') {
          response = {
            status: 500,
            error: err
          }
        } else {
          response = {
            status: error.response.status,
            error: error.response.data
          }
        }
      }
      return response
    }
}

export default xxsProfileService
