// Constants
import {
  TUNNEL_GET_HISTORY_SUCCESS,
  TUNNEL_GET_HISTORY_FAIL,
  TUNNEL_GET_HISTORY_CLEAR_MESSAGE
} from 'redux/Constants/TunnelService'

// Props Types
import IAction from 'redux/Action/types'
import xxreTunnelServiceGetTunnelHistoryTypes from 'redux/Reducers/TunnelService/GetHistory/types'

const getTunnelHistory = (
  state: object = {},
  action: IAction<xxreTunnelServiceGetTunnelHistoryTypes.Props>
) => {
  switch (action.type) {
    case TUNNEL_GET_HISTORY_SUCCESS:
      return {
        ...state,
        ...action.payload,
        type: action.type
      }
    case TUNNEL_GET_HISTORY_FAIL:
      return {
        ...state,
        ...action.payload,
        type: action.type
      }
    case TUNNEL_GET_HISTORY_CLEAR_MESSAGE:
      return {}
    default:
      return state
  }
}

export default getTunnelHistory
