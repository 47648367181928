const freeGasProd = [
  {
    address: '0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063',
    config_symbol: 'MATIC',
    symbol: 'DAI',
    chainId: 137,
    freeGas: true
  },
  {
    address: '0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619',
    config_symbol: 'MATIC',
    symbol: 'WETH',
    chainId: 137,
    freeGas: true
  },

  {
    address: '0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619',
    config_symbol: 'MATIC',
    symbol: 'WETH',
    chainId: 137,
    freeGas: true
  },
  {
    address: '0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174',
    config_symbol: 'MATIC',
    symbol: 'USDC',
    chainId: 137,
    freeGas: true
  }
]

const freeGasDev = [
  {
    address: '0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063',
    config_symbol: 'MATIC',
    symbol: 'DAI',
    chainId: 137,
    freeGas: true
  },
  {
    address: '0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619',
    config_symbol: 'MATIC',
    symbol: 'WETH',
    chainId: 137,
    freeGas: true
  },
  {
    address: '0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174',
    config_symbol: 'MATIC',
    symbol: 'USDC',
    chainId: 137,
    freeGas: true
  }
]

export { freeGasProd, freeGasDev }
