import { useSelector } from 'react-redux'
import { Button } from 'react-bootstrap'
import styled from 'styled-components'

//type
import xxcNavBarTypes from 'components/NavBar/Model'
import xxreStoreTypes from 'redux/types'

import './style.scss'

const NotFoundPage = (): JSX.Element => {
  const themeConfig = useSelector(
    (state: xxreStoreTypes.Props) => state.themeConfig
  )
  const darkMode = useSelector((state: xxreStoreTypes.Props) => state.darkMode)

  return (
    <ContainerCustom
      theme={
        darkMode.darkMode === 'dark'
          ? themeConfig.themeConfig?.dark
          : themeConfig.themeConfig?.light
      }
      className="not-found-container"
    >
      <div className="not-found-box">
        {/* <div className="not-found-box-image image-mobile">
          <img className="not-found-box-image-bg" src={macMonitor} alt="" />
          <img
            className="not-found-box-image-logo"
            src={themeConfig.themeConfig?.[darkMode.darkMode].home.homeImage}
            alt=""
          />
        </div> */}
        <div className="not-found-box-details">
          <div className="title-code">404</div>
          <div className="title-error">Page Not Found</div>
          <div className="description-error">
            We're sorry,the page you requested could not be found, Please go
            back to the homepage
          </div>
          <div className="mt-4 d-flex justify-content-center">
            <Button
              onClick={() => {
                window.location.href = '/'
              }}
              className="btn btn-lg main-btn button convert-button shadow-none home-button-enter-app px-4"
            >
              <b>Go Home</b>
            </Button>
          </div>
        </div>
        {/* <div className="not-found-box-image image-pc">
          <img className="not-found-box-image-bg" src={macMonitor} alt="" />
          <NotFoundIcon bg={themeConfig.themeConfig?.[darkMode.darkMode].background || '#ffffff'} fill={themeConfig.themeConfig?.[darkMode.darkMode].home.fontColor || '#ffffff'} />
          <img
            className="not-found-box-image-logo"
            src={themeConfig.themeConfig?.[darkMode.darkMode].home.homeImage}
            alt=""
          />
        </div> */}
      </div>
    </ContainerCustom>
  )
}

export default NotFoundPage

const ContainerCustom = styled.div<xxcNavBarTypes.NavBarCustom>`
  background: ${props => props.theme.home.homeBackground};
  .convert-button {
    background-color: ${props =>
      props.theme.button.convertButton.backgroundColor};
    border-color: ${props => props.theme.button.convertButton.backgroundColor};
    color: ${props => props.theme.button.convertButton.fontColor};
    &:hover {
      opacity: 0.65;
      color: ${props => props.theme.button.convertButton.fontColor};
    }
    &:active {
      opacity: 0.65;
      color: ${props => props.theme.button.convertButton.fontColor};
    }
    &:active:focus {
      box-shadow: 0 0 0 0.25rem
        ${props => props.theme.button.convertButton.backgroundColor}8c !important;
    }
  }
  .title-code {
    color: ${props => props.theme.home.fontColor};
  }
  .title-error {
    color: ${props => props.theme.home.fontColor};
  }
  .description-error {
    color: ${props => props.theme.home.fontColor};
  }
`
