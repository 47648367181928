// Constants
import {
  NOTI_BOX_UPDATE_MESSAGE,
  NOTI_BOX_CLEAR_MESSAGE
} from 'redux/Constants/NotiBox'

// Props Types
import IAction from 'redux/Action/types'
import xxreNotiBoxReducerTypes from 'redux/Reducers/NotiBox/types'

const notiBox = (
  state: xxreNotiBoxReducerTypes.Props = {
    type: NOTI_BOX_CLEAR_MESSAGE,
    messageType: 'info',
    bodyMessage: '',
    show: false
  },
  action: IAction<xxreNotiBoxReducerTypes.Props>
) => {
  switch (action.type) {
    case NOTI_BOX_UPDATE_MESSAGE:
      return {
        ...state,
        ...action.payload,
        type: action.type
      }
    case NOTI_BOX_CLEAR_MESSAGE:
      return { type: action.type }
    default:
      return state
  }
}

export default notiBox
