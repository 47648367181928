export const platformData = {
  evme: {
    title: 'EVme Convert',
    description:
      'Thailand’s full-service EV ownership experience platform. Go Easy ease into the future of driving electric before you buy into it.',
    baseUrl: 'https://evme.chomchob.finance/',
    image:
      'https://evme-web-assets.s3.ap-southeast-1.amazonaws.com/Open%20Graph_compressed.jpg',
    app_icon_16: './images/evme/evme-icon_16px.png',
    app_icon_32: './images/evme/evme-icon_32px.png',
    app_icon_180: './images/evme/evme-icon_180px.png',
    image_16: './images/evme/evme-icon_16px.png',
    image_32: './images/evme/evme-icon_32px.png',
    image_180: './images/evme/evme-icon_180px.png',
    manifest: './manifest/evme.json'
  },
  tunnel: {
    title: 'ChomCHOB Tunnel',
    description:
      'Accumulate digital assets from multi-chains and reward points, Buy everything whatever you wants.',
    baseUrl: 'https://tunnel.chomchob.finance/',
    image:
      'https://storage.googleapis.com/chomchob/production/public/Tunnel/2fa7c648-83c6-4e29-8a25-ea4537e1a295',
    app_icon_16: './images/chomchob/app_icon_16px.png',
    app_icon_32: './images/chomchob/app_icon_32px.png',
    app_icon_180: './images/chomchob/app_icon_180px.png',
    image_16: './images/chomchob/ChomCHOB_Tunnel_icon_16px.png',
    image_32: './images/chomchob/ChomCHOB_Tunnel_icon_32px.png',
    image_180: './images/chomchob/ChomCHOB_Tunnel_icon_180px.png',
    manifest: './manifest/chomchob.json'
  }
}

export const getPlatformByName = name => platformData[name]
