import { useTranslation } from "react-i18next"

const CryptoToPoint = ({ setCryptoToPointModal }) => {

  // Translation
  const { t } = useTranslation("introduction")
  
  // Href links
  const convertCryptoToPointUrl = '/convert' //Crypto2CCP

  return (
    <div className="crypto-to-point">
      <div className="content-row-gap">
        <div className="text-area">
          <div className="text-container">
            <h1 className="title">{t("content.crypto-to-point.title")}</h1>
            <div className="text-green text-default">
              {t("content.crypto-to-point.green-description")}
            </div>
            <div className="text-default">
              {t("content.crypto-to-point.description")}
            </div>
            <div className="convert-button">
              <a className="convert-button__link" href={convertCryptoToPointUrl} target="_blank" rel="noreferrer">
                <button className="convert-button__link__button">
                  <div className="convert-button__link__button-label">
                    {t("content.crypto-to-point.convert")}
                  </div>
                </button>
              </a>
              <button
                className="text-default how-to-use-button"
                onClick={() => setCryptoToPointModal(true)}
              >
                {t("content.crypto-to-point.how-to-use")}
              </button>
            </div>
          </div>
        </div>
        <div className="image-area">
          <img
            src="https://storage.googleapis.com/chomchob/production/public/Tunnel/584a4578-5c08-4702-bd4d-c08ea380db09"
            alt="point_to_crypto"
            width="100%"
          />
        </div>
      </div>
    </div>
  )
}

export default CryptoToPoint
