//id
const CHAIN_ID = 56
//name
const CHAIN_NAME = 'Binance Smart Chain'
//name
const CHAIN_SHORT_NAME = 'BSC'
//rpc
const RPC = 'https://bsc-dataseed2.defibit.io'
//img
const IMAGE_CHAIN =
  'https://storage.googleapis.com/chomchob/production/public/migrate/logo/057466b4950546afa239ea3861c9502f'
//img info
const IMAGE_INFO =
  'https://storage.googleapis.com/chomchob/production/public/migrate/others/1fc26dd79cca4b529fd5570fb42eda94'
//how to
const HOW_TO = 'https://www.chomchob.com/how-to-use/exchange-ccp-to-crypto-by-tunnel'

const DECIMAL_PRICE = 18

export const off_bsc = {
  chainId: CHAIN_ID,
  chainName: CHAIN_NAME,
  shortName: CHAIN_SHORT_NAME,
  imgChain: IMAGE_CHAIN,
  imgInfo: IMAGE_INFO,
  howToUrl: HOW_TO,
  rpc: RPC,
  decimalPrice: DECIMAL_PRICE
}
