import { useMemo } from 'react'
import { ListGroup, Row, Col } from 'react-bootstrap'
import ReactImageFallback from 'react-image-fallback'

// Types
import xxcTokenListModalItemTypes from './Model'

//icon
import image_icon from 'assets/Svg/icon/image_icon.svg'

function TokenListModalItem({
  onHide,
  setSelectedToken,
  token
}: xxcTokenListModalItemTypes.Props) {
  const onClick = () => {
    setSelectedToken(token)
    onHide()
  }

  const imageToken = useMemo(() => {
    return (
      <ReactImageFallback
        style={{ borderRadius: '8px' }}
        alt={`image-${token.name}`}
        height={'40px'}
        width={'40px'}
        src={token.image}
        fallbackImage={image_icon}
        initialImage={image_icon}
      />
    )
  }, [token])

  return (
    <ListGroup.Item action className="token-list-item" onClick={onClick}>
      <Row className="px-2 px-sm-0">
        <Col
          className="d-flex justify-content-center align-items-center"
          xs={2}
        >
          {imageToken}
        </Col>
        <Col xs={8}>
          <div className="token-symbol">{token.symbol}</div>
          <div className="token-name">{token.name}</div>
        </Col>
        <Col className="token-balance" xs={2}>
          {/* TODO: load token balance */}
        </Col>
      </Row>
    </ListGroup.Item>
  )
}

export default TokenListModalItem
