import { useTranslation } from "react-i18next"

// Icons
import ChomChobIcon from 'components/IntroductionPage/Icons/IconTunnel.png'

const Banner = () => {

	// Translation
	const { t } = useTranslation("introduction")

	// Links
	const bannerImageUrl = "https://storage.googleapis.com/chomchob/production/public/web-assets/chomchob-tunnel-assets/banner/7e1a5391-1bea-4bcf-86b3-03d46b45df91"
	const gradientBackgroundUrl = "https://storage.googleapis.com/chomchob/production/public/web-assets/chomchob-tunnel-assets/banner/0660c7bc-5ad8-44c0-a6ee-52c2eeea69d2"

	return (
		<section className="introduction-container__banner__container" style={{"--gradient-background-link" : `url(${gradientBackgroundUrl})`}}>
			<div className='banner__background-setup-container'>
				<div className='banner__background-setup container'>
					<div className="banner__content-container">
						<div className='banner__content'>
							<div className="title">{t("banner.title")}</div>
							<div className="text-green text-default">{t("banner.green-description")}</div>
							<div className="text-default--banner">{t("banner.description")}</div>
						</div>
					</div>
					<img
						className="banner__object"
						src={bannerImageUrl}
						alt="banner-object"
					/>
				</div>
			</div>
				
			<div className="banner__backdrop-container">
				<div className="icon-container">
					<div className="icon-container__container">
						<div className="icon-container__aura">
							<div className="outer-layer" />
							<div className="middle-layer" />
							<div className="inner-layer" />
						</div>
						<div className="icon-container__icon">
							<img src={ChomChobIcon} alt="icon" />
						</div>
					</div>
				</div>
				<div className="backdrop">
					<div className="semi-oval-shape" />
				</div>
			</div>
		</section>
	)
}


export default Banner