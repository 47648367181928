import {
  Tooltip,
  OverlayTrigger,
  Form,
  Dropdown,
  Col,
  InputGroup
} from 'react-bootstrap'

//type
import xxcAddressBookModalTypes from 'components/ConvertPage/BinanceOptions/Model'

//icon
import question_icon from 'assets/Svg/icon/question_icon.svg'

function BinanceOptions({
  selectedNetworkWithdraw,
  setSelectedNetworkWithdraw,
  selectedToken,
  isProcessConvert,
  onInputMemo,
  memoInput
}: xxcAddressBookModalTypes.Props) {
  return (
    <>
      <Form.Group>
        <div className="d-flex">
          <Col>
            <Form.Label className="d-flex align-items-center convert-container-user-box">
              <div className="convert-container-user-reference">
                {/* {themeConfig.themeConfig?.config.useRefTitle} */}
                Network
              </div>
            </Form.Label>
          </Col>
        </div>
        <Dropdown className="select-network">
          <Dropdown.Toggle id="dropdown-custom-components">
            {selectedNetworkWithdraw ? (
              selectedNetworkWithdraw.name
            ) : (
              <span className="placeholder-text">Please select network</span>
            )}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {selectedToken?.withdraw_chain_list?.map(item => {
              return (
                <Dropdown.Item
                  className={
                    item.short_name === selectedNetworkWithdraw?.short_name
                      ? 'select-network-active'
                      : ''
                  }
                  key={item.name}
                  onClick={() => {
                    setSelectedNetworkWithdraw(item)
                  }}
                  role="button"
                >
                  {item.name}
                </Dropdown.Item>
              )
            })}
          </Dropdown.Menu>
        </Dropdown>
      </Form.Group>
      {selectedNetworkWithdraw?.use_memo && (
        <Form.Group className="mt-2">
          <div className="d-flex">
            <Col>
              <Form.Label className="d-flex align-items-center convert-container-user-box gap-1">
                <div className="convert-container-user-reference">MEMO/Tag</div>
                <OverlayTrigger
                  trigger="click"
                  rootClose
                  overlay={props => (
                    <Tooltip id="ccp-info" {...props}>
                      Please confirm if the receiving address requires a
                      MEMO/Tag. If it is not filled or filled incorrectly, the
                      asset will be lost. Other exchanges or wallets also call
                      Tag names Memo, digital ID, label, and notes.
                    </Tooltip>
                  )}
                  placement="right"
                >
                  <img
                    alt="question icon"
                    // className="convert-help-outline-icon"
                    role="button"
                    src={question_icon}
                  />
                </OverlayTrigger>
              </Form.Label>
            </Col>
          </div>
          <InputGroup>
            <Form.Control
              className="convert-input text-on-input text-on-input-text-start convert-text-left "
              disabled={isProcessConvert}
              onInput={onInputMemo}
              placeholder="Please enter MEMO or Tag"
              type="text"
              value={memoInput}
            />
          </InputGroup>
        </Form.Group>
      )}
    </>
  )
}

export default BinanceOptions
