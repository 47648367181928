// Constants
import {
  GET_LOGIN_CLEAR_MESSAGE,
  GET_LOGIN_FAIL,
  GET_LOGIN_SUCCESS
} from 'redux/Constants/Login/GetLogin'

// Props Types
import IAction from 'redux/Action/types'
import xxreGetLoginReducerTypes from 'redux/Reducers/Login/GetLogin/types'

const getLogin = (
  state: object = {},
  action: IAction<xxreGetLoginReducerTypes.Props>
) => {
  switch (action.type) {
    case GET_LOGIN_SUCCESS:
      return {
        ...state,
        ...action.payload,
        type: action.type
      }
    case GET_LOGIN_FAIL:
      return {
        ...state,
        ...action.payload,
        type: action.type
      }
    case GET_LOGIN_CLEAR_MESSAGE:
      return {}
    default:
      return state
  }
}

export default getLogin
