import generator from 'generate-password'
import dayjs from 'dayjs'

const xxaDevice = {
  GetDeviceId: (): Promise<string> => {
    return new Promise(resolve => {
      const deviceId = window.localStorage.getItem('d')
      if (!deviceId) {
        const toDay: string = dayjs().format('YYYYMMDD')
        const deviceCode: string = generator.generate({
          length: 20,
          uppercase: true,
          numbers: true
        })

        const newDeviceId = toDay + '-' + deviceCode
        window.localStorage.setItem('d', newDeviceId)
        resolve(newDeviceId)
      } else {
        resolve(deviceId)
      }
    })
  }
}

export default xxaDevice
