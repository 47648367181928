import { useTranslation } from "react-i18next"

const PointToCrypto = ({ setPointToCryptoModal }) => {

  // Translation
  const { t } = useTranslation("introduction")

  // Href links
  const convertPointToCryptoUrl = '/point-to-crypto' //CCP2Crypto

  return (
    <div className="point-to-crypto">
      <div className="content-row-gap">
        <div className="image-area">
          <img
            src="https://storage.googleapis.com/chomchob/production/public/Tunnel/89d8b0c4-3ef7-4979-92ee-36804bac1cd1"
            alt="crypto_to_point"
            width="100%"
          />
        </div>
        <div className="text-area">
          <div className="text-container">
            <h1 className="title">{t("content.point-to-crypto.title")}</h1>
            <div className="text-green text-default">
              {t("content.point-to-crypto.green-description")}
            </div>
            <div className="text-default">
              {t("content.point-to-crypto.description")}
            </div>
            <div className="convert-button">
              <a className="convert-button__link" href={convertPointToCryptoUrl} target="_blank" rel="noreferrer">
                <button className="convert-button__link__button">
									<div className="convert-button__link__button-label">
                    {t("content.point-to-crypto.convert")}
                  </div>
								</button>
              </a>
              <button
                className="text-default how-to-use-button"
                onClick={() => setPointToCryptoModal(true)}
              >
                {t("content.point-to-crypto.how-to-use")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PointToCrypto
