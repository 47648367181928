// Constants
import {
  SET_DARK_MODE_SUCCESS,
  UPDATE_DARK_MODE_SUCCESS
} from 'redux/Constants/DarkMode'

// Props Types
import IAction from 'redux/Action/types'
import xxreDarkModeReducerTypes from 'redux/Reducers/DarkMode/types'

const theme: any = window.localStorage.getItem('theme')
const themeConfig = theme ?? 'light'

const darkMode = (
  state: xxreDarkModeReducerTypes.Props = {
    type: SET_DARK_MODE_SUCCESS,
    darkMode: themeConfig
  },
  action: IAction<xxreDarkModeReducerTypes.Props>
) => {
  switch (action.type) {
    case SET_DARK_MODE_SUCCESS:
      return {
        ...state,
        type: action.type
      }
    case UPDATE_DARK_MODE_SUCCESS:
      return {
        ...state,
        ...action.payload,
        type: action.type
      }
    default:
      return state
  }
}

export default darkMode
