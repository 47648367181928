import Modal from 'react-bootstrap/Modal'

// Icons
import { CloseIcon } from 'components/IntroductionPage/Icons'

const HowToUseModal = ({ show, onHide, scrollable, modalDetail }) => {
  return (
    <Modal
      show={show}
      scrollable={scrollable}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="custom_modal"
      key="how-to-use-modal"
    >
      <div className="modal__content">
        {modalDetail?.imageLists &&
          modalDetail?.imageLists.map((imgUrl, index) => (
            <img
              className="image-modal"
              src={imgUrl}
              key={`modalDetail-${index + 1}`}
              alt={modalDetail?.altText}
            />
          ))}
      </div>
      <div className="d-flex justify-content-center p-4 custom-close-button__container">
        <button
          type="button"
          className="btn custom-close-button"
          aria-label="Close"
          onClick={onHide}
        >
          <CloseIcon />
        </button>
      </div>
    </Modal>
  )
}

export default HowToUseModal
