import { Button } from 'react-bootstrap'
import styled from 'styled-components'

//type
import xxcNavBarTypes from 'components/NavBar/Model'

import './style.scss'

const NotFoundDefault = ({
  code,
  message,
  description
}: {
  code?: number
  message?: string
  description?: string
}): JSX.Element => {
  return (
    <ContainerCustom className="not-found-default-container">
      <div className="not-found-default-box">
        <div className="not-found-default-box-details">
          <div className="title-code">{code ?? '404'}</div>
          <div className="title-error">{message ?? 'Page Not Found'}</div>
          <div className="description-error">
            {description ??
              "We're sorry,the page you requested could not be found, Please go back to the homepage"}
          </div>
          <div className="mt-4 d-flex justify-content-center">
            <Button
              onClick={() => {
                window.location.href = '/'
              }}
              className="btn btn-lg main-btn button convert-button shadow-none home-button-enter-app px-4"
            >
              <b>Go Home</b>
            </Button>
          </div>
        </div>
      </div>
    </ContainerCustom>
  )
}

export default NotFoundDefault

const ContainerCustom = styled.div<xxcNavBarTypes.NavBarCustom>`
  background: #ffffff;
  .convert-button {
    background-color: #72d9e2;
    border-color: #72d9e2;
    color: #ffffff;
    &:hover {
      opacity: 0.65;
      color: #ffffff;
    }
    &:active {
      opacity: 0.65;
      color: #ffffff;
    }
    &:active:focus {
      box-shadow: 0 0 0 0.25rem #72d9e28c !important;
    }
  }
  .title-code {
    color: #1a586a;
  }
  .title-error {
    color: #1a586a;
  }
  .description-error {
    color: #1a586a;
  }
`
