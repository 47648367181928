import { useSelector } from 'react-redux'
import { Modal, Button } from 'react-bootstrap'
import ReactImageFallback from 'react-image-fallback'
import dayjs from 'dayjs'
import styled from 'styled-components'
import ClipboardJS from 'clipboard'
import { toast } from 'react-toastify'

// Types
import xxcModalDetailsHistoryTypes from './Model'
import xxreStoreTypes from 'redux/types'

// Config
import * as configs from 'configs'

// Styles
import './style.scss'

//icon
import image_icon from 'assets/Svg/icon/image_icon.svg'

function ModalUserRef({
  onHide,
  show,
  data,
  handleOpenBinancePay
}: xxcModalDetailsHistoryTypes.Props) {
  new ClipboardJS('#copy-tx')
  const darkMode = useSelector((state: xxreStoreTypes.Props) => state.darkMode)
  const themeConfig = useSelector(
    (state: xxreStoreTypes.Props) => state.themeConfig
  )

  const parseStatus = (
    status: 'open' | 'processing' | 'success' | 'fail' | 'expire'
  ): string => {
    const statusMap = {
      success: 'success',
      fail: 'fail',
      void: 'fail',
      open: 'open',
      processing: 'processing',
      expire: 'expire'
    }
    return statusMap[status] || 'processing'
  }

  const formatAddress = (address: string): string => {
    if (address) {
      return `${address.slice(0, 4)}..${address.slice(-4)}`
    }
    return ''
  }

  const onClickCopyLink = (text: string) => {
    navigator.clipboard.writeText(text)
    toast.success(<div className="text-center">Copy!</div>, {
      icon: false
    })
  }

  const openTransaction = () => {
    if (data.crypto_to_point) {
      window.open(
        `${data.crypto_to_point.explorer_url}/tx/${data.crypto_to_point?.transaction_hash}`,
        '_blank'
      )
    }
    if (data.point_to_crypto) {
      window.open(
        `${data.point_to_crypto.explorer_url}/tx/${data.point_to_crypto.transaction_hash}`,
        '_blank'
      )
    }
  }

  return (
    <ModalCustom
      theme={
        darkMode.darkMode === 'dark'
          ? themeConfig.themeConfig?.dark
          : themeConfig.themeConfig?.light
      }
      aria-labelledby="contained-modal-title-vcenter"
      centered
      dialogClassName="detail-txs-modal"
      className="modal-fullscreen-md-down"
      id="item-modal"
      onHide={onHide}
      show={show}
    >
      <Modal.Header closeButton>Transaction Details</Modal.Header>
      <Modal.Body className="p-0">
        {data ? (
          <div className="detail-txs-modal-container">
            <div className="detail-txs-modal-header">
              <div className="detail-txs-modal-header-image">
                <ReactImageFallback
                  alt={`image-${
                    data.crypto_to_point
                      ? data.crypto_to_point.from_token.name
                      : data.point_to_crypto
                      ? data.point_to_crypto?.to_token.name
                      : data.binance_to_point?.from_token.name
                  }`}
                  // height="70px"
                  // width="70px"
                  src={
                    data.crypto_to_point
                      ? data.crypto_to_point.from_token.image
                      : data.point_to_crypto
                      ? data.point_to_crypto?.to_token.image
                      : data.binance_to_point?.from_token.image
                  }
                  fallbackImage={image_icon}
                  initialImage={image_icon}
                />
              </div>
              <div className="detail-txs-modal-header-title d-flex flex-column justify-content-center text-end w-100 ">
                <div className="detail-txs-modal-header-symbol">
                  <div>CHAIN</div>
                  <div>
                    {data.crypto_to_point
                      ? configs.web3.find(item => {
                          return item.chainId === data.crypto_to_point?.chain_id
                        })?.shortName
                      : data.point_to_crypto
                      ? data.point_to_crypto?.chain_name
                      : data.binance_to_point?.chain_name}
                  </div>
                </div>
                <div className="detail-txs-modal-header-symbol">
                  <div>SYMBOL</div>
                  <div>
                    {data.crypto_to_point
                      ? data.crypto_to_point.from_token.symbol
                      : data.point_to_crypto
                      ? data.point_to_crypto?.to_token.symbol
                      : data.binance_to_point?.from_token.symbol}
                  </div>
                </div>
                <div className="detail-txs-modal-header-name">
                  <div>NAME</div>
                  <div>
                    {data.crypto_to_point
                      ? data.crypto_to_point.from_token.name
                      : data.point_to_crypto
                      ? data.point_to_crypto?.to_token.name
                      : data.binance_to_point?.from_token.name}
                  </div>
                </div>
              </div>
            </div>
            <div className="detail-txs-modal-card">
              {data.point_to_crypto && data.point_to_crypto.transaction_hash ? (
                <div className="detail-txs-modal-card-row">
                  <div>Tx Hash</div>
                  <div
                    id="copy-tx"
                    onClick={() =>
                      onClickCopyLink(
                        data.point_to_crypto?.transaction_hash ?? ''
                      )
                    }
                    data-clipboard-text={
                      data.point_to_crypto?.transaction_hash ?? ''
                    }
                  >
                    {formatAddress(
                      data.point_to_crypto?.transaction_hash ?? ''
                    )}
                  </div>
                </div>
              ) : null}
              {data.binance_to_point &&
              data.binance_to_point.binance_pay_prepay_id ? (
                <div className="detail-txs-modal-card-row">
                  <div>Binance Pay ID</div>
                  <div
                    id="copy-tx"
                    onClick={() =>
                      onClickCopyLink(
                        data.binance_to_point?.binance_pay_prepay_id ?? ''
                      )
                    }
                    data-clipboard-text={
                      data.binance_to_point.binance_pay_prepay_id ?? ''
                    }
                  >
                    {formatAddress(data.binance_to_point.binance_pay_prepay_id)}
                  </div>
                </div>
              ) : null}
              <div className="detail-txs-modal-card-row">
                <div>
                  {data.crypto_to_point
                    ? 'Amount'
                    : data.point_to_crypto
                    ? themeConfig.themeConfig?.config.pointUnit + ' Amount'
                    : `${data.binance_to_point?.from_token.symbol} Amount`}
                </div>
                <div>
                  {Number(
                    data.crypto_to_point
                      ? data.crypto_to_point.from_amount
                      : data.point_to_crypto
                      ? data.point_to_crypto?.from_point
                      : data.binance_to_point?.from_amount
                  ).toLocaleString('en-US', {
                    maximumFractionDigits: 6,
                    minimumFractionDigits: 2
                  })}
                </div>
              </div>
              <div className="detail-txs-modal-card-row">
                <div>
                  Received{' '}
                  {data.crypto_to_point
                    ? themeConfig.themeConfig?.config.pointUnit
                    : data.point_to_crypto
                    ? data.point_to_crypto?.to_token.symbol
                    : themeConfig.themeConfig?.config.pointUnit}
                </div>
                <div>
                  {data.crypto_to_point
                    ? data.crypto_to_point.topup_point
                      ? Number(
                          data.crypto_to_point?.topup_point
                        ).toLocaleString('en-US', {
                          maximumFractionDigits: 6,
                          minimumFractionDigits: 2
                        })
                      : '-'
                    : data.point_to_crypto
                    ? data.point_to_crypto?.to_token.amount
                      ? Number(
                          data.point_to_crypto.to_token.amount
                        ).toLocaleString('en-US', {
                          maximumFractionDigits: 6,
                          minimumFractionDigits: 2
                        })
                      : '-'
                    : data.binance_to_point?.topup_point
                    ? Number(data.binance_to_point?.topup_point).toLocaleString(
                        'en-US',
                        {
                          maximumFractionDigits: 6,
                          minimumFractionDigits: 2
                        }
                      )
                    : '-'}
                </div>
              </div>
              {data.binance_to_point ? null : (
                <div className="detail-txs-modal-card-row">
                  <div>
                    {data.crypto_to_point ? 'User REF' : 'User Address'}
                  </div>
                  <div>
                    {data.crypto_to_point
                      ? data.crypto_to_point.user_ref
                      : formatAddress(data.point_to_crypto?.to_address || '')}
                  </div>
                </div>
              )}
              <div className="detail-txs-modal-card-row">
                <div>Status</div>
                <div>
                  <span
                    className={`badge text-uppercase bg-${
                      parseStatus(
                        data.crypto_to_point
                          ? data.crypto_to_point.status
                          : data.point_to_crypto
                          ? data.point_to_crypto.status
                          : data.binance_to_point
                          ? data.binance_to_point.status
                          : 'processing'
                      ) === 'success'
                        ? 'success'
                        : parseStatus(
                            data.crypto_to_point
                              ? data.crypto_to_point.status
                              : data.point_to_crypto
                              ? data.point_to_crypto.status
                              : data.binance_to_point
                              ? data.binance_to_point.status
                              : 'fail'
                          ) === 'fail'
                        ? 'danger'
                        : parseStatus(
                            data.crypto_to_point
                              ? data.crypto_to_point.status
                              : data.point_to_crypto
                              ? data.point_to_crypto.status
                              : data.binance_to_point
                              ? data.binance_to_point.status
                              : 'expire'
                          ) === 'expire'
                        ? 'secondary'
                        : 'warning'
                    }`}
                  >
                    {parseStatus(
                      data.crypto_to_point
                        ? data.crypto_to_point.status
                        : data.point_to_crypto
                        ? data.point_to_crypto.status
                        : data.binance_to_point
                        ? data.binance_to_point.status
                        : 'processing'
                    )}
                  </span>
                </div>
              </div>
              <div className="detail-txs-modal-card-row">
                <div>Date</div>
                <div>
                  {dayjs(
                    data.crypto_to_point
                      ? data.crypto_to_point.exchange_date
                      : data.point_to_crypto
                      ? data.point_to_crypto.exchange_date
                      : data.binance_to_point?.exchange_date
                  ).format('DD MMM YYYY, HH:mm A')}
                </div>
              </div>
            </div>
            {(data.crypto_to_point?.transaction_hash &&
              data.crypto_to_point?.explorer_url) ||
            (data.point_to_crypto?.transaction_hash &&
              data.point_to_crypto.explorer_url) ? (
              <div className="w-100 d-flex justify-content-center mt-3">
                <Button
                  onClick={openTransaction}
                  className="w-100 button button-primary view-transaction"
                >
                  View Transaction
                </Button>
              </div>
            ) : null}
            {data.binance_to_point &&
            data.binance_to_point.status === 'open' ? (
              <div className="w-100 d-flex justify-content-center mt-3">
                <Button
                  onClick={() => {
                    handleOpenBinancePay &&
                      handleOpenBinancePay(data.binance_to_point)
                    onHide()
                  }}
                  className="w-100 button button-primary view-transaction"
                >
                  Pay Now
                </Button>
              </div>
            ) : null}
          </div>
        ) : null}
      </Modal.Body>
    </ModalCustom>
  )
}

export default ModalUserRef

const ModalCustom = styled(Modal)<xxcModalDetailsHistoryTypes.SettingModal>`
  .modal-content {
    background-color: ${props => props.theme.cardColor};
    color: ${props => props.theme.fontPrimaryColor};

    & .detail-txs-modal-card{
      background-color:  ${props => props.theme.cardColor};
    }
  }
  .btn-close {
    background: url('data:image/svg+xml,%3Csvg xmlns="http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg" width="32px" height="32px" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"%3E%3Cpath fill="${props =>
      props.theme.fontPrimaryColor?.replace(
        '#',
        '%23'
      )}" d="m13.41 12l4.3-4.29a1 1 0 1 0-1.42-1.42L12 10.59l-4.29-4.3a1 1 0 0 0-1.42 1.42l4.3 4.29l-4.3 4.29a1 1 0 0 0 0 1.42a1 1 0 0 0 1.42 0l4.29-4.3l4.29 4.3a1 1 0 0 0 1.42 0a1 1 0 0 0 0-1.42Z"%2F%3E%3C%2Fsvg%3E');
  }
  .detail-txs-modal.modal-dialog > .modal-content > .modal-body {
    & .detail-txs-modal-header {
      &-symbol {
        color: ${props => props.theme.primaryColor};
        > :first-child {
          color: ${props => props.theme.fontPrimaryColor};
        }
      }
      &-name {
        color: ${props => props.theme.primaryColor};
        > :first-child {
          color: ${props => props.theme.fontPrimaryColor};
        }
      }
    }
  .view-transaction {
      border-color: ${props => props.theme.button.primary.backgroundColor};
      background-color: ${props => props.theme.button.primary.backgroundColor};
      color: ${props => props.theme.button.primary.fontColor};
      &:hover {
        opacity: 0.65;
      }
      &:active {
        opacity: 0.65;
      }
      &:active:focus {
        opacity: 1;
        box-shadow: 0 0 0 0.25rem
          ${props => props.theme.button.primary.backgroundColor}8c !important;
      }
  }
  @media (max-width: 540px) {
    .view-transaction {
      &:hover {
        background-color: ${props =>
          props.theme.button.primary.backgroundColor};
        border-color: ${props => props.theme.button.primary.backgroundColor};
        color: ${props => props.theme.button.primary.fontColor};
        opacity: unset;
      }
      &:active {
        box-shadow: 0 0 0 0.25rem
          ${props => props.theme.button.primary.backgroundColor}8c !important;
        opacity: 0.65;
        color: ${props => props.theme.button.primary.fontColor};
      }
      &:disabled {
        opacity: 0.65 !important;
      }
    }
  }
`
