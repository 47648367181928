// Icons
import {
  FacebookIcon,
  LineIcon,
  LinkedInIcon,
  TwitterIcon,
  InternetIcon,
} from 'components/IntroductionPage/Icons';

export const socialButton = [
  {
    key: 'Facebook',
    url: 'https://www.facebook.com/chomchobth',
    icon: <FacebookIcon />,
  },
  {
    key: 'Twitter',
    url: 'https://twitter.com/ChomCHOBLabs',
    icon: <TwitterIcon />,
  },
  {
    key: 'Line',
    url: 'https://page.line.me/chomchobth?openQrModal=true',
    icon: <LineIcon />,
  },
  {
    key: 'Linkedin',
    url: 'https://www.linkedin.com/company/chomchob/',
    icon: <LinkedInIcon />,
  },
  {
    key: 'Website',
    url: 'https://www.chomchob.com/',
    icon: <InternetIcon />,
  },
];
