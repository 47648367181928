const ThaiFlagIcon = () => (
  <svg
    width={25}
    height={19}
    viewBox="0 0 25 19"
    fill="none"
    id="thai-flag-icon"
  >
    <mask
      id="mask0_2949_2321"
      style={{
        maskType: "alpha",
      }}
      maskUnits="userSpaceOnUse"
      x={4}
      y={0}
      width={20}
      height={19}
    >
      <ellipse cx={13.657} cy={9.5} rx={9.61047} ry={9.5} fill="#C4C4C4" />
    </mask>
    <g mask="url(#mask0_2949_2321)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0H26.3023V19H0V0Z"
        fill="#F4F5F8"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 6.4323H26.3023V12.7656H0V6.4323Z"
        fill="#2D2A4A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0H26.3023V3.26562H0V0ZM0 15.8333H26.3023V19H0V15.8333Z"
        fill="#A51931"
      />
    </g>
  </svg>
)

export default ThaiFlagIcon
