import { useSelector } from 'react-redux'
import styled from 'styled-components'

//type
import xxcNavBarTypes from 'components/NavBar/Model'
import xxreStoreTypes from 'redux/types'

import './style.scss'

const MaintenancePage = (): JSX.Element => {
  const themeConfig = useSelector(
    (state: xxreStoreTypes.Props) => state.themeConfig
  )
  const darkMode = useSelector((state: xxreStoreTypes.Props) => state.darkMode)

  return (
    <ContainerCustom
      theme={
        darkMode.darkMode === 'dark'
          ? themeConfig.themeConfig?.dark
          : themeConfig.themeConfig?.light
      }
      className="maintenance-container"
    >
      <div className="maintenance-box">
        <div className="maintenance-box-details">
          {/* <div className="title-code">SORRY</div> */}
          <div className="title-error">Website Is Under</div>
          <div className="title-error"> Maintenance</div>
          <div className="description-error">
            Unfortunately the website is down for a bit of maintenance right
            now. We will be online as soon as possible. Please check again in a
            little while. Thank you!
          </div>
        </div>
      </div>
    </ContainerCustom>
  )
}

export default MaintenancePage

const ContainerCustom = styled.div<xxcNavBarTypes.NavBarCustom>`
  background: ${props => props.theme.home.homeBackground};
  .convert-button {
    background-color: ${props =>
      props.theme.button.convertButton.backgroundColor};
    border-color: ${props => props.theme.button.convertButton.backgroundColor};
    color: ${props => props.theme.button.convertButton.fontColor};
    &:hover {
      opacity: 0.65;
      color: ${props => props.theme.button.convertButton.fontColor};
    }
    &:active {
      opacity: 0.65;
      color: ${props => props.theme.button.convertButton.fontColor};
    }
    &:active:focus {
      box-shadow: 0 0 0 0.25rem
        ${props => props.theme.button.convertButton.backgroundColor}8c !important;
    }
  }
  .title-code {
    color: ${props => props.theme.home.fontColor};
  }
  .title-error {
    color: ${props => props.theme.home.fontColor};
  }
  .description-error {
    color: ${props => props.theme.home.fontColor};
  }
`
