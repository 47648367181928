import { useState, useEffect } from 'react'
import { ListGroup, Modal, Form } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

// Redux
import xxreStoreTypes from 'redux/types'

// Components
import xxcTokenListModalTypes from 'components/ConvertPage/TokenListModal/Model'
import TokenListModalItem from 'components/ConvertPage/TokenListModalItem'
import TokenError from 'components/ConvertPage/TokenError'

function TokenListModal({
  onHide,
  setSelectedToken,
  show
}: xxcTokenListModalTypes.Props) {
  // Redux
  const tunnelConfig = useSelector(
    (state: xxreStoreTypes.Props) => state.getConfig
  )
  const anonymousToken = useSelector(
    (state: xxreStoreTypes.Props) => state.anonymousToken
  )
  const darkMode = useSelector((state: xxreStoreTypes.Props) => state.darkMode)
  const themeConfig = useSelector(
    (state: xxreStoreTypes.Props) => state.themeConfig
  )

  const [tokenShow, setTokenShow] = useState<any>([])

  const sortArray = () => {
    if (tunnelConfig.config) {
      let sortData = [...tunnelConfig.config?.tokens]
      let findSix = sortData.filter(item => {
        return item.symbol === 'SIX'
      })
      let newArray = sortData.filter(item => {
        return item.symbol !== 'SIX'
      })
      if (findSix.length > 0) {
        newArray.unshift(...findSix)
      }
      return newArray
    } else {
      return []
    }
  }

  const tokens = sortArray()

  const searchToken = (keyWord: string) => {
    let data = tokens.filter(item => {
      return (
        item.symbol.toLowerCase().indexOf(keyWord.toLowerCase()) !== -1 ||
        item.name.toLowerCase().indexOf(keyWord.toLowerCase()) !== -1 ||
        item.address?.toLowerCase() === keyWord.toLowerCase()
      )
    })
    setTokenShow(data)
  }

  useEffect(() => {
    if (tunnelConfig.config) {
      setTokenShow(sortArray())
    }
  }, [tunnelConfig, show])

  return (
    <ModalCustom
      theme={
        darkMode.darkMode === 'dark'
          ? themeConfig.themeConfig?.dark
          : themeConfig.themeConfig?.light
      }
      aria-labelledby="contained-modal-title-vcenter"
      centered
      dialogClassName="token-list-modal"
      className="modal-fullscreen-md-down"
      id="item-modal"
      onHide={onHide}
      show={show}
      // size="lg"
    >
      <Modal.Body className="p-0">
        <Modal.Header closeButton>Select Token</Modal.Header>
        <div className="box-input-select-token">
          <Form.Control
            className="convert-input"
            onChange={e => {
              searchToken(e.target.value)
            }}
            // disabled={isProcessConvert}
            // onInput={onInputCcpAmount}
            placeholder="ex. SIX"
            type="text"
            // value={ccpAmount}
          />
        </div>
        {anonymousToken.error ? (
          <TokenError />
        ) : (
          <ListGroup className="token-list" defaultActiveKey="#link1">
            {tokenShow.length > 0 ? (
              tokenShow.map((token: any) => (
                <TokenListModalItem
                  key={token.name}
                  {...{
                    token,
                    setSelectedToken,
                    onHide
                  }}
                />
              ))
            ) : (
              <div className="token-not-found ">Token not found</div>
            )}
          </ListGroup>
        )}
      </Modal.Body>
      <Modal.Footer></Modal.Footer>
    </ModalCustom>
  )
}

export default TokenListModal

const ModalCustom = styled(Modal)<xxcTokenListModalTypes.SettingModal>`
  .modal-content {
    background-color: ${props => props.theme.cardColor};
    color: ${props => props.theme.fontPrimaryColor};
  }
  .form-control {
    border: 2px solid ${props => props.theme.input.borderColor};
    background-color: ${props => props.theme.input.inputBackgroundColor};
    color: ${props => props.theme.input.inputTextColor};
    font-size: 20px;
  }
  .form-control::placeholder {
    color: ${props => props.theme.input.placeholderTextColor} !important;
    opacity: 1;
  }
  .form-control:focus {
    border-color: ${props => props.theme.input.inputFocusBackGroundColor};
    background-color: ${props => props.theme.input.inputFocusBackGroundColor}4d;
  }
  .btn-close {
    background: url('data:image/svg+xml,%3Csvg xmlns="http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg" width="32px" height="32px" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"%3E%3Cpath fill="${props =>
      props.theme.fontPrimaryColor.replace(
        '#',
        '%23'
      )}" d="m13.41 12l4.3-4.29a1 1 0 1 0-1.42-1.42L12 10.59l-4.29-4.3a1 1 0 0 0-1.42 1.42l4.3 4.29l-4.3 4.29a1 1 0 0 0 0 1.42a1 1 0 0 0 1.42 0l4.29-4.3l4.29 4.3a1 1 0 0 0 1.42 0a1 1 0 0 0 0-1.42Z"%2F%3E%3C%2Fsvg%3E');
  }
  .token-list {
    background-color: transparent;
    &-item {
      background-color: ${props => props.theme.cardColor};
      & .token-symbol {
        color: ${props => props.theme.fontPrimaryColor};
      }
      & .token-name {
        color: ${props => props.theme.fontSecondaryColor};
      }
      &:hover {
        background-color: ${props => props.theme.navBar.navBarFontHoverColor};
        opacity: 0.8;
      }
    }
  }
  .token-not-found {
    color: ${props => props.theme.fontPrimaryColor}40;
    font-size: 24px;
    font-weight: 700;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 120px;
  }
`
