//id
const CHAIN_ID = 0
//name
const CHAIN_NAME = 'Binance Exchange'
//name
const CHAIN_SHORT_NAME = 'Binance'
//rpc
const RPC = ''
//img
const IMAGE_CHAIN =
  'https://storage.googleapis.com/chomchob/production/public/migrate/logo/9abf73c82df844eaa21ae022a3fed365'
//img info
const IMAGE_INFO =
  'https://storage.googleapis.com/chomchob/production/public/migrate/others/fdd4e7ed1efb4368a46176a40d639886'
//how to
const HOW_TO =
  'https://www.chomchob.com/how-to-use/ccp-to-binance'

const DECIMAL_PRICE = 18

export const off_bx = {
  chainId: CHAIN_ID,
  chainName: CHAIN_NAME,
  shortName: CHAIN_SHORT_NAME,
  imgChain: IMAGE_CHAIN,
  imgInfo: IMAGE_INFO,
  howToUrl: HOW_TO,
  rpc: RPC,
  decimalPrice: DECIMAL_PRICE
}
