//react
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

//components
import LoadingPage from 'components/LoadingPage'
import NotFoundDefaultPage from 'views/NotFoundDefault'
import MaintenancePage from 'views/Maintenance'

// Utils
import { getPlatformByName } from 'platform/platform.js'

//type
import xxreStoreTypes from 'redux/types'

// Actions
import xxaThemeService from 'actions/Theme'
import xxaPlatform from 'actions/Platform'
import xxaAnonymousToken from 'actions/Auth'

// Config
import * as configs from 'configs'

const App = ({ children }: { children: any }) => {
  const theme: any = window.localStorage.getItem('theme')
  if (!theme) {
    window.localStorage.setItem('theme', 'light')
  }
  const anonymousToken = useSelector(
    (state: xxreStoreTypes.Props) => state.anonymousToken
  )
  const themeConfig = useSelector(
    (state: xxreStoreTypes.Props) => state.themeConfig
  )
  const platformConfig = useSelector(
    (state: xxreStoreTypes.Props) => state.platformConfig
  )
  const dispatch = useDispatch()
  // const router = useHistory()

  var renderComponent = <LoadingPage />

  const checkActiveTab = (pathName: string) => {
    let currentPath = window.location.pathname
    if (currentPath === pathName) {
      return true
    } else {
      return false
    }
  }

  const checkOnchain = () => {
    if (
      checkActiveTab('/convert') ||
      checkActiveTab('/history') ||
      checkActiveTab('/')
    ) {
      return true
    } else {
      return false
    }
  }

  if (themeConfig.themeConfig && platformConfig.platformConfig) {
    if (
      !checkOnchain() &&
      !platformConfig.platformConfig.network_ids.point_to_crypto
    ) {
      renderComponent = <NotFoundDefaultPage />
    } else {
      switch (platformConfig.platformConfig.status) {
        case 'enable':
          renderComponent = children
          break
        case 'disable':
          renderComponent = <MaintenancePage />
          break
        case 'maintenance':
          renderComponent = <MaintenancePage />
          break
        default:
          renderComponent = <NotFoundDefaultPage />
          break
      }
    }
  }
  if (anonymousToken.error) {
    renderComponent = (
      <NotFoundDefaultPage
        code={anonymousToken.error.status}
        message={anonymousToken.error.msg}
        description={anonymousToken.error.data?.description}
      />
    )
  }

  let subDomain = xxaPlatform.GetPlatformBySubdomain() as string
  let metaTag = getPlatformByName(subDomain)
  if (!metaTag) {
    metaTag = getPlatformByName('tunnel')
  }
  useEffect(() => {
    dispatch(xxaAnonymousToken.GetAnonymousToken())
  }, [dispatch])

  useEffect(() => {
    if (anonymousToken.anonymous) {
      dispatch(xxaThemeService.GetThemeConfig())
      dispatch(xxaPlatform.GetPlatformConfig())
    }
  }, [anonymousToken])

  useEffect(() => {
    if (platformConfig.platformConfig) {
      let onChain: any = []
      let offChain: any = []
      platformConfig.platformConfig.network_ids.crypto_to_point.forEach(
        (item: number) => {
          let data = configs.web3.find(i => {
            return item === i.chainId
          })
          if (data) {
            onChain.push(data)
          }
        }
      )
      platformConfig.platformConfig.network_ids.point_to_crypto?.forEach(
        (item: number) => {
          let data = configs.offChain.find(i => {
            return item === i.chainId
          })
          if (data) {
            offChain.push(data)
          }
        }
      )
      dispatch(xxaPlatform.SetPlatformChain(onChain, offChain))
    }
  }, [platformConfig])

  return <>{renderComponent}</>
}

export default App
