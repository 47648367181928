import { useTranslation } from 'react-i18next'

const ConvertButton = () => {

	// Translation
	const { t } = useTranslation('introduction')

	// Href links
	const convertNowUrl = '/convert'

	return (
		<div className="header-container__button__convert-container">
		<a
			// className="convert__link"
			href={convertNowUrl}
			target="_blank"
			rel="noreferrer"
		>
			<button className="convert__button">
				<div className="convert__button-label">
					{t('header')}
				</div>
			</button>
		</a>
	</div>
	)
}

export default ConvertButton
