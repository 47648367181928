import { Dispatch } from 'redux'

// Redux Constants
import {
  GET_PROFILE_CLEAR_MESSAGE,
  GET_PROFILE_FAIL,
  GET_PROFILE_SUCCESS
} from 'redux/Constants/Profile/Get'

//Services
import xxsProfileService from 'services/Profile'
import xxsProfileServiceTypes from 'services/Profile/types'

// Utils
import xxuResponse from 'utils/Response'

const xxaProfile = {
  GetProfile:
    () =>
    async (
      dispatch: Dispatch
    ): Promise<xxsProfileServiceTypes.GetProfileSuccess | undefined> => {
      const response = await xxsProfileService.GetProfile()
      if (response.status === 200) {
        xxuResponse.Success({
          type: GET_PROFILE_SUCCESS,
          dispatch,
          payload: response
        })
      } else {
        xxuResponse.Error({
          type: GET_PROFILE_FAIL,
          errorPage: true,
          dispatch,
          error: response
        })
      }
      return response.getProfile
    },
  ClearGetProfile: () => async (dispatch: Dispatch) => {
    dispatch({
      type: GET_PROFILE_CLEAR_MESSAGE
    })
  }
}

export default xxaProfile
