import { Dispatch } from 'redux'

// Redux Constants
import { UPDATE_DARK_MODE_SUCCESS } from 'redux/Constants/DarkMode'

const xxaDarkMode = {
  SetDarkMode: (darkMode: 'light' | 'dark') => async (dispatch: Dispatch) => {
    dispatch({
      type: UPDATE_DARK_MODE_SUCCESS,
      payload: { darkMode }
    })
  }
}

export default xxaDarkMode
