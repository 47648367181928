import { useTranslation } from "react-i18next"

const ConvertWithBinance = ({ setPointToBinanceModal }) => {

  // Translation
  const { t } = useTranslation("introduction")

  // Href links
  const pointToCryptoUrl = '/binance-to-point' //Binance2CCP

  return (
    <div className="convert-binance">
      <div className="content-row-gap">
        <div className="text-area">
          <div className="text-container">
            <h1 className="title">{t("content.convert-with-binance.title")}</h1>
            <div className="text-green text-default">
              {t("content.convert-with-binance.green-description")}
            </div>
            <div className="text-default">
              <span dangerouslySetInnerHTML={{ __html: t("content.convert-with-binance.description") }}/>
              <button
                className="text-default how-to-use-button"
                onClick={() => setPointToBinanceModal(true)}
              >
                {t("content.convert-with-binance.how-to-use")}
              </button>
            </div>
            <div className="convert-button">
              <a className="convert-button__link">
                <button className="convert-button__link__button" disabled>
                  <div className="convert-button__link__button-label">
                    {t("content.convert-with-binance.button.crypto-to-point")}
                  </div>
                </button>
              </a>
              <a className="convert-button__link" href={pointToCryptoUrl} target="_blank" rel="noreferrer">
                <button className="convert-button__link__button">
                  <div className="convert-button__link__button-label">
                    {t("content.convert-with-binance.button.point-to-crypto")}
                  </div>
                </button>
              </a>
            </div>
          </div>
        </div>
        <div className="image-area">
          <img
            src="https://storage.googleapis.com/chomchob/production/public/Tunnel/c4638a7b-11e8-433c-9d0e-bc2a3e9f8ea7"
            alt="convert-binance"
            width="100%"
          />
        </div>
      </div>
    </div>
  )
}

export default ConvertWithBinance
