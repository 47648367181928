import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ethers } from 'ethers'
import { Navbar, Container, Nav, Button, NavDropdown } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import ReactImageFallback from 'react-image-fallback'
import { toast } from 'react-toastify'
import styled from 'styled-components'
import Switch from 'react-switch'
import ClipboardJS from 'clipboard'

//components
import ChainListModal from 'components/NavBar/ChainListModal'
import MobileSettingModal from 'components/NavBar/MobileSettingModal'
import MoonIcon from 'components/CustomIcon/MoonIcon'
import SunIcon from 'components/CustomIcon/SunIcon'
import SettingIcon from 'components/CustomIcon/SettingIcon'
import CopyIcon from 'components/CustomIcon/CopyIcon'
import MoreIcon from 'components/CustomIcon/MoreIcon'
import SkeletonLoad from 'components/SkeletonLoad'
import ModalLogin from 'components/NavBar/ModalLogin'
import LogoutIcon from 'components/CustomIcon/LogoutIcon'

// Types
import xxreStoreTypes from 'redux/types'
import xxcNavBarTypes from 'components/NavBar/Model'
import {
  CONNECT_WALLET_SUCCESS,
  SWITCH_ACCOUNT_SUCCESS,
  SWITCH_CHAIN_SUCCESS,
  CONNECT_WALLET_FAIL,
  ADD_CHAIN_SUCCESS
} from 'redux/Constants/ConnectWallet'

// Actions
import xxaConnectWallet from 'actions/ConnectWallet'
import xxaProfile from 'actions/Profile'
import xxaLogin from 'actions/Login'
import xxaQueryString from 'actions/QueryString'

// Utils
import * as configs from 'configs'
import xxColor from 'utils/ColorShade'
import xxuURL from 'utils/URL'

// Styles
import './style.scss'

//icon
import image_icon from 'assets/Svg/icon/image_icon.svg'
import image_white_icon from 'assets/Svg/icon/image_white_icon.svg'
import sun_icon from 'assets/Svg/icon/sun_icon.svg'

//image
const bsc_image =
  'https://storage.googleapis.com/chomchob/production/public/migrate/logo/9abf73c82df844eaa21ae022a3fed365'
// import polygon_image from 'assets/Images/Chain/polygon.jpg'

function NavBarComponent({ setToggleTheme }: xxcNavBarTypes.Props) {
  // Initial
  const dispatch = useDispatch()
  new ClipboardJS('.btn')

  const darkMode = window.localStorage.getItem('theme') as 'dark' | 'light'
  const menuData = [
    {
      path: ['/convert', '/history'],
      name: 'Crypto To Point'
    },
    {
      path: ['/point-to-crypto', '/point-to-crypto/history'],
      name: 'Point To Crypto'
    },
    {
      path: ['/binance-to-point', '/binance-to-point/history'],
      name: 'Binance To Point'
    }
  ]
  const menuPointToCrypto = [
    {
      path: '/convert',
      name: 'Convert'
    },
    {
      path: '/history',
      name: 'History'
    }
  ]

  // Redux
  const connectWallet = useSelector(
    (state: xxreStoreTypes.Props) => state.connectWallet
  )
  const themeConfig = useSelector(
    (state: xxreStoreTypes.Props) => state.themeConfig
  )
  const platformChain = useSelector(
    (state: xxreStoreTypes.Props) => state.platformChain
  )
  const verifyLogin = useSelector(
    (state: xxreStoreTypes.Props) => state.verifyLogin
  )
  const getProfile = useSelector(
    (state: xxreStoreTypes.Props) => state.getProfile
  )
  const logout = useSelector((state: xxreStoreTypes.Props) => state.logout)
  const platformConfig = useSelector(
    (state: xxreStoreTypes.Props) => state.platformConfig
  )

  //const
  const router = useHistory()

  const [showLogin, setShowLogin] = useState(false)
  const [showChainList, setShowChainList] = useState(false)
  const [showSetting, setShowSetting] = useState(false)
  const [chainList, setChainList] = useState<
    xxcNavBarTypes.chainType | undefined
  >(undefined)

  const checkOnchain = () => {
    if (
      checkActiveTab(menuData[0].path[0]) ||
      checkActiveTab(menuData[0].path[1])
    ) {
      return 'onChain'
    } else if (
      checkActiveTab(menuData[1].path[0]) ||
      checkActiveTab(menuData[1].path[1])
    ) {
      return 'offChain'
    } else {
      return 'binance'
    }
  }
  // const formatMobile = (address: string): string => {
  //   if (address) {
  //     return `${address.slice(0, 3)}xxxx${address.slice(-3)}`
  //   }
  //   return ''
  // }

  const onClickConnectWallet = async () => {
    const queryStr = xxaQueryString.GetQueryString()
    dispatch(xxaConnectWallet.ConnectWallet(queryStr.chainId))
    if (!window.ethereum) {
      toast.error('Please install metamask.')
    }
  }

  const checkActiveTab = (pathName: string) => {
    let currentPath = window.location.pathname
    if (currentPath === pathName) {
      return true
    } else {
      return false
    }
  }

  const onClickCopyLink = () => {
    navigator.clipboard.writeText(connectWallet.accountAddress)
    toast.success(<div className="text-center">Copy!</div>, {
      icon: false
    })
  }

  const toPath = (pathName: string) => {
    router.push(pathName)
  }

  const onAccountChange = async (accounts: Array<string>) => {
    let Web3 = new ethers.providers.Web3Provider(window.ethereum)
    const chainID = (await Web3.getNetwork()).chainId
    const networkConnect = platformChain.platformChain?.onChain.find(item => {
      return item.chainId === chainID
    })

    let userAgent = navigator.userAgent || navigator.vendor
    if (/android/i.test(userAgent) || /iPad|iPhone|iPod/.test(userAgent)) {
      document.location.reload()
    } else {
      if (accounts[0]) {
        dispatch(
          xxaConnectWallet.ChangeChainAndAccount(
            chainID,
            accounts[0],
            networkConnect ? true : false,
            SWITCH_ACCOUNT_SUCCESS
          )
        )
      } else {
        dispatch(xxaConnectWallet.ClearConnectWallet())
      }
    }
  }

  const onChainChange = async (chainId: string) => {
    const checkChain = platformChain.platformChain?.onChain.find(item => {
      return item.chainId === parseInt(chainId, 16)
    })
    if (checkChain === undefined) {
      dispatch(xxaConnectWallet.ClearConnectWallet())
    } else {
      xxuURL.ChangeURL('chain_id', checkChain.chainId.toString())
      dispatch(xxaConnectWallet.SwitchChain(checkChain.chainId))
    }
  }

  // Helper Function
  const formatAddress = (address: string): string => {
    if (address) {
      return `${address.slice(0, 4)}..${address.slice(-4)}`
    }
    return ''
  }

  const handleLogout = () => {
    if (checkOnchain() === 'onChain') {
      dispatch(xxaConnectWallet.ClearConnectWallet())
      window.localStorage.removeItem('metamask')
      setShowSetting(false)
    } else {
      dispatch(xxaLogin.Logout())
    }
  }

  const openChainList = () => {
    if (platformChain.platformChain?.onChain.length > 1) {
      setShowChainList(true)
      setShowSetting(false)
    }
  }
  const OpenSetting = () => {
    setShowSetting(true)
  }

  const hideModalLogin = () => {
    setShowLogin(false)
    dispatch(xxaLogin.ClearGetLogin())
    dispatch(xxaLogin.ClearGetVerify())
  }

  const toggleTheme = (
    <Switch
      onChange={() => setToggleTheme()}
      checked={darkMode === 'dark' ?? false}
      className="react-switch"
      offColor="#bdc2c4"
      onColor={
        themeConfig.themeConfig?.[darkMode].button.selectChainButton
          .backgroundColor
      }
      offHandleColor="#fff"
      onHandleColor={themeConfig.themeConfig?.[darkMode].cardColor}
      height={32}
      width={60}
      activeBoxShadow="unset"
      uncheckedIcon={
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%'
          }}
        >
          <MoonIcon width={20} height={20} fill="#ffffff" />
        </div>
      }
      checkedIcon={
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%'
          }}
        >
          <SunIcon width={20} height={20} fill="#ffffff" />
        </div>
      }
      uncheckedHandleIcon={
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%'
          }}
        >
          <img src={sun_icon} width="20px" height="20px" alt="light mode" />
        </div>
      }
      checkedHandleIcon={
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%'
          }}
        >
          <MoonIcon
            width={20}
            height={20}
            fill={themeConfig.themeConfig?.[darkMode].primaryColor}
          />
        </div>
      }
    />
  )

  // React Hook
  useEffect(() => {
    if (checkOnchain() === 'onChain') {
      if (connectWallet.type === CONNECT_WALLET_FAIL) {
        dispatch(xxaConnectWallet.SwitchChain(connectWallet.chainId))
      } else if (connectWallet.type === ADD_CHAIN_SUCCESS) {
        const queryStr = xxaQueryString.GetQueryString()
        dispatch(xxaConnectWallet.ConnectWallet(queryStr.chainId))
      }
      if (
        connectWallet.type === CONNECT_WALLET_SUCCESS ||
        connectWallet.type === SWITCH_CHAIN_SUCCESS
      ) {
        const currentChain = configs.web3.find(item => {
          return item.chainId === connectWallet.chainId
        })
        setChainList(currentChain)
      }
    }
  }, [connectWallet])

  useEffect(() => {
    if (checkOnchain() === 'onChain') {
      let connect = window.localStorage.getItem('metamask')
      if (connect) {
        let queryStr = xxaQueryString.GetQueryString()
        dispatch(xxaConnectWallet.ConnectWallet(queryStr.chainId))
      }
      if (typeof window.ethereum !== 'undefined') {
        const ethereum = window.ethereum
        window.ethereum.autoRefreshOnNetworkChange = false
        ethereum.on('accountsChanged', onAccountChange)
        ethereum.on('chainChanged', onChainChange)

        return () => {
          window.ethereum.removeListener('accountsChanged', onAccountChange)
          window.ethereum.removeListener('chainChanged', onChainChange)
        }
      }
      return () => {
        window.ethereum?.removeListener('accountsChanged', onAccountChange)
        window.ethereum?.removeListener('chainChanged', onChainChange)
      }
    }
  }, [platformChain])

  useEffect(() => {
    if (platformChain.platformChain) {
      if (checkOnchain() === 'offChain' || checkOnchain() === 'binance') {
        let { crossToken, chainId } = xxaQueryString.GetQueryString()
        let checkToken = xxaLogin.GetAccessTokenFromLocalStorage()
        if (chainId || chainId === 0) {
          const check = platformChain.platformChain.offChain.find(item => {
            return item.chainId === chainId
          })
          if (check) {
            window.localStorage.setItem('chainID', chainId.toString())
          }
        } else {
          let checkChain = window.localStorage.getItem('chainID')
          if (!checkChain) {
            window.localStorage.setItem('chainID', '0')
          }
        }
        if (crossToken) {
          dispatch(xxaLogin.Logout())
          dispatch(xxaLogin.GetAccessTokenFormCrossToken(crossToken))
        } else if (checkToken.token) {
          dispatch(xxaProfile.GetProfile())
        }
      }
    }
  }, [platformChain])

  useEffect(() => {
    if (
      verifyLogin.verifyLogin?.token &&
      (checkOnchain() === 'offChain' || checkOnchain() === 'binance')
    ) {
      xxuURL.RemoveParam('cross_token')
      dispatch(xxaProfile.GetProfile())
    }
  }, [verifyLogin])

  useEffect(() => {
    if (logout.logout?.msg === 'success') {
      setShowSetting(false)
    }
  }, [logout])

  return (
    <>
      <NavBarCustom
        theme={
          darkMode === 'dark'
            ? themeConfig.themeConfig?.dark
            : themeConfig.themeConfig?.light
        }
        className="NavBar shadow-sm py-0"
        expand="lg"
        sticky="top"
      >
        <Container fluid className="py-2 py-lg-0">
          {/* logo platform desktop */}
          <Navbar.Brand className="NavBrand d-none d-sm-block" href="/">
            <div
              className="d-flex align-items-center"
              style={{ height: '38px' }}
            >
              <ReactImageFallback
                className={`${darkMode === 'dark' ? 'd-block' : 'd-none'}`}
                alt="chomchob-tunnel-logo"
                height="42px"
                src={themeConfig.themeConfig?.dark.navBar.logoImage}
                fallbackImage={image_icon}
                initialImage={
                  <div
                    className={`${darkMode === 'dark' ? 'd-block' : 'd-none'}`}
                  >
                    <SkeletonLoad
                      baseColor={
                        themeConfig.themeConfig?.[darkMode].navBar
                          .navBarBackgroundColor
                      }
                      height={38}
                      width={172.2}
                    />
                  </div>
                }
              />
              <ReactImageFallback
                alt="chomchob-tunnel-logo"
                className={`${darkMode === 'dark' ? 'd-none' : 'd-block'}`}
                height="42px"
                src={themeConfig.themeConfig?.light.navBar.logoImage}
                fallbackImage={image_icon}
                initialImage={
                  <div
                    className={`${darkMode === 'dark' ? 'd-none' : 'd-flex'}`}
                  >
                    <SkeletonLoad
                      baseColor={
                        themeConfig.themeConfig?.[darkMode].navBar
                          .navBarBackgroundColor
                      }
                      height={38}
                      width={172.2}
                    />
                  </div>
                }
              />
            </div>
          </Navbar.Brand>
          {/* logo platform mobile */}
          <Navbar.Brand className="NavBrand mx-0 d-block d-sm-none" href="/">
            <div
              className="d-flex align-items-center"
              style={{ height: '34px' }}
            >
              <div className={`${darkMode === 'dark' ? 'd-block' : 'd-none'}`}>
                <ReactImageFallback
                  className={`${darkMode === 'dark' ? 'd-block' : 'd-none'}`}
                  alt="chomchob-tunnel-logo"
                  height={window.innerWidth > 375 ? '36px' : '30px'}
                  src={themeConfig.themeConfig?.dark.navBar.logoImage}
                  fallbackImage={image_icon}
                  initialImage={
                    <div
                      className={`${
                        darkMode === 'dark' ? 'd-block' : 'd-none'
                      }`}
                    >
                      <SkeletonLoad
                        baseColor={
                          themeConfig.themeConfig?.[darkMode].navBar
                            .navBarBackgroundColor
                        }
                        height={34}
                        width={142.2}
                      />
                    </div>
                  }
                />
              </div>
              <div className={`${darkMode === 'dark' ? 'd-none' : 'd-block'}`}>
                <ReactImageFallback
                  alt="chomchob-tunnel-logo"
                  height={window.innerWidth > 375 ? '36px' : '30px'}
                  src={themeConfig.themeConfig?.light.navBar.logoImage}
                  fallbackImage={image_icon}
                  initialImage={
                    <div
                      className={`${
                        darkMode === 'dark' ? 'd-block' : 'd-none'
                      }`}
                    >
                      <SkeletonLoad
                        baseColor={
                          themeConfig.themeConfig?.[darkMode].navBar
                            .navBarBackgroundColor
                        }
                        height={34}
                        width={142.2}
                      />
                    </div>
                  }
                />
              </div>
            </div>
          </Navbar.Brand>
          {/* setting modal connect wallet mobile */}
          <Nav activeKey="/convert" style={{ flexDirection: 'row' }}>
            <div className="d-flex d-lg-none justify-content-center align-items-center px-2">
              <SettingIcon
                onClick={OpenSetting}
                width={window.innerWidth > 375 ? 28 : 20}
                height={window.innerWidth > 375 ? 28 : 20}
                fill={themeConfig.themeConfig?.[darkMode].fontSecondaryColor}
              />
            </div>
            {checkOnchain() === 'onChain' ? (
              connectWallet.accountAddress ? (
                <>
                  <Button
                    data-clipboard-text={connectWallet.accountAddress}
                    onClick={onClickCopyLink}
                    className="d-block d-lg-none btn-sm button button-address-wallet  d-flex justify-content-center align-items-center gap-1"
                  >
                    <CopyIcon
                      width={20}
                      height={20}
                      fill={
                        themeConfig.themeConfig?.[darkMode].button.addressButton
                          .fontColor
                      }
                    />
                    <div>{formatAddress(connectWallet.accountAddress)}</div>
                  </Button>
                </>
              ) : (
                <Button
                  className="shadow-none button button-connect d-block d-lg-none"
                  onClick={onClickConnectWallet}
                >
                  <b>Connect Wallet</b>
                </Button>
              )
            ) : getProfile.getProfile ? (
              <div className="d-flex d-lg-none">
                <div
                  data-clipboard-text={connectWallet.accountAddress}
                  // onClick={onClickCopyLink}
                  // disabled
                  className="button btn-sm button-address-wallet display-name d-flex justify-content-center align-items-center gap-1"
                >
                  <div>{getProfile.getProfile.display_name}</div>
                </div>
              </div>
            ) : (
              <div className="d-flex d-lg-none">
                <Button
                  className="shadow-none button  button-connect px-4"
                  onClick={() => {
                    setShowLogin(true)
                  }}
                >
                  <b>Login</b>
                </Button>
              </div>
            )}

            <Navbar.Toggle aria-controls="navbarScroll">
              <MoreIcon
                fill={
                  themeConfig.themeConfig?.[darkMode].button.convertButton
                    .backgroundColor || '#FFFFFF'
                }
              />
            </Navbar.Toggle>
          </Nav>
          {/* menu */}
          <Navbar.Collapse className="nav-sm-mt" id="navbarScroll">
            <Nav className="me-auto gap-2">
              <div className="d-bock d-lg-none">
                {menuData.map(item => {
                  return (
                    <Nav.Link
                      key={item.name}
                      className={`px-3 link-nav-tab ${
                        checkActiveTab(item.path[0]) ||
                        checkActiveTab(item.path[1])
                          ? 'link-active'
                          : ''
                      }`}
                      onClick={() =>
                        toPath(
                          `${item.path[0]}${
                            xxaQueryString.GetQueryString().qrURef
                              ? `?user_reference=${
                                  xxaQueryString.GetQueryString().qrURef
                                }`
                              : ''
                          }`
                        )
                      }
                    >
                      {item.name}
                    </Nav.Link>
                  )
                })}
              </div>
              <div
                className="nav-link d-none d-lg-block"
                // onMouseEnter={handleShowDropdown}
                // onMouseLeave={hideDropdown}
              >
                <div className="nav">
                  {/* <NavDropdown
                    show={showDropdown}
                    title="Trade"
                    id="nav-dropdown"
                  >
                    {menuData.map(item => {
                      return (
                        <NavDropdown.Item
                          key={item.name}
                          className={`${checkActiveTab(item.path[0]) ||
                            checkActiveTab(item.path[1])
                            ? 'active'
                            : ''
                            }`}
                          onClick={() =>
                            toPath(
                              `${item.path[0]}${xxaQueryString.GetQueryString().qrURef
                                ? `?user_reference=${xxaQueryString.GetQueryString().qrURef
                                }`
                                : ''
                              }`
                            )
                          }
                        >
                          {item.name}
                        </NavDropdown.Item>
                      )
                    })}
                  </NavDropdown> */}
                  {platformConfig.platformConfig?.network_ids.point_to_crypto
                    ? menuData.map(item => {
                        return (
                          <Nav.Link
                            key={item.name}
                            className={`px-3 link-nav-tab ${
                              checkActiveTab(item.path[0]) ||
                              checkActiveTab(item.path[1])
                                ? 'link-active'
                                : ''
                            }`}
                            onClick={() =>
                              toPath(
                                `${item.path[0]}${
                                  xxaQueryString.GetQueryString().qrURef
                                    ? `?user_reference=${
                                        xxaQueryString.GetQueryString().qrURef
                                      }`
                                    : ''
                                }`
                              )
                            }
                          >
                            {item.name}
                          </Nav.Link>
                        )
                      })
                    : menuPointToCrypto.map(item => {
                        return (
                          <Nav.Link
                            key={item.name}
                            className={`px-3 link-nav-tab ${
                              checkActiveTab(item.path) ? 'link-active' : ''
                            }`}
                            onClick={() =>
                              toPath(
                                `${item.path}${
                                  xxaQueryString.GetQueryString().qrURef
                                    ? `?user_reference=${
                                        xxaQueryString.GetQueryString().qrURef
                                      }`
                                    : ''
                                }`
                              )
                            }
                          >
                            {item.name}
                          </Nav.Link>
                        )
                      })}
                </div>
              </div>
            </Nav>
          </Navbar.Collapse>
          {/* toggle and connect wallet desktop */}
          <Nav className="d-none d-lg-flex">
            <div className="d-flex justify-content-center align-items-center mx-1"></div>
            {checkOnchain() === 'onChain' && !connectWallet.accountAddress ? (
              <div className="d-flex align-items-center mx-1">
                {toggleTheme}
              </div>
            ) : null}
            {checkOnchain() === 'offChain' && !getProfile.getProfile ? (
              <div className="d-flex align-items-center mx-1">
                {toggleTheme}
              </div>
            ) : null}
            {checkOnchain() === 'onChain' ? (
              connectWallet.accountAddress ? (
                <Button
                  className="shadow-none button chain-button"
                  onClick={openChainList}
                >
                  <ReactImageFallback
                    width="26px"
                    alt="chain-image"
                    src={chainList ? chainList.imgChain : bsc_image}
                    fallbackImage={image_white_icon}
                    initialImage={image_white_icon}
                  />
                </Button>
              ) : null
            ) : null}
            {checkOnchain() === 'onChain' && !connectWallet.accountAddress ? (
              <Button
                className="shadow-none button  button-connect"
                onClick={onClickConnectWallet}
              >
                <b>Connect Wallet</b>
              </Button>
            ) : (checkOnchain() === 'offChain' ||
                checkOnchain() === 'binance') &&
              !getProfile.getProfile ? (
              <Button
                className="shadow-none button  button-connect px-4"
                onClick={() => {
                  setShowLogin(true)
                }}
              >
                <b>Login</b>
              </Button>
            ) : getProfile.getProfile || connectWallet.accountAddress ? (
              <div className="address-wallet">
                <NavDropdown
                  title={
                    checkOnchain() === 'onChain'
                      ? formatAddress(connectWallet.accountAddress)
                      : getProfile.getProfile?.display_name
                  }
                  id="nav-dropdown"
                >
                  {checkOnchain() === 'onChain' ? (
                    <NavDropdown.Item onClick={onClickCopyLink}>
                      <div className="d-flex justify-content-between align-items-center">
                        Copy Address
                        <CopyIcon
                          width={20}
                          height={20}
                          fill={
                            themeConfig.themeConfig?.[darkMode].button
                              .addressButton.fontColor
                          }
                        />
                      </div>
                    </NavDropdown.Item>
                  ) : null}

                  <div className="d-flex justify-content-between dropdown-item align-items-center">
                    <div>Dark Mode</div>
                    <Switch
                      onChange={() => setToggleTheme()}
                      checked={darkMode === 'dark' ?? false}
                      className="react-switch"
                      offColor="#bdc2c4"
                      onColor={
                        themeConfig.themeConfig?.[darkMode].button
                          .selectChainButton.backgroundColor
                      }
                      offHandleColor="#fff"
                      onHandleColor={
                        themeConfig.themeConfig?.[darkMode].cardColor
                      }
                      height={32}
                      width={60}
                      activeBoxShadow="unset"
                      uncheckedIcon={
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '100%'
                          }}
                        >
                          <MoonIcon width={20} height={20} fill="#ffffff" />
                        </div>
                      }
                      checkedIcon={
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '100%'
                          }}
                        >
                          <SunIcon width={20} height={20} fill="#ffffff" />
                        </div>
                      }
                      uncheckedHandleIcon={
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '100%'
                          }}
                        >
                          <img
                            src={sun_icon}
                            width="20px"
                            height="20px"
                            alt="light mode"
                          />
                        </div>
                      }
                      checkedHandleIcon={
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '100%'
                          }}
                        >
                          <MoonIcon
                            width={20}
                            height={20}
                            fill={
                              themeConfig.themeConfig?.[darkMode].primaryColor
                            }
                          />
                        </div>
                      }
                    />
                  </div>
                  <NavDropdown.Item onClick={handleLogout}>
                    <div className="d-flex justify-content-between align-items-center">
                      Logout
                      <LogoutIcon
                        width={20}
                        height={20}
                        fill={
                          themeConfig.themeConfig?.[darkMode].button
                            .addressButton.fontColor || '#ffffff'
                        }
                      />
                    </div>
                  </NavDropdown.Item>
                </NavDropdown>
              </div>
            ) : null}
          </Nav>
        </Container>
      </NavBarCustom>
      {/* tab */}
      {platformConfig.platformConfig?.network_ids.point_to_crypto ? (
        <NavBarTabCustom
          theme={
            darkMode === 'dark'
              ? themeConfig.themeConfig?.dark
              : themeConfig.themeConfig?.light
          }
          className="NavBar shadow-sm py-0"
        >
          <Nav
            className="d-flex justify-content-center"
            variant="tabs"
            defaultActiveKey={window.location.pathname}
          >
            <Nav.Item>
              <Nav.Link
                className={`${
                  checkActiveTab(
                    checkOnchain() === 'onChain'
                      ? menuData[0].path[0]
                      : checkOnchain() === 'offChain'
                      ? menuData[1].path[0]
                      : menuData[2].path[0]
                  )
                    ? 'active'
                    : ''
                }`}
                onClick={() =>
                  toPath(
                    `${
                      checkOnchain() === 'onChain'
                        ? menuData[0].path[0]
                        : checkOnchain() === 'offChain'
                        ? menuData[1].path[0]
                        : menuData[2].path[0]
                    }${
                      xxaQueryString.GetQueryString().qrURef
                        ? `?user_reference=${
                            xxaQueryString.GetQueryString().qrURef
                          }`
                        : ''
                    }`
                  )
                }
              >
                Convert
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                className={`${
                  checkActiveTab(
                    checkOnchain() === 'onChain'
                      ? menuData[0].path[1]
                      : checkOnchain() === 'offChain'
                      ? menuData[1].path[1]
                      : menuData[2].path[1]
                  )
                    ? 'active'
                    : ''
                }`}
                onClick={() =>
                  toPath(
                    `${
                      checkOnchain() === 'onChain'
                        ? menuData[0].path[1]
                        : checkOnchain() === 'offChain'
                        ? menuData[1].path[1]
                        : menuData[2].path[1]
                    }${
                      xxaQueryString.GetQueryString().qrURef
                        ? `?user_reference=${
                            xxaQueryString.GetQueryString().qrURef
                          }`
                        : ''
                    }`
                  )
                }
              >
                History
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </NavBarTabCustom>
      ) : null}
      <ModalLogin show={showLogin} onHide={hideModalLogin} />
      <ChainListModal
        show={showChainList}
        setChainSelect={setChainList}
        onHide={() => {
          setShowChainList(false)
        }}
        type="crypto_to_point"
      />
      <MobileSettingModal
        show={showSetting}
        openChainList={openChainList}
        onHide={() => {
          setShowSetting(false)
        }}
        handleLogout={handleLogout}
        child={toggleTheme}
      />
    </>
  )
}

export default NavBarComponent

const NavBarCustom = styled(Navbar)<xxcNavBarTypes.NavBarCustom>`
  background-color: ${props => props.theme.navBar.navBarBackgroundColor};
  .link-nav-tab {
    color: ${props => props.theme.navBar.navBarFontColor} !important;
    &:hover {
      background-color: ${props => props.theme.navBar.navBarFontHoverColor};
    }
  }
  .link-active {
    color: ${props => props.theme.navBar.navBarFontActiveColor} !important;
  }
  .nav-link:hover {
    .dropdown {
      background-color: ${props => props.theme.navBar.navBarFontHoverColor};
      border-radius: 15px;
    }
  }
  .address-wallet {
    .dropdown {
      .dropdown-toggle {
        border-color: ${props =>
          props.theme.button.addressButton.backgroundColor};
        background-color: ${props =>
          props.theme.button.addressButton.backgroundColor};
        color: ${props => props.theme.button.addressButton.fontColor};
        font-weight: 600;
        font-size: 20px;
        border-radius: 15px;
        padding-inline: 0.75rem;
        &:hover {
          opacity: 0.65;
        }
        &:active {
          opacity: 0.65;
        }
        &:active:focus {
          opacity: 1;
          box-shadow: 0 0 0 0.25rem
            ${props => props.theme.button.addressButton.backgroundColor}8c !important;
        }
        &::after {
          display: none;
        }
      }
      & .dropdown-menu {
        background-color: ${props => props.theme.navBar.navBarBackgroundColor};
        border: 1px solid
          ${props =>
            xxColor.colorShade(props.theme.navBar.navBarBackgroundColor, -10)};
        border-radius: 16px;
        padding-bottom: 10px;
        padding-top: 10px;
        pointer-events: auto;
        width: 220px;
        visibility: visible;
        z-index: 1001;
        margin-top: 8px;
        top: unset;
        left: unset;
        right: 0;
        > .dropdown-item {
          color: ${props => props.theme.navBar.navBarFontColor} !important;
          font-weight: 600;
          font-size: 20px;
          padding: 0.375rem 1rem;
          &:hover {
            background-color: ${props =>
              props.theme.navBar.navBarFontHoverColor};
          }
          &.active {
            background-color: unset;
            color: ${props =>
              props.theme.navBar.navBarFontActiveColor} !important;
          }
        }
        & :first-child {
          border-top-left-radius: 2px;
          border-top-right-radius: 2px;
        }
        & :last-child {
          border-bottom-left-radius: 2px;
          border-bottom-right-radius: 2px;
        }
      }
    }
  }
  .nav-menu {
    .dropdown {
      .dropdown-toggle {
        color: ${props => props.theme.navBar.navBarFontActiveColor} !important;
        font-weight: 600;
        font-size: 20px;
        border-radius: 15px;
        &::after {
          display: none;
        }
      }
      &:hover {
        background-color: ${props => props.theme.navBar.navBarFontHoverColor};
        border-radius: 15px;
      }
      & .dropdown-menu {
        background-color: ${props => props.theme.navBar.navBarBackgroundColor};
        border: 1px solid
          ${props =>
            xxColor.colorShade(props.theme.navBar.navBarBackgroundColor, -10)};
        border-radius: 16px;
        padding-bottom: 10px;
        padding-top: 10px;
        pointer-events: auto;
        width: auto;
        visibility: visible;
        z-index: 1001;
        margin-top: 8px;
        top: unset;
        > .dropdown-item {
          color: ${props => props.theme.navBar.navBarFontColor} !important;
          font-weight: 600;
          font-size: 20px;
          padding: 0.375rem 1rem;
          &:hover {
            background-color: ${props =>
              props.theme.navBar.navBarFontHoverColor};
          }
          &.active {
            background-color: unset;
            color: ${props =>
              props.theme.navBar.navBarFontActiveColor} !important;
          }
        }
        & :first-child {
          border-top-left-radius: 2px;
          border-top-right-radius: 2px;
        }
        & :last-child {
          border-bottom-left-radius: 2px;
          border-bottom-right-radius: 2px;
        }
      }
    }
  }

  .display-name {
    height: 42px;
    &:hover {
      opacity: 1 !important;
    }
  }
  .button-address-wallet {
    border-color: ${props => props.theme.button.addressButton.backgroundColor};
    background-color: ${props =>
      props.theme.button.addressButton.backgroundColor};
    color: ${props => props.theme.button.addressButton.fontColor};
    &:hover {
      opacity: 0.65;
    }
    &:active {
      opacity: 0.65;
    }
    &:active:focus {
      opacity: 1;
      box-shadow: 0 0 0 0.25rem
        ${props => props.theme.button.addressButton.backgroundColor}8c !important;
    }
  }

  .button-connect {
    border-color: ${props =>
      props.theme.button.connectWalletButton.backgroundColor};
    background-color: ${props =>
      props.theme.button.connectWalletButton.backgroundColor};
    color: ${props => props.theme.button.connectWalletButton.fontColor};
    &:hover {
      opacity: 0.65;
    }
    &:active {
      opacity: 0.65;
    }
    &:active:focus {
      opacity: 1;
      box-shadow: 0 0 0 0.25rem
        ${props => props.theme.button.connectWalletButton.backgroundColor}8c !important;
    }
  }

  .chain-button {
    border-color: ${props =>
      props.theme.button.selectChainButton.backgroundColor};
    background-color: ${props =>
      props.theme.button.selectChainButton.backgroundColor};
    color: ${props => props.theme.button.selectChainButton.fontColor};
    &:hover {
      opacity: 0.65;
    }
    &:active {
      opacity: 0.65;
    }
    &:active:focus {
      opacity: 1;
      box-shadow: 0 0 0 0.25rem
        ${props => props.theme.button.selectChainButton.backgroundColor}8c !important;
    }
  }
  @media (max-width: 540px) {
    .button-address-wallet {
      &:hover {
        background-color: ${props =>
          props.theme.button.addressButton.backgroundColor};
        border-color: ${props =>
          props.theme.button.addressButton.backgroundColor};
        color: ${props => props.theme.button.addressButton.fontColor};
        opacity: unset;
      }
      &:active {
        box-shadow: 0 0 0 0.25rem
          ${props => props.theme.button.addressButton.backgroundColor}8c !important;
        opacity: 0.65;
        color: ${props => props.theme.button.addressButton.fontColor};
      }
      &:disabled {
        opacity: 0.65 !important;
      }
    }
    .display-name {
      &:active {
        box-shadow: none !important;
        opacity: 1 !important;
        color: ${props => props.theme.button.addressButton.fontColor};
      }
    }
    .button-connect {
      &:hover {
        background-color: ${props =>
          props.theme.button.connectWalletButton.backgroundColor};
        border-color: ${props =>
          props.theme.button.connectWalletButton.backgroundColor};
        color: ${props => props.theme.button.connectWalletButton.fontColor};
        opacity: unset;
      }
      &:active {
        box-shadow: 0 0 0 0.25rem
          ${props => props.theme.button.connectWalletButton.backgroundColor}8c !important;
        opacity: 0.65;
        color: ${props => props.theme.button.connectWalletButton.fontColor};
      }
      &:disabled {
        opacity: 0.65 !important;
      }
    }
  }
`
const NavBarTabCustom = styled.div<xxcNavBarTypes.NavBarCustom>`
  background-color: ${props =>
    xxColor.colorShade(props.theme.navBar.navBarBackgroundColor, -5)};
  .nav-tabs {
    border: none;
    .nav-link {
      font-weight: 600;
      font-size: 18px;
      border: none;
      margin-bottom: 0 !important;
      color: ${props => props.theme.navBar.navBarFontColor} !important;
      &:hover {
        border-color: ${props => props.theme.navBar.navBarFontHoverColor};
        background-color: ${props => props.theme.navBar.navBarFontHoverColor};
        border: none;
        border-radius: 0;
        margin-bottom: 0 !important;
      }
      &.active {
        background: unset;
        color: ${props => props.theme.navBar.navBarFontActiveColor} !important;
        position: relative;
        border: none;
        &::after {
          content: '';
          position: absolute;
          bottom: 0px;
          height: 4px;
          width: 100%;
          background-color: ${props =>
            props.theme.navBar.navBarFontActiveColor} !important;
          border-radius: 2px 2px 0px 0px;
          left: 0;
        }
      }
    }
  }
`
