import axios from 'axios'
import xxsLoginServiceTypes from 'services/Login/types'
import xxaDevice from 'actions/Utils/Device'
import { servicePath } from 'configs'
import xxaInitial from 'actions/Initial'

const xxsLoginService = {
  GetLogin: async (
    mobileNumber: string
  ): Promise<xxsLoginServiceTypes.GetLoginResponse> => {
    let response: xxsLoginServiceTypes.GetLoginResponse
    const anonymousToken = await xxaInitial.CheckAnonymous()
    let data = {
      mobile_number: mobileNumber
    }
    try {
      const responseData =
        await axios.post<xxsLoginServiceTypes.GetLoginSuccess>(
          `${servicePath.service.auth}/v1/tunnel/otp/request`,
          data,
          {
            headers: {
              Authorization: anonymousToken,
              'device-Id': await xxaDevice.GetDeviceId(),
              'accept-language': servicePath.acceptLanguage
            }
          }
        )
      response = {
        status: responseData.status,
        getLogin: responseData.data
      }
    } catch (error: any) {
      const err = error.toJSON()
      if (err.message === 'Network Error') {
        response = {
          status: 500,
          error: err
        }
      } else {
        response = {
          status: error.response.status,
          error: error.response.data
        }
      }
    }
    return response
  },
  VerifyLogin: async (
    mobileNumber: string,
    otp: string,
    otpRefCode: string
  ): Promise<xxsLoginServiceTypes.VerifyLoginResponse> => {
    let response: xxsLoginServiceTypes.VerifyLoginResponse
    const anonymousToken = await xxaInitial.CheckAnonymous()

    let data = {
      mobile_number: mobileNumber,
      otp: otp,
      ref_code: otpRefCode
    }

    try {
      const responseData =
        await axios.post<xxsLoginServiceTypes.VerifyLoginSuccess>(
          `${servicePath.service.auth}/v1/tunnel/otp/verify`,
          data,
          {
            headers: {
              Authorization: anonymousToken,
              'device-Id': await xxaDevice.GetDeviceId(),
              'accept-language': servicePath.acceptLanguage
            }
          }
        )
      response = {
        status: responseData.status,
        verifyLogin: responseData.data
      }
    } catch (error: any) {
      const err = error.toJSON()
      if (err.message === 'Network Error') {
        response = {
          status: 500,
          error: err
        }
      } else {
        response = {
          status: error.response.status,
          error: error.response.data
        }
      }
    }
    return response
  },
  GetRefreshToken: async (
    refresh_token: string
  ): Promise<xxsLoginServiceTypes.VerifyLoginResponse> => {
    let response: xxsLoginServiceTypes.VerifyLoginResponse
    try {
      const responseData =
        await axios.post<xxsLoginServiceTypes.VerifyLoginSuccess>(
          `${servicePath.service.auth}/v1/tunnel/refresh-token`,
          {},
          {
            headers: {
              Authorization: refresh_token,
              'device-Id': await xxaDevice.GetDeviceId(),
              'accept-language': servicePath.acceptLanguage
            }
          }
        )
      response = {
        status: responseData.status,
        verifyLogin: responseData.data
      }
    } catch (error: any) {
      const err = error.toJSON()
      if (err.message === 'Network Error') {
        response = {
          status: 500,
          error: err
        }
      } else {
        response = {
          status: error.response.status,
          error: error.response.data
        }
      }
    }
    return response
  },
  GetAccessTokenFormCrossToken: async (
    cross_token: string
  ): Promise<xxsLoginServiceTypes.VerifyLoginResponse> => {
    let response: xxsLoginServiceTypes.VerifyLoginResponse
    const anonymousToken = await xxaInitial.CheckAnonymous()

    try {
      const responseData =
        await axios.post<xxsLoginServiceTypes.VerifyLoginSuccess>(
          `${servicePath.service.auth}/v1/tunnel/cross-token/access-token`,
          { cross_token },
          {
            headers: {
              Authorization: anonymousToken,
              'device-Id': await xxaDevice.GetDeviceId(),
              'accept-language': servicePath.acceptLanguage
            }
          }
        )
      response = {
        status: responseData.status,
        verifyLogin: responseData.data
      }
    } catch (error: any) {
      const err = error.toJSON()
      if (err.message === 'Network Error') {
        response = {
          status: 500,
          error: err
        }
      } else {
        response = {
          status: error.response.status,
          error: error.response.data
        }
      }
    }
    return response
  },
  Logout: async (
    refresh_token: string
  ): Promise<xxsLoginServiceTypes.LogoutResponse> => {
    let response: xxsLoginServiceTypes.LogoutResponse
    try {
      const responseData = await axios.post<xxsLoginServiceTypes.LogoutSuccess>(
        `${servicePath.service.auth}/v1/tunnel/revoke-token`,
        {},
        {
          headers: {
            Authorization: refresh_token,
            'device-Id': await xxaDevice.GetDeviceId(),
            'accept-language': servicePath.acceptLanguage
          }
        }
      )
      response = {
        status: responseData.status,
        logout: responseData.data
      }
    } catch (error: any) {
      const err = error.toJSON()
      if (err.message === 'Network Error') {
        response = {
          status: 500,
          error: err
        }
      } else {
        response = {
          status: error.response.status,
          error: error.response.data
        }
      }
    }
    return response
  }
}

export default xxsLoginService
