// Constants
import {
  POINT_TO_CRYPTO_REQUEST_CLEAR_MESSAGE,
  POINT_TO_CRYPTO_REQUEST_FAIL,
  POINT_TO_CRYPTO_REQUEST_SUCCESS
} from 'redux/Constants/TunnelService'

// Props Types
import IAction from 'redux/Action/types'
import xxreTunnelServicePointToCryptoRequestReducerTypes from 'redux/Reducers/TunnelService/PointToCryptoRequest/types'

const pointToCryptoRequest = (
  state: object = {},
  action: IAction<xxreTunnelServicePointToCryptoRequestReducerTypes.Props>
) => {
  switch (action.type) {
    case POINT_TO_CRYPTO_REQUEST_SUCCESS:
      return {
        ...state,
        ...action.payload,
        type: action.type
      }
    case POINT_TO_CRYPTO_REQUEST_FAIL:
      return {
        ...state,
        ...action.payload,
        type: action.type
      }
    case POINT_TO_CRYPTO_REQUEST_CLEAR_MESSAGE:
      return {}
    default:
      return state
  }
}

export default pointToCryptoRequest
