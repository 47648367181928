const xxaQueryString = {
  GetQueryString: () => {
    const search = window.location.search
    const qs = new URLSearchParams(search)
    const chainId = qs.get('chain_id') ? Number(qs.get('chain_id')) : null
    const tokenSymbol = qs.get('token_symbol')
    const qrURef = qs.get('user_reference')
    const crossToken = qs.get('cross_token')
    const userAddress = qs.get('user_address')
    const tid = qs.get('tid')

    return { chainId, tokenSymbol, qrURef, crossToken, userAddress, tid }
  }
}

export default xxaQueryString
