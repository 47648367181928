import { Dispatch } from 'redux'
import dayjs from 'dayjs'

// Redux Constants
import {
  GET_ANONYMOUS_SUCCESS,
  GET_ANONYMOUS_FAIL,
  GET_ANONYMOUS_CLEAR_MESSAGES
} from 'redux/Constants/Auth/AnonymousToken'

//Services
import xxsAuthAnonymousTokenService from 'services/Auth/AnonymousToken'

// Utils
import xxuResponse from 'utils/Response'

const xxaAnonymousToken = {
  GetAnonymousToken: () => async (dispatch: Dispatch) => {
    // Clear data of example in Redux
    dispatch({
      type: GET_ANONYMOUS_CLEAR_MESSAGES
    })

    const response = await xxsAuthAnonymousTokenService.GetAnonymousToken()
    if (response.status === 200) {
      window.localStorage.setItem('a', JSON.stringify(response.anonymous))
      xxuResponse.Success({
        type: GET_ANONYMOUS_SUCCESS,
        dispatch,
        payload: response
      })
    } else {
      xxuResponse.Error({
        type: GET_ANONYMOUS_FAIL,
        errorPage: true,
        dispatch,
        error: response
      })
    }
  },
  GetAndCheckAnonymousToken: () => async (dispatch: Dispatch) => {
    try {
      const anonymousData = window.localStorage.getItem('a')
      let { token, token_expire_at } = anonymousData
        ? JSON.parse(anonymousData)
        : { token: '', token_expire_at: '' }
      if (
        !token ||
        !token_expire_at ||
        dayjs().isAfter(dayjs(token_expire_at).subtract(10, 'seconds'))
      ) {
        const response = await xxsAuthAnonymousTokenService.GetAnonymousToken()
        if (
          response.status === 200 &&
          response.anonymous?.token &&
          response.anonymous?.token_expire_at
        ) {
          token = response.anonymous.token
          token_expire_at = response.anonymous.token_expire_at
        } else {
          throw response.error
        }
      }

      xxuResponse.Success({
        type: GET_ANONYMOUS_SUCCESS,
        dispatch,
        payload: {
          token,
          token_expire_at
        }
      })
    } catch (error) {
      xxuResponse.Error({
        type: GET_ANONYMOUS_FAIL,
        errorPage: true,
        dispatch,
        error: error
      })
    }
  },
  GetAnonymousTokenFromLocalStorage: () => {
    return new Promise<{
      status: number
      anonymous?: any
      error?: any
    }>(async resolve => {
      const anonymousData = window.localStorage.getItem('a')

      const anonymous = anonymousData ? JSON.parse(anonymousData) : ''

      if (anonymous) {
        resolve({
          status: 200,
          anonymous: anonymous
        })
      } else {
        resolve({
          status: 403,
          error: {
            msg: 'cannot get data from local storage'
          }
        })
      }
    })
  }
}

export default xxaAnonymousToken
