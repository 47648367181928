import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'

// Redux Store
import configStore from 'redux/Store'

// Routers
import xxroMainRouter from 'routers'

// Layout & Views
import Layout from 'layouts'
import NotFoundPage from 'views/NotFound'
import App from './App'

// PWA
import * as serviceWorkerRegistration from 'serviceWorkerRegistration'
import reportWebVitals from 'reportWebVitals'

// i18n & Styles
import 'bootstrap/dist/css/bootstrap.min.css'
import 'react-toastify/dist/ReactToastify.css'
import 'i18n'
import 'styles/Main.scss'
import 'react-loading-skeleton/dist/skeleton.css'

// Initial Store
const store = configStore()

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Suspense fallback="Loading">
        <App>
          <Router>
            <Switch>
              {xxroMainRouter.map(route => {
                return (
                  <Route
                    key={`router-${route.path.replaceAll('/', '-')}`}
                    path={route.path}
                    exact={route.exact}
                    render={() => {
                      return (
                        <Layout isAuth={route.isAuth} layout={route.layout}>
                          <route.component />
                        </Layout>
                      )
                    }}
                  />
                )
              })}
              <Route path="*" component={NotFoundPage} />
            </Switch>
          </Router>
        </App>
      </Suspense>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
