import { combineReducers } from 'redux'

import anonymousToken from 'redux/Reducers/Auth/AnonymousToken'
import approveToken from 'redux/Reducers/TunnelContract/ApproveToken'
import checkUserRef from 'redux/Reducers/TunnelService/CheckUserRef'
import convertToken from 'redux/Reducers/TunnelContract/ConvertToken'
import getConfig from 'redux/Reducers/TunnelService/GetConfig'
import getHistory from 'redux/Reducers/TunnelService/GetHistory'
import getTokenPrice from 'redux/Reducers/TunnelService/GetTokenPrice'
import notiBox from 'redux/Reducers/NotiBox'
import web3 from 'redux/Reducers/Web3'
import connectWallet from 'redux/Reducers/ConnectWallet'
import themeConfig from 'redux/Reducers/Theme'
import darkMode from 'redux/Reducers/DarkMode'
import getPlatform from 'redux/Reducers/Platform/Get'
import platformConfig from 'redux/Reducers/Platform/Config'
import platformChain from 'redux/Reducers/Platform/Chain'
import getLogin from 'redux/Reducers/Login/GetLogin'
import verifyLogin from 'redux/Reducers/Login/VerifyLogin'
import getProfile from 'redux/Reducers/Profile/Get'
import logout from 'redux/Reducers/Login/Logout'
import pointToCryptoRequest from 'redux/Reducers/TunnelService/PointToCryptoRequest'
import pointToCryptoConfirm from 'redux/Reducers/TunnelService/PointToCryptoConfirm'
import pointToCryptoHistory from 'redux/Reducers/TunnelService/PointToCryptoHistory'
import addWalletAddress from 'redux/Reducers/Address/Add'
import getWalletAddress from 'redux/Reducers/Address/Get'
import deleteWalletAddress from 'redux/Reducers/Address/Delete'
import binanceToPointRequest from 'redux/Reducers/TunnelService/BinanceToPointRequest'
import binanceToPointHistory from 'redux/Reducers/TunnelService/BinanceToPointHistory'
import binanceToPointGetTransaction from 'redux/Reducers/TunnelService/BinanceToPointGetTransaction'

export default combineReducers({
  anonymousToken,
  approveToken,
  checkUserRef,
  convertToken,
  getConfig,
  getHistory,
  getTokenPrice,
  notiBox,
  web3,
  connectWallet,
  themeConfig,
  darkMode,
  getPlatform,
  platformConfig,
  platformChain,
  getLogin,
  verifyLogin,
  getProfile,
  logout,
  pointToCryptoRequest,
  pointToCryptoConfirm,
  pointToCryptoHistory,
  addWalletAddress,
  getWalletAddress,
  deleteWalletAddress,
  binanceToPointRequest,
  binanceToPointHistory,
  binanceToPointGetTransaction
})
