//service
import xxsLoginService from 'services/Login'

const xxaToken = {
  GetRefreshToken: (
    refresh_token: string
  ): Promise<{ status: number; token?: any; error?: any }> => {
    return new Promise(async resolve => {
      const result = await xxsLoginService.GetRefreshToken(refresh_token)
      if (result.status === 200) {
        const token = result.verifyLogin
        window.localStorage.setItem('u', JSON.stringify(result.verifyLogin))
        resolve({
          status: 200,
          token: token
        })
      } else {
        resolve({
          status: result.status,
          error: result.error
        })
        // Logout
        // xaToken.SetTokenExpires()
      }
    })
  }
}

export default xxaToken
