// Constants
import {
  GET_PROFILE_CLEAR_MESSAGE,
  GET_PROFILE_FAIL,
  GET_PROFILE_SUCCESS
} from 'redux/Constants/Profile/Get'

// Props Types
import IAction from 'redux/Action/types'
import xxreGetProfileReducerTypes from 'redux/Reducers/Profile/Get/types'

const getProfile = (
  state: object = {},
  action: IAction<xxreGetProfileReducerTypes.Props>
) => {
  switch (action.type) {
    case GET_PROFILE_SUCCESS:
      return {
        ...state,
        ...action.payload
      }
    case GET_PROFILE_FAIL:
      return {
        ...state,
        ...action.payload
      }
    case GET_PROFILE_CLEAR_MESSAGE:
      return {}
    default:
      return state
  }
}

export default getProfile
