import { useState } from 'react'
import { Container, Row, Col, Button } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify'
import ReactImageFallback from 'react-image-fallback'
import styled from 'styled-components'
import ClipboardJS from 'clipboard'

// Components
import ModalUserRef from 'components/ConvertPage/ModalUserRef'

// Types
import xxcNavBarTypes from 'components/NavBar/Model'
import xxreStoreTypes from 'redux/types'

// Styles
import './style.scss'
//icon
import image_icon from 'assets/Svg/icon/image_icon.svg'
// import xxaQueryString from 'actions/QueryString'

declare let window: any

const HomePage = (): JSX.Element => {
  new ClipboardJS('.btn')
  const { search } = useLocation()
  const userAgent = navigator.userAgent || navigator.vendor
  // const queryStr = xxaQueryString.GetQueryString()

  const themeConfig = useSelector(
    (state: xxreStoreTypes.Props) => state.themeConfig
  )
  const darkMode = useSelector((state: xxreStoreTypes.Props) => state.darkMode)

  const [openModalHowTo, setOpenModalHowTo] = useState<boolean>(false)

  const baseUrl = `${window.location.origin}/convert${search}`

  const deepLinkUrl = `https://metamask.app.link/dapp/${window.location.hostname}/convert${search}`

  const appUrl =
    /android/i.test(userAgent) || /iPad|iPhone|iPod/.test(userAgent)
      ? window.ethereum
        ? baseUrl
        : deepLinkUrl
      : baseUrl

  const onClickCopyLink = () => {
    navigator.clipboard.writeText(baseUrl)
    toast.success(<div className="text-center">Copy!</div>, {
      icon: false
    })
  }

  return (
    <ContainerCustom
      theme={
        darkMode.darkMode === 'dark'
          ? themeConfig.themeConfig?.dark
          : themeConfig.themeConfig?.light
      }
      fluid
      className="Home d-flex flex-column min-vh-100 justify-content-center align-items-center"
    >
      <div>
        <Row className="home-logo-content">
          <Col
            className="d-flex justify-content-center align-items-center"
            xs={12}
          >
            <ReactImageFallback
              alt="icon"
              src={themeConfig.themeConfig?.[darkMode.darkMode].home.homeImage}
              fallbackImage={image_icon}
              initialImage={<div style={{ height: '266.61px' }}></div>}
            />
          </Col>
        </Row>
        <Row className="home-title-content">
          <Col xs={12} className="d-flex justify-content-center">
            <p id="p_standard" className="main-caption">
              {themeConfig.themeConfig?.[darkMode.darkMode].home.description}
            </p>
          </Col>
        </Row>
        <Row className="d-none d-md-block" style={{ marginTop: '' }}>
          <Col
            className="d-flex justify-content-center align-items-center"
            xs={12}
          >
            <a href={appUrl}>
              <Button className="btn btn-lg main-btn button convert-button shadow-none home-button-enter-app">
                <b>Convert Now!</b>
              </Button>
            </a>
          </Col>
        </Row>
        <Row className="d-block d-md-none">
          <Col md={6} xs={12}>
            <a href={appUrl}>
              <Button className="btn btn-lg main-btn button convert-button shadow-none home-button-full-width">
                <b>Convert Now!</b>
              </Button>
            </a>
          </Col>
          <Col className="home-margin-top-10" md={6} xs={12}>
            <form className="mobile-active">
              <Button
                data-clipboard-text={baseUrl}
                className="btn btn-lg button-outline copy-link-button shadow-none home-button-full-width"
                onClick={onClickCopyLink}
              >
                <b>Copy Link</b>
              </Button>
            </form>
          </Col>
        </Row>
        <div className="mt-2 learnMore">
          <span onClick={() => setOpenModalHowTo(true)} role="button">
            Learn more
          </span>
        </div>
      </div>
      <ToastContainer
        autoClose={3000}
        closeOnClick
        draggable
        hideProgressBar={false}
        newestOnTop={false}
        pauseOnFocusLoss
        pauseOnHover
        position="top-right"
        rtl={false}
        // style={toastContainerStyle}
        toastClassName="noti-toast"
      />
      <ModalUserRef
        show={openModalHowTo}
        onHide={() => setOpenModalHowTo(false)}
        imgSrc={themeConfig.themeConfig?.config.howToUseImage || []}
        height={100}
      />
    </ContainerCustom>
  )
}

export default HomePage

const ContainerCustom = styled(Container)<xxcNavBarTypes.NavBarCustom>`
  background: ${props => props.theme.home.homeBackground};
  .main-caption {
    color: ${props => props.theme.home.fontColor};
  }
  .learnMore {
    color: ${props => props.theme.fontSecondaryColor};
    text-align: center;
  }

  .convert-button {
    background-color: ${props =>
      props.theme.button.convertButton.backgroundColor};
    border-color: ${props => props.theme.button.convertButton.backgroundColor};
    color: ${props => props.theme.button.convertButton.fontColor};
    &:hover {
      opacity: 0.65;
      color: ${props => props.theme.button.convertButton.fontColor};
    }
    &:active {
      opacity: 0.65;
      color: ${props => props.theme.button.convertButton.fontColor};
    }
    &:active:focus {
      box-shadow: 0 0 0 0.25rem
        ${props =>
          props.theme.button.convertButton.backgroundColor}8c !important;
    }
  }
  .copy-link-button {
    border-color: ${props => props.theme.button.convertButton.backgroundColor};
    color: ${props => props.theme.button.convertButton.backgroundColor};
    &:hover {
      background-color: ${props =>
        props.theme.button.convertButton.backgroundColor};
      border-color: ${props =>
        props.theme.button.convertButton.backgroundColor};
      color: ${props => props.theme.button.convertButton.fontColor};
    }
    &:focus {
      background-color: ${props => props.theme.button.convertButton.fontColor};
      border-color: ${props =>
        props.theme.button.convertButton.backgroundColor};
      color: ${props => props.theme.button.convertButton.backgroundColor};
    }
    &:active {
      background-color: ${props =>
        props.theme.button.convertButton.backgroundColor};
      border-color: ${props =>
        props.theme.button.convertButton.backgroundColor};
      color: ${props => props.theme.button.convertButton.fontColor};
    }
    &:active:focus {
      box-shadow: 0 0 0 0.25rem
        ${props =>
          props.theme.button.convertButton.backgroundColor}8c !important;
    }

  @media (max-width: 540px) {
    .convert-button {
      &:hover {
        background-color: ${props =>
          props.theme.button.convertButton.backgroundColor};
        border-color: ${props =>
          props.theme.button.convertButton.backgroundColor};
        color: ${props => props.theme.button.convertButton.fontColor};
        opacity: unset;
      }
      &:active {
        box-shadow: 0 0 0 0.25rem
          ${props =>
            props.theme.button.convertButton.backgroundColor}8c !important;
        opacity: 0.65;
        color: ${props => props.theme.button.convertButton.fontColor};
      }
      &:disabled {
        opacity: 0.65 !important;
      }
    }
    .copy-link-button {
      &:hover {
        background-color: unset;
        border-color: ${props =>
          props.theme.button.convertButton.backgroundColor};
        color: ${props => props.theme.button.convertButton.backgroundColor};
      }
      &:active {
        box-shadow: 0 0 0 0.25rem
          ${props =>
            props.theme.button.convertButton.backgroundColor}8c !important;
        background-color: ${props =>
          props.theme.button.convertButton.backgroundColor};
        color: ${props => props.theme.button.convertButton.fontColor};
      }
    }
  }

`
