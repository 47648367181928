// Constants
import {
  TUNNEL_GET_CONFIG_SUCCESS,
  TUNNEL_GET_CONFIG_FAIL,
  TUNNEL_GET_CONFIG_CLEAR_MESSAGE
} from 'redux/Constants/TunnelService'

// Props Types
import IAction from 'redux/Action/types'
import xxreTunnelServiceGetConfigTypes from 'redux/Reducers/TunnelService/GetConfig/types'

const getTunnelConfig = (
  state: object = {},
  action: IAction<xxreTunnelServiceGetConfigTypes.Props>
) => {
  switch (action.type) {
    case TUNNEL_GET_CONFIG_SUCCESS:
      return {
        ...state,
        ...action.payload,
        type: action.type
      }
    case TUNNEL_GET_CONFIG_FAIL:
      return {
        ...state,
        ...action.payload,
        type: action.type
      }
    case TUNNEL_GET_CONFIG_CLEAR_MESSAGE:
      return {}
    default:
      return state
  }
}

export default getTunnelConfig
