import { ethers } from 'ethers'
import { Dispatch } from 'redux'

//services
import xxsTunnelService from 'services/Tunnel'

// Redux Constants
import {
  TUNNEL_APPROVE_TOKEN_CLEAR_MESSAGE,
  TUNNEL_APPROVE_TOKEN_SUCCESS,
  TUNNEL_APPROVE_TOKEN_FAIL,
  TUNNEL_CONVERT_TOKEN_CLEAR_MESSAGE,
  TUNNEL_CONVERT_TOKEN_SUCCESS,
  TUNNEL_CONVERT_TOKEN_FAIL
} from 'redux/Constants/TunnelContract'

// Files
import ERC20ABI from 'contracts/ERC20.json'
import TunnelABI from 'contracts/tunnel.json'
import TunnelABIFreeGas from 'contracts/tunnel-abi.json'
import TokenABI from 'contracts/token-abi.json'

const xxaTunnelContract = {
  ApproveToken:
    (tokenAddress: string, spenderAddress: string, signer: ethers.Signer) =>
    async (dispatch: Dispatch) => {
      dispatch({
        type: TUNNEL_APPROVE_TOKEN_CLEAR_MESSAGE
      })

      try {
        const token = new ethers.Contract(tokenAddress, ERC20ABI, signer)
        const txn = await token.approve(
          spenderAddress,
          ethers.constants.MaxUint256
        )
        await txn.wait()
        dispatch({
          type: TUNNEL_APPROVE_TOKEN_SUCCESS,
          payload: {
            transaction: txn
          }
        })
      } catch (error) {
        dispatch({
          type: TUNNEL_APPROVE_TOKEN_FAIL,
          payload: {
            error
          }
        })
      }
    },
  ApproveTokenFreeGas:
    (
      tokenAddress: string,
      tunnelAddress: string,
      signer: ethers.providers.JsonRpcSigner,
      provider: ethers.providers.Web3Provider
    ) =>
    async (dispatch: Dispatch, getState: Function) => {
      dispatch({
        type: TUNNEL_APPROVE_TOKEN_CLEAR_MESSAGE
      })

      const usdcAddress = '0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174'
      const chainID = `0x${(await signer.getChainId()).toString(16)}`

      try {
        const tunnel = new ethers.Contract(tokenAddress, TokenABI, provider)
        const userAddress = await signer.getAddress()
        const nonce =
          usdcAddress.toLowerCase() === tokenAddress.toLowerCase()
            ? Number(ethers.utils.formatEther(await tunnel.nonces(userAddress)))
            : (await tunnel.getNonce(userAddress)).toNumber()
        const functionSignature = new ethers.utils.Interface(
          TokenABI
        ).encodeFunctionData('approve', [
          tunnelAddress,
          ethers.constants.MaxUint256
        ])
        const domain = {
          name: await tunnel.name(),
          version:
            usdcAddress.toLowerCase() === tokenAddress.toLowerCase()
              ? await tunnel.EIP712_VERSION()
              : await tunnel.ERC712_VERSION(),
          verifyingContract: tunnel.address,
          salt: ethers.utils.hexZeroPad(chainID, 32)
        }

        const metaTransaction = {
          nonce,
          from: userAddress,
          functionSignature
        }

        const types = {
          MetaTransaction: [
            { name: 'nonce', type: 'uint256' },
            { name: 'from', type: 'address' },
            { name: 'functionSignature', type: 'bytes' }
          ]
        }

        const signature = await signer._signTypedData(
          domain,
          types,
          metaTransaction
        )

        const response = await xxsTunnelService.ExecuteMetaTransaction(
          userAddress,
          signature,
          functionSignature,
          tokenAddress
        )

        if (response.status === 200 && response.executeMetaTransaction) {
          const receiptTxn = await provider.waitForTransaction(
            response.executeMetaTransaction.hash
          )
          dispatch({
            type: TUNNEL_APPROVE_TOKEN_SUCCESS,
            payload: {
              transaction: receiptTxn
            }
          })
        } else {
          dispatch({
            type: TUNNEL_APPROVE_TOKEN_FAIL,
            payload: {
              error: response.error
            }
          })
        }
      } catch (error) {
        dispatch({
          type: TUNNEL_APPROVE_TOKEN_FAIL,
          payload: {
            error
          }
        })
      }
    },
  ConvertToken:
    (
      tunnelAddress: string,
      tokenAddress: string,
      amountIn: string,
      amountOutMin: string,
      deadline: number,
      uref: string,
      signer: ethers.Signer
    ) =>
    async (dispatch: Dispatch, getState: Function) => {
      dispatch({
        type: TUNNEL_CONVERT_TOKEN_CLEAR_MESSAGE
      })
      let prefix =
        getState().platformConfig.platformConfig?.prefix_user_reference
      var userRef = `${prefix}:${uref}`
      try {
        const tunnel = new ethers.Contract(tunnelAddress, TunnelABI, signer)
        const txn = await tunnel.convert(
          tokenAddress,
          amountIn,
          amountOutMin,
          deadline,
          userRef
        )
        await txn.wait()
        dispatch({
          type: TUNNEL_CONVERT_TOKEN_SUCCESS,
          payload: {
            transaction: txn
          }
        })
      } catch (error) {
        dispatch({
          type: TUNNEL_CONVERT_TOKEN_FAIL,
          payload: {
            error
          }
        })
      }
    },
  ConvertTokenSupportingFeeOnTransfer:
    (
      tunnelAddress: string,
      tokenAddress: string,
      amountIn: string,
      amountOutMin: string,
      deadline: number,
      uref: string,
      signer: ethers.Signer
    ) =>
    async (dispatch: Dispatch, getState: Function) => {
      dispatch({
        type: TUNNEL_CONVERT_TOKEN_CLEAR_MESSAGE
      })
      let prefix =
        getState().platformConfig.platformConfig?.prefix_user_reference
      var userRef = `${prefix}:${uref}`
      try {
        const tunnel = new ethers.Contract(tunnelAddress, TunnelABI, signer)
        const txn = await tunnel.convertSupportingFeeOnTransfer(
          tokenAddress,
          amountIn,
          amountOutMin,
          deadline,
          userRef
        )
        await txn.wait()
        dispatch({
          type: TUNNEL_CONVERT_TOKEN_SUCCESS,
          payload: {
            transaction: txn
          }
        })
      } catch (error) {
        dispatch({
          type: TUNNEL_CONVERT_TOKEN_FAIL,
          payload: {
            error
          }
        })
      }
    },
  ConvertWithTransferETH:
    (
      tunnelAddress: string,
      amountIn: string,
      uref: string,
      signer: ethers.Signer
    ) =>
    async (dispatch: Dispatch, getState: Function) => {
      dispatch({
        type: TUNNEL_CONVERT_TOKEN_CLEAR_MESSAGE
      })
      let prefix =
        getState().platformConfig.platformConfig?.prefix_user_reference
      var userRef = `${prefix}:${uref}`
      try {
        const tunnel = new ethers.Contract(tunnelAddress, TunnelABI, signer)
        const destinationWallet: string = await tunnel.destinationWallet()
        const txn = await signer.sendTransaction({
          to: destinationWallet,
          value: ethers.BigNumber.from(amountIn),
          data: ethers.utils.toUtf8Bytes(userRef)
        })

        await txn.wait()
        dispatch({
          type: TUNNEL_CONVERT_TOKEN_SUCCESS,
          payload: {
            transaction: txn
          }
        })
      } catch (error) {
        dispatch({
          type: TUNNEL_CONVERT_TOKEN_FAIL,
          payload: {
            error
          }
        })
      }
    },
  ConvertWithTransferMiddleETH:
    (
      tunnelAddress: string,
      amountIn: string,
      deadline: number,
      uref: string,
      signer: ethers.Signer
    ) =>
    async (dispatch: Dispatch, getState: Function) => {
      dispatch({
        type: TUNNEL_CONVERT_TOKEN_CLEAR_MESSAGE
      })
      let prefix =
        getState().platformConfig.platformConfig?.prefix_user_reference
      var userRef = `${prefix}:${uref}`

      try {
        var option = {
          value: ethers.BigNumber.from(amountIn)
        }
        const tunnel = new ethers.Contract(tunnelAddress, TunnelABI, signer)
        const txn = await tunnel.convertWithTransferMiddleETH(
          deadline,
          userRef,
          option
        )
        await txn.wait()
        dispatch({
          type: TUNNEL_CONVERT_TOKEN_SUCCESS,
          payload: {
            transaction: txn
          }
        })
      } catch (error) {
        dispatch({
          type: TUNNEL_CONVERT_TOKEN_CLEAR_MESSAGE
        })
      }
    },
  ConvertWithTransfer:
    (
      tunnelAddress: string,
      tokenAddress: string,
      amountIn: string,
      deadline: number,
      uref: string,
      signer: ethers.Signer
    ) =>
    async (dispatch: Dispatch, getState: Function) => {
      let prefix =
        getState().platformConfig.platformConfig?.prefix_user_reference
      var userRef = `${prefix}:${uref}`
      dispatch({
        type: TUNNEL_CONVERT_TOKEN_CLEAR_MESSAGE
      })

      try {
        const tunnel = new ethers.Contract(tunnelAddress, TunnelABI, signer)
        const txn = await tunnel.convertWithTransfer(
          tokenAddress,
          amountIn,
          deadline,
          userRef
        )
        await txn.wait()
        dispatch({
          type: TUNNEL_CONVERT_TOKEN_SUCCESS,
          payload: {
            transaction: txn
          }
        })
      } catch (error) {
        dispatch({
          type: TUNNEL_CONVERT_TOKEN_FAIL,
          payload: {
            error
          }
        })
      }
    },
  ConvertForETH:
    (
      tunnelAddress: string,
      tokenAddress: string,
      amountIn: string,
      amountOutMin: string,
      deadline: number,
      uref: string,
      signer: ethers.Signer
    ) =>
    async (dispatch: Dispatch, getState: Function) => {
      let prefix =
        getState().platformConfig.platformConfig?.prefix_user_reference
      var userRef = `${prefix}:${uref}`
      dispatch({
        type: TUNNEL_CONVERT_TOKEN_CLEAR_MESSAGE
      })
      try {
        const tunnel = new ethers.Contract(tunnelAddress, TunnelABI, signer)
        const txn = await tunnel.convertForETH(
          tokenAddress,
          amountIn,
          amountOutMin,
          deadline,
          userRef
        )
        await txn.wait()
        dispatch({
          type: TUNNEL_CONVERT_TOKEN_SUCCESS,
          payload: {
            transaction: txn
          }
        })
      } catch (error) {
        dispatch({
          type: TUNNEL_CONVERT_TOKEN_FAIL,
          payload: {
            error
          }
        })
      }
    },
  ConvertForETHSupportingFeeOnTransfer:
    (
      tunnelAddress: string,
      tokenAddress: string,
      amountIn: string,
      amountOutMin: string,
      deadline: number,
      uref: string,
      signer: ethers.Signer
    ) =>
    async (dispatch: Dispatch, getState: Function) => {
      dispatch({
        type: TUNNEL_CONVERT_TOKEN_CLEAR_MESSAGE
      })
      let prefix =
        getState().platformConfig.platformConfig?.prefix_user_reference
      var userRef = `${prefix}:${uref}`

      try {
        const tunnel = new ethers.Contract(tunnelAddress, TunnelABI, signer)
        const txn = await tunnel.convertForETHSupportingFeeOnTransfer(
          tokenAddress,
          amountIn,
          amountOutMin,
          deadline,
          userRef
        )
        await txn.wait()
        dispatch({
          type: TUNNEL_CONVERT_TOKEN_SUCCESS,
          payload: {
            transaction: txn
          }
        })
      } catch (error) {
        dispatch({
          type: TUNNEL_CONVERT_TOKEN_FAIL,
          payload: {
            error
          }
        })
      }
    },
  ConvertFreeGas:
    (
      tunnelAddress: string,
      tokenAddress: string,
      amountIn: string,
      amountOutMin: string,
      deadline: number,
      uref: string,
      signer: ethers.providers.JsonRpcSigner,
      provider: ethers.providers.Web3Provider
    ) =>
    async (dispatch: Dispatch, getState: Function) => {
      dispatch({
        type: TUNNEL_CONVERT_TOKEN_CLEAR_MESSAGE
      })
      let prefix =
        getState().platformConfig.platformConfig?.prefix_user_reference
      var userRef = `${prefix}:${uref}`
      const chainID = `0x${(await signer.getChainId()).toString(16)}`

      try {
        const tunnel = new ethers.Contract(
          tunnelAddress,
          TunnelABIFreeGas,
          provider
        )

        const userAddress = await signer.getAddress()
        const nonce = (await tunnel.getNonce(userAddress)).toNumber()

        const functionSignature = new ethers.utils.Interface(
          TunnelABIFreeGas
        ).encodeFunctionData('convertForETH', [
          tokenAddress,
          amountIn,
          amountOutMin,
          deadline,
          userRef
        ])

        const domain = {
          name: await tunnel.name(),
          version: await tunnel.ERC712_VERSION(),
          verifyingContract: tunnel.address,
          salt: ethers.utils.hexZeroPad(chainID, 32)
        }

        const metaTransaction = {
          nonce,
          from: userAddress,
          functionSignature
        }

        const types = {
          MetaTransaction: [
            { name: 'nonce', type: 'uint256' },
            { name: 'from', type: 'address' },
            { name: 'functionSignature', type: 'bytes' }
          ]
        }

        const signature = await signer._signTypedData(
          domain,
          types,
          metaTransaction
        )

        const response = await xxsTunnelService.ExecuteMetaTransaction(
          userAddress,
          signature,
          functionSignature,
          tunnelAddress
        )

        if (response.status === 200 && response.executeMetaTransaction) {
          const receiptTxn = await provider.waitForTransaction(
            response.executeMetaTransaction.hash
          )
          dispatch({
            type: TUNNEL_CONVERT_TOKEN_SUCCESS,
            payload: {
              transaction: receiptTxn
            }
          })
        } else {
          dispatch({
            type: TUNNEL_CONVERT_TOKEN_FAIL,
            payload: {
              error: response.error
            }
          })
        }
      } catch (error) {
        dispatch({
          type: TUNNEL_CONVERT_TOKEN_FAIL,
          payload: {
            error
          }
        })
      }
    }
}

export default xxaTunnelContract
