import ReactImageFallback from 'react-image-fallback'
import styled from 'styled-components'
import { useSelector } from 'react-redux'

// Actions
import xxcTokenDropdownTypes from 'components/ConvertPage/TokenDropdown/Model'

//type
import xxreStoreTypes from 'redux/types'

//icon
import DownIcon from 'components/CustomIcon/DownIcon'
import image_icon from 'assets/Svg/icon/image_icon.svg'

// Style
import './style.scss'

function TokenDropdown({
  disabled,
  onClick,
  selectedToken
}: xxcTokenDropdownTypes.Props) {
  const themeConfig = useSelector(
    (state: xxreStoreTypes.Props) => state.themeConfig
  )
  const darkMode = useSelector((state: xxreStoreTypes.Props) => state.darkMode)

  return (
    <TokenDropdownCustom
      theme={
        darkMode.darkMode === 'dark'
          ? themeConfig.themeConfig?.dark
          : themeConfig.themeConfig?.light
      }
      type="button"
      className="token-dropdown shadow-none"
      onClick={e => {
        e.preventDefault()
        onClick()
      }}
      disabled={disabled}
    >
      <div className="token-dropdown-display-inline">
        <div className="d-flex align-items-center">
          <ReactImageFallback
            className="token-dropdown-image"
            alt={`image-${selectedToken?.name}`}
            height="24px"
            width="24px"
            src={selectedToken?.image}
            fallbackImage={image_icon}
            initialImage={image_icon}
          />{' '}
          <div className="token-dropdown-padding">
            <div className="token-dropdown-padding-name">
              {selectedToken?.symbol}
            </div>
            <DownIcon
              width={16}
              height={16}
              fill={
                themeConfig.themeConfig?.[darkMode.darkMode].primaryColor ||
                '#ffffff'
              }
            />
          </div>
        </div>
      </div>
    </TokenDropdownCustom>
  )
}

export default TokenDropdown

const TokenDropdownCustom = styled.button<xxcTokenDropdownTypes.TokenDropdownCustom>`
  color: ${props => props.theme.fontPrimaryColor};
`
