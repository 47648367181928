import dayjs from 'dayjs'

// action
import xxaLogin from 'actions/Login'
import xxaAnonymousToken from 'actions/Auth'
import xxaToken from 'actions/Utils/Token'

//service
import xxsAuthAnonymousTokenService from 'services/Auth/AnonymousToken'

const xxaInitial = {
  Check: () => {
    return new Promise(async resolve => {
      const checkToken = xxaLogin.GetAccessTokenFromLocalStorage()
      if (checkToken.status === 200) {
        if (
          dayjs(checkToken.token?.token_expire_at).diff(dayjs(), 'minute') > 0
        ) {
          resolve(checkToken.token?.token)
        } else {
          //call refresh token
          const newToken = await xxaToken.GetRefreshToken(
            checkToken.token.refresh_token
          )
          resolve(newToken.token?.token)
        }
      } else {
        const anonymousData =
          await xxaAnonymousToken.GetAnonymousTokenFromLocalStorage()
        if (
          anonymousData.status === 200 &&
          dayjs(anonymousData.anonymous?.token_expire_at).diff(
            dayjs(),
            'minute'
          ) > 0
        ) {
          resolve(anonymousData.anonymous?.token)
        } else {
          // Call Anonymous Token
          const resultAnonymous =
            await xxsAuthAnonymousTokenService.GetAnonymousToken()
          if (resultAnonymous.status === 200) {
            window.localStorage.setItem(
              'a',
              JSON.stringify(resultAnonymous.anonymous)
            )
            resolve(resultAnonymous.anonymous?.token)
          }
        }
      }
    })
  },
  CheckAnonymous: () => {
    return new Promise(async resolve => {
      const anonymousData =
        await xxaAnonymousToken.GetAnonymousTokenFromLocalStorage()
      if (
        anonymousData.status === 200 &&
        dayjs(anonymousData.anonymous?.token_expire_at).diff(
          dayjs(),
          'minute'
        ) > 0
      ) {
        resolve(anonymousData.anonymous?.token)
      } else {
        // Call Anonymous Token
        const resultAnonymous =
          await xxsAuthAnonymousTokenService.GetAnonymousToken()
        if (resultAnonymous.status === 200) {
          // xaAnonymous.SetAnonymousTokenToLocalStorage(resultAnonymous)
          window.localStorage.setItem(
            'a',
            JSON.stringify(resultAnonymous.anonymous)
          )
          resolve(resultAnonymous.anonymous?.token)
        }
      }
    })
  },
  GetRefreshTokenFormLocalStorage: () => {
    return new Promise<string | null>(async resolve => {
      const checkToken = xxaLogin.GetAccessTokenFromLocalStorage()
      if (
        checkToken.status === 200 &&
        dayjs(checkToken.token?.refresh_token_expire_at).diff(
          dayjs(),
          'minute'
        ) > 0
      ) {
        resolve(checkToken.token?.refresh_token)
      } else {
        resolve(null)
      }
    })
  }
}

export default xxaInitial
