import { Dispatch } from 'redux'

// Redux Constants
import {
  TUNNEL_GET_CONFIG_CLEAR_MESSAGE,
  TUNNEL_GET_CONFIG_FAIL,
  TUNNEL_GET_CONFIG_SUCCESS,
  TUNNEL_GET_TOKEN_PRICE_FAIL,
  TUNNEL_GET_TOKEN_PRICE_SUCCESS,
  TUNNEL_GET_TOKEN_PRICE_CLEAR_MESSAGE,
  TUNNEL_CHECK_USER_REF_CLEAR_MESSAGE,
  TUNNEL_CHECK_USER_REF_FAIL,
  TUNNEL_CHECK_USER_REF_SUCCESS,
  TUNNEL_GET_HISTORY_SUCCESS,
  TUNNEL_GET_HISTORY_FAIL,
  POINT_TO_CRYPTO_REQUEST_SUCCESS,
  POINT_TO_CRYPTO_REQUEST_FAIL,
  POINT_TO_CRYPTO_REQUEST_CLEAR_MESSAGE,
  POINT_TO_CRYPTO_CONFIRM_SUCCESS,
  POINT_TO_CRYPTO_CONFIRM_FAIL,
  POINT_TO_CRYPTO_CONFIRM_CLEAR_MESSAGE,
  POINT_TO_CRYPTO_HISTORY_FAIL,
  POINT_TO_CRYPTO_HISTORY_SUCCESS,
  BINANCE_TO_POINT_REQUEST_CLEAR_MESSAGE,
  BINANCE_TO_POINT_REQUEST_FAIL,
  BINANCE_TO_POINT_REQUEST_SUCCESS,
  BINANCE_TO_POINT_HISTORY_SUCCESS,
  BINANCE_TO_POINT_HISTORY_FAIL,
  BINANCE_TO_POINT_GET_TRANSACTION_CLEAR_MESSAGE,
  BINANCE_TO_POINT_GET_TRANSACTION_FAIL,
  BINANCE_TO_POINT_GET_TRANSACTION_SUCCESS
} from 'redux/Constants/TunnelService'
import xxcChainModalTypes from 'configs/Model'

//Services
import xxsTunnelService from 'services/Tunnel'
import xxsTunnelServiceTypes from 'services/Tunnel/types'
import xxuAuth from 'utils/Auth'

// Utils
import xxuResponse from 'utils/Response'

const xxaTunnelService = {
  ClearTunnelConfig: () => async (dispatch: Dispatch) => {
    dispatch({
      type: TUNNEL_GET_CONFIG_CLEAR_MESSAGE
    })
  },
  GetTunnelConfig:
    (networkId: number, type: xxcChainModalTypes.tunnelType) =>
    async (
      dispatch: Dispatch
    ): Promise<
      xxsTunnelServiceTypes.GetTunnelConfigResponseSuccess | undefined
    > => {
      dispatch({
        type: TUNNEL_GET_CONFIG_CLEAR_MESSAGE
      })
      const anonymousToken = await xxuAuth.GetAnonymousToken()

      const response = await xxsTunnelService.GetTunnelConfig(
        networkId,
        anonymousToken,
        type
      )
      if (response.status === 200) {
        xxuResponse.Success({
          type: TUNNEL_GET_CONFIG_SUCCESS,
          dispatch,
          payload: response
        })
      } else {
        xxuResponse.Error({
          type: TUNNEL_GET_CONFIG_FAIL,
          errorPage: true,
          dispatch,
          error: response
        })
      }
      return response.config
    },
  GetTokenPrice:
    (
      token: string,
      networkId: number,
      type: xxcChainModalTypes.tunnelType = 'crypto_to_point',
      short_name?: string
    ) =>
    async (
      dispatch: Dispatch
    ): Promise<
      xxsTunnelServiceTypes.GetTokenPriceResponseSuccess | undefined
    > => {
      const anonymousToken = await xxuAuth.GetAnonymousToken()
      const response = await xxsTunnelService.GetTokenPrice(
        token,
        networkId,
        anonymousToken,
        type,
        short_name
      )
      if (response.status === 200) {
        xxuResponse.Success({
          type: TUNNEL_GET_TOKEN_PRICE_SUCCESS,
          dispatch,
          payload: response
        })
      } else {
        xxuResponse.Error({
          type: TUNNEL_GET_TOKEN_PRICE_FAIL,
          errorPage: true,
          dispatch,
          error: response
        })
      }
      return response.price
    },
  ClearGetTokenPrice: () => async (dispatch: Dispatch) => {
    dispatch({
      type: TUNNEL_GET_TOKEN_PRICE_CLEAR_MESSAGE
    })
  },
  CheckUserRef:
    (userRef: string) =>
    async (dispatch: Dispatch): Promise<boolean | undefined> => {
      dispatch({
        type: TUNNEL_CHECK_USER_REF_CLEAR_MESSAGE
      })
      const anonymousToken = await xxuAuth.GetAnonymousToken()
      const response = await xxsTunnelService.CheckUserRef(
        userRef,
        anonymousToken
      )
      if (response.status === 200) {
        xxuResponse.Success({
          type: TUNNEL_CHECK_USER_REF_SUCCESS,
          dispatch,
          payload: response
        })
      } else {
        xxuResponse.Error({
          type: TUNNEL_CHECK_USER_REF_FAIL,
          errorPage: true,
          dispatch,
          error: response
        })
      }
      return response.is_exist
    },
  ClearUserRef: () => async (dispatch: Dispatch) => {
    dispatch({
      type: TUNNEL_CHECK_USER_REF_CLEAR_MESSAGE
    })
  },
  GetHistory:
    (address: string, limit: number, offset: number) =>
    async (
      dispatch: Dispatch
    ): Promise<
      xxsTunnelServiceTypes.GetTunnelHistoryResponseSuccess | undefined
    > => {
      const anonymousToken = await xxuAuth.GetAnonymousToken()
      const response = await xxsTunnelService.GetHistory(
        address,
        limit,
        offset,
        anonymousToken
      )
      if (response.status === 200) {
        xxuResponse.Success({
          type: TUNNEL_GET_HISTORY_SUCCESS,
          dispatch,
          payload: response
        })
      } else {
        xxuResponse.Error({
          type: TUNNEL_GET_HISTORY_FAIL,
          errorPage: true,
          dispatch,
          error: response
        })
      }
      return response.history
    },
  PointToCryptoRequest:
    (
      address: string,
      network_id: number,
      token: string,
      point: number,
      sort_name: string,
      memoInput: string,
      amountOutMin: string
    ) =>
    async (
      dispatch: Dispatch
    ): Promise<
      xxsTunnelServiceTypes.PointToCryptoRequestResponseSuccess | undefined
    > => {
      const response = await xxsTunnelService.PointToCryptoRequest(
        address,
        network_id,
        token,
        point,
        sort_name,
        memoInput,
        amountOutMin
      )
      if (response.status === 200) {
        xxuResponse.Success({
          type: POINT_TO_CRYPTO_REQUEST_SUCCESS,
          dispatch,
          payload: response
        })
      } else {
        xxuResponse.Error({
          type: POINT_TO_CRYPTO_REQUEST_FAIL,
          errorPage: true,
          dispatch,
          error: response
        })
      }
      return response.pointToCryptoRequest
    },
  ClearPointToCryptoRequest: () => async (dispatch: Dispatch) => {
    dispatch({
      type: POINT_TO_CRYPTO_REQUEST_CLEAR_MESSAGE
    })
  },
  PointToCryptoConfirm:
    (tid: string) =>
    async (
      dispatch: Dispatch
    ): Promise<
      xxsTunnelServiceTypes.PointToCryptoConfirmResponseSuccess | undefined
    > => {
      const response = await xxsTunnelService.PointToCryptoConfirm(tid)
      if (response.status === 200) {
        xxuResponse.Success({
          type: POINT_TO_CRYPTO_CONFIRM_SUCCESS,
          dispatch,
          payload: response
        })
      } else {
        xxuResponse.Error({
          type: POINT_TO_CRYPTO_CONFIRM_FAIL,
          errorPage: true,
          dispatch,
          error: response
        })
      }
      return response.pointToCryptoConfirm
    },
  ClearPointToCryptoConfirm: () => async (dispatch: Dispatch) => {
    dispatch({
      type: POINT_TO_CRYPTO_CONFIRM_CLEAR_MESSAGE
    })
  },
  GetHistoryPointToCrypto:
    (limit: number, offset: number) =>
    async (
      dispatch: Dispatch
    ): Promise<
      xxsTunnelServiceTypes.PointToCryptoHistoryResponseSuccess | undefined
    > => {
      const response = await xxsTunnelService.GetHistoryPointToCrypto(
        limit,
        offset
      )
      if (response.status === 200) {
        xxuResponse.Success({
          type: POINT_TO_CRYPTO_HISTORY_SUCCESS,
          dispatch,
          payload: response
        })
      } else {
        xxuResponse.Error({
          type: POINT_TO_CRYPTO_HISTORY_FAIL,
          errorPage: true,
          dispatch,
          error: response
        })
      }
      return response.pointToCryptoHistory
    },
  BinanceToPointRequest:
    (token: string, amount: string) =>
    async (
      dispatch: Dispatch
    ): Promise<
      xxsTunnelServiceTypes.BinanceToPointRequestResponseSuccess | undefined
    > => {
      const response = await xxsTunnelService.BinanceToPointRequest(
        token,
        amount
      )
      if (response.status === 200) {
        xxuResponse.Success({
          type: BINANCE_TO_POINT_REQUEST_SUCCESS,
          dispatch,
          payload: response
        })
      } else {
        xxuResponse.Error({
          type: BINANCE_TO_POINT_REQUEST_FAIL,
          errorPage: true,
          dispatch,
          error: response
        })
      }
      return response.binanceToPointRequest
    },
  ClearBinanceToPointRequest: () => async (dispatch: Dispatch) => {
    dispatch({
      type: BINANCE_TO_POINT_REQUEST_CLEAR_MESSAGE
    })
  },
  GetHistoryBinanceToPoint:
    (limit: number, offset: number) =>
    async (
      dispatch: Dispatch
    ): Promise<
      xxsTunnelServiceTypes.BinanceToPointHistoryResponseSuccess | undefined
    > => {
      const response = await xxsTunnelService.GetHistoryBinanceToPoint(
        limit,
        offset
      )
      if (response.status === 200) {
        xxuResponse.Success({
          type: BINANCE_TO_POINT_HISTORY_SUCCESS,
          dispatch,
          payload: response
        })
      } else {
        xxuResponse.Error({
          type: BINANCE_TO_POINT_HISTORY_FAIL,
          errorPage: true,
          dispatch,
          error: response
        })
      }
      return response.binanceToPointHistory
    },
  GetTransactionBinanceToPoint:
    (transaction_id: string) =>
    async (
      dispatch: Dispatch
    ): Promise<xxsTunnelServiceTypes.BinanceToPointHistory | undefined> => {
      const response = await xxsTunnelService.GetTransactionBinanceToPoint(
        transaction_id
      )
      if (response.status === 200) {
        xxuResponse.Success({
          type: BINANCE_TO_POINT_GET_TRANSACTION_SUCCESS,
          dispatch,
          payload: response
        })
      } else {
        xxuResponse.Error({
          type: BINANCE_TO_POINT_GET_TRANSACTION_FAIL,
          errorPage: true,
          dispatch,
          error: response
        })
      }
      return response.binanceToPointGetTransaction
    },
  ClearTransactionBinanceToPoint: () => async (dispatch: Dispatch) => {
    dispatch({
      type: BINANCE_TO_POINT_GET_TRANSACTION_CLEAR_MESSAGE
    })
  }
}

export default xxaTunnelService
