// import React from 'react'

function MoreIcon({
  fill,
  width,
  height,
  onClick
}: {
  fill: string | undefined
  width?: number
  height?: number
  onClick?: Function
}) {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        onClick={() => (onClick ? onClick() : () => {})}
        width={width ?? 5}
        height={height ?? 23}
        viewBox="0 0 5 23"
      >
        <g id="Group_34" data-name="Group 34" transform="translate(-382 -21)">
          <circle
            id="Ellipse_15"
            data-name="Ellipse 15"
            cx="2.5"
            cy="2.5"
            r="2.5"
            transform="translate(382 21)"
            fill={fill}
          />
          <circle
            id="Ellipse_16"
            data-name="Ellipse 16"
            cx="2.5"
            cy="2.5"
            r="2.5"
            transform="translate(382 30)"
            fill={fill}
          />
          <circle
            id="Ellipse_17"
            data-name="Ellipse 17"
            cx="2.5"
            cy="2.5"
            r="2.5"
            transform="translate(382 39)"
            fill={fill}
          />
        </g>
      </svg>
    </>
  )
}

export default MoreIcon
