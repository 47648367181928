import { useState, useEffect } from 'react'
import { Modal, Button, Form } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import PinInput from 'react-pin-input'
import styled from 'styled-components'
import { toast } from 'react-toastify'

// Redux
import xxreStoreTypes from 'redux/types'

// Components
import xxcModalLoginModalTypes from 'components/NavBar/ModalLogin/Model'
//icon
// import image_white_icon from 'assets/Svg/icon/image_white_icon.svg'

//config
import xxaLogin from 'actions/Login'

let intervalHandle: NodeJS.Timeout
let countDownResendOTP: number = 60

function ModalLogin({ onHide, show }: xxcModalLoginModalTypes.Props) {
  const dispatch = useDispatch()

  const [otp, setOtp] = useState<any>('')
  const [countdown, setCountdown] = useState<number>(60)
  const [mobileNumber, setMobileNumber] = useState<any>('')
  const [enableGetLogin, setEnableGetLogin] = useState<any>(true)
  const [enableVerifyLogin, setEnableVerifyLogin] = useState<any>(true)

  // Redux
  const darkMode = useSelector((state: xxreStoreTypes.Props) => state.darkMode)
  const themeConfig = useSelector(
    (state: xxreStoreTypes.Props) => state.themeConfig
  )
  const getLogin = useSelector((state: xxreStoreTypes.Props) => state.getLogin)
  const verifyLogin = useSelector(
    (state: xxreStoreTypes.Props) => state.verifyLogin
  )

  const handleSetOTP = (otp: any) => {
    setOtp(otp)
  }

  const handleGetLogin = () => {
    dispatch(xxaLogin.GetLogin(mobileNumber))
  }

  const handleVerifyLogin = () => {
    dispatch(
      xxaLogin.GetVerify(mobileNumber, otp, getLogin.getLogin?.ref_code || '')
    )
  }

  const onInputMobileNumber = (event: React.FormEvent<HTMLInputElement>) => {
    let value = event.currentTarget.value
    if (/^[\d]*$/.test(value) && value.length <= 10) {
      setMobileNumber(value)
    }
  }

  const handleHideModal = () => {
    onHide()
    setMobileNumber('')
    setOtp('')
    setCountdown(60)
    countDownResendOTP = 60
  }
  const handleChangeMobileNumber = () => {
    setMobileNumber('')
    setOtp('')
    setCountdown(60)
    countDownResendOTP = 60
    dispatch(xxaLogin.ClearGetLogin())
  }

  useEffect(() => {
    var phoneno = /^\d{10}$/
    if (mobileNumber.match(phoneno)) {
      setEnableGetLogin(false)
    } else {
      setEnableGetLogin(true)
    }
  }, [mobileNumber])

  useEffect(() => {
    if (verifyLogin.verifyLogin?.token) {
      handleHideModal()
    } else if (verifyLogin.error) {
      toast.error(
        <div className="noti-message-txn">
          <div>{verifyLogin.error.msg}</div>
        </div>
      )
    }
  }, [verifyLogin])

  useEffect(() => {
    if (otp.length < 6) {
      setEnableVerifyLogin(true)
    } else {
      setEnableVerifyLogin(false)
      handleVerifyLogin()
    }
  }, [otp])

  const stopIntervalTask = () => {
    clearInterval(intervalHandle)
    setCountdown(60)
    countDownResendOTP = 60
  }

  useEffect(() => {
    if (getLogin.getLogin) {
      countDownResendOTP = countDownResendOTP - 1
      setCountdown(countDownResendOTP)
      intervalHandle = setInterval(() => {
        countDownResendOTP = countDownResendOTP - 1
        if (countDownResendOTP === 0) {
          stopIntervalTask()
        } else {
          setCountdown(countDownResendOTP)
        }
      }, 1000)
      return () => clearInterval(intervalHandle)
    } else if (getLogin.error) {
      toast.error(
        <div className="noti-message-txn">
          <div>{getLogin.error.msg}</div>
        </div>
      )
    }
  }, [getLogin])

  return (
    <ModalCustom
      theme={
        darkMode.darkMode === 'dark'
          ? themeConfig.themeConfig?.dark
          : themeConfig.themeConfig?.light
      }
      aria-labelledby="contained-modal-title-vcenter"
      centered
      dialogClassName="token-list-modal"
      className="modal-fullscreen-md-down"
      id="item-modal"
      onHide={handleHideModal}
      show={show}
      // size="lg"
    >
      <Modal.Body className="p-0">
        <Modal.Header closeButton>Login</Modal.Header>
        <div className="px-3 px-sm-5 py-3">
          {getLogin.getLogin ? (
            <>
              <div className="mobile-number d-flex justify-content-center">
                {mobileNumber}
              </div>
              <div className="d-flex justify-content-center mb-3">
                <span
                  role="button"
                  onClick={handleChangeMobileNumber}
                  className="mobile-number-change "
                >
                  Change Mobile Number
                </span>
              </div>

              <Form.Group className="">
                <Form.Label className="d-flex align-items-center mb-1">
                  <div className="convert-container-user-reference">
                    {/* {themeConfig.themeConfig?.config.useRefTitle} */}
                    Enter OTP Code (Ref: {getLogin.getLogin.ref_code})
                  </div>
                </Form.Label>
                {/* <OtpInput
                  shouldAutoFocus={true}
                  isInputNum
                  value={otp}
                  onChange={handleSetOTP}
                  numInputs={6}
                  inputStyle="otp-input"
                  focusStyle="none"
                  containerStyle="otp-input-container"
                // separator={<span>-</span>}
                /> */}
                <PinInput
                  length={6}
                  initialValue=""
                  onChange={handleSetOTP}
                  type="numeric"
                  inputMode="number"
                  focus
                  onComplete={value => {
                    if (value.length === 6) {
                      handleSetOTP(value)
                    }
                  }}
                  autoSelect={true}
                  regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
                />
                <div className="d-flex justify-content-center my-1 mt-3">
                  <span className="link-custom">
                    Dont receive OTP ? (
                    {countdown === 60 ? (
                      <span
                        className="active-link"
                        role="button"
                        onClick={handleGetLogin}
                      >
                        Resend OTP
                      </span>
                    ) : (
                      <span className="countdown">
                        Resend OTP in {countdown}
                      </span>
                    )}
                    )
                  </span>
                </div>
                <Button
                  className="shadow-none button w-100  button-connect px-4 mt-3"
                  onClick={handleVerifyLogin}
                  disabled={enableVerifyLogin}
                >
                  <b>LOGIN</b>
                </Button>
              </Form.Group>
            </>
          ) : (
            <>
              <Form.Group className="mb-3">
                <Form.Label className="d-flex align-items-center mb-1">
                  <div className="convert-container-user-reference">
                    {/* {themeConfig.themeConfig?.config.useRefTitle} */}
                    Mobile Number
                  </div>
                </Form.Label>
                <Form.Control
                  className="convert-input"
                  id="floatingInputURef"
                  onInput={onInputMobileNumber}
                  // placeholder={themeConfig.themeConfig?.config.useRefEX}
                  placeholder="ex. 085XXXXX28"
                  type="tel"
                  value={mobileNumber}
                />
              </Form.Group>
              <Button
                className="shadow-none button w-100  button-connect px-4"
                onClick={handleGetLogin}
                disabled={enableGetLogin}
              >
                <b>CONTINUE</b>
              </Button>
            </>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer></Modal.Footer>
    </ModalCustom>
  )
}

export default ModalLogin

// const styleOTP = (props: any) => {
//   let style = {
//     input: {
//       width: '2.4em',
//       borderRadius: '16px',
//       borderWidth: '2px',
//       fontSize: '20px',
//       backgroundColor: `${props.input.inputBackgroundColor} `,
//       color: `${props.input.inputTextColor}`,
//       padding: '0.375rem 0.75rem',
//       borderColor: props.input.borderColor,
//       borderStyle: 'solid'
//     },
//     focus: {
//       borderColor: `${props.input.inputFocusBackGroundColor} !important;`,
//       backgroundColor: `${props.input.inputFocusBackGroundColor}4d !important;`
//     },
//     container: {
//       display: 'flex',
//       justifyContent: 'space-between'
//     }
//   }
//   return style
// }

const ModalCustom = styled(Modal)<xxcModalLoginModalTypes.SettingModal>`
  .mobile-number {
  font-size: 28px;
  font-weight: 600;
    &-change {
    font-size: 18px;
    font-weight: 500;
    color: ${props => props.theme.navBar.navBarFontActiveColor} !important;
    margin-top: -5px;
      &:hover {
      color: ${props => props.theme.navBar.navBarFontActiveColor} !important;
      text-decoration-line: underline;
      }
  }
}
  .link-custom {
  font- size: 18px;
  font-weight: 500;
    .active-link {
    color: ${props => props.theme.navBar.navBarFontActiveColor} !important;
      &:hover {
      color: ${props => props.theme.navBar.navBarFontActiveColor} !important;
      text-decoration-line: underline;
    }
  }
    .countdown {
    font-weight: 500;
  }
}
  .modal-content {
  background-color: ${props => props.theme.cardColor};
  color: ${props => props.theme.fontPrimaryColor};
}
  .form-control {
  border: 2px solid ${props => props.theme.input.borderColor};
  background-color: ${props => props.theme.input.inputBackgroundColor};
  color: ${props => props.theme.input.inputTextColor};
}
  .form-control::placeholder {
  color: ${props => props.theme.input.placeholderTextColor} !important;
  opacity: 1;
}
  .form-control:focus {
  border-color: ${props => props.theme.input.inputFocusBackGroundColor};
  background-color: ${props => props.theme.input.inputFocusBackGroundColor} 4d;
}
  .text-on-input-text {
  color: ${props => props.theme.input.inputTextColor};
}
.pincode-input-container{
  display:flex;
  justify-content:space-between;
}
  .pincode-input-text:focus {
  border-color: ${props =>
    props.theme.input.inputFocusBackGroundColor} !important;
  background-color: ${props =>
    props.theme.input.inputFocusBackGroundColor}4d!important;
}
  .pincode-input-text:focus-visible {
  outline: none;
  border-color: ${props =>
    props.theme.input.inputFocusBackGroundColor} !important;
  background-color: ${props =>
    props.theme.input.inputFocusBackGroundColor} 4d!important;
}
  .pincode-input-text {
  width: 2.4em!important;
  height: 2.4em !important;
  border-radius: 16px;
  border-width: 2px;
  font-size: 20px;
  border: 2px solid ${props => props.theme.input.borderColor} !important;
  background-color: ${props => props.theme.input.inputBackgroundColor};
  color: ${props => props.theme.input.inputTextColor};
  padding: 0.375rem 0.75rem;
}
  .button-connect {
  border-color: ${props =>
    props.theme.button.connectWalletButton.backgroundColor};
  background-color: ${props =>
    props.theme.button.connectWalletButton.backgroundColor};
  color: ${props => props.theme.button.connectWalletButton.fontColor};
    &:hover {
    opacity: 0.65;
  }
    &:active {
    opacity: 0.65;
  }
    &: active:focus {
    opacity: 1;
    box-shadow: 0 0 0 0.25rem
        ${props =>
          props.theme.button.connectWalletButton.backgroundColor} 8c!important;
  }
}

  .modal-footer {
  height: 0px!important;
}
  .btn-close {
  background: url('data:image/svg+xml,%3Csvg xmlns="http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg" width="32px" height="32px" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"%3E%3Cpath fill="${props =>
    props.theme.fontPrimaryColor.replace(
      '#',
      '%23'
    )}" d="m13.41 12l4.3-4.29a1 1 0 1 0-1.42-1.42L12 10.59l-4.29-4.3a1 1 0 0 0-1.42 1.42l4.3 4.29l-4.3 4.29a1 1 0 0 0 0 1.42a1 1 0 0 0 1.42 0l4.29-4.3l4.29 4.3a1 1 0 0 0 1.42 0a1 1 0 0 0 0-1.42Z"%2F%3E%3C%2Fsvg%3E');
    }
  .button-address-wallet {
  padding: 0.25rem 0.25rem;
  height: 32px;
  fontsize: 18px!important;
  align-items: center;
  border-color: ${props => props.theme.button.addressButton.backgroundColor};
  background-color: ${props =>
    props.theme.button.addressButton.backgroundColor};
  color: ${props => props.theme.button.addressButton.fontColor};
    &:hover {
    opacity: 0.65;
  }
    &:active {
    opacity: 0.65;
  }
    &: active:focus {
    opacity: 1;
    box-shadow: 0 0 0 0.25rem
        ${props =>
          props.theme.button.addressButton.backgroundColor} 8c!important;
  }
}

@media(max-width: 540px) {
    .button-connect {
      &:hover {
      background-color: ${props =>
        props.theme.button.connectWalletButton.backgroundColor};
      border-color: ${props =>
        props.theme.button.connectWalletButton.backgroundColor};
      color: ${props => props.theme.button.connectWalletButton.fontColor};
      opacity: unset;
    }
      &:active {
      box-shadow: 0 0 0 0.25rem
          ${props =>
            props.theme.button.connectWalletButton
              .backgroundColor} 8c!important;
      opacity: 0.65;
      color: ${props => props.theme.button.connectWalletButton.fontColor};
    }
      &:disabled {
      opacity: 0.65!important;
    }
  }
    .button-address-wallet {
      &:hover {
      background-color: ${props =>
        props.theme.button.addressButton.backgroundColor};
      border-color: ${props =>
        props.theme.button.addressButton.backgroundColor};
      color: ${props => props.theme.button.addressButton.fontColor};
      opacity: unset;
    }
      &:active {
      box-shadow: 0 0 0 0.25rem
          ${props =>
            props.theme.button.addressButton.backgroundColor} 8c!important;
      opacity: 0.65;
      color: ${props => props.theme.button.addressButton.fontColor};
    }
      &:disabled {
      opacity: 0.65!important;
    }
  }
    .pincode-input-text {
    width: 2.2em !important;
    height: 2.2em !important;
    padding: 0.315rem 0.75rem;
  }
}

@media(max-width: 360px) {
    .pincode-input-text {
    font-size: 18px;
    width: 2.2em!important;
    height: 2.2em !important;
    padding: 0.156rem 0rem;
  }
}
`
