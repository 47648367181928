// Constants
import {
  CONNECT_WALLET_CLEAR_MESSAGE,
  CONNECT_WALLET_FAIL,
  CONNECT_WALLET_SUCCESS,
  SWITCH_CHAIN_SUCCESS,
  SWITCH_ACCOUNT_SUCCESS,
  ADD_CHAIN_CLEAR_MESSAGE,
  ADD_CHAIN_FAIL,
  ADD_CHAIN_SUCCESS,
  CANCEL_CONNECT_WALLET
} from 'redux/Constants/ConnectWallet'

// Props Types
import IAction from 'redux/Action/types'
import xxreConnectWalletReducerTypes from 'redux/Reducers/ConnectWallet/types'

const connectWallet = (
  state: object = {},
  action: IAction<xxreConnectWalletReducerTypes.Props>
) => {
  switch (action.type) {
    case CONNECT_WALLET_SUCCESS:
      return {
        ...state,
        ...action.payload,
        type: action.type
      }
    case SWITCH_CHAIN_SUCCESS:
      return {
        ...state,
        ...action.payload,
        type: action.type
      }
    case SWITCH_ACCOUNT_SUCCESS:
      return {
        ...state,
        ...action.payload,
        type: action.type
      }
    case CONNECT_WALLET_FAIL:
      return {
        ...state,
        ...action.payload,
        type: action.type
      }
    case CANCEL_CONNECT_WALLET:
      return {
        ...state,
        ...action.payload,
        type: action.type
      }
    case ADD_CHAIN_SUCCESS:
      return {
        ...state,
        ...action.payload,
        type: action.type
      }
    case ADD_CHAIN_FAIL:
      return {
        ...state,
        ...action.payload,
        type: action.type
      }
    case ADD_CHAIN_CLEAR_MESSAGE:
      return {}
    case CONNECT_WALLET_CLEAR_MESSAGE:
      return {}
    default:
      return state
  }
}

export default connectWallet
