//id
const CHAIN_ID = 96
//name
const CHAIN_NAME = 'Bitkub Chain'
//name
const CHAIN_SHORT_NAME = 'BKC'
//rpc
const RPC = 'https://rpc.bitkubchain.io'
//blockExplorerUrls
const BLOCK_EXPLORER_URLS = 'https://www.bkcscan.com'
//native currency name
const NAME_CURRENCY = 'KUB Coin'
//symbol
const NATIVE_CURRENCY_NAME = 'KUB'
//token price\
const TOKEN_PRICE = 'USDT'
//decimal
const DECIMAL = 18
//gas
const GAS_PRICE = 5

//img
const IMAGE_CHAIN =
  'https://s2.coinmarketcap.com/static/img/coins/200x200/16093.png'

export const bkc = {
  chainId: CHAIN_ID,
  chainName: CHAIN_NAME,
  shortName: CHAIN_SHORT_NAME,
  nativeCurrency: {
    name: NAME_CURRENCY,
    symbol: NATIVE_CURRENCY_NAME,
    decimals: DECIMAL
  },
  rpcURL: RPC,
  blockExplorerUrls: BLOCK_EXPLORER_URLS,
  chainUrl: BLOCK_EXPLORER_URLS,
  gasPrice: GAS_PRICE,
  imgChain: IMAGE_CHAIN,
  tokenPrice: TOKEN_PRICE
}
