import axios from 'axios'

// Types
import xxsTunnelServiceTypes from 'services/Tunnel/types'
import xxcChainModalTypes from 'configs/Model'
// Utils
import xxaDevice from 'actions/Utils/Device'
import xxaInitial from 'actions/Initial'

// Config
import { servicePath } from 'configs'

const xxsTunnelService = {
  GetTunnelConfig: async (
    networkId: number,
    anonymousToken: string,
    type: xxcChainModalTypes.tunnelType
  ): Promise<xxsTunnelServiceTypes.GetTunnelConfigResponse> => {
    let response: xxsTunnelServiceTypes.GetTunnelConfigResponse

    try {
      const responseData =
        await axios.get<xxsTunnelServiceTypes.GetTunnelConfigResponseSuccess>(
          `${servicePath.service.tunnel}/v1/tunnel/config`,
          {
            params: { network_id: networkId, type },
            headers: {
              Authorization: anonymousToken,
              'device-Id': await xxaDevice.GetDeviceId(),
              'accept-language': servicePath.acceptLanguage
            }
          }
        )

      response = {
        status: responseData.status,
        config: responseData.data
      }
    } catch (error: any) {
      const err = error.toJSON()
      if (err.message === 'Network Error') {
        response = {
          status: 500,
          error: err
        }
      } else {
        response = {
          status: error.response.status,
          error: error.response.data
        }
      }
    }
    return response
  },
  GetTokenPrice: async (
    token: string,
    networkId: number,
    anonymousToken: string,
    type: xxcChainModalTypes.tunnelType,
    short_name?: string
  ): Promise<xxsTunnelServiceTypes.GetTokenPriceResponse> => {
    let response: xxsTunnelServiceTypes.GetTokenPriceResponse
    try {
      const responseData =
        await axios.get<xxsTunnelServiceTypes.GetTokenPriceResponseSuccess>(
          `${servicePath.service.tunnel}/v1/tunnel/token/config`,
          {
            params: {
              token,
              network_id: networkId,
              type,
              to_chain_short_name: short_name
            },
            headers: {
              Authorization: anonymousToken,
              'device-Id': await xxaDevice.GetDeviceId(),
              'accept-language': servicePath.acceptLanguage
            }
          }
        )
      response = {
        status: responseData.status,
        price: responseData.data
      }
    } catch (error: any) {
      const err = error.toJSON()
      if (err.message === 'Network Error') {
        response = {
          status: 500,
          error: err
        }
      } else {
        response = {
          status: error.response.status,
          error: error.response.data
        }
      }
    }
    return response
  },
  CheckUserRef: async (
    userRef: string,
    anonymousToken: string
  ): Promise<xxsTunnelServiceTypes.CheckUserRefResponse> => {
    let response: xxsTunnelServiceTypes.CheckUserRefResponse

    try {
      const responseData = await axios.get<{ is_exist: boolean }>(
        `${servicePath.service.tunnel}/v1/tunnel/check-user-ref`,
        {
          params: { user_ref: userRef },
          headers: {
            Authorization: anonymousToken,
            'device-Id': await xxaDevice.GetDeviceId(),
            'accept-language': servicePath.acceptLanguage
          }
        }
      )

      response = {
        status: responseData.status,
        is_exist: responseData.data.is_exist
      }
    } catch (error: any) {
      const err = error.toJSON()
      if (err.message === 'Network Error') {
        response = {
          status: 500,
          error: err
        }
      } else {
        response = {
          status: error.response.status,
          error: error.response.data
        }
      }
    }
    return response
  },
  GetHistory: async (
    address: string,
    limit: number,
    offset: number,
    anonymousToken: string
  ): Promise<xxsTunnelServiceTypes.GetTunnelHistoryResponse> => {
    let response: xxsTunnelServiceTypes.GetTunnelHistoryResponse

    try {
      const responseData =
        await axios.get<xxsTunnelServiceTypes.GetTunnelHistoryResponseSuccess>(
          `${servicePath.service.tunnel}/v1/tunnel/convert/history`,
          {
            params: {
              address,
              limit,
              offset
            },
            headers: {
              Authorization: anonymousToken,
              'device-Id': await xxaDevice.GetDeviceId(),
              'accept-language': servicePath.acceptLanguage
            }
          }
        )

      response = {
        status: responseData.status,
        history: responseData.data
      }
    } catch (error: any) {
      const err = error.toJSON()
      if (err.message === 'Network Error') {
        response = {
          status: 500,
          error: err
        }
      } else {
        response = {
          status: error.response.status,
          error: error.response.data
        }
      }
    }
    return response
  },
  PointToCryptoRequest: async (
    address: string,
    network_id: number,
    token: string,
    point: number,
    sort_name: string,
    memoInput: string,
    amountOutMin: string
  ): Promise<xxsTunnelServiceTypes.PointToCryptoRequestResponse> => {
    let response: xxsTunnelServiceTypes.PointToCryptoRequestResponse
    const access_token = await xxaInitial.Check()

    const param: any = {
      to_wallet_address: address,
      network_id,
      token,
      point
    }
    if (network_id <= 0) {
      param.to_chain_short_name = sort_name
      if (memoInput) {
      param.memo = memoInput
    }}
    if (network_id !== 0) {
      param.amount_out_min = amountOutMin
    }
    
    try {
      const responseData =
        await axios.post<xxsTunnelServiceTypes.PointToCryptoRequestResponseSuccess>(
          `${servicePath.service.tunnel}/v1/tunnel/convert/point-to-crypto/request`,
          param,
          {
            headers: {
              Authorization: access_token,
              'device-Id': await xxaDevice.GetDeviceId(),
              'accept-language': servicePath.acceptLanguage
            }
          }
        )

      response = {
        status: responseData.status,
        pointToCryptoRequest: responseData.data
      }
    } catch (error: any) {
      const err = error.toJSON()
      if (err.message === 'Network Error') {
        response = {
          status: 500,
          error: err
        }
      } else {
        response = {
          status: error.response.status,
          error: error.response.data
        }
      }
    }
    return response
  },
  PointToCryptoConfirm: async (
    tid: string
  ): Promise<xxsTunnelServiceTypes.PointToCryptoConfirmResponse> => {
    let response: xxsTunnelServiceTypes.PointToCryptoConfirmResponse
    const access_token = await xxaInitial.Check()

    try {
      const responseData =
        await axios.post<xxsTunnelServiceTypes.PointToCryptoConfirmResponseSuccess>(
          `${servicePath.service.tunnel}/v1/tunnel/convert/point-to-crypto/confirm`,
          {
            tid
          },
          {
            headers: {
              Authorization: access_token,
              'device-Id': await xxaDevice.GetDeviceId(),
              'accept-language': servicePath.acceptLanguage
            }
          }
        )

      response = {
        status: responseData.status,
        pointToCryptoConfirm: responseData.data
      }
    } catch (error: any) {
      const err = error.toJSON()
      if (err.message === 'Network Error') {
        response = {
          status: 500,
          error: err
        }
      } else {
        response = {
          status: error.response.status,
          error: error.response.data
        }
      }
    }
    return response
  },
  GetHistoryPointToCrypto: async (
    limit: number,
    offset: number
  ): Promise<xxsTunnelServiceTypes.PointToCryptoHistoryResponse> => {
    let response: xxsTunnelServiceTypes.PointToCryptoHistoryResponse
    const accessToken = await xxaInitial.Check()

    try {
      const responseData =
        await axios.get<xxsTunnelServiceTypes.PointToCryptoHistoryResponseSuccess>(
          `${servicePath.service.tunnel}/v1/tunnel/convert/history/point-to-crypto`,
          {
            params: {
              limit,
              offset
            },
            headers: {
              Authorization: accessToken,
              'device-Id': await xxaDevice.GetDeviceId(),
              'accept-language': servicePath.acceptLanguage
            }
          }
        )
      response = {
        status: responseData.status,
        pointToCryptoHistory: responseData.data
      }
    } catch (error: any) {
      const err = error.toJSON()
      if (err.message === 'Network Error') {
        response = {
          status: 500,
          error: err
        }
      } else {
        response = {
          status: error.response.status,
          error: error.response.data
        }
      }
    }
    return response
  },
  BinanceToPointRequest: async (
    token: string,
    amount: string
  ): Promise<xxsTunnelServiceTypes.BinanceToPointRequestResponse> => {
    let response: xxsTunnelServiceTypes.BinanceToPointRequestResponse
    const access_token = await xxaInitial.Check()

    const param = {
      token,
      amount,
      callback_url: `${window.location.origin}/binance-to-point`
    }

    try {
      const responseData =
        await axios.post<xxsTunnelServiceTypes.BinanceToPointRequestResponseSuccess>(
          `${servicePath.service.tunnel}/v1/tunnel/convert/binance-to-point/request`,
          param,
          {
            headers: {
              Authorization: access_token,
              'device-Id': await xxaDevice.GetDeviceId(),
              'accept-language': servicePath.acceptLanguage
            }
          }
        )

      response = {
        status: responseData.status,
        binanceToPointRequest: responseData.data
      }
    } catch (error: any) {
      const err = error.toJSON()
      if (err.message === 'Network Error') {
        response = {
          status: 500,
          error: err
        }
      } else {
        response = {
          status: error.response.status,
          error: error.response.data
        }
      }
    }
    return response
  },
  GetHistoryBinanceToPoint: async (
    limit: number,
    offset: number
  ): Promise<xxsTunnelServiceTypes.BinanceToPointHistoryResponse> => {
    let response: xxsTunnelServiceTypes.BinanceToPointHistoryResponse
    const accessToken = await xxaInitial.Check()

    try {
      const responseData =
        await axios.get<xxsTunnelServiceTypes.BinanceToPointHistoryResponseSuccess>(
          `${servicePath.service.tunnel}/v1/tunnel/convert/history/binance-to-point`,
          {
            params: {
              limit,
              offset
            },
            headers: {
              Authorization: accessToken,
              'device-Id': await xxaDevice.GetDeviceId(),
              'accept-language': servicePath.acceptLanguage
            }
          }
        )
      response = {
        status: responseData.status,
        binanceToPointHistory: responseData.data
      }
    } catch (error: any) {
      const err = error.toJSON()
      if (err.message === 'Network Error') {
        response = {
          status: 500,
          error: err
        }
      } else {
        response = {
          status: error.response.status,
          error: error.response.data
        }
      }
    }
    return response
  },
  GetTransactionBinanceToPoint: async (
    transaction_id: string
  ): Promise<xxsTunnelServiceTypes.BinanceToPointTransactionResponse> => {
    let response: xxsTunnelServiceTypes.BinanceToPointTransactionResponse
    const accessToken = await xxaInitial.Check()

    try {
      const responseData =
        await axios.get<xxsTunnelServiceTypes.BinanceToPointHistory>(
          `${servicePath.service.tunnel}/v1/tunnel/convert/transaction/binance-to-point?transaction_id=${transaction_id}`,
          {
            headers: {
              Authorization: accessToken,
              'device-Id': await xxaDevice.GetDeviceId(),
              'accept-language': servicePath.acceptLanguage
            }
          }
        )
      response = {
        status: responseData.status,
        binanceToPointGetTransaction: responseData.data
      }
    } catch (error: any) {
      const err = error.toJSON()
      if (err.message === 'Network Error') {
        response = {
          status: 500,
          error: err
        }
      } else {
        response = {
          status: error.response.status,
          error: error.response.data
        }
      }
    }
    return response
  },
  ExecuteMetaTransaction: async (
    user_wallet_address: string,
    signature: string,
    function_signature: string,
    to_contract_address: string
  ): Promise<xxsTunnelServiceTypes.ExecuteMetaTransactionResponse> => {
    let response: xxsTunnelServiceTypes.ExecuteMetaTransactionResponse
    const accessToken = await xxaInitial.Check()

    const data = {
      user_wallet_address,
      signature,
      function_signature,
      to_contract_address
    }

    try {
      const responseData =
        await axios.post<xxsTunnelServiceTypes.ExecuteMetaTransactionResponseSuccess>(
          `${servicePath.service.tunnel}/v1/tunnel/execute-meta-transaction`,
          data,
          {
            headers: {
              Authorization: accessToken,
              'device-Id': await xxaDevice.GetDeviceId(),
              'accept-language': servicePath.acceptLanguage
            }
          }
        )
      response = {
        status: responseData.status,
        executeMetaTransaction: responseData.data
      }
    } catch (error: any) {
      const err = error.toJSON()
      if (err.message === 'Network Error') {
        response = {
          status: 500,
          error: err
        }
      } else {
        response = {
          status: error.response.status,
          error: error.response.data
        }
      }
    }
    return response
  }
}

export default xxsTunnelService
