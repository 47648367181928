import React from 'react'
import ReactLoading from 'react-loading'
import styled from 'styled-components'

function LoadingPage() {
  return (
    <Loading>
      <ReactLoading
        type="bubbles"
        delay={0}
        height={80}
        width={80}
        color="#82DAD3"
      />
    </Loading>
  )
}

const Loading = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

export default LoadingPage
