import { ethers } from 'ethers'
import { Dispatch } from 'redux'
import { WEB3_CONNECT_SUCCESS } from 'redux/Constants/Web3'

const xxaWeb3 = {
  ChangeWeb3Provider: (rpc: string) => async (dispatch: Dispatch) => {
    const provider = new ethers.providers.JsonRpcProvider(rpc)
    dispatch({
      type: WEB3_CONNECT_SUCCESS,
      payload: {
        provider
      }
    })
  }
}

export default xxaWeb3
