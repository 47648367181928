// Constants
import {
  BINANCE_TO_POINT_GET_TRANSACTION_SUCCESS,
  BINANCE_TO_POINT_GET_TRANSACTION_CLEAR_MESSAGE,
  BINANCE_TO_POINT_GET_TRANSACTION_FAIL
} from 'redux/Constants/TunnelService'

// Props Types
import IAction from 'redux/Action/types'
import xxreTunnelServiceBinanceToPointGetTransactionReducerTypes from 'redux/Reducers/TunnelService/BinanceToPointGetTransaction/types'

const binanceToPointGetTransaction = (
  state: object = {},
  action: IAction<xxreTunnelServiceBinanceToPointGetTransactionReducerTypes.Props>
) => {
  switch (action.type) {
    case BINANCE_TO_POINT_GET_TRANSACTION_SUCCESS:
      return {
        ...state,
        ...action.payload,
        type: action.type
      }
    case BINANCE_TO_POINT_GET_TRANSACTION_FAIL:
      return {
        ...state,
        ...action.payload,
        type: action.type
      }
    case BINANCE_TO_POINT_GET_TRANSACTION_CLEAR_MESSAGE:
      return {}
    default:
      return state
  }
}

export default binanceToPointGetTransaction
