import xxaDarkMode from 'actions/DarkMode'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

export const useDarkMode = () => {
  const dispatch = useDispatch()
  const [themeMode, setThemeMode] = useState('light')
  const setToggleTheme: any = () => {
    if (themeMode === 'light') {
      dispatch(xxaDarkMode.SetDarkMode('dark'))
      window.localStorage.setItem('theme', 'dark')
      setThemeMode('dark')
    } else {
      dispatch(xxaDarkMode.SetDarkMode('light'))
      window.localStorage.setItem('theme', 'light')
      setThemeMode('light')
    }
  }

  useEffect(() => {
    const localTheme = window.localStorage.getItem('theme')
    localTheme && setThemeMode(localTheme)
  }, [])

  return [themeMode, setToggleTheme]
}
