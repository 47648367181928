import { useState, useEffect } from 'react'
import { ListGroup, Modal, Form, Button } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import styled from 'styled-components'

// Redux
import xxreStoreTypes from 'redux/types'

// Components
import DeleteIcon from 'components/CustomIcon/DeleteIcon'

//action
import xxaAddress from 'actions/Address'

//type
import xxcAddressBookModalTypes from 'components/ConvertPage/AddressBookModal/Model'

function AddressBookModal({
  onHide,
  setSelectedAddress,
  selectedAddress,
  show,
  selectedChain
}: xxcAddressBookModalTypes.Props) {
  // Redux
  const dispatch = useDispatch()

  const darkMode = useSelector((state: xxreStoreTypes.Props) => state.darkMode)
  const themeConfig = useSelector(
    (state: xxreStoreTypes.Props) => state.themeConfig
  )
  const platformChain = useSelector(
    (state: xxreStoreTypes.Props) => state.platformChain
  )
  const addWalletAddress = useSelector(
    (state: xxreStoreTypes.Props) => state.addWalletAddress
  )
  const deleteWalletAddress = useSelector(
    (state: xxreStoreTypes.Props) => state.deleteWalletAddress
  )
  const getWalletAddress = useSelector(
    (state: xxreStoreTypes.Props) => state.getWalletAddress
  )
  const getProfile = useSelector(
    (state: xxreStoreTypes.Props) => state.getProfile
  )

  const [addAddress, setAddAddress] = useState(false)
  const [name, setName] = useState('')
  const [chainID, setChainID] = useState(0)
  const [walletAddress, setWalletAddress] = useState('')
  const [isProcess, setIsProcess] = useState(false)
  const [currentAddress, setCurrentAddress] = useState('')
  const [disableButtonSave, setDisableButtonSave] = useState(false)
  const [disableButtonConfirm, setDisableButtonConfirm] = useState(false)

  const handleSetAddress = () => {
    setSelectedAddress(currentAddress)
    handleHide()
  }

  const formatAddress = (address: string): string => {
    if (address) {
      return `${address.slice(0, 6)}...${address.slice(-6)}`
    }
    return ''
  }

  const displayWalletAddress = (address: string) => {
    let width = window.screen.width
    if (width <= 540) {
      return formatAddress(address)
    } else {
      return address
    }
  }

  const handleSetName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value)
  }
  const handleSetWallet = (e: React.ChangeEvent<HTMLInputElement>) => {
    setWalletAddress(e.target.value)
  }
  const handleAddWallet = () => {
    setIsProcess(true)
    dispatch(xxaAddress.AddWalletAddress(chainID, walletAddress, name))
  }
  const handleDeleteWallet = (address: string) => {
    if (selectedChain) {
      setIsProcess(true)
      dispatch(xxaAddress.DeleteWalletAddress(selectedChain.chainId, address))
    }
  }
  const backToSelect = () => {
    setAddAddress(false)
    setWalletAddress('')
    setName('')
    setChainID(selectedChain?.chainId || 0)
  }
  const handleHide = () => {
    onHide()
    setWalletAddress('')
    setCurrentAddress('')
    setAddAddress(false)
  }

  useEffect(() => {
    if (selectedChain && getProfile.getProfile) {
      setChainID(selectedChain.chainId)
      dispatch(xxaAddress.GetWalletAddress(selectedChain.chainId))
    }
  }, [selectedChain, getProfile])

  useEffect(() => {
    if (selectedAddress && getWalletAddress.getWalletAddress) {
      let userAddress = getWalletAddress.getWalletAddress.wallet_list.find(
        item => {
          return item.address === selectedAddress
        }
      )
      if (userAddress) {
        setCurrentAddress(userAddress.address)
      }
    }
  }, [selectedAddress, getWalletAddress])

  useEffect(() => {
    if (addWalletAddress.addWalletAddress?.msg === 'success' && selectedChain) {
      toast.success(
        <div className="noti-message-txn">
          <div>Add Wallet Address Success</div>
        </div>
      )
      setAddAddress(false)
      dispatch(xxaAddress.ClearAddWalletAddress())
      dispatch(xxaAddress.GetWalletAddress(selectedChain.chainId))
      setWalletAddress('')
      setName('')
      setChainID(selectedChain.chainId)
      setIsProcess(false)
    } else if (addWalletAddress.error) {
      toast.error(
        <div className="noti-message-txn">
          <div>{addWalletAddress.error.msg}</div>
        </div>
      )
      setIsProcess(false)
      dispatch(xxaAddress.ClearAddWalletAddress())
    }
  }, [addWalletAddress])

  useEffect(() => {
    if (
      deleteWalletAddress.deleteWalletAddress?.msg === 'success' &&
      selectedChain
    ) {
      toast.success(
        <div className="noti-message-txn">
          <div>Delete Wallet Address Success</div>
        </div>
      )
      dispatch(xxaAddress.GetWalletAddress(selectedChain.chainId))
      dispatch(xxaAddress.ClearDeleteWalletAddress())
      setIsProcess(false)
    }
  }, [deleteWalletAddress])

  useEffect(() => {
    if (!name || !walletAddress) {
      setDisableButtonSave(true)
    } else {
      setDisableButtonSave(false)
    }
  }, [name, walletAddress])

  useEffect(() => {
    if (!currentAddress) {
      setDisableButtonConfirm(true)
    } else {
      setDisableButtonConfirm(false)
    }
  }, [currentAddress])

  return (
    <ModalCustom
      theme={
        darkMode.darkMode === 'dark'
          ? themeConfig.themeConfig?.dark
          : themeConfig.themeConfig?.light
      }
      aria-labelledby="contained-modal-title-vcenter"
      centered
      dialogClassName="token-list-modal"
      className="modal-fullscreen-md-down"
      id="item-modal"
      onHide={isProcess ? () => {} : handleHide}
      show={show}
      // size="lg"
    >
      <Modal.Body className="p-0">
        <Modal.Header closeButton>
          {addAddress ? 'New Address' : 'Select Address'}
        </Modal.Header>
        <div className="py-2 address-book-container">
          {!addAddress ? (
            getWalletAddress.getWalletAddress &&
            getWalletAddress.getWalletAddress.wallet_list.length > 0 ? (
              getWalletAddress.getWalletAddress.wallet_list.map(item => {
                return (
                  <ListGroup.Item
                    key={item.address}
                    action
                    className="address-list-item"
                  >
                    <div className="d-flex justify-content-between">
                      <div className="w-100">
                        <Form.Check
                          className="d-flex align-items-center"
                          type="radio"
                          checked={
                            selectedAddress === item.address ||
                            currentAddress === item.address
                              ? true
                              : false
                          }
                          onChange={e => {
                            if (e.target.checked) {
                              setCurrentAddress(item.address)
                            }
                          }}
                          id={item.address}
                          label={
                            <div role="button w-100">
                              {/* <Col onClick={() => { handleSetAddress(item.address) }} xs={9}> */}
                              <div className="chain-name">{item.name}</div>
                              <div className="address-name">
                                {displayWalletAddress(item.address)}
                              </div>
                            </div>
                          }
                          name="address"
                        />
                      </div>
                      <div className="d-flex justify-content-center align-items-center">
                        <DeleteIcon
                          onClick={() => {
                            handleDeleteWallet(item.address)
                          }}
                          fill={
                            themeConfig.themeConfig?.[darkMode.darkMode]
                              .fontPrimaryColor
                          }
                          width={20}
                          height={20}
                        />
                      </div>
                    </div>
                  </ListGroup.Item>
                )
              })
            ) : (
              <div className="no-address">No available address</div>
            )
          ) : (
            <div className="box-input-select-token">
              <Form>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>
                    <div className="convert-container-user-reference">Name</div>
                  </Form.Label>
                  <Form.Control
                    onChange={(e: any) => handleSetName(e)}
                    type="text"
                    placeholder="Enter Name Address"
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>
                    <div className="convert-container-user-reference">
                      Wallet Address
                    </div>
                  </Form.Label>
                  <Form.Control
                    onChange={(e: any) => handleSetWallet(e)}
                    type="text"
                    placeholder="Enter Wallet Address"
                  />
                </Form.Group>
              </Form>
            </div>
          )}
        </div>
      </Modal.Body>
      {getProfile.getProfile ? (
        <Modal.Footer className="modal-footer-custom">
          <div className="d-flex w-100 gap-3">
            {addAddress ? (
              <>
                <Button
                  disabled={isProcess}
                  className="btn button cancel-button w-100"
                  variant="primary"
                  onClick={backToSelect}
                >
                  <b>CANCEL</b>
                </Button>
                <Button
                  disabled={isProcess || disableButtonSave}
                  className="btn button convert-button w-100"
                  variant="primary"
                  onClick={handleAddWallet}
                >
                  {isProcess && (
                    <span
                      aria-hidden="true"
                      className="spinner-border spinner-border-sm convert-margin-right"
                      role="status"
                    ></span>
                  )}
                  <b>SAVE</b>
                </Button>
              </>
            ) : (
              <>
                {getWalletAddress.getWalletAddress &&
                getWalletAddress.getWalletAddress.wallet_list.length > 0 ? (
                  <>
                    <Button
                      disabled={isProcess}
                      className="btn button new-button w-100"
                      variant="primary"
                      onClick={() => {
                        setAddAddress(true)
                      }}
                    >
                      <b>NEW</b>
                    </Button>
                    <Button
                      disabled={isProcess || disableButtonConfirm}
                      className="btn button convert-button w-100"
                      variant="primary"
                      onClick={handleSetAddress}
                    >
                      <b>CONFIRM</b>
                    </Button>
                  </>
                ) : (
                  <Button
                    disabled={isProcess}
                    className="btn button new-button w-100"
                    variant="primary"
                    onClick={() => {
                      setAddAddress(true)
                    }}
                  >
                    <b>NEW</b>
                  </Button>
                )}
              </>
            )}
          </div>
        </Modal.Footer>
      ) : null}
    </ModalCustom>
  )
}

export default AddressBookModal

const ModalCustom = styled(Modal)<xxcAddressBookModalTypes.SettingModal>`
  .modal-content {
    background-color: ${props => props.theme.cardColor};
    color: ${props => props.theme.fontPrimaryColor};
    .form-check {
      column-gap: 15px;
    }
    .form-check-input:checked {
      background-color: ${props => props.theme.primaryColor};
      border-color: ${props => props.theme.primaryColor};
    }
    .form-check-input:focus {
      border-color: ${props => props.theme.primaryColor};
      outline: 0;
      box-shadow: 0 0 0 0.25rem ${props => props.theme.primaryColor}40;
    }
    .form-check-label {
      width: 100%;
    }
    .address-book-container {
      min-height: 180px;
      max-height: 340px;
      overflow: auto;
    }
    .no-address {
      display: flex;
      justify-content: center;
      align-items: center;
      min-height: 200px;
      font-size: 24px;
      font-weight: 700;
      color: ${props => props.theme.fontPrimaryColor}40;
    }
  }
  .form-select {
    border: 2px solid ${props => props.theme.input.borderColor};
    background-color: ${props => props.theme.input.inputBackgroundColor};
    color: ${props => props.theme.input.inputTextColor};
    font-size: 20px;
    border-radius: 16px;
  }
  .form-control {
    border: 2px solid ${props => props.theme.input.borderColor};
    background-color: ${props => props.theme.input.inputBackgroundColor};
    color: ${props => props.theme.input.inputTextColor};
    font-size: 20px;
  }
  .form-control:disabled {
    color: ${props => props.theme.input.inputTextColor} !important;
    opacity: 1;
    -webkit-text-fill-color: ${props => props.theme.input.inputTextColor};
  }
  .form-control::placeholder {
    color: ${props => props.theme.input.placeholderTextColor} !important;
    opacity: 1;
  }
  .form-control:focus {
    border-color: ${props => props.theme.input.inputFocusBackGroundColor};
    background-color: ${props => props.theme.input.inputFocusBackGroundColor}4d;
  }
  .modal-footer-custom {
    height: 80px !important;
  }
  .btn-close {
    background: url('data:image/svg+xml,%3Csvg xmlns="http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg" width="32px" height="32px" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"%3E%3Cpath fill="${props =>
      props.theme.fontPrimaryColor.replace(
        '#',
        '%23'
      )}" d="m13.41 12l4.3-4.29a1 1 0 1 0-1.42-1.42L12 10.59l-4.29-4.3a1 1 0 0 0-1.42 1.42l4.3 4.29l-4.3 4.29a1 1 0 0 0 0 1.42a1 1 0 0 0 1.42 0l4.29-4.3l4.29 4.3a1 1 0 0 0 1.42 0a1 1 0 0 0 0-1.42Z"%2F%3E%3C%2Fsvg%3E');
  }
  .address-list {
    background-color: transparent;
    &-item {
      background-color: ${props => props.theme.cardColor};
      & .chain-name {
        color: ${props => props.theme.fontPrimaryColor};
        font-weight: 500;
      }
      & .address-name {
        color: ${props => props.theme.fontSecondaryColor};
        font-weight: 500;
        font-size: 18px;
        color: #6c757d;
      }
      &:hover {
        background-color: ${props => props.theme.navBar.navBarFontHoverColor};
        opacity: 0.8;
      }
    }
  }
  .token-not-found {
    color: ${props => props.theme.fontSecondaryColor};
    font-size: 24px;
    font-weight: 700;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 120px;
  }
  .convert-button {
    background-color: ${props =>
      props.theme.button.convertButton.backgroundColor};
    border-color: ${props => props.theme.button.convertButton.backgroundColor};
    color: ${props => props.theme.button.convertButton.fontColor};
    &:hover {
      opacity: 0.65;
      color: ${props => props.theme.button.convertButton.fontColor};
    }
    &:active {
      opacity: 0.65;
      color: ${props => props.theme.button.convertButton.fontColor};
    }
    &:active:focus {
      box-shadow: 0 0 0 0.25rem
        ${props => props.theme.button.convertButton.backgroundColor}8c !important;
    }
  }
  .cancel-button {
    background-color: ${props => props.theme.navBar.navBarFontHoverColor};
    border-color: ${props => props.theme.navBar.navBarFontHoverColor};
    color: ${props => props.theme.fontSecondaryColor};
    &:hover {
      opacity: 0.65;
      color: ${props => props.theme.fontSecondaryColor};
    }
    &:active {
      opacity: 0.65;
      color: ${props => props.theme.fontSecondaryColor};
    }
    &:active:focus {
      box-shadow: 0 0 0 0.25rem
        ${props => props.theme.navBar.navBarFontHoverColor}8c !important;
    }
  }
  .new-button {
    background-color: ${props =>
      props.theme.button.approveButton.backgroundColor};
    border-color: ${props => props.theme.button.approveButton.backgroundColor};
    color: ${props => props.theme.button.approveButton.fontColor};
    &:hover {
      opacity: 0.65;
      color: ${props => props.theme.button.approveButton.fontColor};
    }
    &:active {
      opacity: 0.65;
      color: ${props => props.theme.button.approveButton.fontColor};
    }
    &:active:focus {
      box-shadow: 0 0 0 0.25rem
        ${props => props.theme.button.approveButton.backgroundColor}8c !important;
    }
  }
  @media (max-width: 540px) {
    .modal-content {
      .form-check {
        column-gap: 15px;
      }
    }
    .convert-button {
      &:hover {
        background-color: ${props =>
          props.theme.button.convertButton.backgroundColor};
        border-color: ${props =>
          props.theme.button.convertButton.backgroundColor};
        color: ${props => props.theme.button.convertButton.fontColor};
        opacity: unset;
      }
      &:active {
        box-shadow: 0 0 0 0.25rem
          ${props => props.theme.button.convertButton.backgroundColor}8c !important;
        opacity: 0.65;
        color: ${props => props.theme.button.convertButton.fontColor};
      }
      &:disabled {
        opacity: 0.65 !important;
      }
    }
    .cancel-button {
      &:hover {
        background-color: ${props => props.theme.navBar.navBarFontHoverColor};
        border-color: ${props => props.theme.navBar.navBarFontHoverColor};
        color: ${props => props.theme.fontSecondaryColor};
        opacity: unset;
      }
      &:active {
        box-shadow: 0 0 0 0.25rem ${props => props.theme.fontSecondaryColor}8c !important;
        opacity: 0.65;
        color: ${props => props.theme.button.convertButton.fontColor};
      }
      &:disabled {
        opacity: 0.65 !important;
      }
    }
    .new-button {
      &:hover {
        background-color: ${props =>
          props.theme.button.approveButton.backgroundColor};
        border-color: ${props =>
          props.theme.button.approveButton.backgroundColor};
        color: ${props => props.theme.button.approveButton.fontColor};
        opacity: unset;
      }
      &:active {
        box-shadow: 0 0 0 0.25rem
          ${props => props.theme.button.approveButton.backgroundColor}8c !important;
        opacity: 0.65;
        color: ${props => props.theme.button.approveButton.fontColor};
      }
      &:disabled {
        opacity: 0.65 !important;
      }
    }
  }
`
