const xxuURL = {
  ChangeURL: (key: string, value: string): void => {
    const updateQueryStringParameter = (
      uri: string,
      key: string,
      newValue: string
    ) => {
      var re = new RegExp('([?&])' + key + '=.*?(&|$)', 'i')
      var separator = uri.indexOf('?') !== -1 ? '&' : '?'
      if (uri.match(re)) {
        return uri.replace(re, '$1' + key + '=' + newValue + '$2')
      } else {
        return uri + separator + key + '=' + newValue
      }
    }
    let url = window.location.href
    let newURL = updateQueryStringParameter(url, key, value)
    window.history.pushState({}, '', newURL)
  },
  RemoveParam: (key: string): void => {
    const updateQueryStringParameter = (uri: string, key: string) => {
      var re = new RegExp('([?&])' + key + '=.*?(&|$)', 'i')
      var separator = uri.indexOf('?') !== -1 ? '&' : '?'
      if (uri.match(re)) {
        return uri.replace(re, '$1' + '')
      } else {
        return uri + separator
      }
    }
    let url = window.location.href
    let newURL = updateQueryStringParameter(url, key)
    window.history.pushState({}, '', newURL)
  }
}

export default xxuURL
