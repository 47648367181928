import axios from 'axios'

// Types
import xxsPlatformServiceTypes from 'services/Platform/types'

// Utils
import xxaDevice from 'actions/Utils/Device'

// Config
import { servicePath } from 'configs'

const xxsPlatformService = {
  GetPlatformConfig: async (
    anonymousToken: string
  ): Promise<xxsPlatformServiceTypes.GetPlatformConfigResponse> => {
    let response: xxsPlatformServiceTypes.GetPlatformConfigResponse

    try {
      const responseData =
        await axios.get<xxsPlatformServiceTypes.GetPlatformConfigResponseSuccess>(
          `${servicePath.service.tunnel}/v1/tunnel/platform/config`,
          {
            headers: {
              Authorization: anonymousToken,
              'device-Id': await xxaDevice.GetDeviceId(),
              'accept-language': servicePath.acceptLanguage
            }
          }
        )
      response = {
        status: responseData.status,
        platformConfig: responseData.data
      }
    } catch (error: any) {
      const err = error.toJSON()
      if (err.message === 'Network Error') {
        response = {
          status: 500,
          error: err
        }
      } else {
        response = {
          status: error.response.status,
          error: error.response.data
        }
      }
    }
    return response
  }
}

export default xxsPlatformService
