import { Button } from 'react-bootstrap'
import ReactImageFallback from 'react-image-fallback'

//icon
import network_icon from 'assets/Svg/icon/network_icon.svg'
import image_icon from 'assets/Svg/icon/image_icon.svg'

// style
import './style.scss'

function TokenError(): JSX.Element {
  // Redux

  const reload = () => {
    window.location.reload()
  }

  return (
    <div className="token-error">
      <div className="token-error-box">
        <div>
          <ReactImageFallback
            alt="network-error"
            src={network_icon}
            fallbackImage={image_icon}
            initialImage={image_icon}
          />
        </div>
        <div className="token-error-text">Oops! Please try to reload page.</div>
        <div className="token-error-button">
          <Button className="button button-primary" onClick={reload}>
            Reload Page
          </Button>
        </div>
      </div>
    </div>
  )
}

export default TokenError
