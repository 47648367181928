import { bsc, polygon, mumbai, chapel, bkc } from 'configs/chain'
import {
  off_bx,
  off_bsc,
  off_polygon,
  off_chapel,
  off_mumbai,
  off_bkc,
  off_bkx,
} from 'configs/offChain'
import { freeGasProd, freeGasDev } from 'configs/freeGas'

export const EXAMPLE = process.env.REACT_APP_EXAMPLE
export const SERVICE_GRAPHQL = process.env.REACT_APP_SERVICE_GRAPHQL

export const servicePath = {
  base_url:
    process.env.REACT_APP_RUN_ON === 'production'
      ? 'http://localhost:3000'
      : 'http://localhost:3000',
  domainName:
    process.env.REACT_APP_RUN_ON === 'production'
      ? 'tunnel.chomchob.finance'
      : 'dev-tunnel.chomchob.finance',
  acceptLanguage: 'en',
  service: {
    auth:
      process.env.REACT_APP_RUN_ON === 'production'
        ? 'https://crypto.chomchob.com'
        : 'https://dev-crypto.chomchob.com',
    tunnel:
      process.env.REACT_APP_RUN_ON === 'production'
        ? 'https://crypto.chomchob.com'
        : 'https://dev-crypto.chomchob.com'
  }
}

export const web3 =
  process.env.REACT_APP_RUN_ON === 'production'
    ? [bsc, polygon, bkc]
    : [bsc, polygon, mumbai, chapel, bkc]
export const offChain =
  process.env.REACT_APP_RUN_ON === 'production'
    ? [off_bx, off_bsc, off_polygon, off_bkc, off_bkx]
    : [off_bx, off_bsc, off_polygon, off_chapel, off_mumbai, off_bkc, off_bkx]
export const freeGas =
  process.env.REACT_APP_RUN_ON === 'production' ? freeGasProd : freeGasDev
