import { Modal } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import ReactImageFallback from 'react-image-fallback'
import styled from 'styled-components'

// Redux
import xxreStoreTypes from 'redux/types'

//type
import xxcChainModalTypes from 'configs/Model'
import xxcChainListModalTypes from 'components/NavBar/ChainListModal/Model'

//icon
import image_white_icon from 'assets/Svg/icon/image_white_icon.svg'
//action
import xxaConnectWallet from 'actions/ConnectWallet'

function ChainListModal({
  onHide,
  setChainSelect,
  show,
  type
}: xxcChainListModalTypes.Props) {
  // Initial
  const dispatch = useDispatch()
  // Redux
  const connectWallet = useSelector(
    (state: xxreStoreTypes.Props) => state.connectWallet
  )
  const darkMode = useSelector((state: xxreStoreTypes.Props) => state.darkMode)
  const themeConfig = useSelector(
    (state: xxreStoreTypes.Props) => state.themeConfig
  )
  const platformChain = useSelector(
    (state: xxreStoreTypes.Props) => state.platformChain
  )

  const handleSwitchChain = (chainID: number) => {
    dispatch(xxaConnectWallet.SwitchChain(chainID))
    onHide()
  }

  const checkActiveChain = (chainID: number) => {
    let chain = window.localStorage.getItem('chainID')
    if (chain) {
      if (Number(chain) === chainID) {
        return true
      } else {
        return false
      }
    } else {
      return false
    }
  }

  const handleSelectChain = (chainData: xxcChainModalTypes.offChainType) => {
    setChainSelect(chainData)
    onHide()
  }

  return (
    <ModalCustom
      theme={
        darkMode.darkMode === 'dark'
          ? themeConfig.themeConfig?.dark
          : themeConfig.themeConfig?.light
      }
      aria-labelledby="contained-modal-title-vcenter"
      centered
      dialogClassName="token-list-modal"
      className="modal-fullscreen-md-down"
      id="item-modal"
      onHide={onHide}
      show={show}
      // size="lg"
    >
      <Modal.Body className="p-0">
        <Modal.Header closeButton>Select Chain</Modal.Header>
        <div className="p-4">
          <div className="chain-container">
            {type === 'crypto_to_point'
              ? platformChain.platformChain?.onChain.length > 0
                ? platformChain.platformChain.onChain.map(item => {
                    return (
                      <div
                        role="button"
                        key={`chain-${item.shortName}`}
                        className={`chain-list ${
                          connectWallet.chainId === item.chainId ? 'active' : ''
                        }`}
                        onClick={() => {
                          handleSwitchChain(item.chainId)
                        }}
                      >
                        <div className="w-100 chain-list-button">
                          <ReactImageFallback
                            height="40px"
                            width="40px"
                            alt={`chain-${item.shortName}`}
                            src={item.imgChain}
                            fallbackImage={image_white_icon}
                            initialImage={image_white_icon}
                          />
                          <div>
                            <div>{item.shortName}</div>
                          </div>
                        </div>
                      </div>
                    )
                  })
                : null
              : type === 'point_to_crypto'
              ? platformChain.platformChain?.offChain.length > 0
                ? platformChain.platformChain.offChain.map(item => {
                    return (
                      <div
                        role="button"
                        key={`chain-${item.shortName}`}
                        className={`chain-list ${
                          checkActiveChain(item.chainId) ? 'active' : ''
                        }`}
                        onClick={() => {
                          handleSelectChain(item)
                        }}
                      >
                        <div className="w-100 chain-list-button">
                          <ReactImageFallback
                            height="40px"
                            width="40px"
                            alt={`chain-${item.shortName}`}
                            src={item.imgChain}
                            fallbackImage={image_white_icon}
                            initialImage={image_white_icon}
                          />
                          <div>
                            <div>{item.shortName}</div>
                          </div>
                        </div>
                      </div>
                    )
                  })
                : null
              : null}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer></Modal.Footer>
    </ModalCustom>
  )
}

export default ChainListModal

const ModalCustom = styled(Modal)<xxcChainListModalTypes.SettingModal>`
  .modal-content {
    background-color: ${props => props.theme.cardColor};
    color: ${props => props.theme.fontPrimaryColor};
  }
  .modal-footer {
    height: 0px !important;
  }
  .btn-close {
    background: url('data:image/svg+xml,%3Csvg xmlns="http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg" width="32px" height="32px" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"%3E%3Cpath fill="${props =>
      props.theme.fontPrimaryColor.replace(
        '#',
        '%23'
      )}" d="m13.41 12l4.3-4.29a1 1 0 1 0-1.42-1.42L12 10.59l-4.29-4.3a1 1 0 0 0-1.42 1.42l4.3 4.29l-4.3 4.29a1 1 0 0 0 0 1.42a1 1 0 0 0 1.42 0l4.29-4.3l4.29 4.3a1 1 0 0 0 1.42 0a1 1 0 0 0 0-1.42Z"%2F%3E%3C%2Fsvg%3E');
  }
  .chain-list-button {
    color: ${props => props.theme.button.selectChainButton.fontColor};
  }
  .chain-list.active {
    background-color: ${props =>
      props.theme.button.selectChainButton.backgroundColor} !important;
    color: ${props =>
      props.theme.button.selectChainButton.fontColor} !important;
  }
  .chain-list:hover {
    background-color: ${props =>
      props.theme.button.selectChainButton.backgroundColor};
    color: ${props => props.theme.button.selectChainButton.fontColor};
  }
  @media (max-width: 540px) {
    .chain-list {
      &:active,
      :focus {
        background-color: ${props =>
          props.theme.button.selectChainButton.backgroundColor};
        color: ${props => props.theme.button.selectChainButton.fontColor};
        opacity: unset;
      }
      &:hover {
        background-color: transparent;
        color: ${props => props.theme.button.selectChainButton.fontColor};
      }
    }
  }
`
