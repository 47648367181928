// Constants
import {
  TUNNEL_GET_TOKEN_PRICE_SUCCESS,
  TUNNEL_GET_TOKEN_PRICE_FAIL,
  TUNNEL_GET_TOKEN_PRICE_CLEAR_MESSAGE
} from 'redux/Constants/TunnelService'

// Props Types
import IAction from 'redux/Action/types'
import xxreTunnelServiceGetTokenPriceTypes from 'redux/Reducers/TunnelService/GetTokenPrice/types'

const getTokenPrice = (
  state: object = { price: '33' },
  action: IAction<xxreTunnelServiceGetTokenPriceTypes.Props>
) => {
  switch (action.type) {
    case TUNNEL_GET_TOKEN_PRICE_SUCCESS:
      return {
        ...state,
        ...action.payload,
        type: action.type
      }
    case TUNNEL_GET_TOKEN_PRICE_FAIL:
      return {
        ...state,
        ...action.payload,
        type: action.type
      }
    case TUNNEL_GET_TOKEN_PRICE_CLEAR_MESSAGE:
      return {}
    default:
      return state
  }
}

export default getTokenPrice
