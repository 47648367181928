// Interfaces
import xxroRouterProps from 'routers/types'

// Routes
import xxrAuthRouter from 'routers/Auth'
import xxrHomeRouter from 'routers/Home'
import xxrTunnelRouter from 'routers/Tunnel'

const xxroMainRouter: xxroRouterProps.PropTypes[] = [
  ...xxrAuthRouter,
  ...xxrHomeRouter,
  ...xxrTunnelRouter
]

export default xxroMainRouter
