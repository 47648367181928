import { useState, useEffect } from 'react'
import { Modal, Button } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import ReactImageFallback from 'react-image-fallback'
import styled from 'styled-components'
import { toast } from 'react-toastify'

// Redux
import xxreStoreTypes from 'redux/types'

// Components
import xxcMobileSettingModalTypes from 'components/NavBar/MobileSettingModal/Model'
import CopyIcon from 'components/CustomIcon/CopyIcon'
import LogoutIcon from 'components/CustomIcon/LogoutIcon'

//icon
import image_white_icon from 'assets/Svg/icon/image_white_icon.svg'

//config
import * as configs from 'configs'

function MobileSettingModal({
  onHide,
  openChainList,
  show,
  child,
  handleLogout
}: xxcMobileSettingModalTypes.Props) {
  const [chain, setChain] = useState<any>('')
  // Redux
  const connectWallet = useSelector(
    (state: xxreStoreTypes.Props) => state.connectWallet
  )
  const darkMode = useSelector((state: xxreStoreTypes.Props) => state.darkMode)
  const themeConfig = useSelector(
    (state: xxreStoreTypes.Props) => state.themeConfig
  )
  const getProfile = useSelector(
    (state: xxreStoreTypes.Props) => state.getProfile
  )

  const checkActiveTab = (pathName: string) => {
    let currentPath = window.location.pathname
    if (currentPath === pathName) {
      return true
    } else {
      return false
    }
  }

  const onClickCopyLink = () => {
    navigator.clipboard.writeText(connectWallet.accountAddress)
    toast.success(<div className="text-center">Copy!</div>, {
      icon: false
    })
  }

  const checkOnchain = () => {
    if (checkActiveTab('/convert') || checkActiveTab('/history')) {
      return true
    } else {
      return false
    }
  }

  useEffect(() => {
    if (connectWallet.accountAddress && connectWallet.networkConnect) {
      const chainData = configs.web3.find(item => {
        return item.chainId === connectWallet.chainId
      })
      setChain(chainData)
    }
  }, [connectWallet])

  return (
    <ModalCustom
      theme={
        darkMode.darkMode === 'dark'
          ? themeConfig.themeConfig?.dark
          : themeConfig.themeConfig?.light
      }
      aria-labelledby="contained-modal-title-vcenter"
      centered
      dialogClassName="token-list-modal"
      className="modal-fullscreen-md-down"
      id="item-modal"
      onHide={onHide}
      show={show}
      // size="lg"
    >
      <Modal.Body className="p-0">
        <Modal.Header closeButton>Setting</Modal.Header>
        <div className="px-4 py-3">
          <div className="setting-container">
            {checkOnchain() ? (
              <div className="setting-item">
                <div>Copy Address</div>
                <CopyIcon
                  onClick={onClickCopyLink}
                  width={24}
                  height={24}
                  fill={
                    themeConfig.themeConfig?.[darkMode.darkMode].button
                      .addressButton.fontColor
                  }
                />
              </div>
            ) : null}
            {checkOnchain() && connectWallet.accountAddress ? (
              <div className="setting-item">
                <div>Select Chain</div>
                {chain ? (
                  <Button
                    onClick={() => openChainList()}
                    className="d-flex btn-sm button button-address-wallet"
                  >
                    <ReactImageFallback
                      height="26px"
                      width="26px"
                      alt={`chain-${chain.shortName}`}
                      src={chain.imgChain}
                      fallbackImage={image_white_icon}
                      initialImage={image_white_icon}
                    />
                    <div>{chain.shortName}</div>
                  </Button>
                ) : null}
              </div>
            ) : null}

            <div className="setting-item">
              <div>Dark Mode</div>
              {child}
            </div>
            {(checkOnchain() && connectWallet.accountAddress) ||
            (!checkOnchain() && getProfile.getProfile) ? (
              <div className="setting-item">
                <div>Logout</div>
                <LogoutIcon
                  onClick={handleLogout}
                  width={24}
                  height={24}
                  fill={
                    themeConfig.themeConfig?.[darkMode.darkMode].button
                      .addressButton.fontColor || '#ffffff'
                  }
                />
              </div>
            ) : null}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer></Modal.Footer>
    </ModalCustom>
  )
}

export default MobileSettingModal

const ModalCustom = styled(Modal)<xxcMobileSettingModalTypes.SettingModal>`
  .modal-content {
    background-color: ${props => props.theme.cardColor};
    color: ${props => props.theme.fontPrimaryColor};
  }
  .modal-footer {
    height: 0px !important;
  }
  .btn-close {
    background: url('data:image/svg+xml,%3Csvg xmlns="http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg" width="32px" height="32px" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"%3E%3Cpath fill="${props =>
      props.theme.fontPrimaryColor.replace(
        '#',
        '%23'
      )}" d="m13.41 12l4.3-4.29a1 1 0 1 0-1.42-1.42L12 10.59l-4.29-4.3a1 1 0 0 0-1.42 1.42l4.3 4.29l-4.3 4.29a1 1 0 0 0 0 1.42a1 1 0 0 0 1.42 0l4.29-4.3l4.29 4.3a1 1 0 0 0 1.42 0a1 1 0 0 0 0-1.42Z"%2F%3E%3C%2Fsvg%3E');
  }
  .button-address-wallet {
    padding: 0.25rem 0.25rem;
    height: 32px;
    font-size: 18px !important;
    align-items: center;
    border-color: ${props => props.theme.button.addressButton.backgroundColor};
    background-color: ${props =>
      props.theme.button.addressButton.backgroundColor};
    color: ${props => props.theme.button.addressButton.fontColor};
    &:hover {
      opacity: 0.65;
    }
    &:active {
      opacity: 0.65;
    }
    &:active:focus {
      opacity: 1;
      box-shadow: 0 0 0 0.25rem
        ${props => props.theme.button.addressButton.backgroundColor}8c !important;
    }
  }

  @media (max-width: 540px) {
    .button-address-wallet {
      &:hover {
        background-color: ${props =>
          props.theme.button.addressButton.backgroundColor};
        border-color: ${props =>
          props.theme.button.addressButton.backgroundColor};
        color: ${props => props.theme.button.addressButton.fontColor};
        opacity: unset;
      }
      &:active {
        box-shadow: 0 0 0 0.25rem
          ${props => props.theme.button.addressButton.backgroundColor}8c !important;
        opacity: 0.65;
        color: ${props => props.theme.button.addressButton.fontColor};
      }
      &:disabled {
        opacity: 0.65 !important;
      }
    }
  }
`
