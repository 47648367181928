import { Dispatch } from 'redux'

// Redux Constants
import { SET_THEME_SUCCESS, SET_THEME_FAIL } from 'redux/Constants/Theme'

//Services
import xxsThemeService from 'services/Theme'
import xxsThemeServiceTypes from 'services/Theme/types'
import xxuAuth from 'utils/Auth'

// Utils
import xxuResponse from 'utils/Response'

const xxaThemeService = {
  GetThemeConfig:
    () =>
    async (
      dispatch: Dispatch
    ): Promise<
      xxsThemeServiceTypes.GetThemeConfigResponseSuccess | undefined
    > => {
      // dispatch({
      //   type: SET_THEME_CLEAR_MESSAGE
      // })
      const anonymousToken = await xxuAuth.GetAnonymousToken()
      const response = await xxsThemeService.GetThemeConfig(anonymousToken)

      if (response.status === 200) {
        xxuResponse.Success({
          type: SET_THEME_SUCCESS,
          dispatch,
          payload: response
        })
      } else {
        xxuResponse.Error({
          type: SET_THEME_FAIL,
          errorPage: true,
          dispatch,
          error: response
        })
      }
      return response.themeConfig
    }
}

export default xxaThemeService
