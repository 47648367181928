//id
const CHAIN_ID = 56
//name
const CHAIN_NAME = 'Binance Smart Chain'
//name
const CHAIN_SHORT_NAME = 'BSC'
//rpc
const RPC = 'https://bsc-dataseed2.defibit.io'
//blockExplorerUrls
const BLOCK_EXPLORER_URLS = 'https://bscscan.com'
//native currency name
const NAME_CURRENCY = 'Binance Coin'
//symbol
const NATIVE_CURRENCY_NAME = 'BNB'
//token price\
const TOKEN_PRICE = 'USDT'
//decimal
const DECIMAL = 18
//gas
const GAS_PRICE = 5

//img
const IMAGE_CHAIN =
  'https://storage.googleapis.com/chomchob/production/public/migrate/logo/057466b4950546afa239ea3861c9502f'

export const bsc = {
  chainId: CHAIN_ID,
  chainName: CHAIN_NAME,
  shortName: CHAIN_SHORT_NAME,
  nativeCurrency: {
    name: NAME_CURRENCY,
    symbol: NATIVE_CURRENCY_NAME,
    decimals: DECIMAL
  },
  rpcURL: RPC,
  blockExplorerUrls: BLOCK_EXPLORER_URLS,
  chainUrl: BLOCK_EXPLORER_URLS,
  gasPrice: GAS_PRICE,
  imgChain: IMAGE_CHAIN,
  tokenPrice: TOKEN_PRICE
}
