// Constants
import {
  TUNNEL_CONVERT_TOKEN_SUCCESS,
  TUNNEL_CONVERT_TOKEN_FAIL,
  TUNNEL_CONVERT_TOKEN_CLEAR_MESSAGE
} from 'redux/Constants/TunnelContract'

// Props Types
import IAction from 'redux/Action/types'
import xxreTunnelContractConvertTokenTypes from 'redux/Reducers/TunnelContract/ConvertToken/types'

const convertToken = (
  state: object = {},
  action: IAction<xxreTunnelContractConvertTokenTypes.Props>
) => {
  switch (action.type) {
    case TUNNEL_CONVERT_TOKEN_SUCCESS:
      return {
        ...state,
        ...action.payload,
        type: action.type
      }
    case TUNNEL_CONVERT_TOKEN_FAIL:
      return {
        ...state,
        ...action.payload,
        type: action.type
      }
    case TUNNEL_CONVERT_TOKEN_CLEAR_MESSAGE:
      return {
        type: action.type
      }
    default:
      return state
  }
}

export default convertToken
