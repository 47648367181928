//id
const CHAIN_ID = 137
//name
const CHAIN_NAME = 'Polygon Mainnet'
//name
const CHAIN_SHORT_NAME = 'Polygon'
//rpc
const RPC = 'https://polygon-rpc.com/'
//img
const IMAGE_CHAIN =
  'https://storage.googleapis.com/chomchob/production/public/migrate/logo/2cc9a9f6296a47ce9c183d61f9124e40'
//img info
const IMAGE_INFO =
  'https://storage.googleapis.com/chomchob/production/public/migrate/others/1fc26dd79cca4b529fd5570fb42eda94'
//how to
const HOW_TO = 'https://www.chomchob.com/how-to-use/exchange-ccp-to-crypto-by-tunnel'

const DECIMAL_PRICE = 6

export const off_polygon = {
  chainId: CHAIN_ID,
  chainName: CHAIN_NAME,
  shortName: CHAIN_SHORT_NAME,
  imgChain: IMAGE_CHAIN,
  imgInfo: IMAGE_INFO,
  howToUrl: HOW_TO,
  rpc: RPC,
  decimalPrice: DECIMAL_PRICE
}
