//id
const CHAIN_ID = 96
//name
const CHAIN_NAME = 'Bitkub Chain'
//name
const CHAIN_SHORT_NAME = 'BKC'
//rpc
const RPC = 'https://rpc.bitkubchain.io'
//img
const IMAGE_CHAIN =
  'https://s2.coinmarketcap.com/static/img/coins/200x200/16093.png'
//img info
const IMAGE_INFO =
  'https://storage.googleapis.com/chomchob/production/public/migrate/others/1fc26dd79cca4b529fd5570fb42eda94'
//how to
const HOW_TO = 'https://www.chomchob.com/how-to-use/ccp-to-binance'

const DECIMAL_PRICE = 18

export const off_bkc = {
  chainId: CHAIN_ID,
  chainName: CHAIN_NAME,
  shortName: CHAIN_SHORT_NAME,
  imgChain: IMAGE_CHAIN,
  imgInfo: IMAGE_INFO,
  howToUrl: HOW_TO,
  rpc: RPC,
  decimalPrice: DECIMAL_PRICE
}
