//id
const CHAIN_ID = -1
//name
const CHAIN_NAME = "Bitkub Exchange"
//name
const CHAIN_SHORT_NAME = 'Bitkub'
//rpc
const RPC = 'https://bsc-dataseed2.defibit.io'
//img
const IMAGE_CHAIN = "https://storage.googleapis.com/chomchob/production/public/crypto/chain/14c043bc-88f9-4af3-9f9d-a03d9704ad6e"
//img info
const IMAGE_INFO =
"https://storage.googleapis.com/chomchob/production/public/crypto/tunnel/how-to/c104b5a9-d593-4bfa-9585-0a47dd8c968a"
//how to
const HOW_TO =
"https://www.chomchob.com/how-to-use/exchange-ccp-to-crypto-by-tunnel"

const DECIMAL_PRICE = 18

export const off_bkx = {
  chainId: CHAIN_ID,
  chainName: CHAIN_NAME,
  shortName: CHAIN_SHORT_NAME,
  imgChain: IMAGE_CHAIN,
  imgInfo: IMAGE_INFO,
  howToUrl: HOW_TO,
  rpc: RPC,
  decimalPrice: DECIMAL_PRICE
}