import { Modal, Button, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

// Redux
import xxreStoreTypes from 'redux/types'

// Components
import xxcConfirmConvertModalTypes from 'components/ConvertPage/ConfirmConvertModal/Model'

//icon
import question_icon from 'assets/Svg/icon/question_icon.svg'

function ConfirmConvertModal({
  onHide,
  show,
  formData,
  handleConfirmConvert
}: xxcConfirmConvertModalTypes.Props) {
  // Redux
  const darkMode = useSelector((state: xxreStoreTypes.Props) => state.darkMode)
  const themeConfig = useSelector(
    (state: xxreStoreTypes.Props) => state.themeConfig
  )

  return (
    <ModalCustom
      theme={
        darkMode.darkMode === 'dark'
          ? themeConfig.themeConfig?.dark
          : themeConfig.themeConfig?.light
      }
      aria-labelledby="contained-modal-title-vcenter"
      centered
      dialogClassName="token-list-modal"
      className="modal-fullscreen-md-down"
      id="item-modal"
      onHide={onHide}
      show={show}
      // size="lg"
    >
      <Modal.Body className="p-0">
        <Modal.Header closeButton>Confirm Convert</Modal.Header>
        <div className="py-2 px-4">
          <div className="summary-list">
            <div className="title">Chain Name</div>
            <div className="value">{formData.chainName}</div>
          </div>
          <div className="summary-list">
            <div className="title">User Address</div>
            <div className="value">{formData.userAddress}</div>
          </div>
          <div className="summary-list">
            <div className="title">Token Symbol</div>
            <div className="value">{formData.tokenSymbol}</div>
          </div>
          <div className="summary-list">
            <div className="title">Point Amount</div>
            <div className="value">{`${formData.pointAmount} ${themeConfig.themeConfig?.config.pointUnit}`}</div>
          </div>
          <div className="summary-list">
            <div className="d-flex">
              <div className="title d-flex align-items-center gap-1">
                Token Receive{' '}
                <OverlayTrigger
                  trigger="click"
                  rootClose
                  overlay={props => (
                    <Tooltip id="ccp-info" {...props}>
                      This is just an estimate of cryptocurrencies. The actual
                      crypto maybe change because the fluctuation of price,
                      exchange condition and additional fee of network.
                    </Tooltip>
                  )}
                  placement="right"
                >
                  <img
                    alt="question icon"
                    // className="convert-help-outline-icon"
                    role="button"
                    src={question_icon}
                  />
                </OverlayTrigger>
              </div>
            </div>
            <div className="value value-primary">{`≈ ${Number(
              formData.tokenReceive
            ).toLocaleString('en-US', {
              maximumFractionDigits: 6,
              minimumFractionDigits: 2
            })} ${formData.tokenSymbol}`}</div>
          </div>
        </div>
        <div className="warning-box">
          <div className="title">Warning</div>
          Ensure the network or platform matches the withdrawal address. Wrong
          address and network can make loss of assets and ChomCHOB will not be
          responsible and help in this mistake.
        </div>
      </Modal.Body>
      <Modal.Footer className="modal-footer-custom">
        <div className="d-flex w-100 gap-3">
          <Button
            className="btn button cancel-button w-100"
            variant="primary"
            onClick={onHide}
          >
            <b>CANCEL</b>
          </Button>
          <Button
            className="btn button convert-button w-100"
            variant="primary"
            onClick={handleConfirmConvert}
          >
            <b>CONFIRM</b>
          </Button>
        </div>
      </Modal.Footer>
    </ModalCustom>
  )
}

export default ConfirmConvertModal

const ModalCustom = styled(Modal)<xxcConfirmConvertModalTypes.SettingModal>`
  .modal-content {
    background-color: ${props => props.theme.cardColor};
    color: ${props => props.theme.fontPrimaryColor};
    .summary-list {
      display: flex;
      justify-content: space-between;
      margin-top: 15px;
      .title {
        font-size: 22px;
        font-weight: 600;
      }
      .value {
        max-width: 62%;
        font-size: 22px;
        word-wrap: break-word;
        text-align: end;
        &-primary {
          color: ${props => props.theme.primaryColor};
          font-weight: 600;
        }
      }
    }
    .warning-box {
      margin-top: 20px;
      padding: 0.75rem;
      border-radius: 16px;
      border: 1px solid #e3ebea;
      margin-left: 1rem;
      margin-right: 1rem;
      font-size: 18px;
      font-weight: 500;
      position: relative;
      .title {
        font-size: 22px;
        font-weight: 600;
        color: #ffc12d;
        width: max-content;
        background: ${props => props.theme.cardColor};
        padding: 0 0.15em;
        line-height: 1;
        position: absolute;
        top: -14px;
      }
    }
  }
  .form-control {
    border: 2px solid ${props => props.theme.input.borderColor};
    background-color: ${props => props.theme.input.inputBackgroundColor};
    color: ${props => props.theme.input.inputTextColor};
    font-size: 20px;
  }
  .form-control::placeholder {
    color: ${props => props.theme.input.placeholderTextColor} !important;
    opacity: 1;
  }
  .form-control:focus {
    border-color: ${props => props.theme.input.inputFocusBackGroundColor};
    background-color: ${props => props.theme.input.inputFocusBackGroundColor}4d;
  }
  .btn-close {
    background: url('data:image/svg+xml,%3Csvg xmlns="http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg" width="32px" height="32px" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"%3E%3Cpath fill="${props =>
      props.theme.fontPrimaryColor.replace(
        '#',
        '%23'
      )}" d="m13.41 12l4.3-4.29a1 1 0 1 0-1.42-1.42L12 10.59l-4.29-4.3a1 1 0 0 0-1.42 1.42l4.3 4.29l-4.3 4.29a1 1 0 0 0 0 1.42a1 1 0 0 0 1.42 0l4.29-4.3l4.29 4.3a1 1 0 0 0 1.42 0a1 1 0 0 0 0-1.42Z"%2F%3E%3C%2Fsvg%3E');
  }
  .modal-footer-custom {
    height: 80px !important;
  }
  .convert-button {
    background-color: ${props =>
      props.theme.button.convertButton.backgroundColor};
    border-color: ${props => props.theme.button.convertButton.backgroundColor};
    color: ${props => props.theme.button.convertButton.fontColor};
    &:hover {
      opacity: 0.65;
      color: ${props => props.theme.button.convertButton.fontColor};
    }
    &:active {
      opacity: 0.65;
      color: ${props => props.theme.button.convertButton.fontColor};
    }
    &:active:focus {
      box-shadow: 0 0 0 0.25rem
        ${props => props.theme.button.convertButton.backgroundColor}8c !important;
    }
  }
  .cancel-button {
    background-color: ${props => props.theme.navBar.navBarFontHoverColor};
    border-color: ${props => props.theme.navBar.navBarFontHoverColor};
    color: ${props => props.theme.fontSecondaryColor};
    &:hover {
      opacity: 0.65;
      color: ${props => props.theme.fontSecondaryColor};
    }
    &:active {
      opacity: 0.65;
      color: ${props => props.theme.fontSecondaryColor};
    }
    &:active:focus {
      box-shadow: 0 0 0 0.25rem
        ${props => props.theme.navBar.navBarFontHoverColor}8c !important;
    }
  }
  @media (max-width: 540px) {
    .modal-content {
      .summary-list {
        .title {
          font-size: 20px;
        }
        .value {
          max-width: 62%;
          font-size: 20px;
        }
      }
      .warning-box {
        font-size: 18px;
      }
    }
    .convert-button {
      &:hover {
        background-color: ${props =>
          props.theme.button.convertButton.backgroundColor};
        border-color: ${props =>
          props.theme.button.convertButton.backgroundColor};
        color: ${props => props.theme.button.convertButton.fontColor};
        opacity: unset;
      }
      &:active {
        box-shadow: 0 0 0 0.25rem
          ${props => props.theme.button.convertButton.backgroundColor}8c !important;
        opacity: 0.65;
        color: ${props => props.theme.button.convertButton.fontColor};
      }
      &:disabled {
        opacity: 0.65 !important;
      }
    }
    .cancel-button {
      &:hover {
        background-color: ${props => props.theme.navBar.navBarFontHoverColor};
        border-color: ${props => props.theme.navBar.navBarFontHoverColor};
        color: ${props => props.theme.fontSecondaryColor};
        opacity: unset;
      }
      &:active {
        box-shadow: 0 0 0 0.25rem
          ${props => props.theme.navBar.navBarFontHoverColor}8c !important;
        opacity: 0.65;
        color: ${props => props.theme.fontSecondaryColor};
      }
      &:disabled {
        opacity: 0.65 !important;
      }
    }
  }
  @media (max-width: 375px) {
    .modal-content {
      .summary-list {
        .title {
          font-size: 16px;
        }
        .value {
          max-width: 60%;
          font-size: 16px;
        }
      }
      .warning-box {
        font-size: 16px;
      }
    }
  }
`
