import { Dispatch } from 'redux'

// Redux Constants
import {
  ADD_ADDRESS_WALLET_CLEAR_MESSAGES,
  ADD_ADDRESS_WALLET_FAIL,
  ADD_ADDRESS_WALLET_SUCCESS,
  GET_ADDRESS_WALLET_FAIL,
  GET_ADDRESS_WALLET_SUCCESS,
  DELETE_ADDRESS_WALLET_SUCCESS,
  DELETE_ADDRESS_WALLET_CLEAR_MESSAGES,
  DELETE_ADDRESS_WALLET_FAIL
} from 'redux/Constants/Address'

//Services
import xxsAddressService from 'services/Address'
import xxsAddressServiceTypes from 'services/Address/types'

// Utils
import xxuResponse from 'utils/Response'

const xxaAddress = {
  AddWalletAddress:
    (network_id: number, wallet_address: string, wallet_name: string) =>
    async (
      dispatch: Dispatch
    ): Promise<
      xxsAddressServiceTypes.AddWalletAddressResponseSuccess | undefined
    > => {
      const response = await xxsAddressService.AddWalletAddress(
        network_id,
        wallet_address,
        wallet_name
      )
      if (response.status === 200) {
        xxuResponse.Success({
          type: ADD_ADDRESS_WALLET_SUCCESS,
          dispatch,
          payload: response
        })
      } else {
        xxuResponse.Error({
          type: ADD_ADDRESS_WALLET_FAIL,
          errorPage: true,
          dispatch,
          error: response
        })
      }
      return response.addWalletAddress
    },
  ClearAddWalletAddress: () => async (dispatch: Dispatch) => {
    dispatch({
      type: ADD_ADDRESS_WALLET_CLEAR_MESSAGES
    })
  },
  GetWalletAddress:
    (network_id: number) =>
    async (
      dispatch: Dispatch
    ): Promise<
      xxsAddressServiceTypes.GetWalletAddressResponseSuccess | undefined
    > => {
      const response = await xxsAddressService.GetWalletAddress(network_id)
      if (response.status === 200) {
        xxuResponse.Success({
          type: GET_ADDRESS_WALLET_SUCCESS,
          dispatch,
          payload: response
        })
      } else {
        xxuResponse.Error({
          type: GET_ADDRESS_WALLET_FAIL,
          errorPage: true,
          dispatch,
          error: response
        })
      }
      return response.getWalletAddress
    },
  DeleteWalletAddress:
    (network_id: number, wallet_address: string) =>
    async (
      dispatch: Dispatch
    ): Promise<
      xxsAddressServiceTypes.DeleteWalletAddressResponseSuccess | undefined
    > => {
      const response = await xxsAddressService.DeleteWalletAddress(
        network_id,
        wallet_address
      )
      if (response.status === 200) {
        xxuResponse.Success({
          type: DELETE_ADDRESS_WALLET_SUCCESS,
          dispatch,
          payload: response
        })
      } else {
        xxuResponse.Error({
          type: DELETE_ADDRESS_WALLET_FAIL,
          errorPage: true,
          dispatch,
          error: response
        })
      }
      return response.deleteWalletAddress
    },
  ClearDeleteWalletAddress: () => async (dispatch: Dispatch) => {
    dispatch({
      type: DELETE_ADDRESS_WALLET_CLEAR_MESSAGES
    })
  }
}
export default xxaAddress
