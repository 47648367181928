//Styles
import "views/Introduction/style.scss";

//components
import Header from "components/IntroductionPage/Header";
import Banner from "components/IntroductionPage/Banner";
import Content from "components/IntroductionPage/Content";
import Footer from "components/IntroductionPage/Footer";


const Introduction = (): JSX.Element => (
    <div className="introduction-container">
      <Header/>
      <Banner/>
      <Content/>
      <Footer/>
    </div>
  )

export default Introduction
