// import React from 'react'

function SunIcon({
  fill,
  width,
  height
}: {
  fill: string
  width?: number
  height?: number
}) {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        aria-hidden="true"
        width={width ?? 50}
        height={height ?? 50}
        viewBox="0 0 256 256"
      >
        <path
          d="M128 56a72 72 0 1 0 72 72 72.1 72.1 0 0 0-72-72zm0 120a48 48 0 1 1 48-48 48 48 0 0 1-48 48zM116 28V12a12 12 0 0 1 24 0v16a12 12 0 0 1-24 0zm74.2 37.8a12 12 0 0 1 0-17l11.3-11.3a12 12 0 0 1 17 17l-11.3 11.3a12 12 0 0 1-8.5 3.5 12.2 12.2 0 0 1-8.5-3.5zM256 128a12 12 0 0 1-12 12h-16a12 12 0 0 1 0-24h16a12 12 0 0 1 12 12zm-37.5 73.5a12 12 0 0 1 0 17 11.6 11.6 0 0 1-8.5 3.5 12 12 0 0 1-8.5-3.5l-11.3-11.3a12 12 0 0 1 17-17zM140 228v16a12 12 0 0 1-24 0v-16a12 12 0 0 1 24 0zm-74.2-37.8a12 12 0 0 1 0 17l-11.3 11.3A12 12 0 0 1 46 222a11.6 11.6 0 0 1-8.5-3.5 12 12 0 0 1 0-17l11.3-11.3a12 12 0 0 1 17 0zM28 140H12a12 12 0 0 1 0-24h16a12 12 0 0 1 0 24zm9.5-85.5a12 12 0 0 1 17-17l11.3 11.3a12 12 0 0 1 0 17 12.2 12.2 0 0 1-8.5 3.5 12 12 0 0 1-8.5-3.5z"
          fill={fill}
        />
      </svg>
    </>
  )
}

export default SunIcon
