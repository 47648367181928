import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import dayjs from 'dayjs'
import * as uuid from 'uuid'
import {
  Table,
  Card,
  Col,
  OverlayTrigger,
  Tooltip,
  Button
} from 'react-bootstrap'
import ReactImageFallback from 'react-image-fallback'
import styled from 'styled-components'
import ClipboardJS from 'clipboard'
import { toast } from 'react-toastify'

// Types
import xxreStoreTypes from 'redux/types'
import xxcNavBarTypes from 'components/NavBar/Model'
import xxcTableHistoryTypes from 'components/HistoryPage/TableHistory/Model'
import xxcModalDetailsHistoryTypes from 'components/HistoryPage/ModalDetailsHistory/Model'
import xxsTunnelServiceTypes from 'services/Tunnel/types'

// Actions
import xxaTunnelService from 'actions/TunnelService'

// Components
import ModalDetailsHistory from 'components/HistoryPage/ModalDetailsHistory'
import BinancePayModal from 'components/ConvertPage/BinancePayModal'

// Config
import * as configs from 'configs'

// Styles
import './style.scss'

//icon
import image_icon from 'assets/Svg/icon/image_icon.svg'
import ChevronLeftIcon from 'components/CustomIcon/ChevronLeftIcon'
import ChevronRightIcon from 'components/CustomIcon/ChevronRightIcon'
import LaunchIcon from 'components/CustomIcon/LaunchIcon'

function TableHistory({ type }: xxcTableHistoryTypes.Props) {
  new ClipboardJS('#copy-tx')
  // Initial & Constant
  const TXN_PER_PAGE = 5
  const dispatch = useDispatch()

  // Redux
  const txns = useSelector((state: xxreStoreTypes.Props) => state.getHistory)
  const pointToCryptoHistory = useSelector(
    (state: xxreStoreTypes.Props) => state.pointToCryptoHistory
  )
  const binanceToPointHistory = useSelector(
    (state: xxreStoreTypes.Props) => state.binanceToPointHistory
  )
  const getProfile = useSelector(
    (state: xxreStoreTypes.Props) => state.getProfile
  )
  const connectWallet = useSelector(
    (state: xxreStoreTypes.Props) => state.connectWallet
  )
  const themeConfig = useSelector(
    (state: xxreStoreTypes.Props) => state.themeConfig
  )
  const darkMode = useSelector((state: xxreStoreTypes.Props) => state.darkMode)

  // State
  const [page, setPage] = useState<number>(1)
  const [curPage, setCurPage] = useState<number>(1)
  const [dataDetails, setDataDetails] = useState<any>(null)
  const [showModal, setShowModal] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [showBinancePayModal, setShowBinancePayModal] = useState(false)
  const [binancePayDetail, setBinancePayDetail] = useState<any>(null)

  // Event Listener
  const onChangePage = (i: number) => {
    setCurPage(i)
  }

  const handleOpenBinancePay = (
    transaction: xxsTunnelServiceTypes.BinanceToPointHistory
  ) => {
    let data = {
      binancePayID: transaction.binance_pay_prepay_id,
      tokenSymbol: transaction.from_token.symbol,
      tokenAmount: transaction.from_amount,
      pointReceive: transaction.topup_point,
      qrCode: transaction.payment_qr_image_url,
      urlLink: transaction.payment_url,
      deepLink: transaction.payment_deep_link_url
    }
    dispatch(
      xxaTunnelService.GetTokenPrice(
        transaction.from_token.symbol,
        transaction.chain_id,
        'binance_to_point'
      )
    )
    setBinancePayDetail(data)
    setShowBinancePayModal(true)
  }
  const handleHideBinancePay = () => {
    setShowBinancePayModal(false)
    setBinancePayDetail(null)
    dispatch(xxaTunnelService.ClearGetTokenPrice())
  }

  // Helper Function
  const loadHistory = async (offset: number) => {
    if (type === 'crypto_to_point') {
      if (connectWallet.accountAddress) {
        dispatch(
          xxaTunnelService.GetHistory(
            connectWallet.accountAddress,
            TXN_PER_PAGE,
            offset
          )
        )
      }
    } else if (type === 'point_to_crypto') {
      dispatch(xxaTunnelService.GetHistoryPointToCrypto(TXN_PER_PAGE, offset))
    } else if (type === 'binance_to_point') {
      dispatch(xxaTunnelService.GetHistoryBinanceToPoint(TXN_PER_PAGE, offset))
    }
  }

  const onClickCopyLink = (text: string) => {
    navigator.clipboard.writeText(text)
    toast.success(<div className="text-center">Copy!</div>, {
      icon: false
    })
  }

  const parseStatus = (
    status: 'open' | 'processing' | 'success' | 'fail' | 'expire'
  ): string => {
    const statusMap = {
      success: 'success',
      fail: 'fail',
      void: 'fail',
      open: 'open',
      processing: 'processing',
      expire: 'expire'
    }
    return statusMap[status] || 'processing'
  }

  const openModalDetails = (txn: any) => {
    let userAgent = navigator.userAgent || navigator.vendor
    if (/android/i.test(userAgent) || /iPad|iPhone|iPod/.test(userAgent)) {
      let dataHistory: xxcModalDetailsHistoryTypes.dataHistory = {}
      if (type === 'crypto_to_point') {
        dataHistory.crypto_to_point = txn
      } else if (type === 'point_to_crypto') {
        dataHistory.point_to_crypto = txn
      } else if (type === 'binance_to_point') {
        dataHistory.binance_to_point = txn
      }
      setDataDetails(dataHistory)
      setShowModal(true)
    }
  }

  const formatAddress = (address: string): string => {
    if (address) {
      return `${address.slice(0, 4)}..${address.slice(-4)}`
    }
    return ''
  }

  // React Hook
  useEffect(() => {
    if (txns.history?.count !== undefined && type === 'crypto_to_point') {
      const _page = Math.ceil(txns.history?.count / TXN_PER_PAGE) || 1
      setPage(_page)
      setTimeout(() => {
        setIsLoading(false)
      }, 600)
    } else if (
      pointToCryptoHistory.pointToCryptoHistory?.count !== undefined &&
      type === 'point_to_crypto' &&
      getProfile.getProfile
    ) {
      const _page =
        Math.ceil(
          pointToCryptoHistory.pointToCryptoHistory?.count / TXN_PER_PAGE
        ) || 1
      setPage(_page)
      setTimeout(() => {
        setIsLoading(false)
      }, 600)
    } else if (
      binanceToPointHistory.binanceToPointHistory?.count !== undefined &&
      type === 'binance_to_point' &&
      getProfile.getProfile
    ) {
      const _page =
        Math.ceil(
          binanceToPointHistory.binanceToPointHistory.count / TXN_PER_PAGE
        ) || 1
      setPage(_page)
      setTimeout(() => {
        setIsLoading(false)
      }, 600)
    }
  }, [txns, pointToCryptoHistory, binanceToPointHistory])

  useEffect(() => {
    const _offset = (curPage - 1) * TXN_PER_PAGE
    if (connectWallet.accountAddress && type === 'crypto_to_point') {
      loadHistory(_offset)
    } else if (
      (type === 'point_to_crypto' || type === 'binance_to_point') &&
      getProfile.getProfile
    ) {
      loadHistory(_offset)
    }
  }, [curPage, connectWallet, getProfile])

  const showTable = useMemo(() => {
    return txns.history?.count && type === 'crypto_to_point' ? (
      <Table className="history-table history-margin-bottom-0" hover>
        <thead>
          <tr>
            <th style={{ width: '59px' }} className="text-center"></th>
            <th className="d-none d-sm-block d-sm-table-cell  text-center">
              Token
            </th>
            <th className="d-none d-sm-block d-sm-table-cell text-center">
              Chain
            </th>
            <th className="text-center">Amount</th>
            <th className="text-center">
              {themeConfig.themeConfig?.config.pointUnit}
            </th>
            <th className="d-none d-sm-block d-sm-table-cell text-center">
              User Ref
            </th>
            <th className="text-center">Status</th>
            <th className="d-none d-sm-block d-sm-table-cell history-table-date text-center">
              Date
            </th>
            <th className="d-none d-sm-block d-sm-table-cell text-center"></th>
          </tr>
        </thead>
        <tbody>
          {txns.history.tunnel_convert_transactions?.map((txn, i) => (
            <tr
              aria-controls={`txn-info-${i}`}
              key={uuid.v4()}
              onClick={() => openModalDetails(txn)}
            >
              <td>
                <div className="d-flex justify-content-center align-items-center">
                  <ReactImageFallback
                    className="history-image-token"
                    alt={`image-${txn.from_token.name}`}
                    height="30px"
                    width="30px"
                    src={txn.from_token.image}
                    fallbackImage={image_icon}
                    initialImage={image_icon}
                  />
                </div>
              </td>
              <td className="d-none d-sm-block d-sm-table-cell history-max-width-column">
                <div className="d-flex flex-column history-max-width-column justify-content-center">
                  <div className="history-row-symbol">
                    {txn.from_token.symbol}
                  </div>
                  <div className="sub-content">{txn.from_token.name}</div>
                </div>
              </td>
              <td className="d-none d-sm-block d-sm-table-cell history-row-amount">
                <div className="d-flex align-items-center justify-content-center">
                  {
                    configs.web3.find(item => {
                      return item.chainId === txn.chain_id
                    })?.shortName
                  }
                </div>
              </td>
              <td className="history-row-amount text-end">
                <div className="d-flex align-items-center justify-content-end">
                  {Number(txn.from_amount).toLocaleString('en-US', {
                    maximumFractionDigits: 6,
                    minimumFractionDigits: 2
                  })}
                </div>
              </td>
              <td>
                <div className="history-row-amount d-flex align-items-center justify-content-end">
                  {txn.topup_point === ''
                    ? '-'
                    : Number(txn.topup_point).toLocaleString('en-US', {
                        maximumFractionDigits: 6,
                        minimumFractionDigits: 2
                      })}
                </div>
              </td>
              <td className="d-none d-sm-block d-sm-table-cell">
                <div className="history-row-amount d-flex align-items-center justify-content-center">
                  {txn.user_ref}
                </div>
              </td>
              <td>
                <div
                  className={`d-flex align-items-center justify-content-center status-${parseStatus(
                    txn.status
                  )}`}
                >
                  <span
                    className={`badge text-uppercase bg-${
                      parseStatus(txn.status) === 'success'
                        ? 'success'
                        : parseStatus(txn.status) === 'fail'
                        ? 'danger'
                        : 'warning'
                    }`}
                  >
                    {parseStatus(txn.status)}
                  </span>
                  {/* {parseStatus(txn.status)} */}
                </div>
              </td>
              <td className="d-none d-sm-block d-sm-table-cell history-max-width-column">
                <div className="d-flex flex-column history-max-width-column justify-content-center">
                  <div>{dayjs(txn.exchange_date).format('DD MMM YYYY')}</div>
                  <div className="sub-content">
                    {dayjs(txn.exchange_date).format('HH:mm:ss')}
                  </div>
                </div>
              </td>
              <td className="d-none d-sm-block d-sm-table-cell">
                <div className="d-flex align-items-center">
                  <a
                    href={`${txn.explorer_url}/tx/${txn.transaction_hash}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <LaunchIcon
                      width={20}
                      height={20}
                      fill={
                        themeConfig.themeConfig?.[darkMode.darkMode]
                          .primaryColor || '#ffffff'
                      }
                    />
                  </a>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    ) : pointToCryptoHistory.pointToCryptoHistory?.count &&
      type === 'point_to_crypto' ? (
      <Table className="history-table history-margin-bottom-0" hover>
        <thead>
          <tr>
            <th style={{ width: '59px' }} className="text-center"></th>
            <th className="d-none d-sm-block d-sm-table-cell  text-center">
              Token
            </th>
            <th className="d-none d-sm-block d-sm-table-cell  text-center">
              Tx Hash
            </th>
            <th className="d-none d-sm-block d-sm-table-cell text-center">
              Chain
            </th>
            <th className="text-center">
              {themeConfig.themeConfig?.config.pointUnit}
            </th>
            <th className="text-center">Receive</th>
            <th className="d-none d-sm-block d-sm-table-cell text-center">
              User Address
            </th>
            <th className="text-center">Status</th>
            <th className="d-none d-sm-block d-sm-table-cell history-table-date text-center">
              Date
            </th>
            <th
              className={`d-none d-sm-block d-sm-table-cell text-center ${
                pointToCryptoHistory.pointToCryptoHistory.tunnel_convert_transactions.some(
                  item => {
                    return item.transaction_hash && item.explorer_url
                  }
                )
                  ? 'history-table-history'
                  : ''
              }`}
            ></th>
          </tr>
        </thead>
        <tbody>
          {pointToCryptoHistory.pointToCryptoHistory.tunnel_convert_transactions?.map(
            (txn, i) => (
              <tr
                aria-controls={`txn-info-${i}`}
                key={uuid.v4()}
                onClick={() => openModalDetails(txn)}
              >
                <td>
                  <div className="d-flex justify-content-center align-items-center">
                    <ReactImageFallback
                      className="history-image-token"
                      alt={`image-${txn.to_token.name}`}
                      height="30px"
                      width="30px"
                      src={txn.to_token.image}
                      fallbackImage={image_icon}
                      initialImage={image_icon}
                    />
                  </div>
                </td>
                <td className="d-none d-sm-block d-sm-table-cell history-max-width-column">
                  <div className="d-flex flex-column history-max-width-column justify-content-center">
                    <div className="history-row-symbol">
                      {txn.to_token.symbol}
                    </div>
                    <div className="sub-content">{txn.to_token.name}</div>
                  </div>
                </td>
                <td className="d-none d-sm-block d-sm-table-cell history-row-amount">
                  <div className="d-flex align-items-center justify-content-center">
                    {txn.transaction_hash ? (
                      <OverlayTrigger
                        placement="top"
                        overlay={
                          <Tooltip id="tooltip-top">
                            {txn.transaction_hash}
                          </Tooltip>
                        }
                      >
                        <div
                          role="button"
                          id="copy-tx"
                          onClick={() =>
                            onClickCopyLink(txn.transaction_hash ?? '')
                          }
                          data-clipboard-text={txn.transaction_hash}
                        >
                          {formatAddress(txn.transaction_hash)}
                        </div>
                      </OverlayTrigger>
                    ) : (
                      '-'
                    )}
                  </div>
                </td>
                <td className="d-none d-sm-block d-sm-table-cell history-row-amount">
                  <div className="d-flex align-items-center justify-content-center">
                    {txn.chain_name}
                  </div>
                </td>
                <td className="history-row-amount text-end">
                  <div className="d-flex align-items-center justify-content-end">
                    {Number(txn.from_point).toLocaleString('en-US', {
                      maximumFractionDigits: 6,
                      minimumFractionDigits: 2
                    })}
                  </div>
                </td>
                <td>
                  <div className="history-row-amount d-flex align-items-center justify-content-end">
                    {txn.to_token.amount === null
                      ? '-'
                      : Number(txn.to_token.amount).toLocaleString('en-US', {
                          maximumFractionDigits: 6,
                          minimumFractionDigits: 2
                        })}
                  </div>
                </td>
                <td className="d-none d-sm-block d-sm-table-cell">
                  <div className="history-row-amount d-flex align-items-center justify-content-center">
                    {formatAddress(txn.to_address)}
                  </div>
                </td>
                <td>
                  <div
                    className={`d-flex align-items-center justify-content-center status-${parseStatus(
                      txn.status
                    )}`}
                  >
                    <span
                      className={`badge text-uppercase bg-${
                        parseStatus(txn.status) === 'success'
                          ? 'success'
                          : parseStatus(txn.status) === 'fail'
                          ? 'danger'
                          : 'warning'
                      }`}
                    >
                      {parseStatus(txn.status)}
                    </span>
                    {/* {parseStatus(txn.status)} */}
                  </div>
                </td>
                <td className="d-none d-sm-block d-sm-table-cell history-max-width-column">
                  <div className="d-flex flex-column history-max-width-column justify-content-center">
                    <div>{dayjs(txn.exchange_date).format('DD MMM YYYY')}</div>
                    <div className="sub-content">
                      {dayjs(txn.exchange_date).format('HH:mm:ss')}
                    </div>
                  </div>
                </td>
                <td className="d-none d-sm-block d-sm-table-cell">
                  <div className="d-flex align-items-center">
                    {txn.explorer_url && txn.transaction_hash ? (
                      <a
                        href={`${txn.explorer_url}/tx/${txn.transaction_hash}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <LaunchIcon
                          width={20}
                          height={20}
                          fill={
                            themeConfig.themeConfig?.[darkMode.darkMode]
                              .primaryColor || '#ffffff'
                          }
                        />
                      </a>
                    ) : null}
                  </div>
                </td>
              </tr>
            )
          )}
        </tbody>
      </Table>
    ) : binanceToPointHistory.binanceToPointHistory?.count &&
      type === 'binance_to_point' ? (
      <Table className="history-table history-margin-bottom-0" hover>
        <thead>
          <tr>
            <th style={{ width: '59px' }} className="text-center"></th>
            <th className="d-none d-sm-block d-sm-table-cell  text-center">
              Token
            </th>
            <th className="d-none d-sm-block d-sm-table-cell  text-center">
              Pay ID
            </th>
            <th className="d-none d-sm-block d-sm-table-cell text-center">
              Chain
            </th>
            <th className="text-center">
              {themeConfig.themeConfig?.config.pointUnit}
            </th>
            <th className="text-center">Amount</th>
            <th className="text-center">Status</th>
            <th className="d-none d-sm-block d-sm-table-cell history-table-date text-center">
              Date
            </th>
            <th className="d-none d-sm-block d-sm-table-cell text-center"></th>
          </tr>
        </thead>
        <tbody>
          {binanceToPointHistory.binanceToPointHistory.tunnel_convert_transactions?.map(
            (txn, i) => (
              <tr
                aria-controls={`txn-info-${i}`}
                key={uuid.v4()}
                onClick={() => openModalDetails(txn)}
              >
                <td>
                  <div className="d-flex justify-content-center align-items-center">
                    <ReactImageFallback
                      className="history-image-token"
                      alt={`image-${txn.from_token.name}`}
                      height="30px"
                      width="30px"
                      src={txn.from_token.image}
                      fallbackImage={image_icon}
                      initialImage={image_icon}
                    />
                  </div>
                </td>
                <td className="d-none d-sm-block d-sm-table-cell history-max-width-column">
                  <div className="d-flex flex-column history-max-width-column justify-content-center">
                    <div className="history-row-symbol">
                      {txn.from_token.symbol}
                    </div>
                    <div className="sub-content">{txn.from_token.name}</div>
                  </div>
                </td>
                <td className="d-none d-sm-block d-sm-table-cell history-row-amount">
                  <div className="d-flex align-items-center justify-content-center">
                    {txn.binance_pay_prepay_id ? (
                      <OverlayTrigger
                        placement="top"
                        overlay={
                          <Tooltip id="tooltip-top">
                            {txn.binance_pay_prepay_id}
                          </Tooltip>
                        }
                      >
                        <div
                          role="button"
                          id="copy-tx"
                          onClick={() =>
                            onClickCopyLink(txn.binance_pay_prepay_id ?? '')
                          }
                          data-clipboard-text={txn.binance_pay_prepay_id}
                        >
                          {/* {formatAddress(txn.binance_pay_prepay_id)} */}
                          {formatAddress(txn.binance_pay_prepay_id)}
                        </div>
                      </OverlayTrigger>
                    ) : (
                      '-'
                    )}
                  </div>
                </td>
                <td className="d-none d-sm-block d-sm-table-cell history-row-amount">
                  <div className="d-flex align-items-center justify-content-center">
                    {txn.chain_name}
                  </div>
                </td>
                <td className="history-row-amount text-end">
                  <div className="d-flex align-items-center justify-content-end">
                    {!txn.topup_point
                      ? '-'
                      : Number(txn.topup_point).toLocaleString('en-US', {
                          maximumFractionDigits: 6,
                          minimumFractionDigits: 2
                        })}
                  </div>
                </td>
                <td>
                  <div className="history-row-amount d-flex align-items-center justify-content-end">
                    {txn.from_amount === null
                      ? '-'
                      : Number(txn.from_amount).toLocaleString('en-US', {
                          maximumFractionDigits: 6,
                          minimumFractionDigits: 2
                        })}
                  </div>
                </td>
                <td>
                  <div
                    className={`d-flex align-items-center justify-content-center status-${parseStatus(
                      txn.status
                    )}`}
                  >
                    <span
                      className={`badge text-uppercase bg-${
                        parseStatus(txn.status) === 'success'
                          ? 'success'
                          : parseStatus(txn.status) === 'fail'
                          ? 'danger'
                          : parseStatus(txn.status) === 'expire'
                          ? 'secondary'
                          : 'warning'
                      }`}
                    >
                      {parseStatus(txn.status)}
                    </span>
                  </div>
                </td>
                <td className="d-none d-sm-block d-sm-table-cell history-max-width-column">
                  <div className="d-flex flex-column history-max-width-column justify-content-center">
                    <div className="d-flex justify-content-center">
                      {dayjs(txn.exchange_date).format('DD MMM YYYY')}
                    </div>
                    <div className="d-flex justify-content-center sub-content">
                      {dayjs(txn.exchange_date).format('HH:mm:ss')}
                    </div>
                  </div>
                </td>
                <td
                  className="d-none d-sm-block d-sm-table-cell history-max-width-column"
                  style={{ width: '80px' }}
                >
                  {txn.status === 'open' ? (
                    <div className="d-flex align-items-center justify-content-center">
                      <Button
                        size="sm"
                        onClick={() => handleOpenBinancePay(txn)}
                        className="btn-sm button button-primary view-transaction"
                      >
                        Pay Now
                      </Button>
                    </div>
                  ) : null}
                </td>
              </tr>
            )
          )}
        </tbody>
      </Table>
    ) : (
      <div className="history-card-body-no-data">No recent History</div>
    )
  }, [txns, pointToCryptoHistory, darkMode, binanceToPointHistory])

  return (
    <>
      <Col
        className="history-container"
        lg={10}
        xl={type === 'crypto_to_point' ? 8 : 9}
        xxl={8}
        xs={12}
      >
        <CardCustom
          theme={
            darkMode.darkMode === 'dark'
              ? themeConfig.themeConfig?.dark
              : themeConfig.themeConfig?.light
          }
          className="history-card"
        >
          <Card.Header className="history-header">History</Card.Header>
          <Card.Body className="history-card-body">
            <div>{showTable}</div>
          </Card.Body>

          <Card.Footer>
            <div className="d-flex justify-content-center align-items-center history-footer-content">
              {pointToCryptoHistory.pointToCryptoHistory?.count === 0 &&
              type === 'point_to_crypto' ? null : txns.history?.count === 0 &&
                type === 'crypto_to_point' ? null : (
                <>
                  <ChevronLeftIcon
                    width={20}
                    height={20}
                    fill={
                      themeConfig.themeConfig?.[darkMode.darkMode]
                        .primaryColor || '#ffffff'
                    }
                    onClick={() => {
                      if (curPage > 1 && !isLoading) {
                        onChangePage(curPage - 1)
                        setIsLoading(true)
                      }
                    }}
                  />
                  {curPage} of {page}
                  <ChevronRightIcon
                    width={20}
                    height={20}
                    fill={
                      themeConfig.themeConfig?.[darkMode.darkMode]
                        .primaryColor || '#ffffff'
                    }
                    onClick={() => {
                      if (!isLoading && curPage < page) {
                        onChangePage(curPage + 1)
                        setIsLoading(true)
                      }
                    }}
                  />
                </>
              )}
            </div>
          </Card.Footer>
        </CardCustom>
      </Col>
      <ModalDetailsHistory
        onHide={() => setShowModal(false)}
        data={dataDetails}
        show={showModal}
        handleOpenBinancePay={handleOpenBinancePay}
      />
      {binancePayDetail ? (
        <BinancePayModal
          show={showBinancePayModal}
          onHide={handleHideBinancePay}
          formData={binancePayDetail}
        />
      ) : null}
    </>
  )
}

export default TableHistory

const CardCustom = styled(Col)<xxcNavBarTypes.NavBarCustom>`
  background-color: ${props => props.theme.cardColor};
  .history-table {
    & th {
      color: ${props => props.theme.primaryColor} !important;
    }
    & td {
      color: ${props => props.theme.fontPrimaryColor} !important;
    }
  }
  .sub-content {
    color: ${props => props.theme.fontSecondaryColor} !important;
  }
`
