import axios from 'axios'

// Types
import xxsAddressServiceTypes from 'services/Address/types'
// Utils
import xxaDevice from 'actions/Utils/Device'
import xxaInitial from 'actions/Initial'

// Config
import { servicePath } from 'configs'

const xxsAddressService = {
  AddWalletAddress: async (
    network_id: number,
    wallet_address: string,
    wallet_name: string
  ): Promise<xxsAddressServiceTypes.AddWalletAddressResponse> => {
    let response: xxsAddressServiceTypes.AddWalletAddressResponse
    let access_token = await xxaInitial.Check()
    let data = {
      network_id: network_id.toString(),
      wallet_address,
      wallet_name
    }
    try {
      const responseData =
        await axios.post<xxsAddressServiceTypes.AddWalletAddressResponseSuccess>(
          `${servicePath.service.tunnel}/v1/tunnel/wallet`,
          data,
          {
            headers: {
              Authorization: access_token,
              'device-Id': await xxaDevice.GetDeviceId(),
              'accept-language': servicePath.acceptLanguage
            }
          }
        )

      response = {
        status: responseData.status,
        addWalletAddress: responseData.data
      }
    } catch (error: any) {
      const err = error.toJSON()
      if (err.message === 'Network Error') {
        response = {
          status: 500,
          error: err
        }
      } else {
        response = {
          status: error.response.status,
          error: error.response.data
        }
      }
    }
    return response
  },
  GetWalletAddress: async (
    network_id: number
  ): Promise<xxsAddressServiceTypes.GetWalletAddressResponse> => {
    let response: xxsAddressServiceTypes.GetWalletAddressResponse
    let access_token = await xxaInitial.Check()
    // var data = new FormData();
    // data.append('network_id', )
    try {
      const responseData =
        await axios.get<xxsAddressServiceTypes.GetWalletAddressResponseSuccess>(
          `${servicePath.service.tunnel}/v1/tunnel/wallet`,
          {
            params: {
              network_id: network_id.toString()
            },
            headers: {
              Authorization: access_token,
              'device-Id': await xxaDevice.GetDeviceId(),
              'accept-language': servicePath.acceptLanguage
            }
          }
        )

      response = {
        status: responseData.status,
        getWalletAddress: responseData.data
      }
    } catch (error: any) {
      const err = error.toJSON()
      if (err.message === 'Network Error') {
        response = {
          status: 500,
          error: err
        }
      } else {
        response = {
          status: error.response.status,
          error: error.response.data
        }
      }
    }
    return response
  },
  DeleteWalletAddress: async (
    network_id: number,
    wallet_address: string
  ): Promise<xxsAddressServiceTypes.DeleteWalletAddressResponse> => {
    let response: xxsAddressServiceTypes.DeleteWalletAddressResponse
    let access_token = await xxaInitial.Check()
    // var data = new FormData();
    // data.append('network_id', )
    try {
      const responseData =
        await axios.patch<xxsAddressServiceTypes.DeleteWalletAddressResponseSuccess>(
          `${servicePath.service.tunnel}/v1/tunnel/wallet/disable?network_id=${network_id}&wallet_address=${wallet_address}`,
          {},
          {
            headers: {
              Authorization: access_token,
              'device-Id': await xxaDevice.GetDeviceId(),
              'accept-language': servicePath.acceptLanguage
            }
          }
        )

      response = {
        status: responseData.status,
        deleteWalletAddress: responseData.data
      }
    } catch (error: any) {
      const err = error.toJSON()
      if (err.message === 'Network Error') {
        response = {
          status: 500,
          error: err
        }
      } else {
        response = {
          status: error.response.status,
          error: error.response.data
        }
      }
    }
    return response
  }
}

export default xxsAddressService
