// Constants
import {
  TUNNEL_CHECK_USER_REF_SUCCESS,
  TUNNEL_CHECK_USER_REF_FAIL,
  TUNNEL_CHECK_USER_REF_CLEAR_MESSAGE
} from 'redux/Constants/TunnelService'

// Props Types
import IAction from 'redux/Action/types'
import xxreTunnelServiceCheckUserRefTypes from 'redux/Reducers/TunnelService/CheckUserRef/types'

const checkUserRef = (
  state: object = {},
  action: IAction<xxreTunnelServiceCheckUserRefTypes.Props>
) => {
  switch (action.type) {
    case TUNNEL_CHECK_USER_REF_SUCCESS:
      return {
        ...state,
        ...action.payload,
        type: action.type
      }
    case TUNNEL_CHECK_USER_REF_FAIL:
      return {
        ...state,
        ...action.payload,
        type: action.type
      }
    case TUNNEL_CHECK_USER_REF_CLEAR_MESSAGE:
      return {}
    default:
      return state
  }
}

export default checkUserRef
