import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { ethers } from 'ethers'
import Decimal from 'decimal.js'
import { toast } from 'react-toastify'
import ReactImageFallback from 'react-image-fallback'
import styled from 'styled-components'

import {
  Col,
  Row,
  Form,
  Button,
  InputGroup,
  OverlayTrigger,
  Tooltip,
  Dropdown,
  Collapse
} from 'react-bootstrap'

//file
import TunnelABI from 'contracts/tunnel_reverse.json'

// Types
import xxreStoreTypes from 'redux/types'
import xxsTunnelServiceTypes from 'services/Tunnel/types'
import xxcNavBarTypes from 'components/NavBar/Model'

// Actions
import xxaTunnelService from 'actions/TunnelService'
import xxaQueryString from 'actions/QueryString'
import xxaProfile from 'actions/Profile'

//type
import {
  POINT_TO_CRYPTO_REQUEST_SUCCESS,
  POINT_TO_CRYPTO_REQUEST_FAIL,
  POINT_TO_CRYPTO_REQUEST_CLEAR_MESSAGE,
  POINT_TO_CRYPTO_CONFIRM_CLEAR_MESSAGE,
  POINT_TO_CRYPTO_CONFIRM_FAIL,
  POINT_TO_CRYPTO_CONFIRM_SUCCESS
} from 'redux/Constants/TunnelService'

import { TUNNEL_GET_CONFIG_SUCCESS } from 'redux/Constants/TunnelService'
import xxcChainModalTypes from 'configs/Model'

// Components
import TokenListModal from 'components/ConvertPage/TokenListModal'
import ModalUserRef from 'components/ConvertPage/ModalUserRef'
import SkeletonLoad from 'components/SkeletonLoad'
import ChainListModal from 'components/NavBar/ChainListModal'
import PersonIcon from 'components/CustomIcon/PersonIcon'
import AddressBookModal from 'components/ConvertPage/AddressBookModal'
import ConfirmConvertModal from 'components/ConvertPage/ConfirmConvertModal'
import BinanceOptions from 'components/ConvertPage/BinanceOptions'

//icon
import image_icon from 'assets/Svg/icon/image_icon.svg'
import question_icon from 'assets/Svg/icon/question_icon.svg'
import ConvertIcon from 'components/CustomIcon/ConvertIcon'
import DownIcon from 'components/CustomIcon/DownIcon'

// Styles
import './style.scss'
import xxaWeb3 from 'actions/Web3'

//utils
import xxuURL from 'utils/URL'

let targetInput = ''

const PointToCrypto = () => {
  // Initial
  const dispatch = useDispatch()
  const router = useHistory()
  const tunnelType: xxcChainModalTypes.tunnelType = 'point_to_crypto'

  // Redux
  const web3 = useSelector((state: xxreStoreTypes.Props) => state.web3)
  const tunnelConfig = useSelector(
    (state: xxreStoreTypes.Props) => state.getConfig
  )
  const nativePrice = useSelector(
    (state: xxreStoreTypes.Props) => state.getTokenPrice
  )
  const themeConfig = useSelector(
    (state: xxreStoreTypes.Props) => state.themeConfig
  )
  const platformChain = useSelector(
    (state: xxreStoreTypes.Props) => state.platformChain
  )
  const getProfile = useSelector(
    (state: xxreStoreTypes.Props) => state.getProfile
  )
  const pointToCryptoRequest = useSelector(
    (state: xxreStoreTypes.Props) => state.pointToCryptoRequest
  )
  const pointToCryptoConfirm = useSelector(
    (state: xxreStoreTypes.Props) => state.pointToCryptoConfirm
  )
  const darkMode = useSelector((state: xxreStoreTypes.Props) => state.darkMode)

  // User Input State
  const [selectedToken, setSelectedToken] =
    useState<xxsTunnelServiceTypes.TunnelToken | null>(null)
  const [selectedChain, setSelectedChain] =
    useState<xxcChainModalTypes.offChainType | null>(null)
  const [selectedNetworkWithdraw, setSelectedNetworkWithdraw] =
    useState<xxsTunnelServiceTypes.WithdrawChainList | null>(null)
  const [userAddress, setUserAddress] = useState<string>('')
  const [tokenAmount, setTokenAmount] = useState<string>('')
  const [ccpAmount, setCcpAmount] = useState<string>('')
  const [memoInput, setMemoInput] = useState<string>('')

  //modal state
  const [showChainList, setShowChainList] = useState(false)
  const [showAddressBook, setShowAddressBook] = useState(false)
  const [showConfirmConvert, setShowConfirmConvert] = useState(false)
  const [openAdvanceOptions, setOpenAdvanceOptions] = useState<boolean>(false)

  // Calculation and Display State
  const [ccpBalance, setCcpBalance] = useState<string>('0')
  const [minCcpAmount, setMinCcpAmount] = useState<string>('1')
  const [maxCcpAmount, setMaxCcpAmount] = useState<string>('100000')
  const [amountOutMin, setAmountOutMin] = useState<string>('0')
  const [slippage, setSlippage] = useState<string>('5')

  // UI control state
  const [isProcessConvert, setIsProcessConvert] = useState<boolean>(false)
  const [isTokenInputLoading, setIsTokenInputLoading] = useState<boolean>(false)
  const [isAmountInputLoading, setIsAmountInputLoading] =
    useState<boolean>(false)
  const [openTokenList, setOpenTokenList] = useState<boolean>(false)
  const [openModalUserRef, setOpenModalUserRef] = useState<boolean>(false)

  // Event Listener
  const onInputCCPAmount = (event: React.ChangeEvent<HTMLInputElement>) => {
    // const regx = /^([1-9][0-9]*|0?)\.?[0-9]*$/
    const value = event.currentTarget.value
    if (!value) {
      setCcpAmount('')
    } else if (value === '0' || value === '00') {
      setCcpAmount('0')
    } else {
      if (/^[\d]*\.?[\d]{0,2}$/.test(value)) {
        if (value.startsWith('.')) {
          setCcpAmount('0.')
        } else if (value.startsWith('0.')) {
          setCcpAmount(value)
        } else {
          setCcpAmount(value.replace(/^0+/, ''))
        }
      }
    }
    targetInput = 'CCP_AMOUNT'
  }

  const toPath = (pathName: string) => {
    router.push(pathName)
  }

  const onInputTokenAmount = (event: React.FormEvent<HTMLInputElement>) => {
    // const regx = /^([1-9][0-9]*|0?)\.?[0-9]*$/
    const value = event.currentTarget.value
    if (!value) {
      setTokenAmount('')
    } else if (value === '0' || value === '00') {
      setTokenAmount('0')
    } else {
      if (/^[\d]*\.?[\d]{0,8}$/.test(value)) {
        if (value.startsWith('.')) {
          setTokenAmount('0.')
        } else if (value.startsWith('0.')) {
          setTokenAmount(value)
        } else {
          setTokenAmount(value.replace(/^0+/, ''))
        }
      }
    }
    targetInput = 'TOKEN_AMOUNT'
  }

  const onInputUserAddress = (event: React.FormEvent<HTMLInputElement>) => {
    const regex = /[^A-Za-z0-9]/g
    const value = event.currentTarget.value.toString()
    const address = value.replace(regex, '').slice(0, 200)
    setUserAddress(address)
  }

  const onInputMemo = (event: React.FormEvent<HTMLInputElement>) => {
    const value = event.currentTarget.value.toString()
    setMemoInput(value)
  }

  const onClickConvert = async () => {
    if (selectedChain && selectedToken) {
      try {
        validateConvertParams()
        setIsProcessConvert(true)

        if (selectedChain.chainId <= 0) {

          if (selectedNetworkWithdraw) {
            dispatch(
              xxaTunnelService.PointToCryptoRequest(
                userAddress,
                selectedChain?.chainId,
                selectedToken?.symbol,
                Number(ccpAmount),
                selectedNetworkWithdraw.short_name,
                selectedNetworkWithdraw.use_memo ? memoInput : '',
                amountOutMin,
              )
            )
          }
        } else {

          dispatch(
            xxaTunnelService.PointToCryptoRequest(
              userAddress,
              selectedChain?.chainId,
              selectedToken?.symbol,
              Number(ccpAmount),
              '',
              '',
              amountOutMin
            )
          )
        }
      } catch (error) {
        if (error instanceof Error) {
          toast.error(error.message)
        }
      }
    }
  }

  const handleConfirmConvert = () => {
    if (pointToCryptoRequest.pointToCryptoRequest) {
      dispatch(
        xxaTunnelService.PointToCryptoConfirm(
          pointToCryptoRequest.pointToCryptoRequest.tid
        )
      )
    }
  }

  const onInputSlippage = (event: React.FormEvent<HTMLInputElement>) => {
    const regx = /^([1-9][0-9]*|0?)\.?[0-9]*$/
    const value = event.currentTarget.value
    if (regx.test(value) && Number(value) <= 50) {
      const _value = value.replace(/^0+/, '0').replace(/^\./, '0.')
      setSlippage(_value)
    }
  }

  const onBlurSlippage = (): any => {
    if (!slippage) {
      setSlippage('5')
    }
  }

  // Helper function
  const validateConvertParams = () => {
    if (selectedChain?.rpc === '') {
      if (selectedNetworkWithdraw === null) {
        throw new Error(`Please select network`)
      }
      if (selectedNetworkWithdraw.use_memo && !memoInput) {
        throw new Error(`Please enter MEMO ot Tag.`)
      }
    }
    if (!getProfile.getProfile) {
      throw new Error(`Please sign in before the exchange.`)
    }
    if (Number(ccpAmount) <= 0) {
      throw new Error('Please fill token amount.')
    }
    if (Number(minCcpAmount) > Number(maxCcpAmount)) {
      throw new Error(`${selectedToken?.symbol} is not enough to exchange.`)
    }
    if (userAddress.length <= 0) {
      throw new Error(`Wallet address is not allowed to be empty.`)
    }
    if (Number(ccpAmount) > Number(ccpBalance)) {
      throw new Error(
        `Convert ${themeConfig.themeConfig?.config.pointUnit || 'point'
        } exceed balance.`
      )
    }
    if (Number(ccpAmount) < Number(minCcpAmount)) {
      throw new Error(
        `${themeConfig.themeConfig?.config.pointUnit || 'point'
        } amount is lower than minimum.`
      )
    }
    if (Number(ccpAmount) > Number(maxCcpAmount)) {
      throw new Error(
        `${themeConfig.themeConfig?.config.pointUnit || 'point'
        } amount is higher than maximum.`
      )
    }
  }

  const setMinMaxCCP = () => {
    const minAmountOut = nativePrice.price?.min_point
    const maxAmountOut = nativePrice.price?.max_point
    setMinCcpAmount(minAmountOut || '330')
    setMaxCcpAmount(maxAmountOut || '990000')
    if (
      Number(nativePrice.price?.min_point) >
      Number(nativePrice.price?.max_point)
    ) {
      toast.error(`${selectedToken?.symbol} is not enough to exchange.`)
    }
  }

  const openChainList = () => {
    if (platformChain.platformChain?.offChain.length > 1) {
      setShowChainList(true)
    }
  }

  // Format Function
  const formatCurrency = (value: string | number): string => {
    return Intl.NumberFormat('en-US', { maximumSignificantDigits: 21 }).format(
      Number(value)
    )
  }

  const formatBeforeSet = (value: number | string | Decimal, d: number = 2) =>
    new Decimal(value).toFixed(d).replace(/\.?0*$/, '')

  const getChainFormLocalStorage = () => {
    let chainID = window.localStorage.getItem('chainID')
    if (chainID) {
      const chain = platformChain.platformChain?.offChain.find(item => {
        return Number(chainID) === item.chainId
      })
      if (chain) {
        setSelectedChain(chain)
      } else {
        setSelectedChain(platformChain.platformChain?.offChain[0])
      }
    } else {
      setSelectedChain(platformChain.platformChain?.offChain[0])
    }
  }

  const getTokenPrice = (short_name?: string) => {
    if (selectedToken && selectedChain) {
      dispatch(
        xxaTunnelService.GetTokenPrice(
          selectedToken.config_symbol,
          selectedChain.chainId,
          tunnelType,
          short_name
        )
      )
    }
  }

  const setMaxOnCCPInput = async () => {
    setCcpAmount(ccpBalance)
    targetInput = 'CCP_AMOUNT'
  }

  const setSelectTokenDefault = () => {
    let queryStr = xxaQueryString.GetQueryString()
    const tokenSearch = queryStr.tokenSymbol ?? 'SIX'

    if (tunnelConfig.config) {
      let newTokenSelect = tunnelConfig.config.tokens.find(item => {
        return item.symbol === tokenSearch.toUpperCase()
      })

      if (newTokenSelect) {
        setSelectedToken(newTokenSelect)
      } else {
        setSelectedToken(tunnelConfig.config.tokens[0])
      }
    }
  }

  useEffect(() => {
    if (getProfile.getProfile) {
      setCcpBalance(getProfile.getProfile.point_balance)
    } else {
      setCcpBalance('0')
    }
  }, [getProfile])

  useEffect(() => {
    setSelectedNetworkWithdraw(null)
    setTokenAmount('')
    setCcpAmount('')
    setMemoInput('')
    if (selectedChain && selectedChain.chainId > 0) {
      getTokenPrice()
    }

    if (selectedToken) {
      xxuURL.ChangeURL('token_symbol', selectedToken.symbol)
    }
  }, [selectedToken])

  useEffect(() => {
    if (selectedNetworkWithdraw) {
      getTokenPrice(selectedNetworkWithdraw.short_name)
    } else if (selectedNetworkWithdraw === null) {
      dispatch(xxaTunnelService.ClearGetTokenPrice())
    }
  }, [selectedNetworkWithdraw])

  useEffect(() => {
    if (nativePrice.price) {
      setMinMaxCCP()
    }
  }, [nativePrice])

  useEffect(() => {
    const calculateConvertValue = async (_tokenAddress?: string) => {
      try {
        if (
          web3.provider &&
          Number(tokenAmount) > 0 &&
          tunnelConfig.config?.tunnel_address &&
          selectedToken &&
          selectedChain?.rpc !== '' &&
          _tokenAddress
        ) {
          const provider = web3.provider
          const tunnelAddress = tunnelConfig.config?.tunnel_address
          const tunnel = new ethers.Contract(tunnelAddress, TunnelABI, provider)
          const expectAmountOut = ethers.utils.parseUnits(
            tokenAmount,
            selectedToken.decimal
          )

          const { amountIn } = await tunnel.getAmountIn(
            _tokenAddress,
            nativePrice.price?.router,
            nativePrice.price?.first_pair,
            nativePrice.price?.paths,
            expectAmountOut
          )

          setIsAmountInputLoading(false)
          const _ccpAmount = new Decimal(
            ethers.utils.formatUnits(amountIn, selectedChain?.decimalPrice)
          ).mul(nativePrice.price?.price_point || '33')

          setCcpAmount(formatBeforeSet(_ccpAmount))
          const _amountOutMin = new Decimal(amountIn.toString())
            .mul(100 - Number(slippage))
            .div(100)
            .toFixed(0)
          setAmountOutMin(ethers.utils.formatEther(_amountOutMin))
        } else {
          setIsAmountInputLoading(false)
          const _ccpAmount = new Decimal(Number(tokenAmount)).mul(
            nativePrice.price?.price_point || 33
          )
          setCcpAmount(formatBeforeSet(_ccpAmount, 2))
        }
      } catch (error) {
        setIsAmountInputLoading(false)
        setCcpAmount('0')
        let msg: string = 'something was wrong'
        toast.error(msg)
        console.error('error', error)
      }
    }
    if (tokenAmount === '') {
      return setCcpAmount('')
    }
    if (targetInput === 'TOKEN_AMOUNT') {
      if (
        selectedToken &&
        nativePrice.price?.price_point &&
        selectedChain?.rpc !== '' &&
        selectedToken.tunnel_method !== 'convertWithTransfer'
      ) {
        setIsAmountInputLoading(true)
        const delayDebounceFn = setTimeout(() => {
          calculateConvertValue(selectedToken.address)
        }, 700)
        return () => clearTimeout(delayDebounceFn)
      } else {
        calculateConvertValue()
      }
    }
  }, [tokenAmount, nativePrice, slippage])

  useEffect(() => {
    const calculateConvertValue = async (_tokenAddress?: string) => {
      try {
        if (
          web3.provider &&
          Number(ccpAmount) > 0 &&
          tunnelConfig.config?.tunnel_address &&
          selectedToken &&
          selectedChain?.rpc !== '' &&
          _tokenAddress
        ) {
          const provider = web3.provider
          const tunnelAddress = tunnelConfig.config?.tunnel_address
          const tunnel = new ethers.Contract(tunnelAddress, TunnelABI, provider)

          const expectAmountIn = ethers.utils.parseUnits(
            new Decimal(ccpAmount)
              .div(nativePrice.price?.price_point || '33')
              .toFixed(5),
            selectedChain?.decimalPrice
          )
          const { amountOut } = await tunnel.getAmountOut(
            _tokenAddress,
            nativePrice.price?.router,
            nativePrice.price?.first_pair,
            nativePrice.price?.paths,
            expectAmountIn
          )
          const _tokenAmount = ethers.utils.formatUnits(
            amountOut,
            selectedToken.decimal
          )
          setIsTokenInputLoading(false)
          setTokenAmount(formatBeforeSet(_tokenAmount, 8))

          const _amountOutMin = new Decimal(amountOut.toString())
            .mul(100 - Number(slippage))
            .div(100)
            .toFixed(0)

          setAmountOutMin(ethers.utils.formatEther(_amountOutMin))
        } else {
          setIsAmountInputLoading(false)
          const _tokenAmount = new Decimal(Number(ccpAmount)).div(
            nativePrice.price?.price_point || 33
          )
          setTokenAmount(formatBeforeSet(_tokenAmount, 8))
        }
      } catch (error) {
        setIsAmountInputLoading(false)
        setTokenAmount('0')
        let msg: string = 'something was wrong'
        toast.error(msg)
        console.error('error', error)
      }
    }
    if (ccpAmount === '') {
      setIsTokenInputLoading(false)
      return setTokenAmount('')
    }
    if (targetInput === 'CCP_AMOUNT') {
      if (
        selectedToken &&
        nativePrice.price?.price_point &&
        selectedChain?.rpc !== '' &&
        (selectedToken.tunnel_method === 'convert' ||
          selectedToken.tunnel_method === 'convertForETH')
      ) {
        setIsTokenInputLoading(true)
        const delayDebounceFn = setTimeout(() => {
          calculateConvertValue(selectedToken.address)
        }, 700)
        return () => clearTimeout(delayDebounceFn)
      } else {
        calculateConvertValue()
      }
    }
  }, [ccpAmount, nativePrice, slippage])

  useEffect(() => {
    let queryStr = xxaQueryString.GetQueryString()
    if (queryStr.userAddress) {
      setUserAddress(queryStr.userAddress)
    }
  }, [])

  useEffect(() => {
    if (selectedNetworkWithdraw) {
      let intervalTask = setInterval(() => {
        getTokenPrice(selectedNetworkWithdraw.short_name)
      }, 60000)
      return () => clearInterval(intervalTask)
    }
  }, [selectedNetworkWithdraw])

  useEffect(() => {
    setIsAmountInputLoading(false)
    setIsTokenInputLoading(false)
    if (selectedChain && selectedChain.chainId > 0) {
      let intervalTask = setInterval(() => {
        getTokenPrice()
      }, 60000)
      return () => clearInterval(intervalTask)
    }
  }, [selectedToken])

  useEffect(() => {
    if (pointToCryptoConfirm.type === POINT_TO_CRYPTO_CONFIRM_SUCCESS) {
      setIsProcessConvert(false)
      toast.success(
        <div className="noti-message-txn">
          <div>Convert Success</div>
          <a
            role="button"
            rel="noreferrer"
            onClick={() => toPath('/point-to-crypto/history')}
          >
            See History
          </a>
        </div>
      )
      dispatch(xxaProfile.GetProfile())
      dispatch({ type: POINT_TO_CRYPTO_CONFIRM_CLEAR_MESSAGE })
      dispatch({ type: POINT_TO_CRYPTO_REQUEST_CLEAR_MESSAGE })
    } else if (pointToCryptoConfirm.type === POINT_TO_CRYPTO_CONFIRM_FAIL) {
      setIsProcessConvert(false)
      let msg: string = pointToCryptoConfirm.error?.msg || 'something was wrong'
      toast.error(msg)
      dispatch({ type: POINT_TO_CRYPTO_CONFIRM_CLEAR_MESSAGE })
      dispatch({ type: POINT_TO_CRYPTO_REQUEST_CLEAR_MESSAGE })
    }
  }, [pointToCryptoConfirm])

  useEffect(() => {
    if (tunnelConfig.type === TUNNEL_GET_CONFIG_SUCCESS) {
      if (tunnelConfig.config?.type === 'point_to_crypto') {
        setSelectTokenDefault()
      }
    }
  }, [tunnelConfig])

  useEffect(() => {
    if (platformChain.platformChain) {
      getChainFormLocalStorage()
    }
  }, [platformChain])

  useEffect(() => {
    if (selectedChain) {
      setUserAddress('')
      xxuURL.ChangeURL('chain_id', selectedChain.chainId.toString())
      dispatch(
        xxaTunnelService.GetTunnelConfig(selectedChain.chainId, tunnelType)
      )
      if (selectedChain.rpc !== '') {
        dispatch(xxaWeb3.ChangeWeb3Provider(selectedChain.rpc))
      }
      window.localStorage.setItem('chainID', selectedChain.chainId.toString())
    }
  }, [selectedChain])

  useEffect(() => {
    if (pointToCryptoRequest.type === POINT_TO_CRYPTO_REQUEST_SUCCESS) {
      setShowConfirmConvert(true)
    } else if (pointToCryptoRequest.type === POINT_TO_CRYPTO_REQUEST_FAIL) {
      let msg: string = pointToCryptoRequest.error?.msg || 'something was wrong'
      toast.error(msg)
      dispatch({ type: POINT_TO_CRYPTO_REQUEST_CLEAR_MESSAGE })
    } else {
      setShowConfirmConvert(false)
      setIsProcessConvert(false)
    }
  }, [pointToCryptoRequest])

  return (
    <CardCustom
      theme={
        darkMode.darkMode === 'dark'
          ? themeConfig.themeConfig?.dark
          : themeConfig.themeConfig?.light
      }
      xl={4}
      lg={5}
      md={8}
      sm={10}
      xs={11}
      className="card convert-container"
    >
      <Col lg={12} className="convert-form-header text-center">
        {/* {themeConfig.themeConfig?.config.cardTitle} */}
        CCP to Crypto
      </Col>
      <hr className="m-0 convert-form-divider" />
      <Col className="px-3 px-sm-4 pt-3 pb-4" lg={12}>
        <Form>
          <Form.Group className="mb-2">
            <div className="d-flex">
              <Col>
                <Form.Label className="d-flex align-items-center convert-container-user-box">
                  <div className="convert-container-user-reference">
                    {/* {themeConfig.themeConfig?.config.useRefTitle} */}
                    Token
                  </div>
                </Form.Label>
              </Col>
              <Col className="d-flex justify-content-end">
                {selectedChain !== null ? (
                  platformChain.platformChain.offChain.length > 1 ? (
                    <Button
                      onClick={openChainList}
                      className="d-flex btn-sm button button-address-wallet"
                    >
                      <ReactImageFallback
                        height="20px"
                        width="20px"
                        alt={`chain-binance`}
                        src={selectedChain?.imgChain}
                        fallbackImage={image_icon}
                        initialImage={image_icon}
                      />
                      <div>{selectedChain?.shortName}</div>
                    </Button>
                  ) : (
                    <div className="d-flex btn-sm button display-chain-select">
                      <ReactImageFallback
                        height="20px"
                        width="20px"
                        alt={`chain-binance`}
                        src={selectedChain?.imgChain}
                        fallbackImage={image_icon}
                        initialImage={image_icon}
                      />
                      <div>{selectedChain?.shortName}</div>
                    </div>
                  )
                ) : (
                  <SkeletonLoad
                    width={120}
                    height={32}
                    baseColor={
                      themeConfig.themeConfig?.[darkMode.darkMode].button
                        .addressButton.backgroundColor
                    }
                  />
                )}
              </Col>
            </div>
            <Dropdown className="select-network">
              <Dropdown.Toggle
                onClick={() => {
                  setOpenTokenList(!openTokenList)
                }}
                id="dropdown-custom-components"
              >
                {selectedToken ? (
                  <div className="d-flex align-items-center gap-2">
                    <ReactImageFallback
                      alt="ChomCHOB Point"
                      style={{ display: 'flex' }}
                      height="24px"
                      width="24px"
                      src={selectedToken.image}
                      fallbackImage={image_icon}
                      initialImage={image_icon}
                    />
                    {`${selectedToken.symbol} (${selectedToken.name})`}
                  </div>
                ) : null}
              </Dropdown.Toggle>
            </Dropdown>
          </Form.Group>
          <Form.Group className="mb-2">
            <div className="d-flex">
              <Col>
                <Form.Label className="d-flex align-items-center convert-container-user-box">
                  <div className="convert-container-user-reference">
                    {/* {themeConfig.themeConfig?.config.useRefTitle} */}
                    Wallet Address
                  </div>
                  <ReactImageFallback
                    alt="question icon"
                    className="convert-help-outline-icon"
                    role="button"
                    onClick={() => {
                      setOpenModalUserRef(true)
                    }}
                    src={question_icon}
                    fallbackImage={image_icon}
                    initialImage={image_icon}
                  />
                </Form.Label>
              </Col>
            </div>
            <InputGroup>
              <div className="text-on-input-address">
                <Button
                  disabled={isProcessConvert}
                  onClick={() => {
                    setShowAddressBook(true)
                  }}
                  className="btn-sm button button-address-book"
                >
                  <PersonIcon
                    width={20}
                    height={20}
                    fill={
                      themeConfig.themeConfig?.[darkMode.darkMode].button
                        .addressButton.fontColor
                    }
                  />
                </Button>
              </div>
              <Form.Control
                className="convert-input text-on-input text-on-input-text-start convert-text-left "
                disabled={isProcessConvert}
                onInput={onInputUserAddress}
                placeholder="ex. 0xa382...7a9f65"
                type="text"
                value={userAddress}
              />
            </InputGroup>
          </Form.Group>

          {selectedChain && selectedChain.chainId <= 0 ? (
            <BinanceOptions
              selectedNetworkWithdraw={selectedNetworkWithdraw}
              setSelectedNetworkWithdraw={setSelectedNetworkWithdraw}
              selectedToken={selectedToken}
              isProcessConvert={isProcessConvert}
              onInputMemo={onInputMemo}
              memoInput={memoInput}
            />
          ) : null}

          {selectedChain && selectedChain.chainId > 0 || selectedNetworkWithdraw ? (
            <>
              <Form.Group className="mt-3">
                <Row className="align-items-center">
                  <Col xs={4}>
                    <div className="d-flex align-items-center mb-2 mx-1">
                      <ReactImageFallback
                        alt="ChomCHOB Point"
                        style={{ display: 'flex' }}
                        height="24px"
                        width="24px"
                        src={themeConfig.themeConfig?.config.pointIcon}
                        fallbackImage={image_icon}
                        initialImage={image_icon}
                      />
                      <div className="convert-text-ccp">
                        {themeConfig.themeConfig?.config.pointUnit}
                      </div>
                    </div>
                  </Col>
                  <Col className="text-right" xs={8}>
                    <div className="d-flex justify-content-end align-items-center convert-balance-content">
                      <Form.Label
                        role="button"
                        onClick={isProcessConvert ? () => { } : setMaxOnCCPInput}
                        className="convert-balance-content-label"
                      >
                        Balance:{' '}
                        {Number(
                          new Decimal(ccpBalance).toFixed(6, Decimal.ROUND_DOWN)
                        ).toLocaleString('en-US', {
                          maximumFractionDigits: 2,
                          minimumFractionDigits: 2
                        })}
                      </Form.Label>
                    </div>
                  </Col>
                </Row>
                <InputGroup>
                  <div className="text-on-input-button">
                    <Button
                      disabled={isProcessConvert}
                      onClick={setMaxOnCCPInput}
                      className="button-outline btn-max"
                    >
                      Max
                    </Button>
                  </div>
                  <Form.Control
                    className="convert-input text-on-input text-on-input-text-end convert-text-right "
                    disabled={isProcessConvert || isAmountInputLoading}
                    onChange={onInputCCPAmount}
                    placeholder="0.0"
                    type="text"
                    value={!isAmountInputLoading ? ccpAmount : 'Loading...'}
                  />
                </InputGroup>
              </Form.Group>
              <Row className="mb-3 min-max-label">
                <Col xs={5}>
                  Min: <span>{formatCurrency(minCcpAmount)}</span>{' '}
                  {themeConfig.themeConfig?.config.pointUnit}
                </Col>
                <Col xs={7} className="convert-text-right">
                  Max: <span>{formatCurrency(maxCcpAmount)}</span>{' '}
                  {themeConfig.themeConfig?.config.pointUnit}
                </Col>
              </Row>
              <Form.Group className="mb-0">
                <Row>
                  <Col className="d-flex justify-content-center align-items-center text-center">
                    <div className="d-flex justify-content-center align-items-center convert-arrow-down">
                      <ConvertIcon
                        width={30}
                        height={30}
                        fill={
                          themeConfig.themeConfig?.[darkMode.darkMode]
                            .convertColor || '#ffffff'
                        }
                      />
                    </div>
                  </Col>
                </Row>
              </Form.Group>
              <Form.Group className="mb-3">
                <Row>
                  <Col xs={4}>
                    <div className="d-flex align-items-center mb-2">
                      <div className="d-flex align-items-center mx-1">
                        <ReactImageFallback
                          alt="ChomCHOB Point"
                          style={{ display: 'flex' }}
                          height="24px"
                          width="24px"
                          src={selectedToken?.image}
                          fallbackImage={image_icon}
                          initialImage={image_icon}
                        />
                        <div className="convert-text-ccp">
                          {selectedToken?.symbol}
                        </div>
                      </div>
                      <OverlayTrigger
                        trigger="click"
                        rootClose
                        overlay={props => (
                          <Tooltip id="ccp-info" {...props}>
                            This is just an estimate of cryptocurrencies. The
                            actual crypto maybe change because the fluctuation
                            of price, exchange condition and additional fee of
                            network.
                          </Tooltip>
                        )}
                        placement="right"
                      >
                        <img
                          alt="question icon"
                          // className="convert-help-outline-icon"
                          role="button"
                          src={question_icon}
                        />
                      </OverlayTrigger>
                      {/* <button type="button" className="btn btn-secondary" data-bs-container="body" data-bs-toggle="popover" data-bs-placement="right" data-bs-content="Right popover">
                   Popover on right
                 </button> */}
                    </div>
                  </Col>
                  <Col className="text-right" xs={8}>
                    <div className="d-flex justify-content-end align-items-center convert-balance-content">
                      {selectedToken?.is_display_fee_rate ? (
                        <Form.Label className="min-max-label">
                          Fee: {selectedToken?.fee_rate}%
                        </Form.Label>
                      ) : null}
                    </div>
                  </Col>
                </Row>
                <Form.Control
                  className="convert-input convert-text-right"
                  disabled={isProcessConvert || isTokenInputLoading}
                  onInput={onInputTokenAmount}
                  placeholder="0.0"
                  type="text"
                  value={!isTokenInputLoading ? tokenAmount : 'Loading...'}
                />
              </Form.Group>
              {selectedChain?.rpc !== '' &&
                (selectedToken?.tunnel_method === 'convert' ||
                  selectedToken?.tunnel_method === 'convertForETH') ? (
                <Form.Group className="mb-3">
                  <div className="advance-option-btn">
                    <div className="text-center convert-advance-option-text ">
                      Advance Options
                      <DownIcon
                        onClick={() => {
                          setOpenAdvanceOptions(!openAdvanceOptions)
                        }}
                        width={16}
                        height={16}
                        fill={
                          themeConfig.themeConfig?.[darkMode.darkMode]
                            .primaryColor || '#ffffff'
                        }
                      />
                    </div>
                  </div>
                  <Collapse in={openAdvanceOptions}>
                    <div>
                      <div className={`d-flex w-100 gap-2 mt-2`}>
                        <div className="w-100">
                          <Form.Group className="mb-3">
                            <Form.Label className="d-flex align-items-center advance-options-text">
                              Slippage
                            </Form.Label>
                            <InputGroup>
                              <Form.Control
                                onBlur={onBlurSlippage}
                                className="convert-input text-on-input text-on-input-text-start"
                                disabled={isProcessConvert}
                                onInput={onInputSlippage}
                                placeholder="0.0"
                                type="text"
                                value={slippage}
                              />
                              <InputGroup.Text className="text-on-input-text text-on-input-text-right">
                                %
                              </InputGroup.Text>
                            </InputGroup>
                          </Form.Group>
                        </div>
                      </div>
                    </div>
                  </Collapse>
                </Form.Group>
              ) : null}
              <Form.Group className="d-grid gap-2">
                <Button
                  className="button convert-button"
                  disabled={isProcessConvert}
                  onClick={onClickConvert}
                  variant="primary"
                >
                  {isProcessConvert && (
                    <span
                      aria-hidden="true"
                      className="spinner-border spinner-border-sm convert-margin-right"
                      role="status"
                    ></span>
                  )}
                  <b>CONVERT</b>
                </Button>
              </Form.Group>
            </>
          ) : null}
          <div className="mt-1 d-flex align-items-center w-100 justify-content-center">
            <a
              href={selectedChain?.howToUrl}
              className="custom-link custom-link-how-to"
              target="_blank"
              rel="noopener noreferrer"
            >
              How to use
            </a>
          </div>
        </Form>
        <TokenListModal
          show={openTokenList}
          onHide={() => setOpenTokenList(false)}
          setSelectedToken={setSelectedToken}
        />
        <ModalUserRef
          show={openModalUserRef}
          onHide={() => setOpenModalUserRef(false)}
          imgSrc={[selectedChain?.imgInfo || '']}
          height={400}
        />
        <ChainListModal
          show={showChainList}
          setChainSelect={setSelectedChain}
          onHide={() => {
            setShowChainList(false)
          }}
          type="point_to_crypto"
        />
        <AddressBookModal
          show={showAddressBook}
          onHide={() => {
            setShowAddressBook(false)
          }}
          setSelectedAddress={setUserAddress}
          selectedAddress={userAddress}
          selectedChain={selectedChain}
        />
        <ConfirmConvertModal
          show={showConfirmConvert}
          onHide={() => {
            setShowConfirmConvert(false)
            setIsProcessConvert(false)
            dispatch(xxaTunnelService.ClearPointToCryptoRequest())
          }}
          formData={{
            chainName: selectedChain?.chainName || '',
            userAddress: userAddress,
            tokenSymbol: selectedToken?.symbol || '',
            pointAmount: ccpAmount,
            tokenReceive: tokenAmount
          }}
          handleConfirmConvert={handleConfirmConvert}
        />
      </Col>
    </CardCustom>
  )
}

export default PointToCrypto

const CardCustom = styled(Col) <xxcNavBarTypes.NavBarCustom>`
  background-color: ${props => props.theme.cardColor};
  .placeholder-text {
    color: ${props => props.theme.input.placeholderTextColor} !important;
  }
  .min-max-label {
    color: ${props => props.theme.fontSecondaryColor};
  }
  .convert-balance-content-label {
    color: ${props => props.theme.primaryColor};
  }
  .mini-info-box {
    background-color: ${props => props.theme.input.inputBackgroundColor};
  }

  .advance-options-text {
    color: ${props => props.theme.primaryColor};
  }
  .custom-link {
    text-underline-offset: 0.5px;
    &:hover {
      color: ${props => props.theme.fontPrimaryColor};
      text-decoration: underline solid ${props => props.theme.fontPrimaryColor}
        1px;
    }
    &-how-to {
      color: ${props => props.theme.primaryColor};
      text-decoration: underline solid ${props => props.theme.primaryColor} 1px;
    }
  }
  .form-select {
    border: 2px solid ${props => props.theme.input.borderColor};
    background-color: ${props => props.theme.input.inputBackgroundColor};
    color: ${props => props.theme.input.inputTextColor};
    font-size: 20px;
    border-radius: 16px;
  }

  .select-network {
    .dropdown-toggle {
      min-height: 46px;
      display: block;
      width: 100%;
      padding: 0.375rem 0.75rem;
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.5;
      border: 2px solid ${props => props.theme.input.borderColor};
      background-color: ${props => props.theme.input.inputBackgroundColor};
      color: ${props => props.theme.input.inputTextColor};
      background-clip: padding-box;
      appearance: none;
      border-radius: 0.25rem;
      transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      border-width: 2px;
      border-radius: 16px;
      font-size: 20px;
      text-align: left;
      &:focus {
        box-shadow: unset;
        // border-color: ${props =>
    props.theme.input.inputFocusBackGroundColor};
        // background-color: ${props =>
    props.theme.input.inputFocusBackGroundColor}4d;
      }
      &::after {
        position: absolute;
        right: 12px;
        top: calc(50% - 3px);
      }
    }

    > .dropdown-menu {
      border-radius: 16px;
      font-size: 20px;
      width: 100%;
      background-color: ${props => props.theme.cardColor};
      > a {
        color: ${props => props.theme.input.inputTextColor};
      }
      .dropdown-item {
        &:hover {
          background-color: ${props => props.theme.navBar.navBarFontHoverColor};
        }
        &:focus {
          background-color: ${props => props.theme.navBar.navBarFontHoverColor};
        }
        &:active {
          color: ${props => props.theme.navBar.navBarFontActiveColor};
          background-color: unset;
        }
        &:focus-visible {
          outline: none;
        }
        &:first-child {
          border-top-left-radius: 4px;
          border-top-right-radius: 4px;
        }
        &:last-child {
          border-bottom-left-radius: 4px;
          border-bottom-right-radius: 4px;
        }
      }
      & .select-network-active {
        color: ${props => props.theme.navBar.navBarFontActiveColor};
      }
    }
  }

  .approve-button {
    background-color: ${props =>
    props.theme.button.approveButton.backgroundColor};
    border-color: ${props => props.theme.button.approveButton.backgroundColor};
    color: ${props => props.theme.button.approveButton.fontColor};
    &:hover {
      opacity: 0.65;
      color: ${props => props.theme.button.approveButton.fontColor};
    }
    &:active {
      opacity: 0.65;
      color: ${props => props.theme.button.approveButton.fontColor};
    }
    &:active:focus {
      box-shadow: 0 0 0 0.25rem
        ${props => props.theme.button.approveButton.backgroundColor}8c !important;
    }
  }
  .convert-button {
    background-color: ${props =>
    props.theme.button.convertButton.backgroundColor};
    border-color: ${props => props.theme.button.convertButton.backgroundColor};
    color: ${props => props.theme.button.convertButton.fontColor};
    &:hover {
      opacity: 0.65;
      color: ${props => props.theme.button.convertButton.fontColor};
    }
    &:active {
      opacity: 0.65;
      color: ${props => props.theme.button.convertButton.fontColor};
    }
    &:active:focus {
      box-shadow: 0 0 0 0.25rem
        ${props => props.theme.button.convertButton.backgroundColor}8c !important;
    }
  }

  .display-chain-select {
    padding: 0.5rem 0.5rem;
    height: 32px;
    font-size: 18px !important;
    align-items: center;
    border-color: ${props => props.theme.button.addressButton.backgroundColor};
    background-color: ${props =>
    props.theme.button.addressButton.backgroundColor};
    color: ${props => props.theme.button.addressButton.fontColor};
    > img {
      margin-right: 8px;
    }
  }

  .button-address-wallet {
    padding: 0.5rem 0.5rem;
    height: 32px;
    font-size: 18px !important;
    align-items: center;
    border-color: ${props => props.theme.button.addressButton.backgroundColor};
    background-color: ${props =>
    props.theme.button.addressButton.backgroundColor};
    color: ${props => props.theme.button.addressButton.fontColor};
    &:hover {
      opacity: 0.65;
    }
    &:active {
      opacity: 0.65;
    }
    &:active:focus {
      opacity: 1;
      box-shadow: 0 0 0 0.25rem
        ${props => props.theme.button.addressButton.backgroundColor}8c !important;
    }
  }

  .button-address-book {
    padding: 0.25rem;
    height: 32px;
    font-size: 18px !important;
    align-items: center;
    border-color: ${props => props.theme.button.addressButton.backgroundColor};
    background-color: ${props =>
    props.theme.button.addressButton.backgroundColor};
    color: ${props => props.theme.button.addressButton.fontColor};
    &:hover {
      opacity: 0.65;
    }
    &:active {
      opacity: 0.65;
    }
    &:active:focus {
      opacity: 1;
      box-shadow: 0 0 0 0.25rem
        ${props => props.theme.button.addressButton.backgroundColor}8c !important;
    }
  }

  .btn-max {
    border-radius: 10px !important;
    border-color: ${props => props.theme.button.maxButton.backgroundColor};
    color: ${props => props.theme.button.maxButton.backgroundColor};
    &:hover {
      background-color: ${props =>
    props.theme.button.maxButton.backgroundColor};
      border-color: ${props => props.theme.button.maxButton.backgroundColor};
      color: ${props => props.theme.button.maxButton.fontColor};
    }
    &:focus {
      background-color: ${props => props.theme.button.maxButton.fontColor};
      border-color: ${props => props.theme.button.maxButton.backgroundColor};
      color: ${props => props.theme.button.maxButton.backgroundColor};
    }
    &:active {
      background-color: ${props =>
    props.theme.button.maxButton.backgroundColor};
      border-color: ${props => props.theme.button.maxButton.backgroundColor};
      color: ${props => props.theme.button.maxButton.fontColor};
    }
    &:active:focus {
      box-shadow: 0 0 0 0.25rem
        ${props => props.theme.button.maxButton.backgroundColor}8c !important;
    }
    &:disabled {
      background-color: ${props =>
    props.theme.button.maxButton.backgroundColor};
      color: ${props => props.theme.button.maxButton.fontColor};
    }
  }
  @media (max-width: 540px) {
    .approve-button {
      &:hover {
        background-color: ${props =>
    props.theme.button.approveButton.backgroundColor};
        border-color: ${props =>
    props.theme.button.approveButton.backgroundColor};
        color: ${props => props.theme.button.approveButton.fontColor};
        opacity: unset;
      }
      &:active {
        box-shadow: 0 0 0 0.25rem
          ${props => props.theme.button.approveButton.backgroundColor}8c !important;
        opacity: 0.65;
        color: ${props => props.theme.button.approveButton.fontColor};
      }
      &:disabled {
        opacity: 0.65 !important;
      }
    }
    .button-address-wallet {
      &:hover {
        background-color: ${props =>
    props.theme.button.addressButton.backgroundColor};
        border-color: ${props =>
    props.theme.button.addressButton.backgroundColor};
        color: ${props => props.theme.button.addressButton.fontColor};
        opacity: unset;
      }
      &:active {
        box-shadow: 0 0 0 0.25rem
          ${props => props.theme.button.addressButton.backgroundColor}8c !important;
        opacity: 0.65;
        color: ${props => props.theme.button.addressButton.fontColor};
      }
      &:disabled {
        opacity: 0.65 !important;
      }
    }
    .button-address-book {
      &:hover {
        background-color: ${props =>
    props.theme.button.addressButton.backgroundColor};
        border-color: ${props =>
    props.theme.button.addressButton.backgroundColor};
        color: ${props => props.theme.button.addressButton.fontColor};
        opacity: unset;
      }
      &:active {
        box-shadow: 0 0 0 0.25rem
          ${props => props.theme.button.addressButton.backgroundColor}8c !important;
        opacity: 0.65;
        color: ${props => props.theme.button.addressButton.fontColor};
      }
      &:disabled {
        opacity: 0.65 !important;
      }
    }
    .convert-button {
      &:hover {
        background-color: ${props =>
    props.theme.button.convertButton.backgroundColor};
        border-color: ${props =>
    props.theme.button.convertButton.backgroundColor};
        color: ${props => props.theme.button.convertButton.fontColor};
        opacity: unset;
      }
      &:active {
        box-shadow: 0 0 0 0.25rem
          ${props => props.theme.button.convertButton.backgroundColor}8c !important;
        opacity: 0.65;
        color: ${props => props.theme.button.convertButton.fontColor};
      }
      &:disabled {
        opacity: 0.65 !important;
      }
    }
    .btn-max {
      &:hover {
        background-color: unset;
        border-color: ${props => props.theme.button.maxButton.backgroundColor};
        color: ${props => props.theme.button.maxButton.backgroundColor};
      }
      &:active {
        box-shadow: 0 0 0 0.25rem
          ${props => props.theme.button.maxButton.backgroundColor}8c !important;
        background-color: ${props =>
    props.theme.button.maxButton.backgroundColor};
        color: ${props => props.theme.button.maxButton.fontColor};
      }
    }
  }
`
