//components
import TableHistory from 'components/HistoryPage/TableHistory'

const BinanceToPointHistory = () => {
  return (
    <>
      <TableHistory type="binance_to_point" />
    </>
  )
}

export default BinanceToPointHistory
