import dayjs from 'dayjs'
import configStore from 'redux/Store'

// Redux Constants
import { GET_ANONYMOUS_FAIL } from 'redux/Constants/Auth/AnonymousToken'

// Service
import xxsAuthAnonymousTokenService from 'services/Auth/AnonymousToken'

const xxuAuth = {
  GetAnonymousToken: async (): Promise<string> => {
    const redux = configStore()
    try {
      const anonymousData = window.localStorage.getItem('a')
      let { token, token_expire_at } = anonymousData
        ? JSON.parse(anonymousData)
        : { token: '', token_expire_at: '' }
      if (
        !token ||
        !token_expire_at ||
        dayjs().isAfter(dayjs(token_expire_at).subtract(10, 'seconds'))
      ) {
        const response = await xxsAuthAnonymousTokenService.GetAnonymousToken()
        if (
          response.status === 200 &&
          response.anonymous?.token &&
          response.anonymous?.token_expire_at
        ) {
          token = response.anonymous.token
          token_expire_at = response.anonymous.token_expire_at

          window.localStorage.setItem('a', JSON.stringify(response.anonymous))
          window.localStorage.setItem('d', response.deviceId || '')
        } else {
          let data: any = {
            error: {
              err: true,
              status: response.status,
              data: {
                ...response.error
              },
              msg: response.error.msg
            }
          }
          redux.dispatch({
            type: GET_ANONYMOUS_FAIL,
            payload: data
          })
          throw response.error
        }
      }
      return token
    } catch (error) {
      throw error
    }
  }
}

export default xxuAuth
