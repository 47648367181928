// Components
import TranslateButton from "components/IntroductionPage/Header/TranslateButton";
import ConvertButton from "components/IntroductionPage/Header/ConvertButton";

// Icons
import { CCTunnelLogo } from 'components/IntroductionPage/Icons'

const Header = () => (
  <section className="introduction-container__header__container">
    <div className="header-container container">
      <CCTunnelLogo /> 
      <div className="header-container__button">
        <TranslateButton />
        <ConvertButton />
      </div>
    </div>
  </section>
)

export default Header
