import { Dispatch } from 'redux'

// Redux Constants
import {
  GET_LOGIN_CLEAR_MESSAGE,
  GET_LOGIN_FAIL,
  GET_LOGIN_SUCCESS
} from 'redux/Constants/Login/GetLogin'
import { GET_PROFILE_CLEAR_MESSAGE } from 'redux/Constants/Profile/Get'
import { GET_ADDRESS_WALLET_CLEAR_MESSAGES } from 'redux/Constants/Address'
import { POINT_TO_CRYPTO_HISTORY_CLEAR_MESSAGE } from 'redux/Constants/TunnelService'
import {
  VERIFY_LOGIN_CLEAR_MESSAGE,
  VERIFY_LOGIN_FAIL,
  VERIFY_LOGIN_SUCCESS
} from 'redux/Constants/Login/VerifyLogin'
import { LOGOUT_FAIL, LOGOUT_SUCCESS } from 'redux/Constants/Login/Logout'

//Services
import xxsLoginService from 'services/Login'
import xxsLoginServiceTypes from 'services/Login/types'

// Utils
import xxuResponse from 'utils/Response'
import xxaInitial from 'actions/Initial'

const xxaLogin = {
  GetLogin:
    (mobileNumber: string) =>
    async (
      dispatch: Dispatch
    ): Promise<xxsLoginServiceTypes.GetLoginSuccess | undefined> => {
      const response = await xxsLoginService.GetLogin(mobileNumber)
      if (response.status === 200) {
        xxuResponse.Success({
          type: GET_LOGIN_SUCCESS,
          dispatch,
          payload: response
        })
      } else {
        xxuResponse.Error({
          type: GET_LOGIN_FAIL,
          errorPage: true,
          dispatch,
          error: response
        })
      }
      return response.getLogin
    },
  ClearGetLogin: () => async (dispatch: Dispatch) => {
    dispatch({
      type: GET_LOGIN_CLEAR_MESSAGE
    })
  },
  GetVerify:
    (mobileNumber: string, otp: string, otpRefCode: string) =>
    async (
      dispatch: Dispatch
    ): Promise<xxsLoginServiceTypes.VerifyLoginSuccess | undefined> => {
      dispatch({
        type: VERIFY_LOGIN_CLEAR_MESSAGE
      })

      const response = await xxsLoginService.VerifyLogin(
        mobileNumber,
        otp,
        otpRefCode
      )
      if (response.status === 200) {
        if (response.verifyLogin) {
          window.localStorage.setItem('u', JSON.stringify(response.verifyLogin))
        }
        xxuResponse.Success({
          type: VERIFY_LOGIN_SUCCESS,
          dispatch,
          payload: response
        })
      } else {
        xxuResponse.Error({
          type: VERIFY_LOGIN_FAIL,
          errorPage: true,
          dispatch,
          error: response
        })
      }
      return response.verifyLogin
    },
  ClearGetVerify: () => async (dispatch: Dispatch) => {
    dispatch({
      type: VERIFY_LOGIN_CLEAR_MESSAGE
    })
  },
  GetAccessTokenFormCrossToken:
    (cross_token: string) =>
    async (
      dispatch: Dispatch
    ): Promise<xxsLoginServiceTypes.VerifyLoginSuccess | undefined> => {
      dispatch({
        type: VERIFY_LOGIN_CLEAR_MESSAGE
      })

      const response = await xxsLoginService.GetAccessTokenFormCrossToken(
        cross_token
      )
      if (response.status === 200) {
        if (response.verifyLogin) {
          window.localStorage.setItem('u', JSON.stringify(response.verifyLogin))
        }
        xxuResponse.Success({
          type: VERIFY_LOGIN_SUCCESS,
          dispatch,
          payload: response
        })
      } else {
        xxuResponse.Error({
          type: VERIFY_LOGIN_FAIL,
          errorPage: true,
          dispatch,
          error: response
        })
      }
      return response.verifyLogin
    },
  GetAccessTokenFromLocalStorage: (): {
    status: number
    token?: any
  } => {
    const rowToken = window.localStorage.getItem('u')
    const accessToken = rowToken ? JSON.parse(rowToken) : null
    if (accessToken) {
      return {
        status: 200,
        token: accessToken
      }
    } else {
      return {
        status: 403
      }
    }
  },
  Logout:
    () =>
    async (
      dispatch: Dispatch
    ): Promise<xxsLoginServiceTypes.LogoutSuccess | undefined> => {
      const refresh_token = await xxaInitial.GetRefreshTokenFormLocalStorage()
      if (refresh_token) {
        const response = await xxsLoginService.Logout(refresh_token)
        if (response.status === 200) {
          if (response.logout) {
            window.localStorage.removeItem('u')
          }
          xxuResponse.Success({
            type: LOGOUT_SUCCESS,
            dispatch,
            payload: response
          })
          dispatch({
            type: GET_LOGIN_CLEAR_MESSAGE
          })
          dispatch({
            type: VERIFY_LOGIN_CLEAR_MESSAGE
          })
          dispatch({
            type: GET_PROFILE_CLEAR_MESSAGE
          })
          dispatch({
            type: GET_ADDRESS_WALLET_CLEAR_MESSAGES
          })
          dispatch({
            type: POINT_TO_CRYPTO_HISTORY_CLEAR_MESSAGE
          })
        } else {
          xxuResponse.Error({
            type: LOGOUT_FAIL,
            errorPage: true,
            dispatch,
            error: response
          })
        }
        return response.logout
      } else {
        dispatch({
          type: GET_LOGIN_CLEAR_MESSAGE
        })
        dispatch({
          type: VERIFY_LOGIN_CLEAR_MESSAGE
        })
        dispatch({
          type: GET_PROFILE_CLEAR_MESSAGE
        })
      }
    }
}
export default xxaLogin
