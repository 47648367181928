const MoonIcon = ({
  fill,
  width,
  height,
  onClick
}: {
  fill: string
  width?: number
  height?: number
  onClick?: Function
}): JSX.Element => {
  return (
    <>
      <svg
        onClick={() => (onClick ? onClick() : () => {})}
        role={onClick ? 'button' : 'img'}
        width={width ?? 100}
        height={height ?? 100}
        xmlns="http://www.w3.org/2000/svg"
        aria-hidden="true"
        viewBox="0 0 16 16"
      >
        <path
          d="m4 6 4 4 4-4"
          fill="none"
          stroke={fill}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
      </svg>
    </>
  )
}

export default MoonIcon
