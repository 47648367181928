// Constants
import {
  GET_PLATFORM_CONFIG_CLEAR_MESSAGE,
  GET_PLATFORM_CONFIG_FAIL,
  GET_PLATFORM_CONFIG_SUCCESS
} from 'redux/Constants/Platform/Config'

// Props Types
import IAction from 'redux/Action/types'
import xxretPlatformConfigReducerTypes from 'redux/Reducers/Platform/Config/types'

const platformConfig = (
  state: object = {},
  action: IAction<xxretPlatformConfigReducerTypes.Props>
) => {
  switch (action.type) {
    case GET_PLATFORM_CONFIG_SUCCESS:
      return {
        ...state,
        ...action.payload,
        type: action.type
      }
    case GET_PLATFORM_CONFIG_FAIL:
      return {
        ...state,
        ...action.payload,
        type: action.type
      }
    case GET_PLATFORM_CONFIG_CLEAR_MESSAGE:
      return {}
    default:
      return state
  }
}

export default platformConfig
