export const ADD_ADDRESS_WALLET_SUCCESS = 'ADD_ADDRESS_WALLET_SUCCESS'
export const ADD_ADDRESS_WALLET_FAIL = 'ADD_ADDRESS_WALLET_FAIL'
export const ADD_ADDRESS_WALLET_CLEAR_MESSAGES =
  'ADD_ADDRESS_WALLET_CLEAR_MESSAGES'

export const GET_ADDRESS_WALLET_SUCCESS = 'GET_ADDRESS_WALLET_SUCCESS'
export const GET_ADDRESS_WALLET_FAIL = 'GET_ADDRESS_WALLET_FAIL'
export const GET_ADDRESS_WALLET_CLEAR_MESSAGES =
  'GET_ADDRESS_WALLET_CLEAR_MESSAGES'

export const DELETE_ADDRESS_WALLET_SUCCESS = 'DELETE_ADDRESS_WALLET_SUCCESS'
export const DELETE_ADDRESS_WALLET_FAIL = 'DELETE_ADDRESS_WALLET_FAIL'
export const DELETE_ADDRESS_WALLET_CLEAR_MESSAGES =
  'DELETE_ADDRESS_WALLET_CLEAR_MESSAGES'
