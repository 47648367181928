// import React from 'react'

function CopyIcon({
  fill,
  width,
  height,
  onClick
}: {
  fill: string | undefined
  width?: number
  height?: number
  onClick?: Function
}) {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        aria-hidden="true"
        onClick={() => (onClick ? onClick() : () => {})}
        role={onClick ? 'button' : 'img'}
        width={width ?? 50}
        height={height ?? 50}
        viewBox="0 0 24 24"
      >
        <path
          d="M7 6V3a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v14a1 1 0 0 1-1 1h-3v3l-1 1H4a1 1 0 0 1-1-1V7l1-1h3zM5 8v12h10V8H5zm4-2h8v10h2V4H9v2z"
          fill={fill}
        />
      </svg>
    </>
  )
}

export default CopyIcon
