import { useTranslation } from "react-i18next"

// Icons
import {
  FooterCCTunnelLogo
} from 'components/IntroductionPage/Icons'

import { socialButton } from 'components/IntroductionPage/Footer/data'

const Footer = () => {

  // Translation
  const { t } = useTranslation("introduction")

  return (
    <section className="introduction-container__footer__container ">
      <div className="footer__text-container container">
        <div className="footer__text">
          {t("footer")}
        </div>
      </div>
      
      <div className="footer__social-box container">
        <div className="footer__social">
          <div className="footer__social__logo">
            <FooterCCTunnelLogo />
          </div>
          <div className="footer__social__line" />
          <div className="footer__social__social-container">
            {socialButton?.map(item => (
              <li className="footer__social__link" key={`social-${item?.key}`}>
                <a href={item?.url} target="_blank">
                  {item?.icon}
                </a>
              </li>
            ))}
          </div>
        </div>
      </div>
    </section>
  )
}

export default Footer
