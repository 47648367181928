// Interfaces
import xxroRouterProps from 'routers/types'

// Views
import Convert from 'views/Convert'
import History from 'views/History'
import HistoryPointToCrypto from 'views/HistoryPointToCrypto'
import BinanceToPoint from 'views/BinanceToPoint'
import PointToCrypto from 'views/PointToCrypto'
import BinanceToPointHistory from 'views/BinanceToPointHistory'

const xxroTunnelRouter: xxroRouterProps.PropTypes[] = [
  // {
  //   path: '/',
  //   exact: true,
  //   isAuth: false,
  //   layout: 'main',
  //   component: Convert,
  // },
  {
    path: '/convert',
    exact: true,
    isAuth: false,
    layout: 'main',
    component: Convert
  },
  {
    path: '/point-to-crypto',
    exact: true,
    isAuth: false,
    layout: 'main',
    component: PointToCrypto
  },
  {
    path: '/history',
    exact: true,
    isAuth: false,
    layout: 'main',
    component: History
  },
  {
    path: '/point-to-crypto/history',
    exact: true,
    isAuth: false,
    layout: 'main',
    component: HistoryPointToCrypto
  },
  {
    path: '/binance-to-point',
    exact: true,
    isAuth: false,
    layout: 'main',
    component: BinanceToPoint
  },
  {
    path: '/binance-to-point/history',
    exact: true,
    isAuth: false,
    layout: 'main',
    component: BinanceToPointHistory
  }
]

export default xxroTunnelRouter
