import { Dispatch } from 'redux'

// Redux Constants
import {
  GET_PLATFORM_CONFIG_CLEAR_MESSAGE,
  GET_PLATFORM_CONFIG_FAIL,
  GET_PLATFORM_CONFIG_SUCCESS
} from 'redux/Constants/Platform/Config'
import {
  GET_PLATFORM_CHAIN_CLEAR_MESSAGE,
  GET_PLATFORM_CHAIN_SUCCESS
} from 'redux/Constants/Platform/Chain'
import xxcChainModalTypes from 'configs/Model'
import xxsPlatformServiceTypes from 'services/Platform/types'

//Services
import xxsPlatformService from 'services/Platform'
import xxuAuth from 'utils/Auth'

// Utils
import xxuResponse from 'utils/Response'

const xxaPlatform = {
  GetPlatformBySubdomain: () => {
    if (typeof window !== 'undefined') {
      const fullUrl: string = window.location.href
      let http = ''
      if (window.location.protocol === 'https:') {
        http = 'https://'
      } else {
        http = 'http://'
      }
      const parts: Array<string> = fullUrl.replace(http, '').split('.')
      let urlName: string = 'tunnel'
      if (parts.length > 1) {
        const platformName: string = parts[0].replace('dev-', '')
        if (
          platformName !== 'www' &&
          platformName !== 'chomdev1' &&
          platformName !== 'chomchob'
        ) {
          urlName = platformName
        } else {
          urlName = 'tunnel'
        }
      }
      return urlName
    } else {
      return ''
    }
  },
  GetPlatformConfig:
    () =>
    async (
      dispatch: Dispatch
    ): Promise<
      xxsPlatformServiceTypes.GetPlatformConfigResponseSuccess | undefined
    > => {
      dispatch({
        type: GET_PLATFORM_CONFIG_CLEAR_MESSAGE
      })
      const anonymousToken = await xxuAuth.GetAnonymousToken()

      const response = await xxsPlatformService.GetPlatformConfig(
        anonymousToken
      )
      if (response.status === 200) {
        xxuResponse.Success({
          type: GET_PLATFORM_CONFIG_SUCCESS,
          dispatch,
          payload: response
        })
      } else {
        xxuResponse.Error({
          type: GET_PLATFORM_CONFIG_FAIL,
          errorPage: true,
          dispatch,
          error: response
        })
      }
      return response.platformConfig
    },
  SetPlatformChain:
    (
      chainList: xxcChainModalTypes.chainType[],
      offChain: xxcChainModalTypes.chainType[]
    ) =>
    (dispatch: Dispatch) => {
      dispatch({
        type: GET_PLATFORM_CHAIN_CLEAR_MESSAGE
      })
      xxuResponse.Success({
        type: GET_PLATFORM_CHAIN_SUCCESS,
        dispatch,
        payload: { platformChain: { onChain: chainList, offChain: offChain } }
      })
    }
}

export default xxaPlatform
