const CloseIcon = () => (
  <svg
    aria-hidden="true"
    width={30}
    height={30}
    viewBox="0 0 1024 1024"
  >
    <path
      fill="#fff"
      d="M195 195a64 64 0 0 1 91 0l226 227 226-227a64 64 0 0 1 91 91L602 512l227 226a64 64 0 0 1-91 91L512 602 286 829a64 64 0 0 1-91-91l227-226-227-226a64 64 0 0 1 0-91z"
    />
  </svg>
)

export default CloseIcon
