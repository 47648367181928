import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import Dropdown from 'react-bootstrap/Dropdown'

// Icons
import {
  ThaiFlagIcon,
  EnglishFlagIcon
} from 'components/IntroductionPage/Icons'

// Data
import { languageData } from 'components/IntroductionPage/Header/TranslateButton/data'

const TranslateButton = () => {
  const { i18n } = useTranslation('introduction')

  const changeLanguage = lng => {
    document.documentElement.lang = lng
    i18n.changeLanguage(lng)
  }

  const initCurrentLanguage = () => {
    const currentLangFromStorage = window.localStorage.getItem('i18nextLng')
    if (currentLangFromStorage) {
      document.documentElement.lang = currentLangFromStorage
    } else {
      document.documentElement.lang = i18n.language
    }
  }

  useEffect(() => {
    initCurrentLanguage()
  }, [])

  return (
    <Dropdown className="header-container__button__translate-container">
      <Dropdown.Toggle className="language-dropdown">
        {window.localStorage.getItem('i18nextLng') === 'th' ? (
          <div className="language-container">
            <ThaiFlagIcon />
            <div className="language-container__label">
              TH
            </div>
          </div>
        ) : (
          <div className="language-container">
            <EnglishFlagIcon />
            <div className="language-container__label">
              EN
            </div>
          </div>
        )}
      </Dropdown.Toggle>

      <Dropdown.Menu className="language-dropdown-menu">
        {languageData &&
          languageData.map(item => (
            <Dropdown.Item
              key={item?.value}
              className="dropdown"
              id="dropdown"
              as="button"
              onClick={() => changeLanguage(item?.value)}
            >
              <div
                className={`language-container__dropdown${
                  window.localStorage.getItem('i18nextLng') === item?.value
                    ? '--active'
                    : ''
                }`}
              >
                {item?.flag_icon}
                <div className="language-container__label">
                  {item?.value}
                </div>
              </div>
            </Dropdown.Item>
          ))}
      </Dropdown.Menu>
    </Dropdown>
  )
}

export default TranslateButton
