// Interfaces
import xxroRouterProps from 'routers/types'

// Views
import Home from 'views/Home'
import Introduction from 'views/Introduction'

const xxroHomeRouter: xxroRouterProps.PropTypes[] = [
  { path: '/home', exact: true, isAuth: false, layout: 'full', component: Home },
  { path: '/', exact: true, isAuth: false, layout: 'full', component: Introduction }
]

export default xxroHomeRouter
