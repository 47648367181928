import { useSelector } from 'react-redux'
import { ToastContainer } from 'react-toastify'
import { useDarkMode } from 'hooks/useDarkMode'
import styled from 'styled-components'

// Types
import xxlMainLayoutProps from 'layouts/Main/types'
import xxreStoreTypes from 'redux/types'

// Components
import Navigation from 'components/NavBar'

const MainLayout = ({ children }: xxlMainLayoutProps.PropTypes) => {
  const themeConfig = useSelector(
    (state: xxreStoreTypes.Props) => state.themeConfig
  )

  const [themeMode, setToggleTheme] = useDarkMode()

  let darkMode = window.localStorage.getItem('theme')

  return (
    <App
      className="App"
      theme={
        darkMode === 'dark'
          ? themeConfig.themeConfig?.dark
          : themeConfig.themeConfig?.light
      }
    >
      <Navigation setToggleTheme={setToggleTheme}></Navigation>
      <div className="container">
        <div className="row">
          {/* <NotiBox /> */}
          <ToastContainer
            autoClose={5000}
            closeOnClick
            draggable
            hideProgressBar={false}
            newestOnTop={false}
            pauseOnFocusLoss
            pauseOnHover
            position="top-right"
            rtl={false}
            // style={toastContainerStyle}
            toastClassName="noti-toast"
          />
          <div className="app-container justify-content-center d-flex flex-column align-items-center">
            {children}
          </div>
        </div>
      </div>
    </App>
  )
}

export default MainLayout

const App = styled.div<xxlMainLayoutProps.App>`
  background: ${props => props.theme.background};
  color: ${props => props.theme.fontPrimaryColor};

  .form-control {
    border: 2px solid ${props => props.theme.input.borderColor};
    background-color: ${props => props.theme.input.inputBackgroundColor};
    color: ${props => props.theme.input.inputTextColor};
  }
  .form-control::placeholder {
    color: ${props => props.theme.input.placeholderTextColor} !important;
    opacity: 1;
  }
  .form-control:focus {
    border-color: ${props => props.theme.input.inputFocusBackGroundColor};
    background-color: ${props => props.theme.input.inputFocusBackGroundColor}4d;
  }
  .text-on-input-text {
    color: ${props => props.theme.input.inputTextColor};
  }
`
